import { ProductSearchOptions } from './ProductSearchOptions';

export class AppUser {
  constructor(
    public id?: string,
    public userName?: string,
    public defaultLanguage?: string,
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public customerId?: string,
    public newUser?: boolean,
    public currentClaim?: string,
    public showImages: boolean = true,
    public showGroupReference?: boolean,
    public frontEndLanguage?: string,
    public showToolTips: boolean = true,
    public showProToolTips?: boolean,
    public viewPort: boolean = true,
    public showPlaceHolders: boolean = true,
    public featureProfiMode: boolean = false,
    public lastLogin?: Date,
    public lastNewsRead?: Date,
    public productSearchOptions?: ProductSearchOptions,    
    public productSearchOptionsTarget?: ProductSearchOptions,
  ) {
  }
  deserialize(input: any) {
    Object.assign(this, input);

    //fuer jeden komplexen Datentypen notwendig (!)
    //this.userToCustomerList = this.userToCustomerList.map((utc: UserToCustomer) => new UserToCustomer().deserialize(utc));

    return this;
  }
}
