export class Customer {
  constructor(
    public id?: string,
    public name?: string,
    public street?: string,
    public zip?: string,
    public city?: string,
    public phone?: string,
    public country?: string,
    public password?: string,
    public defaultLanguage?: string,
    public isTestAccount?: boolean,
    public accountValidDate?: Date,
    public help?: { title: string; content: string }[],
    public pimLicense?: string,
    public accountType?: string,   
    public isPublic?: boolean,
    public sharedValidationMethodId?: string,
    public linkedCustomerId?: string,
    public sharedCatalogId?: string
  ) {
  }
}


