<div *ngIf="isNoLayoutPage === false" class="container-fluid h-100 w-100 px-0 d-flex flex-column overflow-hidden">
  <dx-toolbar id="toolbar" height="68" class="applicationbar light">
    <dxi-item *ngIf="canChangePassword" widget="dxButton" [options]="menuButtonOptions" location="before"></dxi-item>
    <dxi-item location="before" widget="dxButton" locateInMenu="never">
      <div *dxTemplate>
        <a [routerLink]="['/catalogs']">
          <img alt="nextPim Pim System" width="121" height="55" src="/images/nextpim.svg" style="margin-left: 6px" />
        </a>
      </div>
    </dxi-item>
    <dxi-item location="center" widget="dxButton" locateInMenu="never" *ngIf="currentNavigationTitle">
      <div *dxTemplate>
        <div class="d-flex flex-row justify-content-center">
          <div>{{ currentAccount }}</div>
        </div>
        <div class="d-flex flex-row justify-content-center">
          <div>
            <h6>{{ currentNavigationTitle }}</h6>
          </div>
        </div>
      </div>
    </dxi-item>
    <dxi-item *ngIf="isJobRunning" widget="dxButton" [options]="warningButtonOptions" location="after">
      <div *dxTemplate>
        <div class="dx-icon dx-icon-warning text-danger" (mouseenter)="ttt()" (mouseleave)="ttt()" id="tt22"></div>
        <dx-tooltip target="#tt22" class="toolTip" [(visible)]="isJobWarningVisible">
          <div *dxTemplate="let data = data; of: 'content'">
            {{ 'Es läuft gerade ein Job zum aktuell ausgewählten Katalog' | translate }}
          </div>
        </dx-tooltip>
      </div>
    </dxi-item>
    <dxi-item *ngIf="canView('jobs')" id="jobs" location="after">
      <div *dxTemplate>
        <dx-button id="jobsToolTip" icon="runner" (onClick)="showJobs()" hint="Jobs"></dx-button>
        <div *ngIf="jobbadgevisible">
          <span class="badge badge-job" [ngClass]="jobbadgeclass">0</span>
        </div>
      </div>
    </dxi-item>

    <dxi-item *ngIf="canView('jobs')" widget="dxButton" [(options)]="newsButtonOptions" location="after">
      <div *dxTemplate>
        <dx-button icon="email" (onClick)="showNews()"></dx-button>
        <div *ngIf="hasNewNews && canView('newsBadge')">
          <span class="badge">0</span>
        </div>
      </div>
    </dxi-item>

    <dxi-item widget="dxDropDownButton" location="after">
      <div *dxTemplate="let toolbaritem of 'helpButtonTemplate'">
        <np-help-button>
          <np-help-option
            text="Wiki"
            icon="images/Wiki.svg"
            link="https://knowledge.nextpim.de/?utm_source=app.nextpim.de&utm_medium=affiliate&utm_campaign=wikilink"
          ></np-help-option>
          <np-help-option
            text="YouTube"
            icon="images/YouTube.svg"
            link="https://www.youtube.com/playlist?list=PLrMcDu_lCAZyWl8MclUg40YoWnWXl4Lcr"
          ></np-help-option>
        </np-help-button>
      </div>
    </dxi-item>

    <dxi-item *ngIf="canChangePassword" widget="dxButton" [options]="accountButtonOptions" location="after"></dxi-item>
  </dx-toolbar>

  <dx-drawer
    class="flex-fill drawer dx-theme-accent-as-background-color"
    template="template"
    [opened]="isOpened()"
    [minSize]="canChangePassword ? 52 : 0"
    [maxSize]="300"
    revealMode="expand"
    openedStateMode="shrink"
  >
    <div
      *dxTemplate="let data of 'template'"
      style="width: 300px; height: 100%"
      (mouseenter)="drawerMouseEnter($event)"
      (mouseleave)="drawerMouseLeave($event)"
    >
      <dx-list
        class="navigation flex-fill"
        [dataSource]="navigation"
        [hoverStateEnabled]="false"
        [activeStateEnabled]="false"
        [focusStateEnabled]="false"
        selectionMode="single"
        [selectedItems]="selectedNavigationItems"
      >
        <div *dxTemplate="let item of 'item'">
          <a [routerLink]="['/' + item.path]" title="{{ item.text | translate }}">
            <div class="d-flex flex-row">
              <span style="width: 40px; display: inline-flex; align-items: center" class="{{ item.icon }}"></span>
              <span style="display: inline-flex; align-items: center">{{ item.text | translate }}</span>
            </div>
          </a>
        </div>
      </dx-list>
    </div>
    <div id="content" class="h-100 p-0 dx-theme-background-color"><router-outlet></router-outlet></div>
  </dx-drawer>
</div>

<!--
<div class="accountMenu">
  <a id="accountToolTip" (click)="showAccount()" title="Account: {{account}}">
    <div>
      <span class="loggedin">{{ 'Angemeldet als' | translate }}:<br /></span>
      {{userName}}<br />
      ({{account}})
    </div>
  </a>
</div>
<div class="jobsMenu">
  <a id="jobsToolTip" *ngIf="canChangePassword" (click)="showJobs()">
    {{ 'Jobs' | translate }} <img src="/images/ikon_Arrow_down.png" class="arrowDown" />
  </a>
</div>
-->

<dx-popover
  target="#jobsToolTip"
  position="bottom"
  [showTitle]="true"
  [width]="'75%'"
  title="{{ 'Hintergrund Jobs' | translate }}"
  [showCloseButton]="true"
  [(visible)]="jobsVisible"
>
  <div *dxTemplate="let data of 'content'">
    <job-list
      [storeName]="'JobListStateNewUI_V4_JOBLIST'"
      [jobListMode]="true"
      [catalogMode]="false"
      [adminMode]="false"
    >
      <!-- always choose ONE mode!! -->
    </job-list>
  </div>
</dx-popover>

<dx-popover
  target="#accountToolTip"
  class="accountMenuPopover"
  id="account"
  position="bottom"
  [width]="210"
  [showTitle]="false"
  [showCloseButton]="false"
  [hideOnOutsideClick]="true"
  (onHiding)="hideAccountMenu()"
  [(visible)]="accountMenuVisible"
>
  <div *dxTemplate="let data of 'content'">
    <a (click)="openSupportPage()" class="menuButton">
      <span class="glyphicon glyphicon-envelope"></span>
      {{ 'Support' | translate }}
    </a>
    <a
      [routerLink]="['/Account/myAccount']"
      (click)="hideAccountMenu()"
      title="Account: {{ account }}"
      class="menuButton"
    >
      <span class="glyphicon glyphicon-user"></span>
      {{ 'Account' | translate }}
    </a>
    <a [routerLink]="['/Account/changePassword']" (click)="hideAccountMenu()" class="menuButton">
      <span class="glyphicon glyphicon-lock"></span>
      {{ 'Passwort ändern' | translate }}
    </a>
    <a (click)="logout()" class="menuButton">
      <span class="glyphicon glyphicon-off"></span>
      {{ 'Logout' | translate }}
    </a>
    <a *ngIf="isAdmin()" [routerLink]="['/admin']" (click)="hideAccountMenu()" class="menuButton">
      <span class="glyphicon glyphicon-cog"></span>
      {{ 'ADMIN' | translate }}
    </a>
    <a
      href="https://www.youtube.com/channel/UCCLj1jMNXqZhrU_xVUHULEA"
      target="_blank"
      (click)="hideAccountMenu()"
      class="menuButton"
    >
      <span class="glyphicon glyphicon-facetime-video"></span>
      {{ 'Videos' | translate }}
    </a>
  </div>
</dx-popover>

<template-editor-dialog
  *ngIf="templateService.importTemplateEditorVisible"
  [(visible)]="templateService.importTemplateEditorVisible"
  [showSelector]="false"
  [exportMode]="false"
></template-editor-dialog>
