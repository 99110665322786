export class OxomiConnection {

  constructor(
    public id?: number,
    public customerId?: string,
    public portalId?: string,
    public apiToken?: string,
    public secret?: boolean,
    public user?: string	
  ) {
  }
}