import { Component, OnInit } from "@angular/core";
import { ModelService } from "../../../Services/model.service";
import { TranslateService } from '@ngx-translate/core';
import { Login } from "../../../Model/User/Login";

@Component({
  selector: "admin-logins",
  templateUrl: "admin-logins.component.html"
})
export class AdminLoginsComponent implements OnInit {
  logins: Login[];

  constructor(private modelService: ModelService, private translate: TranslateService) {
    this.reloadLogins = this.reloadLogins.bind(this);
    //this.getLastCustomerName = this.getLastCustomerName.bind(this);
  }

  ngOnInit(): void {
    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(["/"]);
      return;
    }
    if (!this.modelService.loginService.isAdmin) {
      this.modelService.router.navigate(["/"]);
      return;
    }

    this.getLogins();
  }
  onGridToolbarPreparing(e) {

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'refreshButtonTemplate'
    });
  }

/*  getLastCustomerName(rowData) {
    let appUser = rowData as AppUser;
    let lastUserToCustomer = appUser ? appUser : null;


*//*    this.modelService.loginService.customerService.getCustomer(appUser.customerId).subscribe((result: Customer) => {
      return result.name;
    });*//*

    //TODO Zugriff auf translate nicht moeglich, da "this" hier der Scope der DataGrid-Spalte ist
    *//*return lastUserToCustomer ? lastUserToCustomer.name : "customer not found";*//*
  }*/

/*  get logins(): AppUser[] {
    if (this._logins == null) {
      this.getLogins();
    }

    return this._logins;
  }

  set logins(value: AppUser[]) {
    this._logins = value;
  }*/

  getLogins(): void {
    this.modelService.loginService.customerService.getLogins().subscribe((result: Login[]) => {
      this.logins = result;
    });

/*    this.modelService.adminService.getAllUsersWithCustomers().subscribe((appUser: AppUser[]) => {
      //nochmal die konkreten Objekte instanziieren,
      //weil der Rueckgabetyp aus dem http - Client Object und trotz Parameter nicht typisiert ist
      this.logins = appUser.map((au: AppUser) => new AppUser().deserialize(au));
    });*/
  }

  reloadLogins(): void {
    this.getLogins();
  }
}
