export class ProductLogistic {

  constructor(
    public depth?: number,
    public length?: number,
    public weight?: number,
    public width?: number,
    public countryOfOrigin?: string,
    public customsNumber?: string,
    public specialTreatmentClass?: string,
    public specialTreatmentClassType ?: string,
    public hazardousMaterialClassId?: string,
    public warrantyClassId?: string,
  ) {

  }
}
