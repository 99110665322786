
import { Component, ViewChild } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";

import { DxFileUploaderComponent } from "devextreme-angular";
import { Catalog } from "../../../../Model/Catalog/Catalog";
import { ModelService } from "../../../../Services/model.service";

@Component({
  selector: "importfeatureMappingProfil",
  templateUrl: "featureMappingProfil.component.html"
})
export class FeatureMappingProfilComponent {
  @ViewChild(DxFileUploaderComponent, { static: true })
  dxfileuploader: DxFileUploaderComponent;

  value: any[] = [];
  model: Catalog[];
  uploadHeaders: any;

  constructor(private modelService: ModelService, private oAuthService: OAuthService) {
    this.onBeforeSendUpload = this.onBeforeSendUpload.bind(this);

    this.uploadHeaders = {
      CustomerId: this.modelService.loginService.currentUser.customerId,
      MappingProfileId: this.modelService.featureMappingService.selectedMappingId,
    }
  }

  onUploaded() {
    this.dxfileuploader.instance.reset();
  }

  onBeforeSendUpload(e: { request: XMLHttpRequest }) {
    e.request.withCredentials = true;
    e.request.setRequestHeader('Authorization', 'Bearer ' + this.oAuthService.getAccessToken());
  }
}
