import { Component } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { UserActionBaseComponent } from "../userAction-base.component";
import { Customer } from "../../../../Model/User/Customer";
import { UpdateTemplate } from '../../../../Model/Catalog/UpdateTemplate';
import { TranslateService } from '@ngx-translate/core';
import { duplicateItem } from '../../../../Model/Dto/DublicateItem';


@Component({
  selector: "userAction-spreadCatalog",
  templateUrl: "userAction-spreadCatalog.component.html"
})
export class UserActionSpreadCatalogComponent extends UserActionBaseComponent {

  _customers: Customer[];


  _sourceCatalog: string = "";
  selectedCustomers: string[];
  updateTemplates: UpdateTemplate[];


  txtSelectCatalog: string;

  constructor(public modelService: ModelService, public translate: TranslateService) {
    super(modelService);
  }

  ngOnInit(): void {
    super.ngOnInit(); //important



    this.modelService.loginService.customerService.getAllCustomerByUser(this.modelService.loginService.currentUser.id).subscribe((customers: Customer[]) => {
      this.customers = new Array<Customer>();
      customers.forEach((customer: Customer) => {
        if (customer.id != this.modelService.loginService.currentCustomer.id) {
          this.customers.push(customer);
        }
      });
    });
  }


  editTemplatesClick() {
    this.modelService.router.navigate(["updateTemplates"]);
  }



  get customers(): Customer[] {
    return this._customers;
  }

  set customers(value: Customer[]) {
    this._customers = value;
  }



  get sourceCatalog(): string {
    return this._sourceCatalog;
  }

  set sourceCatalog(sourceCatalog: string) {
    this._sourceCatalog = sourceCatalog;
  }

  startMacro() {
    if (this.selectedCustomers.length == 0) {
      this.modelService.systemService.notifyInfo(this.txtSelectCatalog);
      return;
    }

    this.selectedCustomers.forEach((customerId: string) => {
      this.modelService.catalogService.showUserAction = false;
      let duplicateCatalog = new duplicateItem();
      duplicateCatalog.sourceCustomerId = this.modelService.loginService.currentUser.customerId;
      duplicateCatalog.targetCustomerId = customerId;
      duplicateCatalog.sourceItemId = this.modelService.catalogService.selectedCatalogId;
      this.modelService.catalogService.spreadCatalog(duplicateCatalog);
    });
    this.userActionStarted.emit(true);
  }
}
