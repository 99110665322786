import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CatalogFilter } from '../../../../../../Model/exportParameter/CatalogFilter';
import { ExportParameter } from '../../../../../../Model/exportParameter/ExportParameter';
import { ExportFilterService } from '../../../../../../Services/exportFilter.service';
import { ModelService } from '../../../../../../Services/model.service';

@Component({
  selector: 'manufacturerFilter',
  templateUrl: './manufacturerFilter.component.html',
  styleUrls: ['./manufacturerFilter.component.css']
})
export class ManufacturerFilterComponent implements OnInit {
  @Input() exportParameter: ExportParameter;
  @Input() catalogId: string;

  manufacturerFilters: { name: string }[] = [];
  isFilterLoaded: boolean = false;                 

  constructor(public modelService: ModelService, public translate: TranslateService, public exportFilterService: ExportFilterService) {
    this.refreshManufacturersFilter = this.refreshManufacturersFilter.bind(this);
  }

  ngOnInit(): void {
    if (this.exportParameter.manufacturerFilter == null)
      this.exportParameter.manufacturerFilter = new CatalogFilter<string[]>([]);
    if (this.exportParameter.manufacturerFilter.filterKey == null)
      this.exportParameter.manufacturerFilter.filterKey = [];

    this.loadmanufacturerIfNeeded();
  }

  loadmanufacturerIfNeeded() {
    if (this.exportParameter.manufacturerFilter.useFilter)
      this.refreshManufacturersFilter();
  }

  refreshManufacturersFilter() {
    this.isFilterLoaded = false;
    this.exportFilterService.getManufacturerFilters(this.catalogId, this.modelService.loginService.currentUser.customerId)
      .subscribe((filter: string[]) => {
        this.manufacturerFilters = filter.map(x => { return { name: x} });     
        this.isFilterLoaded = true;
      });
  }

}
