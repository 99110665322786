import { Input, Output, EventEmitter, Component } from "@angular/core";
import { Product } from '../../Model/Catalog/Product';
import { FieldNames } from "../../Model/FieldNames";
import { ExcelElement } from "../../Model/ui/ExcelElement";
import { ModelService } from "../../Services/model.service";


@Component({
  selector: "misc",
  templateUrl: "misc.html",
  styleUrls: ["./misc.css", "template.css"]
})
export class Misc {
  @Input() model: Product = null;
  @Input() set excelModel(value: ExcelElement[]) {
    if (value != null)
      this.refreshUpdateModes(value);
  }

  @Output() onValueChanged = new EventEmitter<any>();

  @Input() templateView: Boolean = false;
  @Input() selectedElements: string[] = [""];
  @Input() exportMode: Boolean = false;
  @Output() elementSelected = new EventEmitter<string>();

  @Input() showDragHandle: boolean = false;
  @Input() dragFilter: string = "noDrag";

  @Output() excelElementDropped = new EventEmitter<any>();

  fieldNames: FieldNames;

  constructor(public modelService: ModelService) {
    this.fieldNames = new FieldNames();
  }

  update(event = null) {
    this.onValueChanged.emit(event);
  }

  public elementSelectFunction(event) {
    this.elementSelected.emit(event);
  }

  public excelElementDrop(e) {
    this.excelElementDropped.emit(e);
  }

  selectItemsND = ["NORMAL", "DELETE"];
  selectItemsNDI = ["NORMAL", "DELETE", "IGNORE_EMPTY_FIELDS"];

  public mimeUpdateMode: string = "NORMAL";
  public featureUpdateMode: string = "NORMAL";
  public priceUpdateMode: string = "NORMAL";
  public supplierUpdateMode: string = "NORMAL";
  public categoryUpdateMode: string = "NORMAL";
  public statusUpdateMode: string = "NORMAL";
  public udxUpdateMode: string = "NORMAL";

  private refreshUpdateModes(value: ExcelElement[]) {
    value.forEach(element => {
      if (element != undefined && element.pimFields != undefined) {
        element.pimFields.forEach(x => {
          switch (x.field) {
            case this.fieldNames.mimeUpdateMode:
              this.mimeUpdateMode = element.defaultValue;
              break;
            case this.fieldNames.featureUpdateMode:
              this.featureUpdateMode = element.defaultValue;
              break;
            case this.fieldNames.priceUpdateMode:
              this.priceUpdateMode = element.defaultValue;
              break;
            case this.fieldNames.udxUpdateMode:
              this.udxUpdateMode = element.defaultValue;
              break;
            case this.fieldNames.supplierUpdateMode:
              this.supplierUpdateMode = element.defaultValue;
              break;
            case this.fieldNames.statusUpdateMode:
              this.statusUpdateMode = element.defaultValue;
              break;
          }
        })
      }
    });
  }

  public onUpdateModeChanged(e, updateMode: string) {
    if (this.exportMode || !e|| e.value == e.previousValue || !e.event)
      return;

    e.dField = updateMode;
    e.dSystem = "-1";
    e.dElement = "-1";
    e.value = e.value;
    e.event = updateMode;
    e.createTemplateItem = true;

    this.onValueChanged.emit(e);
  }
}
