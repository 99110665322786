import { Input, Output, EventEmitter, Component } from "@angular/core";
import { UdxField } from "../../Model/Catalog/UdxField";
import { TranslateService } from '@ngx-translate/core';
import { custom } from 'devextreme/ui/dialog';
import { ModelService } from '../../Services/model.service';
import { Message } from '../../Model/System/Message';
import { Product } from "../../Model/Catalog/Product";
import { TemplateService } from "../../Services/template.service";
import { UserManagementService } from "../../Services/userManagment.service";
import { ValidationService } from "../../Services/Validation/validation.service";

@Component({
  selector: "udx",
  templateUrl: "udx.html",
  styleUrls: ["./udx.css"]
})
export class UDX {
  @Input() model: UdxField[] = [];
  @Input() product: Product = null;
  @Input() showLinkButtons: Boolean = false;

  @Output() onValueChanged = new EventEmitter<any>();

  @Input() templateView: Boolean = false;

  @Input() selectedElements: string[] = [""];
  @Output() elementSelected = new EventEmitter<string>();

  @Input() showDragHandle: boolean = false;
  @Input() dragFilter: string = "noDrag";

  @Output() excelElementDropped = new EventEmitter<any>();
  @Output() elementDeleted = new EventEmitter<any>();

  constructor(public templateService: TemplateService, public translate: TranslateService, public modelService: ModelService, public userManagement: UserManagementService, public validationService: ValidationService) { }

  onToolbarPreparing(e) {

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'addButtonTemplate',
    });

  }

  customizeColumns(columns) {
    columns.forEach(column => {
      column.showEditorAlways = true;
    });
  }

  isVirtualCatalog(): boolean {
    return this.modelService?.catalogService?.catalog?.isVirtual;
  }

  update(event = null, field?: string, system: string = "-1", element: string = "-1") {
    // für den template editor erforderlich:
    if (event) {
      event.dField = field;
      event.dSystem = system;
      event.dElement = element;
      event.createTemplateItem = true;
    }

    this.onValueChanged.emit(event);
  }

  addUDX() {
    var udx = new UdxField();
    var udxOrder = 0;
    for (let ud of this.model) {
      if (ud.order > udxOrder) {
        udxOrder = ud.order;
      }
    }
    udxOrder += 1;
    udx.order = udxOrder;
    this.model.push(udx);
    this.update();
    console.log(this.model);
  }

  delete(event = null) {
    let product = this.modelService.catalogService.product;
    let orderString = event.data.order.toString();

    // Prüfen, ob irgend ein Feld dieser Udx gelinked ist
    for (let udxField of this.templateService.allUdxFields) {
      if (product != null && product.isFieldLinked(udxField, orderString, "-1")) {
        product.toggleLinkedField(udxField, orderString, "-1");
      }
    }

    this.onValueChanged.emit(event);

    // für den template editor erforderlich:
    event.dFields = this.templateService.allUdxFields;
    event.dDetailFields = null;
    event.dSystem = orderString;
    event.dElement = "-1";
    this.elementDeleted.emit(event);
  }



  public onRowRemoving(e) {
    let product = this.modelService.catalogService.product;
    let orderString = e.data.order.toString();

    if (product != null && product.isChild && this.hasUdxFieldLinked(orderString)) {
      // Löschen nicht erlaubt, INFO

      let txtNotAllowed = "";
      this.translate.get("DeleteLinkedFieldNotAllowed").subscribe((text: string) => {
        txtNotAllowed = text;
      });

      this.modelService.systemService.notify(new Message(txtNotAllowed, "info"), 3000);
      e.cancel = true;
      return;
    }






    // Löschen erlaubt, Benutzerabfrage, ggf. Abbruch
    let txtYes = "";
    let txtNo = "";
    let txtMsg = "";
    let txtTitle = "";
    let myDialog: any;

    this.translate.get("Ja").subscribe((text: string) => {
      txtYes = text;
    });
    this.translate.get("Nein").subscribe((text: string) => {
      txtNo = text;
    });
    this.translate.get("Wollen Sie dieses UDX wirklich löschen").subscribe((text: string) => {
      txtMsg = text;
      if (!txtMsg.endsWith("?")) {  // Übersetzungen sid nicht einheitlich gepflegt, werden aber auch an anderen Stellen so verwendet.
        txtMsg = txtMsg + "?";
      }
    });
    this.translate.get("Wirklich löschen?").subscribe((text: string) => {
      txtTitle = text;
    });

    myDialog = custom({
      title: txtTitle,
      messageHtml: txtMsg,
      buttons: [
        {
          text: txtYes,
          onClick: (e) => { return false; }
        },
        {
          text: txtNo,
          onClick: (e) => { return true; }
        }
      ]
    });

    // Hier wird ein Promise zurück geliefert. Dies enthält den Return Value des (erst später!!)
    // ausgeführten onClick-Handlers des gedrückten Buttons im Dialog.
    // https://supportcenter.devexpress.com/ticket/details/t978828/datagrid-how-to-cancel-the-rowremoving-event
    e.cancel = myDialog.show();
  }



  templateSelect(identifier: string, orderString: string) {
    this.elementSelected.emit(`${identifier}_${orderString}`);
  }

  public excelElementDrop(e) {
    this.excelElementDropped.emit(e);
  }



  public hasUdxFieldLinked(system?: string): boolean {
    let product = this.modelService.catalogService.product;
    // Prüfen, ob irgend ein Feld dieser Udx gelinked ist
    for (let udxField of this.templateService.allUdxFields) {
      if (product != null && product.isFieldLinked(udxField, system, "-1")) {
        return true;
      }
    }
    return false;
  }






  public readOnlyForLinkedFields(field: string, system: string = "-1", element: string = "-1"): boolean {
    let product = this.modelService.catalogService.product;
    // Wenn dies ein vom Master vererbtes Feld ist, soll es ReadOnly sein:
    if (product != null && field != null) {
      var isLinked = product.isFieldLinked(field, system, element);
      if (isLinked && product.isChild != null && product.isChild) { // Für einen Master darf es nicht ReadOnly werden!!
        return true;
      }
    }

    return false;
  }

  public hasMasterOrChildOrIsTranslated(): boolean {
    let product = this.modelService.catalogService.product;
    if (product != null) {
      return product.hasMasterOrChild();
    }
    return false;
  }

  public isFieldLinked(field: string, system: string = "-1", element: string = "-1"): boolean {
    let product = this.modelService.catalogService.product;
    if (product != null) {
      return product.isFieldLinked(field, system, element);
    }
    return false;
  }

  public toggleLinkedField(field: string, system: string = "-1", element: string = "-1", event = null) {
    let product = this.modelService.catalogService.product;
    var changed = product.toggleLinkedField(field, system, element);
    if (!changed) {
      return;
    }



    var isNowLinked = this.isFieldLinked(field, system, element);
    event.target.className = product.getClassForLinkedField(isNowLinked);

    if (product.isChild && isNowLinked) {
      event.forceReload = true;
    }



    // Etwas schmutzig, aber sonst können wir den Refresh am Tree nicht antriggern.
    //event.forceTreeRefresh = true;



    // SAVE
    this.update(event);                                                                                     //, true);
  }

  public getInitialClassForLinkedField(field: string, system: string = "-1", element: string = "-1"): string {
    let product = this.modelService.catalogService.product;
    var isNowLinked = this.isFieldLinked(field, system, element);
    return product.getClassForLinkedField(isNowLinked);
  }



  canView(name: string): boolean {
    // Wenn product == null befinden wir uns im Katalog oder einer Kategorie.
    return (this.product == null || !this.isVirtualCatalog()) && this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    // Wenn product == null befinden wir uns im Katalog oder einer Kategorie.
    return (this.product != null && this.isVirtualCatalog()) || !this.userManagement.canEdit(name);
  }
}
