<div class="dx-fieldset" style="max-width: 600px;">

  <div class="dx-field">
      <div class="dx-field-label jobListTD">{{ 'Portal-ID' | translate }}</div>
      <div class="dx-field-value">
      <dx-text-box [(value)]="modelOxomi.portalId" [showClearButton]="true" (onValueChanged)="update()"></dx-text-box>
      </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label jobListTD">{{ 'User' | translate }}</div>
    <div class="dx-field-value">
    <dx-text-box [(value)]="modelOxomi.user" [showClearButton]="true" (onValueChanged)="update()"></dx-text-box>
    </div>
</div>

  <div class="dx-field">
    <div class="dx-field-label jobListTD">{{ 'API-Token' | translate }}</div>
    <div class="dx-field-value">
      <dx-text-box [(value)]="modelOxomi.apiToken" [showClearButton]="true" (onValueChanged)="update()"></dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label jobListTD">{{ 'Secret' | translate }}</div>
    <div class="dx-field-value">
      <dx-text-box [(value)]="modelOxomi.secret" [showClearButton]="true" (onValueChanged)="update()"></dx-text-box>
    </div>
  </div>
  
  <dx-toolbar>
    <dxi-item *ngIf="!isTested" location="before">
      <div *dxTemplate>
        <dx-button text="{{'Verbindung testen' | translate}}" stylingMode="text" type="default" (onClick)="testConnection()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item *ngIf="isTested" location="before">
      <div *dxTemplate>
        <dx-button text="{{'Verbindung testen' | translate}}" stylingMode="text" type="default" icon="check" (onClick)="testConnection()"></dx-button>
      </div>
    </dxi-item>
  </dx-toolbar>
  
  <div style="color: red;">
    {{connectionTestMessage}}
  </div>  
  
</div>
  
  
  
  
  
  
  
  
  