import { Component, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NewsArticle } from "../Model/news/newsArticle";
import { ModelService } from "../Services/model.service";
import { NewsService } from "../Services/news.service";
import CustomStore from 'devextreme/data/custom_store';
import { lastValueFrom } from "rxjs";



@Component({
  selector: "news",
  templateUrl: "news.component.html",
  styleUrls: ["news.component.css"]
})
export class NewsComponent implements OnDestroy {
  public articles: NewsArticle[] = [];
  public showBadges: boolean = false;
  public lastRead: Date;
  dataSource: any = {};

  constructor(public modelService: ModelService, public translate: TranslateService, public newsService: NewsService) {

    this.refreshArticles = this.refreshArticles.bind(this);
    this.checkNews = this.checkNews.bind(this);
    this.needsBadge = this.needsBadge.bind(this);


    this.dataSource.store = new CustomStore({
      key: "id",
      load: function (loadOptions: any) {
        return newsService.getSection(loadOptions.skip || 0, loadOptions.take || 20, modelService.loginService.isAdmin).toPromise()
          .then((data: any) => {
            let articles: NewsArticle[] = data;
            return articles;
          });
      },

      insert: function (values: NewsArticle) {
        values.id = 0;
        return newsService.update(values).toPromise()
          .then((data: any) => {
            let articles: NewsArticle[] = data;
            newsService.checkNewNews();
            return articles;
          });
      },

      update: async function (key: any, values: NewsArticle | NewsArticle[]) {
        const article = await lastValueFrom(newsService.getArticles(key));
        const value = Array.isArray(values) ? values[0] : values;
        article.headLine = value.headLine || article.headLine;
        article.content = value.content || article.content;
        article.active = value.active != undefined ? value.active: article.active;
        if (value.releaseDate != undefined)
          article.releaseDate = new Date(value.releaseDate);

        const updatedArticle = await lastValueFrom(newsService.update(article));
        newsService.checkNewNews();
        return updatedArticle;
      },

      remove: function (key) {
        return lastValueFrom(newsService.delete(key)).then(() => {
          newsService.checkNewNews(); 
        });
      }
    });

    /*this.lastRead = new Date(Date.parse(this.modelService.loginService.currentUser.lastNewsRead));*/


    this.lastRead = this.modelService.loginService.lastNewRead;
    this.modelService.loginService.lastNewRead = new Date();

    this.checkNews();
  }
  ngOnDestroy(): void {

  }

  public isAdmin() {
    return this.modelService.loginService.isAdmin;
  }

  needsBadge(data) {
    if (data == undefined || this.lastRead == undefined)
      return false;

    let date = Date.parse(data.data.releaseDate);

    if (date > this.lastRead.getTime() && data.data.active)
      return true;

    return false;
  }

  checkNews() {
    this.newsService.getNewNewsAmount(this.lastRead).subscribe((amount: number) => {
      if (amount > 0)
        this.showBadges = true;
    }, e => {
      console.log("error checking news");
    });
  }

  refreshArticles() {
    var amount = this.articles.length <= 0 ? 50 : this.articles.length;
    this.articles = [];
    this.newsService.getSection(0, amount, this.modelService.loginService.isAdmin).subscribe((e: NewsArticle[]) => {
      this.articles.push(...e);
    });
  }

  groupByDate(rowData) {
    let currentCreatedDate = rowData.releaseDate;

    let groupingCreatedDate = new Date(currentCreatedDate);
    groupingCreatedDate.setHours(0, 0, 0, 0);
    groupingCreatedDate.setDate(1);

    let result = groupingCreatedDate;
    //let month = result.toLocaleString('default', { month: 'long' });
    return result; /*result.getMonth() + 1 + "." + result.getFullYear()*/ /*month+ " " + result.getFullYear()*/
  }

  calculateSortValue(rowData) {
    let d: Date = new Date(rowData.releaseDate);
    return d.getTime();
  }

  /*  customizeDateText(cellInfo) {
      let d: Date = new Date(cellInfo.value);
      return d.toLocaleString('default', { month: 'long' }) + " " + d.getFullYear();
    }*/

  /*  createArticle(article: NewsArticle) {
      article.id = 0;
      this.newsService.update(article).subscribe((result: NewsArticle) => {
        this.modelService.systemService.notify(new Message("Article created"));
      }, () => {
        this.modelService.systemService.notify(new Message("Error while createing article", "error"));
      });
    }
  
    saveArticle(article: NewsArticle) {
      this.newsService.update(article).subscribe((result: NewsArticle) => {
        this.modelService.systemService.notify(new Message("Article saved"));
      }, () => {
        this.modelService.systemService.notify(new Message("Error while saveing article", "error"));
      });
    }
  
    deleteArticle(id: number) {
      this.newsService.delete(id).subscribe((result: NewsArticle) => {
        this.modelService.systemService.notify(new Message("Article delete"));
      }, () => {
        this.modelService.systemService.notify(new Message("Error while deleteing article", "error"));
      });
    }
  
    onSaved(e) {
      const that = this;
      e.changes.forEach(function (value) {
        switch (value.type) {
          case "remove":
            that.deleteArticle(value.key);
            break;
          case "update":
            that.saveArticle(value.data);
            break;
          case "insert":
            that.createArticle(value.data);
            break;
        }
      });
    }*/



}
