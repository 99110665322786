import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OxomiConnection } from 'app/Model/Dto/Oxomi/OxomiConnection';
import { TestResult } from 'app/Model/Dto/TestResult';
import { Message } from 'app/Model/System/Message';
import { LoginService } from 'app/Services/login.service';
import { ModelService } from 'app/Services/model.service';
import { OxomiConnectionService } from 'app/Services/oxomiConnection.service';
import { SystemService } from 'app/Services/system.service';

@Component({
  selector: 'np-oxomi-accessdata',
  templateUrl: './oxomi-accessdata.component.html',
  styleUrls: ['./oxomi-accessdata.component.css']
})
export class OxomiAccessdataComponent implements OnInit {
  isTested:boolean = false;
  connectionTestMessage:string = "";
  modelOxomi: OxomiConnection = new OxomiConnection();

  constructor(public oxomiConnectionService: OxomiConnectionService, public translate: TranslateService, public loginService: LoginService, public systemService: SystemService, public router: Router) {     
    this.testConnection = this.testConnection.bind(this);
  }

  async ngOnInit() {
    this.modelOxomi = await this.oxomiConnectionService.getOxomiConnection(this.loginService.currentCustomer.id);
    if (this.loginService.currentUser == null) {
      this.router.navigate(["/"]);
      return;
    }
  }
  
  update(): void {
    this.oxomiConnectionService.update(this.modelOxomi).then(
      (result: OxomiConnection) => {
        this.modelOxomi.id = result.id;
        this.isTested = false;
      });
  }

  testConnection() {    
    this.oxomiConnectionService.testConnection(this.modelOxomi.id, this.modelOxomi.customerId).then((result: TestResult) => this.connectionTestResult(result));
  }

  connectionTestResult(result: TestResult): void {
    if (result.success) {
      this.isTested = true;
      this.connectionTestMessage = "";
    } else {
      this.connectionTestMessage = result.message;
      this.isTested = false;
      let msg = new Message();
      msg.type = "error";
      msg.message = this.connectionTestMessage;
      this.systemService.notify(msg);
    }
  }

  get showPlaceHolders() {
    return this.loginService.showPlaceHolders;
  }

}
