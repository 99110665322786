<div
  *ngIf="product"
  id="container"
  class="d-flex w-100 h-100 overflow-hidden {{ exportMode ? 'flex-row' : 'flex-row-reverse' }}"
>
  <div
    class="d-flex flex-column align-self-stretch align-items-stretch {{
      template?.isAdvancedTemplate ? 'w-100' : 'w-75'
    }} h-100"
  >
    <div *ngIf="showSelector" class="w-100 h-25">
      <div *ngIf="showSelector" class="p-2">
        <templateSelector (templateSelected)="templateSelected($event)"></templateSelector>
      </div>
      <div
        *ngIf="templateService.template?.isAdvancedTemplate && templateService.template?.templateType !== 'SAP_ARIBA'"
        class="dx-fieldset"
      >
        <div class="dx-field">
          <div class="dx-field-label">{{ 'Füge Header Informationen ein' | translate }}:</div>
          <div class="dx-field-value">
            <dx-switch
              [value]="modelService.catalogService.useMetadata"
              (onValueChanged)="switchMetadataVisibility()"
            ></dx-switch>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 h-100 d-flex justify-content-center" *ngIf="template?.isAdvancedTemplate">
      <dx-button
        class="mt-5"
        stylingMode="outlined"
        [routerLink]="['/templates/expert', customerId, template.id, catalog.id]"
      >
        {{ 'Experten Template bearbeiten' | translate }}
      </dx-button>
    </div>

    <div *ngIf="!template?.isAdvancedTemplate" class="w-100 {{ showSelector ? 'h-75' : 'h-100' }}">
      <dx-scroll-view class="w-100 h-100" reachBottomText="" showScrollbar="always">
        <div class="flex-fill">
          <templateProduct
            class="h-100"
            [model]="product"
            [(selectedElements)]="selectedElements"
            [exportMode]="exportMode"
            [showDragHandle]="exportMode"
            (elementDeleted)="elementDelete($event)"
            (elementSelected)="elementSelectFunction($event)"
            (excelElementDropped)="excelElementDrop($event)"
            (onValueChanged)="addDefaultElement($event)"
            [excelModel]="excelModel"
          ></templateProduct>
        </div>
      </dx-scroll-view>
    </div>
  </div>

  <div
    *ngIf="!template?.isAdvancedTemplate"
    class="d-flex flex-column align-self-stretch align-items-stretch w-25 h-100"
  >
    <div *ngIf="exportMode" class="w-100 h-25">
      <div class="w-100 h-100 d-flex flex-row">
        <div class="w-50 h-100 px-2 py-5">
          <dx-sortable
            class="d-flex justify-content-center align-items-center dropZone"
            style="height: 100%"
            group="dragDropGroup"
            dropFeedbackMode="indicate"
            (onAdd)="onDropAdd($event)"
          >
            <i class="m-auto dx-icon-add bigIcon"></i>
          </dx-sortable>
        </div>

        <div class="w-50 h-100 px-2 py-5">
          <dx-sortable
            class="d-flex justify-content-center align-items-center dropZone"
            style="height: 100%"
            group="dragDropGroup"
            dropFeedbackMode="indicate"
            (onAdd)="onDropRemove($event)"
          >
            <i class="m-auto dx-icon-trash bigIcon"></i>
          </dx-sortable>
        </div>
      </div>
    </div>
    <div class="w-100 {{ exportMode ? 'h-75' : 'h-100' }}">
      <dx-scroll-view class="w-100 h-100" reachBottomText="" showScrollbar="always">
        <div class="flex-fill">
          <templateExcel
            class="h-100"
            [(excelModel)]="excelModel"
            [exportMode]="exportMode"
            [(selectedElements)]="selectedElements"
            (updateValue)="updateTemplate($event)"
            (removeTemplate)="clearItem($event)"
            (reorderModel)="reorderModel($event)"
            (replaceItem)="replaceItem($event)"
            (rowSelected)="excelRowSelected($event)"
          ></templateExcel>
        </div>
      </dx-scroll-view>
    </div>
  </div>

  <dx-load-panel [visible]="isVisible" [showIndicator]="true" [showPane]="true" [shading]="true"></dx-load-panel>
</div>
