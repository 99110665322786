import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { lastValueFrom, Observable } from "rxjs";
import { ValidationRequest } from '../../Model/validation/ValidationRequest';
import { ValidationRequestCatalog } from '../../Model/validation/ValidationRequestCatalog';
import { FieldNames, fieldNames } from '../../Model/FieldNames';
import { ModelService } from './../model.service';
import { TranslateService } from '@ngx-translate/core';
import { Product } from '../../Model/Catalog/Product';
import { ValidationResult } from '../../Model/validation/ValidationResult';
import { Catalog } from '../../Model/Catalog/Catalog';
import { ValidationMethod } from "../../Model/validation/ValidationMethod";
import { StringResponse } from "../../Model/Dto/StringResponse";
import { ValidationField } from "../../Model/validation/ValidationField";


@Injectable()
export class ValidationService {
  public fieldNames: FieldNames;
  public validationResult: ValidationResult;
  public validateFieldRecord: Record<string, (params) => any> = {};

  constructor(private http: HttpClient, public modelService: ModelService, public translate: TranslateService) {
    this.validationResult = new ValidationResult();
    this.validationResult.validateNumbers = new Array<number>();
    this.validationResult.validateNumbers.push(0);
    this.validationResult.validateNumbers.push(0);
    this.validationResult.validateNumbers.push(0);
    this.validationResult.validateNumbers.push(0);
    this.validationResult.validateNumbers.push(0);
    this.fieldNames = fieldNames;
    this.validate = this.validate.bind(this);
    this.validateField = this.validateField.bind(this);
  }

  getValidationMethod(id: string): Observable<ValidationMethod> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("methodId", id)
    }
    return (this.http.get<ValidationMethod>("api/validation/GetValidationMethod", options)) as any;
  }

  getValidationMethodsForEditor(): Observable<ValidationMethod[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams()
    }
    return (this.http.get<ValidationMethod[]>("api/validation/GetValidationMethodsForEditor", options)) as any;
  }

  getValidationMethodsForCatalog(): Observable<ValidationMethod[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams()
    }
    return (this.http.get<ValidationMethod[]>("api/validation/GetValidationMethodsForCatalog", options)) as any;
  }

  getSharedValidationMethodsCreatedByCustomer(): Promise<ValidationMethod[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams()
    }
    return lastValueFrom((this.http.get<ValidationMethod[]>("api/validation/GetSharedValidationMethodsCreatedByCustomer", options)) as Observable<ValidationMethod[]>);
  }

  deleteValidationMethod(model: ValidationMethod): Observable<StringResponse> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      body: model
    }
    return this.http.delete<StringResponse>("api/validation/DeleteValidationMethod", options);
  }

  createValidationMethod(model: ValidationMethod): Observable<ValidationMethod> {
    return this.http.post<ValidationMethod>("api/validation/CreateValidationMethod", model);
  }

  updateValidationMethod(model: ValidationMethod): Observable<StringResponse> {
    return this.http.post<StringResponse>("api/validation/UpdateValidationMethod", model);
  }

  validationMethodChanged(model: ValidationMethod): Observable<boolean> {
    return this.http.post<boolean>("api/validation/ValidationMethodChanged", model);
  }

  getDataQualityAreas(): Observable<string[]> {
    return this.http.get<string[]>("api/validation/GetDataQualityAreas");
  }

  getValidationFieldGroups(): Observable<string[]> {
    return this.http.get<string[]>("api/validation/GetValidationFieldGroups");
  }

  getValidationFields(): Observable<ValidationField[]> {
    return this.http.get<ValidationField[]>("api/validation/GetValidationFields");
  }

  createValidationFieldIdentifier(fieldName: string, systemKey = "-1", elementKey = "-1") {
    return fieldName + "|" + systemKey + "|" + elementKey;
  }

  validateNumberField(fieldName: string, systemKey = "-1", elementKey = "-1"){
    var validationFieldIdentifier = this.createValidationFieldIdentifier(fieldName, systemKey, elementKey);
    if (this.validateFieldRecord[validationFieldIdentifier]) {
      return this.validateFieldRecord[validationFieldIdentifier];
    }
    return this.validateFieldRecord[validationFieldIdentifier] = (params) => {
      var splittedIdentifier = validationFieldIdentifier.split("|");
      if (splittedIdentifier[0] == this.fieldNames.fName && params.value == "temp id") {
        return Promise.resolve(true);
      }
      if (typeof params.value === "string") {
        params.value = params.value.replace(',', '.');
        params.rule.value = params.rule.value.replace(',', '.');
      }

      const priceFields = new Map([
        [this.fieldNames.priceAmount, 'priceAmount'], 
        [this.fieldNames.tax, 'tax'], 
        [this.fieldNames.lowerBound, 'lowerBound'], 
        [this.fieldNames.priceFactor, 'priceFactor']
      ]);

      if (priceFields.has(fieldName)) {
        const prop = priceFields.get(fieldName);
        const product = this.modelService.catalogService.product;
        const detail = product.priceLists[parseInt(systemKey) - 1].productPriceDetails[parseInt(elementKey) - 1];
        detail[prop] = parseFloat(detail[prop].toString().replace(',', '.'));
      }

      const productLogisticsFields = new Map([
        [this.fieldNames.depth, 'depth'], 
        [this.fieldNames.lenght, 'length'], 
        [this.fieldNames.weight, 'weight'], 
        [this.fieldNames.width, 'width']
      ]);

      if (productLogisticsFields.has(fieldName)) {
        const prop = productLogisticsFields.get(fieldName);
        const product = this.modelService.catalogService.product;
        const detail = product.productLogistic[prop];
        product.productLogistic[prop] = parseFloat(detail[prop].toString().replace(',', '.'));
      }

      const orderDetailsFields = new Map([
        [this.fieldNames.priceQuantity, 'priceQuantity'], 
        [this.fieldNames.quanityInterval, 'quanityInterval'], 
        [this.fieldNames.quantityMax, 'quantityMax'], 
        [this.fieldNames.quantityMin, 'quantityMin'],
        [this.fieldNames.noCuPerOu, 'noCuPerOu'],
      ]);

      if (orderDetailsFields.has(fieldName)) {
        const prop = orderDetailsFields.get(fieldName);
        const product = this.modelService.catalogService.product;
        const detail = product.orderDetail[prop];
        product.orderDetail[prop] = parseFloat(detail[prop].toString().replace(',', '.'));
      }

      return this.validate(params, splittedIdentifier[0], splittedIdentifier[1], splittedIdentifier[2]);
    };
  }

  validateField(fieldName: string, systemKey = "-1", elementKey = "-1") {
    var validationFieldIdentifier = this.createValidationFieldIdentifier(fieldName, systemKey, elementKey);
    if (this.validateFieldRecord[validationFieldIdentifier]) {
      return this.validateFieldRecord[validationFieldIdentifier];
    }
    return this.validateFieldRecord[validationFieldIdentifier] = (params) => {
      var splittedIdentifier = validationFieldIdentifier.split("|");
      if (splittedIdentifier[0] == this.fieldNames.fName && params.value == "temp id") {
        return Promise.resolve(true);
      }
      return this.validate(params, splittedIdentifier[0], splittedIdentifier[1], splittedIdentifier[2]);
    };
  }

  async validate(params, field: string, systemKey = "-1", elementKey = "-1") {
    if (!this.modelService.catalogService.catalog)
      return true;

    let validationRequest = new ValidationRequest();
    validationRequest.methodId = this.modelService.catalogService.catalog.validationMethodId;
    validationRequest.product = this.modelService.catalogService.product;
    validationRequest.field = field;
    validationRequest.systemKey = systemKey;
    validationRequest.elementKey = elementKey;
    validationRequest.isBooleanRadioGroup = false;
    validationRequest.dataQualityFacts = this.modelService.catalogService.catalog.dataQualityFacts;

    var response = await lastValueFrom(this.http.post("api/validation/validate", validationRequest));
    let isValid = response["isValid"];
    let message = response["message"];
    if (message && message != "") {
      message = this.translate.instant(message);
    }
    if (this.modelService.catalogService.product) {
      this.modelService.catalogService.product.prepareView();
    }
    params.rule.message = message;
    params.rule.isValid = isValid;
    return isValid;
  }

  async validateBooleanRadioGroup(params, field: string) {
    if (!this.modelService.catalogService.catalog)
      return true;

    let validationRequest = new ValidationRequest();
    validationRequest.methodId = this.modelService.catalogService.catalog.validationMethodId;
    validationRequest.product = this.modelService.catalogService.product;
    validationRequest.field = field;
    validationRequest.isBooleanRadioGroup = true;
    validationRequest.booleanRadioGroupValue = params.value;
    validationRequest.dataQualityFacts = this.modelService.catalogService.catalog.dataQualityFacts;

    var response = await lastValueFrom(this.http.post("api/validation/validate", validationRequest));
    params.rule.isValid = response["isValid"];
    params.rule.message = response["message"];
    if (params.rule.message && params.rule.message != "") {
      params.rule.message = this.translate.instant(params.rule.message);
    }
    if (this.modelService.catalogService.product) {
      this.modelService.catalogService.product.prepareView();
    }
    return params.rule.isValid;
  }

  validateCatalogId(params: any, validationRequest: ValidationRequestCatalog) {
    return this.http.post("api/validation/validateCatalog", validationRequest).subscribe(response => {
      params.rule.isValid = response["isValid"];
      params.rule.message = response["message"];
      if (params.rule.message && params.rule.message != "") {
        params.rule.message = this.translate.instant(params.rule.message);
      }
      params.validator.validate();

      return params.rule.isValid;
    });

  }

  validateProduct(product: Product) {
    this.validateProductPromise(product).then(() => { });
  }

  validateProductPromise(product: Product) {
    let that = this;
    return new Promise(function (resolve, reject) {
      if (!that.modelService.catalogService.catalog)
        that.modelService.catalogService.catalog = new Catalog();

      let validationRequest = new ValidationRequest();
      validationRequest.methodId = that.modelService.catalogService.catalog.validationMethodId;
      validationRequest.product = product;
      validationRequest.dataQualityFacts = that.modelService.catalogService.catalog.dataQualityFacts;
      that.http.post("api/validation/validateProduct", validationRequest).subscribe((response: ValidationResult) => {
        that.validationResult = response;
        that.validationResult.validateNumbers = new Array<number>();
        that.validationResult.validateNumbers.push(response.validateNumbers0);
        that.validationResult.validateNumbers.push(response.validateNumbers1);
        that.validationResult.validateNumbers.push(response.validateNumbers2);
        that.validationResult.validateNumbers.push(response.validateNumbers3);
        that.validationResult.validateNumbers.push(response.validateNumbers4);
        resolve(true);
      },
        error => { reject(error); }
      );
    });
  }

}
