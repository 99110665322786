import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DxTreeListComponent } from 'devextreme-angular';
import { confirm, custom } from 'devextreme/ui/dialog';
import Validator from 'devextreme/ui/validator';
import { Observable } from 'rxjs';
import { Catalog } from '../../../Model/Catalog/Catalog';
import { ProductState } from '../../../Model/Catalog/ProductState';
import { AddItem } from '../../../Model/Dto/AddItem';
import { CatalogItem } from '../../../Model/Dto/CombineCatalog/CatalogItem';
import { TransferCatalogItem } from '../../../Model/Dto/CombineCatalog/TransferCatalogItem';
import { TransferItemModes } from '../../../Model/Dto/CombineCatalog/TransferItemModes';
import { StringResponse } from '../../../Model/Dto/StringResponse';
import { TreeItem } from '../../../Model/Dto/TreeItem';
import { UrlResponse } from '../../../Model/Dto/UrlResponse';
import { Message } from '../../../Model/System/Message';
import { Customer } from '../../../Model/User/Customer';
import { CatalogService } from '../../../Services/CatalogManagement/catalog.service';
import { CombineCatalogService } from '../../../Services/CatalogManagement/combineCatalog.service';
import { ModelService } from '../../../Services/model.service';

@Component({
  selector: 'combineCatalogs',
  templateUrl: 'combineCatalogs.component.html',
  styleUrls: ['combineCatalogs.component.css']
})
export class CombineCatalogsComponent implements OnInit {
  txtAddCategory: string;
  txtDeleteCategory: string;
  txtDeleteProd: string;
  txtLoading: string;
  txtNoCategoriesOrCatalogInProd: string;
  txtNoProdInChild: string;
  txtNoMasterInProd: string;
  txtNoProdInSelf: string;
  txtNoCatInSelf: string;
  txtNoFilteredCatInCat: string;
  txtNoMoveCategoriesRecursive: string;
  txtNoMoveVarAlone: string;
  txtNoProdInCatalog: string;
  txtNoRefForCatalog: string;
  txtNoRefForVar: string;
  txtNoRefInProd: string;
  txtOnlyProdCategoriesOrCatalog: string;
  txtOnlyMasterOrVar: string;
  txtSourceEqualsTarget: string;
  txtNoMoveChildInCat: string;
  txtNoCopyChildInCat: string;

  menuItems = [];
  isCatalogSelected: boolean;
  isCategorySelected: boolean;
  isProductSelected: boolean;
  selectedCategoryId: string;
  selectedProductId: string;
  selectedTreeId: string;

  selectedSearchFields: string[] = [];

  // SOURCE - links
  @ViewChild('treeListSource') treeListSource: DxTreeListComponent;
  sourceCatalogs: Catalog[];
  selectedSourceCatalogId: string;
  dataSourceSource: any;
  treeSelectedRowKeysSource: any[] = [];

  // TARGET - rechts
  @ViewChild('treeListTarget') treeListTarget: DxTreeListComponent;
  targetCatalogs: Catalog[];
  selectedTargetCatalogId: string;

  dataSourceTarget: any;

  filterValue: any;

  treeSelectedRowKeysTarget: any[] = [];

  transferMode: string;
  transferModeItems: any[];

  public showFilter: boolean = false;
  public showFilterTarget: boolean = false;
  public productStates: string[] = new Array<string>();

  set selectedStatesMode(value: string) {
    this.modelService.catalogService.selectedStatesMode = value;
  }

  get selectedStatesMode(): string {
    return this.modelService.catalogService.selectedStatesMode;
  }

  set selectedStates(values: string[]) {
    this.modelService.catalogService.selectedStates = values;
  }

  get selectedStates(): string[] {
    return this.modelService.catalogService.selectedStates;
  }

  set selectedStatesTargetMode(value: string) {
    this.modelService.catalogService.selectedStatesTargetMode = value;
  }

  get selectedStatesTargetMode(): string {
    return this.modelService.catalogService.selectedStatesTargetMode;
  }

  set selectedStatesTarget(values: string[]) {
    this.modelService.catalogService.selectedStatesTarget = values;
  }

  get selectedStatesTarget(): string[] {
    return this.modelService.catalogService.selectedStatesTarget;
  }

  constructor(
    private http: HttpClient,
    public modelService: ModelService,
    public translate: TranslateService,
    private combineCatalogService: CombineCatalogService,
    private catalogService: CatalogService
  ) {
    this.onAddTarget = this.onAddTarget.bind(this);
    this.onDragStartSource = this.onDragStartSource.bind(this);
    this.onDragStartTarget = this.onDragStartTarget.bind(this);

    this.transferModeItems = Object.entries(TransferItemModes).map(([key, value]) => ({
      key,
      value: translate.instant(value)
    }));
    this.transferMode = TransferItemModes.CategoriesAndProducts;

    catalogService.treeList = this.treeListSource;
    catalogService.treeListTarget = this.treeListTarget;
  }

  ngOnInit(): void {
    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(['/']);
      return;
    }

    if (this.modelService.router.url === '/combineCatalogs') {
      this.modelService.systemService.currentNavigationTitle = this.translate.instant('Kataloge kombinieren');
    }

    this.modelService.loginService.customerService
      .getAllCustomerByUser(this.modelService.loginService.currentUser.id)
      .subscribe((customers: Customer[]) => {
        this.customers = customers;
        this.selectedCustomerId = this.modelService.loginService.currentUser.customerId;
      });

    this.modelService.catalogService
      .getCustomerProductStates(this.modelService.loginService.currentUser.customerId)
      .subscribe((states: ProductState[]) => {
        this.modelService.productStateService.productStates = new Array<string>();

        states.forEach((s) => {
          this.modelService.productStateService.productStates.push(s.name);
        });
      });

    this.txtAddCategory = this.translate.instant('Kategorie hinzufügen');
    this.txtDeleteCategory = this.translate.instant('Kategorie löschen');
    this.txtDeleteProd = this.translate.instant('Produkt löschen');
    this.txtLoading = this.translate.instant('Loading');

    this.txtNoCategoriesOrCatalogInProd = this.translate.instant(
      'Kataloge und Kategorien können nicht in Produkte eingefügt werden.'
    );
    this.txtNoProdInChild = this.translate.instant('Produkte können nicht in Varianten eingefügt werden.');
    this.txtNoMasterInProd = this.translate.instant('Master-Produkte können nicht in Produkte eingefügt werden.');
    this.txtNoProdInSelf = this.translate.instant('Ein Produkt darf nicht auf sich selbst verschoben werden.');
    this.txtNoCatInSelf = this.translate.instant('Eine Kategorie darf nicht auf sich selbst verschoben werden.');
    this.txtNoFilteredCatInCat = this.translate.instant(
      'Eine gefilterte Kategorie darf nicht auf eine andere Kategorie verschoben werden.'
    );
    this.txtNoMoveCategoriesRecursive = this.translate.instant(
      'Rekursiv selektierte Kategorien können nicht verschoben werden.'
    );
    this.txtNoMoveVarAlone = this.translate.instant('Varianten können nicht verschoben werden.');
    this.txtNoProdInCatalog = this.translate.instant('Produkte können nicht direkt im Katalog eingefügt werden.');
    this.txtNoRefForCatalog = this.translate.instant('Kataloge können nicht referenziert werden.');
    this.txtNoRefForVar = this.translate.instant('Varianten können nicht referenziert werden.');
    this.txtNoRefInProd = this.translate.instant('Referenzen können nicht in Produkte eingefügt werden.');
    this.txtOnlyProdCategoriesOrCatalog = this.translate.instant(
      'Bitte selektieren sie nur Produkte, nur Kategorien ODER den Katalog.'
    );
    this.txtOnlyMasterOrVar = this.translate.instant('Bitte selektieren sie nur Produkte ODER Varianten.');
    this.txtSourceEqualsTarget = this.translate.instant('Quelle und Ziel sind identisch.');
    this.txtNoMoveChildInCat = this.translate.instant('Ein Kindprodukt darf nicht in eine Kategorie verschoben werden.');
    this.txtNoCopyChildInCat = this.translate.instant('Ein Kindprodukt darf nicht in eine Kategorie kopiert werden.');

    this.txtCopy = this.translate.instant('copy');
    this.txtMove = this.translate.instant('move');
    this.txtReference = this.translate.instant('reference');

    this.operationsAll = [
      { id: 0, text: this.txtCopy },
      { id: 1, text: this.txtMove },
      { id: 2, text: this.txtReference }
    ];

    this.operationsNonVirtualSameCatalog = [
      { id: 1, text: this.txtMove },
      { id: 2, text: this.txtReference }
    ];

    this.operationsNonVirtualNotSameCatalog = [
      { id: 0, text: this.txtCopy },
      { id: 1, text: this.txtMove }
    ];

    this.operationsVirtual = [{ id: 2, text: this.txtReference }];

    this.resetOperations();

    this.populateSourceCatalogs();

    // erst setzten (und jedes mal NEU) wenn ein sourceCatalog gewählt wurde
    this.targetCatalogs = null;
  }

  // popup
  popupVisible = false;
  newCategoryName: string;
  validatorInstance: Validator;
  saveValidatorInstance(e) {
    this.validatorInstance = e.component;
  }

  async onAdd() {
    this.popupVisible = false;

    let customerId = this.selectedCustomerId;

    if (this.isCatalogSelected) {
      await this.addCategory(this.selectedTargetCatalogId, customerId, this.selectedTreeId, this.newCategoryName);
    }
    if (this.isCategorySelected) {
      await this.addCategory(this.selectedCategoryId, customerId, this.selectedTreeId, this.newCategoryName);
    }

    this.validatorInstance.reset();
  }

  // account selection
  _customers: Customer[];
  get customers(): Customer[] {
    return this._customers;
  }
  set customers(value: Customer[]) {
    this._customers = value;
  }

  _selectedCustomerId: string;
  get selectedCustomerId(): string {
    return this._selectedCustomerId;
  }
  set selectedCustomerId(value: string) {
    if (this._selectedCustomerId != value) {
      this._selectedCustomerId = value;
      this.populateTargetCatalogs();
    }
  }

  sourceSelected(event) {
    // ein katalog wurde gelöscht
    if (event == null || event.selectedItem == null) {
      this.selectedSourceCatalogId = null;

      this.populateSourceCatalogs(); // ein katalog wurde gelöscht ==> muss hier 'raus, neu laden
      this.populateTargetCatalogs(); // ein katalog wurde gelöscht ==> muss hier 'raus, neu laden

      this.initDataSourceSource();

      this.resetOperations();

      return;
    }

    this.selectedSourceCatalogId = event.selectedItem.id;
    this.catalogService.selectedCatalogId = this.selectedSourceCatalogId;

    this.populateTargetCatalogs();

    this.initDataSourceSource();

    this.setSelectedOperation();
  }

  populateSourceCatalogs() {
    this.modelService.catalogService
      .getCatalogs(this.modelService.loginService.currentUser.customerId)
      .subscribe((result: Catalog[]) => {
        this.sourceCatalogs = result.filter((c) => !c.isVirtual);
      });
  }

  initDataSourceSource() {
    let that = this;

    this.dataSourceSource = {
      load: async function (loadOptions) {
        if (that.selectedSourceCatalogId == null) {
          // Katalog wurde gelöscht

          that.treeListSource.instance.beginUpdate();
          that.treeListSource.dataSource = null;
          that.treeListSource.instance.endUpdate();

          return null;
        }
        if (that.catalogService.treeList == undefined) that.catalogService.treeList = that.treeListSource;
        let parentId = '';
        if (loadOptions.parentIds != null) {
          loadOptions.parentIds = loadOptions.parentIds.filter((p) => p != undefined);

          parentId = loadOptions.parentIds.join(',');
        }
        this.childs = await that.modelService.catalogService.getChilds(
          that.selectedSourceCatalogId,
          parentId,
          that.modelService.loginService.currentUser.customerId,
          loadOptions.filter,
          that.modelService.catalogService.selectedStates,
          that.modelService.loginService.productSearchOptions,
          that.selectedStatesMode
        );

        return this.childs;
      }
    };
  }

  toggleFilter() {
    if (this.showFilter) {
      this.selectedStates = new Array<string>();
      this.showFilter = false;
    } else {
      this.showFilter = true;
    }
  }
  toggleFilterTarget() {
    if (this.showFilterTarget) {
      this.selectedStatesTarget = new Array<string>();
      this.showFilterTarget = false;
    } else {
      this.showFilterTarget = true;
    }
  }

  updateSearchFields({ mode, fields }) {
    this.selectedSearchFields = fields;
  }

  updateState({ mode, states }) {
    this.selectedStates = states;
    this.selectedStatesMode = mode;
    this.modelService.catalogService.collapsTree();
    this.modelService.catalogService.treeFilter();
  }

  updateStateTarget({ mode, states }) {
    this.selectedStatesTarget = states;
    this.selectedStatesTargetMode = mode;
    this.modelService.catalogService.collapsTreeTarget();
    this.modelService.catalogService.treeFilterTarget();
  }

  targetSelected(event) {
    // sourceCatalog wurde auf den gleichen wie targetCatalog gesetzt, dadurch wurde targetCatalog aus der datasource entfernt <== obsolet?
    if (event.selectedItem == null) {
      this.selectedTargetCatalogId = null;
      this.catalogService.selectedTargetCatalogId = null;
      this.dataSourceTarget = null;
    } else {
      this.selectedTargetCatalogId = event.selectedItem.id;
      this.catalogService.selectedTargetCatalogId = this.selectedTargetCatalogId;
      this.initDataSourceTarget();
    }

    this.isTargetCatalogVirtual = false;
    let catalog = this.targetCatalogs.filter((c) => c.id == this.selectedTargetCatalogId)[0];
    if (catalog != null && catalog.isVirtual) {
      this.isTargetCatalogVirtual = true;
    }

    this.setSelectedOperation();
  }

  populateTargetCatalogs() {
    this.modelService.catalogService.getCatalogs(this.selectedCustomerId).subscribe((result: Catalog[]) => {
      //let allTargetCatalogs = result;
      //this.targetCatalogs = allTargetCatalogs.filter(c => c.id != this.selectedSourceCatalogId);
      this.targetCatalogs = result;
      if (this.modelService?.loginService?.currentUser?.customerId != this.selectedCustomerId) {
        // virtuelle Kataloge soll es nicht Account-Übergreifend geben (TR)
        this.targetCatalogs = result.filter((t) => t.isVirtual == false);
      }
    });
  }

  initDataSourceTarget() {
    if (!this.selectedTargetCatalogId) {
      return;
    }

    this.modelService.catalogService
      .getSpidsInCatalog(this.selectedTargetCatalogId, this.selectedCustomerId)
      .subscribe((response: Array<string>) => {
        this.existingSpids = response;
      });

    let that = this;

    this.dataSourceTarget = {
      load: async function (loadOptions) {
        let parentId = '';
        if (loadOptions.parentIds != null) {
          loadOptions.parentIds = loadOptions.parentIds.filter((p) => p != undefined);

          parentId = loadOptions.parentIds.join(',');
        }
        if (that.catalogService.treeListTarget == undefined) that.catalogService.treeListTarget = that.treeListTarget;

        this.childs = await that.modelService.catalogService.getChilds(
          that.selectedTargetCatalogId,
          parentId,
          that.selectedCustomerId,
          loadOptions.filter,
          that.modelService.catalogService.selectedStatesTarget,
          that.modelService.loginService.productSearchOptionsTarget,
          that.selectedStatesTargetMode
        );

        return this.childs;
      }
    };
  }

  existingSpids = new Array<string>();

  hasSpid(spid): boolean {
    let s = this.existingSpids.filter((e) => e == spid);
    if (s.length == 0) {
      return false;
    }
    return true;
  }

  onAddTarget(e) {
    switch (this.selectedOperation.id) {
      case 0:
        this.processCopy(e);
        break;
      case 1:
        this.processMove(e);
        break;
      case 2:
        this.processReference(e);
        break;
      default:
        return;
    }

    this.treeSelectedRowKeysSource = [];
  }

  onDragStartSource(e) {
    let i = 1;
  }

  onDragStartTarget(e) {
    e.cancel = true;
  }

  async processCopy(e) {
    this.treeListSource.instance.selectRows(e.itemData.treeElementId, true);
    await this.copyMany(e);
  }

  async processMove(e) {
    this.treeListSource.instance.selectRows(e.itemData.treeElementId, true);
    await this.moveMany(e);
  }

  processReference(e) {
    this.treeListSource.instance.selectRows(e.itemData.treeElementId, true);
    this.referenceMany(e);
  }

  async copyMany(e) {
    let sourceSelectedData: TreeItem[] = this.treeListSource.instance.getSelectedRowsData();

    let sourceCatalogs = sourceSelectedData.filter((t) => t.type == 'cat');
    let sourceCategories = sourceSelectedData.filter((t) => t.type == 'grp');
    let sourceProducts = sourceSelectedData.filter((t) => t.type == 'pro');
    let masters = sourceProducts.filter((p) => p.hasChilds);
    let childs = sourceProducts.filter((p) => p.isChild);

    if (
      (sourceCatalogs.length > 0 && sourceCategories.length > 0) ||
      (sourceCatalogs.length > 0 && sourceProducts.length > 0) ||
      (sourceCategories.length > 0 && sourceProducts.length > 0)
    ) {
      this.modelService.systemService.notify(new Message(this.txtOnlyProdCategoriesOrCatalog), 2500);
      return;
    }

    if (masters.length > 0 && childs.length > 0) {
      this.modelService.systemService.notify(new Message(this.txtOnlyMasterOrVar), 2500);
      return;
    }

    let rows = this.treeListTarget.instance.getVisibleRows();
    let targetItem = rows[e.toIndex].node.data;

    if (targetItem.type == 'pro') {
      if (sourceProducts.length > 0 && masters.length > 0) {
        // verboten:
        // master     in prod (master)
        // master     in prod (child)
        this.modelService.systemService.notify(new Message(this.txtNoMasterInProd), 2500);
        return;
      }
      if (sourceCatalogs.length > 0 || sourceCategories.length > 0) {
        // verboten:
        // katalog    in prod
        // kategorie  in prod
        this.modelService.systemService.notify(new Message(this.txtNoCategoriesOrCatalogInProd), 2500);
        return;
      }
      if (targetItem.isChild) {
        this.modelService.systemService.notify(new Message(this.txtNoProdInChild), 2500);
        return;
      }

      let dialogResult = await confirm(
        this.translate.instant('ConfirmVariantCopyText'),
        this.translate.instant('Variante erstellen')
      );
      if (!dialogResult) {
        return;
      }
    }

    if (targetItem.type === 'grp') {
      if (sourceProducts.length > 0 && sourceProducts.some((x) => x.isChild)) {
        // verboten:
        // source darf kein gefiltertes Kindprodukt sein.
        this.modelService.systemService.notify(new Message(this.txtNoCopyChildInCat), 2500);
        return;
      }

      if (sourceCategories.length > 0 && (this.selectedStates.length > 0 || this.filterValue?.trim())) {
        // verboten:
        // source darf keine gefilterte Kategorie sein
        this.modelService.systemService.notify(new Message(this.txtNoFilteredCatInCat), 2500);
        return;
      }
    }

    if (targetItem.type == 'cat') {
      if (sourceProducts.length > 0) {
        // verboten:
        // prod         in katalog
        this.modelService.systemService.notify(new Message(this.txtNoProdInCatalog), 2500);
        return;
      }
    }

    if (sourceProducts.length > 0) {
      sourceSelectedData.forEach((s) => {
        this.existingSpids.push(s.supplierPid);
      });
    }

    let model = new TransferCatalogItem();
    model.targetItem = new CatalogItem();
    model.targetItem.CatalogId = this.selectedTargetCatalogId;
    model.targetItem.CustomerId = this.selectedCustomerId;
    switch (targetItem.type) {
      case 'pro':
        model.targetItem.ProductId = targetItem.id.toString();
        model.targetItem.CategoryId = targetItem.treeElementId.toString().split('_')[1];
        break;
      case 'grp':
        model.targetItem.CategoryId = targetItem.id.toString();
        break;
    }

    model.itemsToTransfer = new Array<CatalogItem>();
    sourceSelectedData.forEach((sourceItem) => {
      let item = new CatalogItem();
      item.CustomerId = this.modelService.loginService.currentUser.customerId;
      item.CatalogId = this.selectedSourceCatalogId;
      switch (sourceItem.type) {
        case 'pro':
          item.ProductId = sourceItem.id.toString();
          item.CategoryId = sourceItem.treeElementId.toString().split('_')[1];
          break;
        case 'grp':
          item.CategoryId = sourceItem.id.toString();
          break;
      }

      model.itemsToTransfer.push(item);
    });

    let response: Observable<StringResponse>;

    switch (this.transferMode) {
      case TransferItemModes.CategoriesAndProducts:
        response = this.combineCatalogService.copyItems(model);
        break;
      case TransferItemModes.OnlyCategories:
        response = this.combineCatalogService.copyCategories(model);
        break;
      case TransferItemModes.OnlyProducts:
        response = this.combineCatalogService.copyProducts(model);
        break;
    }

    this.treeListTarget.instance.beginCustomLoading(this.txtLoading);
    response.subscribe(
      () => {
        this.treeListTarget.instance.endCustomLoading();

        this.modelService.catalogService
          .getSpidsInCatalog(this.selectedTargetCatalogId, this.selectedCustomerId)
          .subscribe((arr: Array<string>) => {
            this.existingSpids = arr;
          });

        this.refreshTargetTree(targetItem.id);
      },
      (error) => {
        this.treeListTarget.instance.endCustomLoading();
      }
    );
  }

  async moveMany(e) {
    let sourceSelectedData: TreeItem[] = this.treeListSource.instance.getSelectedRowsData();

    let sourceCatalogs = sourceSelectedData.filter((t) => t.type == 'cat');
    let sourceCategories = sourceSelectedData.filter((t) => t.type == 'grp');
    let sourceProducts = sourceSelectedData.filter((t) => t.type == 'pro');
    let masters = sourceProducts.filter((p) => p.hasChilds);
    let childs = sourceProducts.filter((p) => p.isChild);

    if (
      (sourceCatalogs.length > 0 && sourceCategories.length > 0) ||
      (sourceCatalogs.length > 0 && sourceProducts.length > 0) ||
      (sourceCategories.length > 0 && sourceProducts.length > 0)
    ) {
      this.modelService.systemService.notify(new Message(this.txtOnlyProdCategoriesOrCatalog), 2500);
      return;
    }

    if (masters.length > 0 && childs.length > 0) {
      this.modelService.systemService.notify(new Message(this.txtOnlyMasterOrVar), 2500);
      return;
    }

    let rows = this.treeListTarget.instance.getVisibleRows();
    let targetItem = rows[e.toIndex].node.data;

    if (targetItem.type == 'pro') {
      if (sourceProducts.length > 0 && sourceProducts.every((x) => x.id === targetItem.id)) {
        // verboten:
        // target darf nicht selbes Produkt sein wie source
        this.modelService.systemService.notify(new Message(this.txtNoProdInSelf), 2500);
        return;
      }

      if (sourceProducts.length > 0 && masters.length > 0) {
        // verboten:
        // master     in prod (master)
        // master     in prod (child)
        this.modelService.systemService.notify(new Message(this.txtNoMasterInProd), 2500);
        return;
      }
      if (sourceCatalogs.length > 0 || sourceCategories.length > 0) {
        // verboten:
        // katalog    in prod
        // kategorie  in prod
        this.modelService.systemService.notify(new Message(this.txtNoCategoriesOrCatalogInProd), 2500);
        return;
      }
      if (targetItem.isChild) {
        this.modelService.systemService.notify(new Message(this.txtNoProdInChild), 2500);
        return;
      }

      let dialogResult = await confirm(
        this.translate.instant('ConfirmVariantMoveText'),
        this.translate.instant('Variante erstellen')
      );
      if (!dialogResult) {
        return;
      }
    }

    if (targetItem.type == 'cat') {
      if (sourceProducts.length > 0) {
        // verboten:
        // prod         in katalog
        this.modelService.systemService.notify(new Message(this.txtNoProdInCatalog), 2500);
        return;
      }
    }

    if (targetItem.type === 'grp') {
      if (sourceCategories.length > 0 && (this.selectedStates.length > 0 || this.filterValue?.trim())) {
        // verboten:
        // source darf keine gefilterte Kategorie sein
        this.modelService.systemService.notify(new Message(this.txtNoFilteredCatInCat), 2500);
        return;
      }

      if (sourceProducts.length > 0 && sourceProducts.some((x) => x.isChild)) {
        // verboten:
        // source darf kein gefiltertes Kindprodukt sein.
        this.modelService.systemService.notify(new Message(this.txtNoMoveChildInCat), 2500);
        return;
      }

      if (sourceCategories.length > 0 && sourceCategories.every((x) => x.id === targetItem.id)) {
        // verboten:
        // target darf nicht selbe Kategorie sein wie source
        this.modelService.systemService.notify(new Message(this.txtNoCatInSelf), 2500);
        return;
      }
    }

    if (sourceProducts.length > 0) {
      sourceSelectedData.forEach((s) => {
        this.existingSpids.push(s.supplierPid);
      });
    }

    let model = new TransferCatalogItem();
    model.targetItem = new CatalogItem();
    model.targetItem.CatalogId = this.selectedTargetCatalogId;
    model.targetItem.CustomerId = this.selectedCustomerId;
    switch (targetItem.type) {
      case 'pro':
        model.targetItem.ProductId = targetItem.id.toString();
        model.targetItem.CategoryId = targetItem.treeElementId.toString().split('_')[1];
        break;
      case 'grp':
        model.targetItem.CategoryId = targetItem.id.toString();
        break;
    }

    model.itemsToTransfer = new Array<CatalogItem>();
    sourceSelectedData.forEach((sourceItem) => {
      let item = new CatalogItem();
      item.CustomerId = this.modelService.loginService.currentUser.customerId;
      item.CatalogId = this.selectedSourceCatalogId;
      switch (sourceItem.type) {
        case 'pro':
          item.ProductId = sourceItem.id.toString();
          item.CategoryId = sourceItem.treeElementId.toString().split('_')[1];
          break;
        case 'grp':
          item.CategoryId = sourceItem.id.toString();
          break;
      }

      model.itemsToTransfer.push(item);
    });

    let response: Observable<StringResponse>;

    switch (this.transferMode) {
      case TransferItemModes.CategoriesAndProducts:
        response = this.combineCatalogService.moveItems(model);
        break;
      case TransferItemModes.OnlyCategories:
        let message = this.translate.instant('OnlyCategoriesNotAllowMoving');
        this.modelService.systemService.notify(new Message(message), 2500);
        return;
      case TransferItemModes.OnlyProducts:
        response = this.combineCatalogService.moveProducts(model);
        break;
    }

    this.treeListTarget.instance.beginCustomLoading(this.txtLoading);
    response.subscribe(
      () => {
        this.treeListTarget.instance.endCustomLoading();

        this.modelService.catalogService
          .getSpidsInCatalog(this.selectedTargetCatalogId, this.selectedCustomerId)
          .subscribe((arr: Array<string>) => {
            this.existingSpids = arr;
          });

        this.refreshTargetTree(targetItem.id);
        if (this.selectedSourceCatalogId == this.selectedTargetCatalogId) {
          this.refreshSourceTree(targetItem.id);
        } else {
          this.refreshSourceTree(model.itemsToTransfer[0].CategoryId);
        }
      },
      (error) => {
        this.treeListTarget.instance.endCustomLoading();
      }
    );
  }

  referenceMany(e) {
    let sourceSelectedData: TreeItem[] = this.treeListSource.instance.getSelectedRowsData();

    let sourceCatalogs = sourceSelectedData.filter((t) => t.type == 'cat');
    let sourceCategories = sourceSelectedData.filter((t) => t.type == 'grp');
    let sourceProducts = sourceSelectedData.filter((t) => t.type == 'pro');
    let masters = sourceProducts.filter((p) => p.hasChilds);
    let childs = sourceProducts.filter((p) => p.isChild);

    let targetCatalog = this.targetCatalogs.find(x => x.id == this.selectedTargetCatalogId);
    let sourceCatalog = this.sourceCatalogs.find(x => x.id == this.selectedSourceCatalogId);

    if (targetCatalog.isVirtual &&
      (!sourceCatalog.catalogId ||
      !sourceCatalog.isActivId)) {
      this.modelService.systemService.notify(new Message(this.translate.instant("CatalogIdRequired")), 2500);
      return;
    }

    if ((sourceCatalogs.length > 0 && sourceCategories.length > 0) ||
      (sourceCatalogs.length > 0 && sourceProducts.length > 0) ||
      (sourceCategories.length > 0 && sourceProducts.length > 0)) {
      this.modelService.systemService.notify(new Message(this.txtOnlyProdCategoriesOrCatalog), 2500);
      return;
    }

    if (masters.length > 0 && childs.length > 0) {
      this.modelService.systemService.notify(new Message(this.txtOnlyMasterOrVar), 2500);
      return;
    }

    let rows = this.treeListTarget.instance.getVisibleRows();
    let targetItem = rows[e.toIndex].node.data;

    if (targetItem.type == 'pro') {
      if (sourceProducts.length > 0) {
        // verboten:
        // prod       in prod
        this.modelService.systemService.notify(new Message(this.txtNoRefInProd), 2500);
        return;
      }
    }

    if (sourceCatalogs.length > 0) {
      // verboten:
      // katalog referenzieren
      this.modelService.systemService.notify(new Message(this.txtNoRefForCatalog), 2500);
      return;
    }

    if (targetItem.type == 'cat') {
      if (sourceProducts.length > 0 || this.transferMode == TransferItemModes.OnlyProducts) {
        // verboten:
        // prod         in katalog
        this.modelService.systemService.notify(new Message(this.txtNoProdInCatalog), 2500);
        return;
      }
    }

    if (targetItem.type === 'grp') {
      if (sourceCategories.length > 0 && (this.selectedStates.length > 0 || this.filterValue?.trim())) {
        // verboten:
        // source darf keine gefilterte Kategorie sein
        this.modelService.systemService.notify(new Message(this.txtNoFilteredCatInCat), 2500);
        return;
      }
    }

    if (childs.length > 0) {
      // verboten:
      // variante referenzieren
      this.modelService.systemService.notify(new Message(this.txtNoRefForVar), 2500);
      return;
    }

    if (sourceProducts.length > 0) {
      sourceSelectedData.forEach((s) => {
        this.existingSpids.push(s.supplierPid);
      });
    }

    let model = new TransferCatalogItem();
    model.targetItem = new CatalogItem();
    model.targetItem.CatalogId = this.selectedTargetCatalogId;
    model.targetItem.CustomerId = this.selectedCustomerId;
    switch (targetItem.type) {
      case 'pro':
        model.targetItem.ProductId = targetItem.id.toString();
        model.targetItem.CategoryId = targetItem.treeElementId.toString().split('_')[1];
        break;
      case 'grp':
        model.targetItem.CategoryId = targetItem.id.toString();
        break;
    }

    model.itemsToTransfer = new Array<CatalogItem>();
    sourceSelectedData.forEach((sourceItem) => {
      let item = new CatalogItem();
      item.CustomerId = this.modelService.loginService.currentUser.customerId;
      item.CatalogId = this.selectedSourceCatalogId;
      switch (sourceItem.type) {
        case 'pro':
          item.ProductId = sourceItem.id.toString();
          item.CategoryId = sourceItem.treeElementId.toString().split('_')[1];
          break;
        case 'grp':
          item.CategoryId = sourceItem.id.toString();
          break;
      }

      model.itemsToTransfer.push(item);
    });

    let response: Observable<StringResponse>;

    switch (this.transferMode) {
      case TransferItemModes.CategoriesAndProducts:
        response = this.combineCatalogService.referenceItems(model);
        break;
      case TransferItemModes.OnlyCategories:
        response = this.combineCatalogService.copyCategories(model);
        break;
      case TransferItemModes.OnlyProducts:
        response = this.combineCatalogService.referenceProducts(model);
        break;
    }

    this.treeListTarget.instance.beginCustomLoading(this.txtLoading);
    response.subscribe(
      () => {
        this.treeListTarget.instance.endCustomLoading();

        this.modelService.catalogService
          .getSpidsInCatalog(this.selectedTargetCatalogId, this.selectedCustomerId)
          .subscribe((arr: Array<string>) => {
            this.existingSpids = arr;
          });

        this.refreshTargetTree(targetItem.id);
        if (this.selectedSourceCatalogId == this.selectedTargetCatalogId) {
          this.refreshSourceTree(targetItem.id);
        }
      },
      (error) => {
        this.treeListTarget.instance.endCustomLoading();
      }
    );
  }

  refreshTargetTree(parentId: string) {
    let that = this;

    this.treeListTarget.instance.refresh().then(function () {
      that.treeListTarget.instance.expandRow(parentId);
    });
  }

  refreshSourceTree(parentId: string) {
    let that = this;

    this.treeListSource.instance.refresh().then(function () {
      that.treeListSource.instance.expandRow(parentId);
    });
  }

  // abgekupfert aus: \Pim\ClientApp\src\app\catalog\browse\browse.component.ts
  onCellPreparedSource(e) {
    // kann eig. nie auftreten, dass hier etwas neues dazwischen kommt??

    if (!e.data.imageUrl) {
      let catalog = this.sourceCatalogs.filter((c) => c.id == this.selectedSourceCatalogId)[0];

      if (catalog && catalog.lastImageUpload > e.data.lastImageLookup) {
        this.modelService.catalogService
          .getThumbUrl(
            e.data.id,
            e.data.type,
            this.modelService.loginService.currentUser.customerId,
            this.selectedSourceCatalogId
          )
          .subscribe((url: UrlResponse) => {
            if (url) e.data.imageUrl = url.url;
            e.data.lastImageLookup = new Date().getTime();
          });
      }
    }
  }

  // abgekupfert aus: \Pim\ClientApp\src\app\catalog\browse\browse.component.ts
  onCellPreparedSourceTarget(e) {
    if (!e.data.imageUrl) {
      let catalog = this.targetCatalogs.filter((c) => c.id == this.selectedTargetCatalogId)[0];

      if (catalog && catalog.lastImageUpload > e.data.lastImageLookup) {
        this.modelService.catalogService
          .getThumbUrl(e.data.id, e.data.type, this.selectedCustomerId, this.selectedTargetCatalogId)
          .subscribe((url: UrlResponse) => {
            if (url) e.data.imageUrl = url.url;
            e.data.lastImageLookup = new Date().getTime();
          });
      }
    }
  }

  // brauchen wir nicht
  // abgekupfert aus: \Pim\ClientApp\src\app\catalog\browse\browse.component.ts
  onRowClick(e) {
    //if (e.event.target !== undefined && e.event.target.localName != "span") {
    //  if ((e.event.srcElement.localName == "img" && e.event.srcElement.className == "flag") ||
    //    e.event.srcElement.localName == "dx-select-box"
    //  ) {
    //    return;
    //  }
    //  if ((e.event.srcElement.localName == "i" && e.event.srcElement.className == "dx-icon dx-icon-menu") ||
    //    (e.event.srcElement.localName == "div" && e.event.srcElement.className == "dx-button-content")
    //  ) {
    //    return;
    //  }
    //}
  }

  // abgekupfert aus: \Pim\ClientApp\src\app\catalog\browse\browse.component.ts
  onSelectionChangedTarget(e) {
    if (e.selectedRowsData.length > 0) {
      let selectedRowData = e.selectedRowsData[0];
      this.openItem(selectedRowData.type, selectedRowData.id, selectedRowData.isChild);
    }
  }

  // abgekupfert aus: \Pim\ClientApp\src\app\catalog\browse\browse.component.ts
  private openItem(type: string, id: string, isChild: boolean) {
    this.isCategorySelected = false;
    this.isCatalogSelected = false;
    this.isProductSelected = false;

    switch (type) {
      case 'cat':
        this.isCatalogSelected = true;
        this.menuItems = [{ id: 2, text: this.txtAddCategory, icon: 'glyphicon glyphicon-plus', beginGroup: false }];
        break;

      case 'grp':
        this.isCategorySelected = true;
        this.selectedCategoryId = id;
        this.menuItems = [
          { id: 2, text: this.txtAddCategory, icon: 'glyphicon glyphicon-plus', beginGroup: false },
          { id: 7, text: this.txtDeleteCategory, icon: 'glyphicon glyphicon-trash', beginGroup: true }
        ];
        break;

      case 'pro':
        this.isProductSelected = true;
        this.selectedProductId = id;
        this.menuItems = [];
        if (!isChild || (isChild && !this.isTargetCatalogVirtual)) {
          this.menuItems = [{ id: 0, text: this.txtDeleteProd, icon: 'glyphicon glyphicon-trash', beginGroup: false }];
        }
        break;
    }
  }

  // abgekupfert aus: \Pim\ClientApp\src\app\catalog\browse\browse.component.ts
  onContextMenuShowing(e, item) {
    let key = item.key;

    if (key != '' && key) {
      let keys = new Array<string>();
      keys.push(key);
      let result = this.treeListTarget.instance.selectRows(keys, false);
    }
  }

  // abgekupfert aus: \Pim\ClientApp\src\app\catalog\browse\browse.component.ts
  async itemClick(e, itemClickData) {
    this.selectedTreeId = itemClickData.data.treeElementId;

    let txtYes = '';
    let txtNo = '';
    let txtMsg = '';
    let txtTitle = '';
    let myDialog: any;

    switch (e.itemData.id) {
      // Kategorie hinzufügen
      case 2:
        this.popupVisible = true;

        break;

      // Kategorie löschen
      case 7:
        txtYes = this.translate.instant('Ja');
        txtNo = this.translate.instant('Nein');
        txtMsg = this.translate.instant('Soll die Kategorie wirklich gelöscht werden?');
        txtTitle = this.translate.instant('Wirklich löschen?');

        myDialog = custom({
          title: txtTitle,
          messageHtml: txtMsg,
          buttons: [
            {
              text: txtYes,
              onClick: (e) => {
                return { buttonText: true };
              }
            },
            {
              text: txtNo,
              onClick: (e) => {
                return { buttonText: false };
              }
            }
          ]
        });
        myDialog.show().then((dialogResult) => {
          if (dialogResult.buttonText == true) {
            this.http
              .delete('api/category/DeleteCategory/' + this.selectedCustomerId + '/' + this.selectedTreeId)
              .subscribe(() => {
                this.treeListTarget.instance.refresh();

                if (this.selectedSourceCatalogId == this.selectedTargetCatalogId) {
                  this.treeListSource.instance.refresh();
                }

                this.modelService.catalogService
                  .getSpidsInCatalog(this.selectedTargetCatalogId, this.selectedCustomerId)
                  .subscribe((response: Array<string>) => {
                    this.existingSpids = response;
                  });
              });
          }
        });

        break;

      // Produkt löschen
      case 0:
        txtYes = this.translate.instant('Ja');
        txtNo = this.translate.instant('Nein');
        txtMsg = this.translate.instant('Soll das Produkt wirklich gelöscht werden?');
        txtTitle = this.translate.instant('Wirklich löschen?');

        myDialog = custom({
          title: txtTitle,
          messageHtml: txtMsg,
          buttons: [
            {
              text: txtYes,
              onClick: (e) => {
                return { buttonText: true };
              }
            },
            {
              text: txtNo,
              onClick: (e) => {
                return { buttonText: false };
              }
            }
          ]
        });
        myDialog.show().then((dialogResult) => {
          if (dialogResult.buttonText == true) {
            this.http
              .delete('api/product/DeleteProduct/' + this.selectedCustomerId + '/' + this.selectedTreeId)
              .subscribe(() => {
                this.treeListTarget.instance.refresh();

                if (this.selectedSourceCatalogId == this.selectedTargetCatalogId) {
                  this.treeListSource.instance.refresh();
                }

                this.modelService.catalogService
                  .getSpidsInCatalog(this.selectedTargetCatalogId, this.selectedCustomerId)
                  .subscribe((response: Array<string>) => {
                    this.existingSpids = response;
                  });
              });
          }
        });

        break;
    }
  }

  // eig. scheisse, duplicate code, aber im catalog.service.ts ist der TREE fest verdrahtet...
  // abgekupfert aus: \Pim\ClientApp\src\app\Services\catalog.service.ts
  async addCategory(parentId: string, customerId: string, treeId: string, newCategoryName: string) {
    let addItemModel = new AddItem();
    addItemModel.parentId = parentId;
    addItemModel.customerId = customerId;
    addItemModel.parentTreeId = treeId;
    addItemModel.newCategoryName = newCategoryName;

    this.http.post<StringResponse>('api/category/AddCategory', addItemModel).subscribe((response: StringResponse) => {
      this.refreshTargetTree(treeId);
      if (this.selectedSourceCatalogId == this.selectedTargetCatalogId) {
        this.refreshSourceTree(treeId);
      }
    });
  }

  txtCopy: string = '';
  txtMove: string = '';
  txtReference: string = '';

  selectedOperation: OperationEntity;

  operationsAll: OperationEntity[];
  operationsNonVirtualSameCatalog: OperationEntity[];
  operationsNonVirtualNotSameCatalog: OperationEntity[];
  operationsNonVirtual: OperationEntity[];
  operationsVirtual: OperationEntity[];

  isTargetCatalogVirtual: boolean = false;

  operationsDisabled: boolean = false;

  resetOperations() {
    this.operationsNonVirtual = this.operationsAll;
    this.selectedOperation = this.operationsNonVirtual[0];
    this.operationsDisabled = true;
  }

  setSelectedOperation() {
    if (this.selectedSourceCatalogId == null || this.selectedTargetCatalogId == null) {
      return;
    }

    let catalog = this.targetCatalogs.filter((c) => c.id == this.selectedTargetCatalogId)[0];

    if (catalog != null && !catalog.isVirtual && this.selectedSourceCatalogId == this.selectedTargetCatalogId) {
      this.operationsNonVirtual = this.operationsNonVirtualSameCatalog;
      this.selectedOperation = this.operationsNonVirtualSameCatalog[0];
      this.operationsDisabled = false;
    }

    if (catalog != null && !catalog.isVirtual && this.selectedSourceCatalogId != this.selectedTargetCatalogId) {
      this.operationsNonVirtual = this.operationsNonVirtualNotSameCatalog;
      this.selectedOperation = this.operationsNonVirtualNotSameCatalog[0];
      this.operationsDisabled = false;
    }

    if (catalog != null && catalog.isVirtual) {
      this.selectedOperation = this.operationsVirtual[0];
      this.operationsDisabled = true;
    }
  }
}

export class OperationEntity {
  id: number;
  text: string;
}
