<dx-data-grid
  style="height: 100%"
  [dataSource]="dataSource"
  keyExpr="order"
  [remoteOperations]="false"
  [allowColumnReordering]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  [editing]="{
    mode: 'cell',
    allowUpdating: true,
    allowAdding: false,
    allowDeleting: true,
    newRowPosition: 'last'
  }"
>
  <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-paging [enabled]="true"></dxo-paging>
  <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
  <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
  <dxo-scrolling mode="standard" [useNative]="false"></dxo-scrolling>

  <dxo-toolbar>
    <dxi-item location="after">
      <div *dxTemplate>
        <div id="addButton" class="d-flex gap-2">
          <dx-button
            stylingMode="text"
            type="default"
            icon="plus"
            text="{{ 'Neue Spalte' | translate }}"
            (onClick)="onAddNewRow($event)"
          ></dx-button>
        </div>
      </div>
    </dxi-item>
  </dxo-toolbar>

  <dxi-column
    dataField="order"
    caption="{{ 'Sortiernummer' | translate }}"
    [allowEditing]="false"
    cellTemplate="cellOrderTemplate"
    minWidth="80"
    width="150"
  />

  <dxi-column
    *ngFor="let item of levels"
    caption="Ebene {{ item + 1 }}"
    [allowEditing]="false"
    cellTemplate="cellLevelTemplate"
    headerCellTemplate="headerCellLevelTemplate"
    minWidth="200"
    width="200"
  />
  <dxi-column
    caption="{{ 'Verwendetes Feld' | translate }}"
    [allowEditing]="false"
    cellTemplate="cellUsedFieldTemplate"
  />

  <dxi-column
    *ngIf="languageFlags?.length"
    dataField="language"
    caption="{{ 'Sprache' | translate }}"
    [allowEditing]="false"
    cellTemplate="cellLanguageTemplate"
    minWidth="100"
    width="100"
  />

  <dxi-column
    dataField="pimFields"
    caption="{{ 'Feldeinstellungen' | translate }}"
    [allowEditing]="false"
    cellTemplate="cellPimFieldTemplate"
    minWidth="100"
    width="100"
  />

  <div *dxTemplate="let cellData of 'headerCellLevelTemplate'">
    <div class="d-flex justify-content-between align-items-center gap-2">
      <div class="flex-grow-1 fw-bold">{{ cellData.column.caption }}</div>
      <dx-button
        *ngIf="cellData.columnIndex + 1 - this.columnOffset === this.levelDepth"
        [disabled]="levelDepth === 1"
        class="btn-headline-icon"
        icon="minus"
        hint="{{ 'Ebene löschen' | translate }}"
        (click)="removeColumn()"
      ></dx-button>
      <dx-button
        *ngIf="cellData.columnIndex + 1 - this.columnOffset === this.levelDepth"
        [disabled]="levelDepth >= MAX_LEVEL"
        class="btn-headline-icon"
        icon="plus"
        hint="{{ 'Ebene hinzufügen' | translate }}"
        (click)="addColumn()"
      ></dx-button>
    </div>
  </div>

  <div *dxTemplate="let cellData of 'headerCellHeadlineTemplate'">
    <div class="d-flex justify-content-between align-items-center gap-2">
      <dx-button
        *ngIf="levelDepth == 0"
        class="btn-headline-icon"
        icon="plus"
        hint="{{ 'Ebene hinzufügen' | translate }}"
        (click)="addColumn()"
      ></dx-button>
      <div class="fw-bold">{{ cellData.column.caption }}</div>
    </div>
  </div>

  <div *dxTemplate="let cellData of 'cellOrderTemplate'">
    <dx-drop-down-box
      class="flex-grow-1 np-highlight"
      [acceptCustomValue]="true"
      [(value)]="cellData.data.order"
      [dataSource]="columnOrderList"
      displayExpr="column"
      valueExpr="order"
      (onValueChanged)="onOrderItemChanged($event, cellData)"
    >
      <div *dxTemplate="let item of 'content'">
        <dx-list
          [searchEnabled]="false"
          [dataSource]="columnOrderList"
          displayExpr="column"
          valueExpr="order"
          selectionMode="single"
          (onItemClick)="onOrderItemClick($event, cellData)"
        ></dx-list>
      </div>
    </dx-drop-down-box>
  </div>

  <div *dxTemplate="let cellData of 'cellLevelTemplate'">
    <dx-select-box
      class="np-highlight"
      [dataSource]="
        levelChoiceGroups[
          cellData.columnIndex - columnOffset > 0 ? cellData.data.keys.slice(0, cellData.columnIndex - columnOffset).join('|') : ''
        ]
      "
      [acceptCustomValue]="true"
      [showClearButton]="true"
      [(value)]="cellData.data.keys[cellData.columnIndex - columnOffset]"
      placeholder="{{ 'Eintragen...' | translate }}"
      (onValueChanged)="onLevelNameChanged(cellData, $event)"
    ></dx-select-box>
  </div>

  <div *dxTemplate="let cellData of 'cellKeyTemplate'">
    <dx-text-box
      class="flex-grow-1 np-highlight"
      [(value)]="cellData.data.key"
      [showClearButton]="true"
      (onValueChanged)="update(cellData)"
    ></dx-text-box>
  </div>

  <div *dxTemplate="let cellData of 'cellUsedFieldTemplate'">
    <div>{{ cellData.order }}</div>
    <div class="d-flex align-items-stretch gap-2">
      <dx-select-box
        class="np-highlight"
        [dataSource]="typeFieldSource"
        [minSearchLength]="0"
        [showClearButton]="false"
        [showDropDownButton]="true"
        displayExpr="display"
        valueExpr="id"
        [(value)]="cellData.data.fieldType"
        (onValueChanged)="update(cellData)"
      ></dx-select-box>

      <div class="d-flex flex-grow-1" *ngIf="cellData.data.fieldType == 'standard'">
        <np-advanced-template-defaultfield
          class="d-flex flex-grow-1"
          [(templateItem)]="cellData.data"
          [customerId]="customerId"
          [fieldList]="fieldService.productFields"
          (onUpdate)="update(cellData)"
        ></np-advanced-template-defaultfield>
      </div>
      <div class="d-flex flex-grow-1" *ngIf="cellData.data.fieldType == 'mime'">
        <np-advanced-template-mime
          class="d-flex flex-grow-1"
          [(templateItem)]="cellData.data"
          [customerId]="customerId"
          [fieldList]="fieldService.mimeFields"
          (onUpdate)="update(cellData)"
        ></np-advanced-template-mime>
      </div>
      <div class="d-flex flex-grow-1" *ngIf="cellData.data.fieldType == 'feature'">
        <np-advanced-template-feature
          class="d-flex flex-grow-1"
          [(templateItem)]="cellData.data"
          [customerId]="customerId"
          [fieldList]="fieldService.featureFields"
          [validFeatureSystems]="validFeatureSystems"
          (onUpdate)="update(cellData)"
        ></np-advanced-template-feature>
      </div>
      <div class="d-flex flex-grow-1" *ngIf="cellData.data.fieldType == 'orderdetail'">
        <np-advanced-template-defaultfield
          class="d-flex flex-grow-1"
          [(templateItem)]="cellData.data"
          [customerId]="customerId"
          [fieldList]="fieldService.orderDetailFields"
          (onUpdate)="update(cellData)"
        ></np-advanced-template-defaultfield>
      </div>
      <div class="d-flex flex-grow-1" *ngIf="cellData.data.fieldType == 'price'">
        <np-advanced-template-price
          class="d-flex flex-grow-1"
          [(templateItem)]="cellData.data"
          [customerId]="customerId"
          [fieldList]="fieldService.pricelistFields"
          (onUpdate)="update(cellData)"
        ></np-advanced-template-price>
      </div>
      <div class="d-flex flex-grow-1" *ngIf="cellData.data.fieldType == 'logistics'">
        <np-advanced-template-defaultfield
          class="d-flex flex-grow-1"
          [(templateItem)]="cellData.data"
          [customerId]="customerId"
          [fieldList]="fieldService.logisticsFields"
          (onUpdate)="update(cellData)"
        ></np-advanced-template-defaultfield>
      </div>
      <div class="d-flex flex-grow-1" *ngIf="cellData.data.fieldType == 'reference'">
        <np-advanced-template-listfield
          class="d-flex flex-grow-1"
          [(templateItem)]="cellData.data"
          [customerId]="customerId"
          [fieldList]="fieldService.referenceFields"
          (onUpdate)="update(cellData)"
        ></np-advanced-template-listfield>
      </div>
      <div class="d-flex flex-grow-1" *ngIf="cellData.data.fieldType == 'udx'">
        <np-advanced-template-listfield
          class="d-flex flex-grow-1"
          [(templateItem)]="cellData.data"
          [customerId]="customerId"
          [fieldList]="fieldService.udxFields"
          (onUpdate)="update(cellData)"
        ></np-advanced-template-listfield>
      </div>
      <div class="d-flex flex-grow-1" *ngIf="cellData.data.fieldType == 'seo'">
        <np-advanced-template-defaultfield
          class="d-flex flex-grow-1"
          [(templateItem)]="cellData.data"
          [customerId]="customerId"
          [fieldList]="fieldService.seoFields"
          (onUpdate)="update(cellData)"
        ></np-advanced-template-defaultfield>
      </div>
      <div class="d-flex flex-grow-1" *ngIf="cellData.data.fieldType == 'legal'">
        <np-advanced-template-legal
          class="d-flex flex-grow-1"
          [(templateItem)]="cellData.data"
          [customerId]="customerId"
          [fieldList]="fieldService.legalFields"
          [warrantyClasses]="warrantyClasses"
          [hazmatClasses]="hazmatClasses"
          (onUpdate)="update(cellData)"
        ></np-advanced-template-legal>
      </div>
      <div class="d-flex flex-grow-1" *ngIf="cellData.data.fieldType == 'misc'">
        <np-advanced-template-defaultfield
          class="d-flex flex-grow-1"
          [(templateItem)]="cellData.data"
          [customerId]="customerId"
          [fieldList]="fieldService.miscFields"
          (onUpdate)="update(cellData)"
        ></np-advanced-template-defaultfield>
      </div>
      <div class="d-flex flex-grow-1" *ngIf="cellData.data.fieldType == 'supplier'">
        <np-advanced-template-listfield
          class="d-flex flex-grow-1"
          [(templateItem)]="cellData.data"
          [customerId]="customerId"
          [fieldList]="fieldService.supplierFields"
          (onUpdate)="update(cellData)"
        ></np-advanced-template-listfield>
      </div>
      <div class="d-flex flex-grow-1" *ngIf="cellData.data.fieldType == 'fixed'">
        <np-advanced-template-fixed
          class="d-flex flex-grow-1"
          [(templateItem)]="cellData.data"
          [customerId]="customerId"
          (onUpdate)="update(cellData)"
        ></np-advanced-template-fixed>
      </div>
    </div>
  </div>

  <div *dxTemplate="let cellData of 'cellLanguageTemplate'">
    <dx-select-box
      *ngIf="isTranslatable(cellData.data)"
      [dataSource]="languageFlags"
      [displayExpr]="getLanguageDisplayExpr"
      valueExpr="code"
      [(value)]="cellData.data.languageCode"
      fieldTemplate="field"
      style="width: 55px"
      (onValueChanged)="update(cellData)"
    >
      <div *dxTemplate="let data of 'item'">
        <div class="custom-item">
          <img src="/images/flags/{{ data?.flag }}" class="flag" style="margin-left: -7px" />
        </div>
      </div>
      <div *dxTemplate="let data of 'field'">
        <div class="custom-item">
          <img src="/images/flags/{{ data?.flag }}" class="flag" style="margin: 14px 0 10px" />
          <dx-text-box [value]="data?.text" [readOnly]="true" style="display: none"></dx-text-box>
        </div>
      </div>
    </dx-select-box>
  </div>

  <div *dxTemplate="let cellData of 'cellPimFieldTemplate'">
    <dx-button
      class="btn-row-icon"
      [style]="'color: ' + (hasTemplateItemSettings(cellData.data) ? 'var(--primary)' : 'var(--secondary)') + ';'"
      hint="{{ 'Feldeinstellungen bearbeiten' | translate }}"
      (onClick)="onOpenFieldSettings($event, cellData)"
    >
      <!--
        Gear Icon from https://icons.getbootstrap.com/icons/gear/
        because DevExtreme Gear Icon doesn't looks good.
      -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        class="bi bi-gear-fill"
        viewBox="0 0 16 16"
      >
        <path
          d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"
        />
      </svg>
    </dx-button>

    <dx-popover
      [target]="popoverView.get(cellData)?.target"
      [visible]="popoverView.get(cellData)?.visible"
      [showCloseButton]="true"
      [shading]="true"
      width="auto"
      height="auto"
      position="left"
      (onHiding)="onCloseFieldSettings(cellData)"
    >
      <np-advanced-template-fieldsettings
        [templateItem]="cellData.data"
        [mappings]="wawiListMapping"
        (onUpdate)="update(cellData)"
      ></np-advanced-template-fieldsettings>
    </dx-popover>
  </div>
</dx-data-grid>
