import { EMPTY, Subject, catchError, switchMap, takeUntil, tap, debounceTime, from, Observable } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import 'devextreme/data/odata/store';
import { Column, ColumnCellTemplateData } from 'devextreme/ui/data_grid';
import { ItemClickEvent } from 'devextreme/ui/tab_panel';
import { ValueChangedEvent } from 'devextreme/ui/text_box';
import { confirm } from 'devextreme/ui/dialog';

import { LoginService } from 'app/Services/login.service';
import { TemplateService } from 'app/Services/template.service';
import { WawiListService } from 'app/Services/wawiList.service';
import { ValidFeatureService } from 'app/Services/validFeature.service';
import { SystemService } from 'app/Services/system.service';

import { fieldNames } from 'app/Model/FieldNames';
import { Template } from 'app/Model/Catalog/Template';
import { TemplateItem } from 'app/Model/Catalog/TemplateItem';
import { ValidFeatureSystem } from 'app/Model/ValidFeatureSystem';
import { LanguageFlag } from 'app/Model/Dto/LanguageFlag';
import { WawiList } from 'app/Model/wawi/WawiList';
import { TemplateWorksheet } from 'app/Model/Catalog/TemplateWorksheet';
import { HazmatClassService } from 'app/Services/hazmatClass.service';
import { WarrantyClassService } from 'app/Services/warrantyClass.service';
import { HazmatClass } from 'app/Model/Catalog/HazmatClass';
import { WarrantyClass } from 'app/Model/Catalog/WarrantyClass';

type DropdownFieldSource = { id: string; display: string; translate?: string[] };
type AutocompleteValues = { groupId: string; value: string };
type TemplateSheetItemWrapper =
  | { isHeader: boolean; order: number; name: string; templateItems: TemplateItem[] }
  | TemplateWorksheet;

@Component({
  selector: 'np-expert-template',
  templateUrl: './advanced-template.component.html',
  styleUrls: ['./advanced-template.component.css']
})
export class AdvancedTemplateComponent implements OnInit, OnDestroy {
  private updateSubject = new Subject<Template | undefined>();
  private unsubscribe$ = new Subject<void>();

  @Input() customerId: string;
  @Input() templateId: string;
  @Input() catalogId: string;

  validFeatureSystems: ValidFeatureSystem[] = [];
  wawiListMapping: WawiList[] = [new WawiList(null, '', null, null, null, null)];
  languageFlags: LanguageFlag[] = [];
  hazmatClasses: HazmatClass[];
  warrantyClasses: WarrantyClass[];

  // Das Popover wird in der registrierten Grid Zelle ausgelöst. Hier wird der State für die Darstellung
  // festgehalten und Elemente, die nicht mehr gebraucht werden, werden automatisch über den Garbage Collector
  // bereinigt.
  popoverView = new WeakMap<ColumnCellTemplateData<TemplateItem>, { target: HTMLElement; visible: boolean }>();

  // Die Property wird verwendet, um Seiteneffekte mit Kontrollelementen (wie z.B. ein drag handle)
  // zu berücksichtigen. Das ist speziell notwendig, da die Ebenen einfach gezählt werden und der
  // offset dann entsprechend mit einkalkuliert werden muss.
  columnOffset = 0;

  levelDepth = 0;
  levelChoices: AutocompleteValues[] = [];
  levelChoiceGroups: { [key: string]: string[] };
  sheetNameEditorIndex = -1;
  sheetNameEditorOriginalName: string = null;
  sheetIndex = 0;

  typeFieldSource: DropdownFieldSource[] = [
    {
      id: 'standard',
      display: 'Standard',
      translate: [
        fieldNames.descriptionShort,
        fieldNames.descriptionLong,
        fieldNames.descriptionLongWithoutHtml,
        fieldNames.manufacturerTypeDescription,
        fieldNames.remarks,
        fieldNames.manufacturerName
      ]
    },
    {
      id: 'media',
      display: 'Medien',
      translate: [fieldNames.mimeAlt, fieldNames.mimeSource, fieldNames.mimeDescription]
    },
    { id: 'price', display: 'Preis' },
    {
      id: 'feature',
      display: 'Feature',
      translate: [
        fieldNames.featureSystemGroupName,
        fieldNames.featureSystemGroupName,
        fieldNames.fName,
        fieldNames.fUnit,
        fieldNames.fValue
      ]
    },
    { id: 'udx', display: 'UDX' },
    { id: 'reference', display: 'Reference' },
    { id: 'supplier', display: 'Lieferant' },
    { id: 'fixed', display: 'Festwert' }
  ];

  columns: Column[] = [];
  template: Template;
  worksheets: TemplateWorksheet[];
  sheetItemWrapper: TemplateSheetItemWrapper[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private wawiListService: WawiListService,
    private templateService: TemplateService,
    private loginService: LoginService,
    private validFeatureService: ValidFeatureService,
    private systemService: SystemService,
    private hazmatService: HazmatClassService,
    private warrantyClassService: WarrantyClassService
  ) {}

  ngOnInit() {
    this.levelChoiceGroups = {};

    // An dieser Stelle sind noch keine Level definiert, also kann ich
    // ganz einfach die Translate Funktion ausführen.
    for (const column of this.columns) {
      column.caption = this.translate.instant(column.caption);
    }
    for (const fieldSource of this.typeFieldSource) {
      fieldSource.display = this.translate.instant(fieldSource.display);
    }

    this.route.paramMap
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((params) => this.handleParams(params)),
        switchMap(() => this.loginService.getLanguages()),
        tap(() => (this.languageFlags = this.loginService.languageFlags ?? [])),
        switchMap(() => this.validFeatureService.getSystems(this.customerId, false)),
        tap((validFeatureSystems) => (this.validFeatureSystems = validFeatureSystems)),
        switchMap(() => this.hazmatService.getClasses()),
        tap((hazmatClasses) => (this.hazmatClasses = hazmatClasses)),
        switchMap(() => this.warrantyClassService.getClasses()),
        tap((warrantyClasses) => (this.warrantyClasses = warrantyClasses)),
        switchMap(() => this.templateService.getTemplateById(this.templateId)),
        switchMap(() => this.wawiListService.getAllListsWithoutFields(this.loginService.currentCustomer.id)),
        tap((wawiList) => this.wawiListMapping.push(...wawiList.filter((x) => x.isMapping))),
        catchError((error) => {
          if ('UnauthorizedCustomer' === error) {
            this.router.navigate(['/login', this.customerId], {
              queryParams: { returnUrl: this.route.snapshot.url }
            });
          }
          console.error(error);
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.template = this.templateService.template;
        this.worksheets = this.template.worksheetItems;

        if (this.worksheets.length === 0) {
          this.worksheets.push(
            new TemplateWorksheet(1, this.translate.instant('Tabelle #no', { count: 1 }), this.template.templateItems)
          );
        }

        if (this.worksheets[this.sheetIndex] === undefined) {
          this.sheetIndex = 0;
        }

        if (this.template.useMetadata && this.template.templateType === 'SAP_ARIBA') {
          this.sheetItemWrapper = [{ isHeader: true, order: 0, name: 'Header', templateItems: [] }, this.template.worksheetItems[0]];
        } else {
          this.sheetItemWrapper = this.template.worksheetItems;
        }
      });

    this.updateSubject
      .pipe(
        debounceTime(300), // 300ms Verzögerung, anpassbar
        switchMap((template) => this.performUpdate(template)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: () => (this.sheetNameEditorIndex = -1),
        error: console.error
      });
  }

  ngOnDestroy() {
    this.templateService.template = null;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSelectWorksheet(event: ItemClickEvent) {
    this.sheetIndex = event.itemIndex;
  }

  async onAddWorksheet() {
    const order = this.worksheets.length + 1;
    this.worksheets.push(new TemplateWorksheet(order, this.translate.instant('Tabelle #no', { count: order })));
    this.update();
  }

  async onRemoveWorksheet(index: number) {
    const count = this.worksheets[index]?.templateItems?.length ?? 0;
    let dialogResult = await confirm(
      this.translate.instant('ConfirmDeleteWorksheet', { count }),
      this.translate.instant('Tabellenseite löschen?')
    );
    if (!dialogResult) return;

    this.worksheets.splice(index, 1);
    this.update();
  }

  async onSaveWorksheetRename(event: ValueChangedEvent) {
    this.sheetNameEditorOriginalName = event.previousValue;
    const sheet = this.worksheets[this.sheetNameEditorIndex];
    sheet.order = this.sheetNameEditorIndex + 1;
    this.update();
  }

  async onCancelWorksheetRename(index: number) {
    this.sheetNameEditorIndex = -1;
    const sheet = this.worksheets[index];
    sheet.name = this.sheetNameEditorOriginalName;
    this.update();
  }

  onSavingSAPAriba() {
    if (this.template.templateType === 'SAP_ARIBA') {
      this.template.templateType = null;
    } else {
      this.template.templateType = 'SAP_ARIBA';
      if (!this.template.metadata?.length) {
        const headerIndex = this.template.worksheetItems.findIndex((x) => ['header'].includes(x.name.toLowerCase()));
        const [headerSheet] = this.template.worksheetItems.splice(headerIndex, 1);
        if (headerSheet.templateItems?.length) {
          this.template.metadata = headerSheet.templateItems.map((x) => ({
            name: x.keys[0],
            order: x.order,
            value: x.defaultValue
          }));
        }
      }
    }

    if (this.template.useMetadata && this.template.templateType === 'SAP_ARIBA') {
      this.sheetItemWrapper = [{ isHeader: true, order: 0, name: 'Header', templateItems: [] }, this.template.worksheetItems[0]];
    } else {
      this.sheetItemWrapper = this.template.worksheetItems;
    }
    
    this.update(this.template);
  }

  update(template?: Template) {
    this.updateSubject.next(template);
  }

  private performUpdate(template?: Template): Observable<string> {
    this.template = template ?? this.template;
    return from(this.templateService.saveTemplate(this.template));
  }

  private handleParams(params: ParamMap) {
    this.templateId = this.templateId ?? params.get('templateId');
    this.customerId = this.customerId ?? params.get('customerId');
    this.catalogId = this.catalogId ?? params.get('catalogId');

    if (this.loginService?.currentUser?.customerId !== this.customerId) {
      throw 'UnauthorizedCustomer';
    }

    this.systemService.currentNavigationTitle = this.translate.instant('Experten Templates');
  }
}
