<div>
  <div class="d-flex flex-row">
    <div class="p-2 flex-grow-1" *ngIf="!showList()">

      <dx-select-box [(value)]="feature.value"
                     [showClearButton]="!readOnly"
                     [readOnly]="readOnly"
                     (onValueChanged)="updateFeatureValue($event)"
                     [minSearchLength]="0"
                     [searchEnabled]="true"
                     acceptCustomValue="true"
                     (onCustomItemCreating)="addCustomFeatureValue($event)"
                     valueExpr="value"
                     displayExpr="value"
                     placeholder=""
                     itemTemplate="customItem"
                     (onFocusIn)="requestFeatureValues(feature)"
                     [dataSource]="featureValueDataSource">
        <div *dxTemplate="let featureItem of 'customItem'">
          <div>{{geValueOfFeatureValue(featureItem, feature)}}</div>
        </div>
        <dx-validator *ngIf="!templateView" validationGroup="FEAT">
          <dxi-validation-rule *ngIf="feature.fRequired" type="required" message="{{ 'Pflichteingabe'  | translate }}"></dxi-validation-rule>
          <dxi-validation-rule type="custom" [validationCallback]="validationService.validateField('FVALUE', featureSystem.validFeatureSystemId, feature.validFeatureId)"></dxi-validation-rule>
        </dx-validator>
      </dx-select-box>
    </div>
    <div class="p-2 flex-grow-1" *ngIf="showList()">
      <dx-text-box [value]="valueListText(feature)"
                   [showClearButton]="false"
                   [readOnly]="true">
      </dx-text-box>
    </div>
    <div class="p-2" style="width=30px;">
      <dx-button *ngIf="!showList() && !readOnly" icon="expand" (onClick)="expandButtonClicked()"> </dx-button>
      <dx-button *ngIf="showList()" stylingMode="contained" icon="bulletlist" (onClick)="expandButtonClicked()"> </dx-button>
    </div>
  </div>
</div>

<dx-popup width="80vw"
          [showTitle]="true"
          title="{{ 'Wert bearbeiten' | translate }}"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [(visible)]="expandPopupVisible"
          contentTemplate="popupContent"
          (onHidden)="onPopupHidden()">
  <div *dxTemplate="let data of 'popupContent'">


    <dx-data-grid (onToolbarPreparing)="onSystemToolbarPreparing($event)"
                  [dataSource]="popupFeatureValues"
                  [allowColumnReordering]="true"
                  [rowAlternationEnabled]="true"
                  [allowColumnResizing]="true"
                  [customizeColumns]="customizeColumns">
      <dxo-editing mode="cell" [allowUpdating]="true" [allowDeleting]="!readOnlyUser('featureListDelete') && featuresEditable" [useIcons]="true">
        <dxo-texts confirmDeleteMessage="" confirmDeleteTitle=""></dxo-texts>
      </dxo-editing>

      <dxo-state-storing [enabled]="true" type="localStorage" storageKey="FeatureValuesNewUI"></dxo-state-storing>



      <div *dxTemplate="let toolbaritem of 'addFeatureValueButtonTemplate'">
        <dx-button stylingMode="text" type="default"
                   text="{{ 'Wert Hinzufügen' | translate }}"
                   (onClick)="addFeatureValueClicked()"></dx-button>
      </div>

      <dxi-column caption="{{ 'Wert' | translate }}" dataField="value" cellTemplate="valueTemplate" editCellTemplate="valueTemplate"></dxi-column>

      <div *dxTemplate="let column of 'valueTemplate'" class="d-flex flex-row">
        <div class="flex-fill">
          <dx-select-box *ngIf="!isClassification()"
                         [(value)]="column.data.value"
                         [showClearButton]="!readOnly"
                         [readOnly]="readOnly"
                         [minSearchLength]="0"
                         [searchEnabled]="true"
                         acceptCustomValue="true"
                         (onCustomItemCreating)="addCustomFeatureValue($event)"
                         valueExpr="value"
                         [displayExpr]="featureValueDisplayExpr"
                         placeholder=""
                         (onFocusIn)="requestFeatureValues(feature)"
                         [dataSource]="featureValueDataSource">
          </dx-select-box>
          <dx-select-box *ngIf="isClassification()"
                         [(value)]="column.data.value"
                         [showClearButton]="!readOnly"
                         [readOnly]="readOnly"
                         [minSearchLength]="0"
                         [searchEnabled]="true"
                         acceptCustomValue="true"
                         (onCustomItemCreating)="addCustomFeatureValue($event)"
                         valueExpr="valueId"
                         [displayExpr]="featureValueDisplayExpr"
                         placeholder=""
                         (onFocusIn)="requestFeatureValues(feature)"
                         [dataSource]="featureValueDataSource">
          </dx-select-box>
        </div>
      </div>
    </dx-data-grid>
  </div>
</dx-popup>
