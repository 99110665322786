<fullscreen-page>
  <content-column look="dark">
    <logo slot="toolbar" [routerLink]="['/']" look="dark" route="['/catalogs']"></logo>
    <div slot="content" class="h-100 p-3">
      <dx-scroll-view [useNative]="true">
        <div class="d-block h-100 w-100 mx-auto " style="max-width: 1200px;">

          <div class="d-flex flex-row">
            <div class="flex-fill pr-3 w-50">
              <form (submit)="onRegistration()">
                <dx-form [formData]="registerUser">
                  <dxi-item itemType="group" [colCount]="2" caption="{{ 'Registrierung' | translate }}">
                    <dxi-item>
                      <div *dxTemplate>
                        <dx-text-box [(value)]="registerUser.firstName" [showClearButton]="true" width="100%" label="{{ 'Vorname' | translate }}">
                          <dx-validator>
                            <dxi-validation-rule type="required" message="{{ 'Bitte geben Sie Ihren Vornamen ein' | translate }}."></dxi-validation-rule>
                          </dx-validator>
                        </dx-text-box>
                      </div>
                    </dxi-item>

                    <dxi-item>
                      <div *dxTemplate>
                        <dx-text-box [(value)]="registerUser.lastName" [showClearButton]="true" width="100%" label="{{ 'Nachname' | translate }}">
                          <dx-validator>
                            <dxi-validation-rule type="required" message="{{ 'Bitte geben Sie Ihren Nachnamen ein' | translate }}."></dxi-validation-rule>
                          </dx-validator>
                        </dx-text-box>
                      </div>
                    </dxi-item>

                    <dxi-item [colSpan]="2">
                      <div *dxTemplate>
                        <dx-text-box [(value)]="registerUser.accountName" [showClearButton]="true" width="100%" label="{{ 'Name des Accounts (Name des Unternehmens)' | translate }}">
                          <dx-validator>
                            <dxi-validation-rule type="required" message="{{ 'Bitte geben Sie einen Accountnamen an' | translate }}."></dxi-validation-rule>
                          </dx-validator>
                        </dx-text-box>
                      </div>
                    </dxi-item>

                    <dxi-item [colSpan]="2">
                      <div *dxTemplate>
                        <dx-text-box [(value)]="registerUser.mail" [showClearButton]="true" width="100%" label="{{ 'Email' | translate }}">
                          <dx-validator>
                            <dxi-validation-rule type="required" message="{{ 'Bitte geben Sie Ihre Emailadresse ein' | translate }}."></dxi-validation-rule>
                            <dxi-validation-rule type="email" message="{{ 'Bitte geben Sie eine gültige Emailadresse ein' | translate }}."></dxi-validation-rule>
                          </dx-validator>
                        </dx-text-box>
                      </div>
                    </dxi-item>

                    <dxi-item>
                      <div *dxTemplate>
                        <dx-text-box [(value)]="registerUser.password" mode="password" width="100%" [showClearButton]="true" label="{{ 'Passwort' | translate }}">
                          <dx-validator>
                            <dxi-validation-rule type="required" message="{{ 'Bitte geben Sie Ihr Passwort ein' | translate }}."></dxi-validation-rule>
                          </dx-validator>
                        </dx-text-box>
                      </div>
                    </dxi-item>

                    <dxi-item>
                      <div *dxTemplate>
                        <dx-text-box mode="password" width="100%" [showClearButton]="true" label="{{ 'Passwort wiederholen' | translate }}">
                          <dx-validator #targetValidator>
                            <dxi-validation-rule type="required" message="{{ 'Bitte geben Sie Ihr Passwort erneut ein' | translate }}."></dxi-validation-rule>
                            <dxi-validation-rule type="compare"
                                                 [comparisonTarget]="passwordComparison"
                                                 message="{{ 'Passwörter stimmen nicht überein' | translate }}."></dxi-validation-rule>
                          </dx-validator>
                        </dx-text-box>
                      </div>
                    </dxi-item>

                    <dxi-item [colSpan]="2">
                      <div *dxTemplate>
                        <dx-text-box [(value)]="registerUser.street" [showClearButton]="true" width="100%" label="{{ 'Straße' | translate }}"></dx-text-box>
                      </div>
                    </dxi-item>

                    <dxi-item>
                      <div *dxTemplate>
                        <dx-text-box [(value)]="registerUser.city" [showClearButton]="true" width="100%" label="{{ 'Stadt' | translate }}"></dx-text-box>
                      </div>
                    </dxi-item>

                    <dxi-item>
                      <div *dxTemplate>
                        <dx-text-box [(value)]="registerUser.zipCode" [showClearButton]="true" width="100%" label="{{ 'Postleitzahl' | translate }}"></dx-text-box>
                      </div>
                    </dxi-item>

                    <dxi-item [colSpan]="2">
                      <div *dxTemplate>
                        <dx-select-box [items]="licenses"
                                       [(value)]="registerUser.pimLicense"
                                       width="100%"
                                       label="{{ 'Lizenz' | translate }}"
                                       [readOnly]="registerUser.linkedCustomerId"
                                       (onValueChanged)="onLicenseChanged()">
                        </dx-select-box>
                      </div>
                    </dxi-item>
                  </dxi-item>



                </dx-form>
                <dx-validation-summary id="summary"></dx-validation-summary>

                <div class="dx-field d-flex flex-row">
                  <dx-button stylingMode="text" type="default" text="{{ 'Registrieren' | translate }}" [useSubmitBehavior]="true"></dx-button>
                </div>
              </form>
            </div>
            <div class="flex-fill pr-3 w-50">
              <div> <h6>{{'Ansprechpartner' | translate }}</h6>  </div>
              <dx-accordion [dataSource]="contacts"
                            [selectedItems]="contacts" 
                            [collapsible]="false"
                            [multiple]="true"
                            style="padding-top:30px">
                <div *dxTemplate="let contact of 'title'">
                  <div class="header">{{ contact.contactName }} </div>
                </div>
                <div *dxTemplate="let contact of 'item'">
                  <div class="d-flex flex-row">
                    <div class="w-50">
                      <p *ngIf="contact.contactName">
                        <b>{{ 'Name' | translate }}:</b> {{ contact.contactName }}
                      </p>
                      <p *ngIf="contact.contactEmail">
                        <b>{{ 'E-Mail' | translate }}:</b> {{ contact.contactEmail }}
                      </p>
                      <p *ngIf="contact.contactPhone">
                        <b>{{ 'Telefon' | translate }}:</b> {{ contact.contactPhone }}
                      </p>
                      <p *ngIf="contact.domain">
                        <b>{{ 'Webseite' | translate }}:</b>
                        <a href="{{ contact.domain }}" target="_blank">
                          {{ contact.domain }}
                        </a>
                      </p>
                    </div>
                    <div class="w-50 d-flex justify-content-center align-items-center">
                      <div style="max-width: 240px; overflow: visible;">
                        <img [src]="contact.logoUrl"
                             alt="image"
                             style="width: 100%; height: auto;">
                      </div>
                    </div>
                    </div>
                  </div>
              </dx-accordion>

            </div>
          </div>
        </div>
      </dx-scroll-view>
    </div>
  </content-column>
</fullscreen-page>
