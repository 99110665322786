export class DscContact {
  constructor(
    public id?: string,
    public customerId?: string,
    public logoUrl?: string,
    public contactName?: string,
    public contactEmail?: string,
    public contactPhone?: string,
    public domain?: string,
   
  ) {
  }
}

