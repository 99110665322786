import { Component, ViewChild } from '@angular/core';
import { OxomiSupplier } from '../Model/Dto/Oxomi/OxomiSupplier';
import { TranslateService } from '@ngx-translate/core';
import { OxomiSortimentService } from 'app/Services/oxomiSortiment.service';
import { LoginService } from 'app/Services/login.service';
import { SystemService } from 'app/Services/system.service';
import { HttpErrorResponse } from '@angular/common/http';
import { OAuthService } from "angular-oauth2-oidc";
import { Message } from "../Model/System/Message";
import { JobService } from 'app/Services/job.service';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from "devextreme/excel_exporter";
import { DxDataGridComponent } from "devextreme-angular";
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';

@Component({
  selector: 'oxomi-sortiment',
  templateUrl: './oxomi-sortiment.component.html',
  styleUrl: './oxomi-sortiment.component.css'
})
export class OxomiSortimentComponent {

  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  oxomiSupplierList: OxomiSupplier[] = [];
  loggedInSubscription: any = null;
  addVisible = false;
  excelImportVisible = false;
  importResumeVisible = false;
  excelExportVisible = true;
  supplierWithoutNameExists = false;
  supplierWithoutNumberExists = false;
  missingValues = false;
  excelImportNewCount = 0;
  excelImportUpdateCount = 0;
  excelImportIgnoreCount = 0;
  supplierWithoutName = "";
  supplierWithoutNumber = "";
  newSupplier: OxomiSupplier;

  constructor(public translate: TranslateService, public oxomiSortimentService: OxomiSortimentService, 
    public loginService: LoginService, public systemService: SystemService, private oAuthService: OAuthService, public jobService: JobService) {
    this.systemService.currentNavigationTitle = this.translate.instant("Oxomi Sortiment");
    this.showAdd = this.showAdd.bind(this);
    this.addSupplier = this.addSupplier.bind(this);
    this.showExcelImport = this.showExcelImport.bind(this);    
    this.onUploaded = this.onUploaded.bind(this);

    if (this.loginService.loggedIn)
      this.reloadSupplierList();
    else
      this.loggedInSubscription = this.loginService.onLoggedIn.subscribe(() => this.reloadSupplierList());
  }

  onContentToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'excelExportButtonTemplate',      
    });
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'excelImportButtonTemplate',
    });
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'addSupllierTemplate',
    });
  }

  onRemovedSupplier(e) {
    var supplierToDelete = new OxomiSupplier(e.data.id, e.data.customerId, e.data.number, e.data.name);
    this.oxomiSortimentService.deleteSupplier(supplierToDelete, this.loginService.currentCustomer.id).then((result: boolean) => {
      this.systemService.notifyInfo("Lieferant wurde gelöscht.");
    })
  }

  onUpdatedSupplier(e) {
    var supplierToUpdate = new OxomiSupplier(e.data.id, e.data.customerId, e.data.number, e.data.name);  
    if (supplierToUpdate.number == "") {
      this.systemService.notifyInfo("Bitte einen Lieferantennummer angeben!");
      return;
    }
    if (supplierToUpdate.name == "") {
      this.systemService.notifyInfo("Bitte einen Lieferantennamen angeben!");
      return;
    }

    this.oxomiSortimentService.updateSupplier(supplierToUpdate, this.loginService.currentCustomer.id).then((result: boolean) => {
      this.systemService.notifyInfo("Lieferant wurde geändert.");
      this.reloadSupplierList();
    }).catch(error => {
      var response = new HttpErrorResponse(error);
      if (response.status == 409) {
        this.systemService.notifyInfo("Lieferant konnte nicht gändert werden! Ein Lieferant mit der Lieferantennummer " + response.error.number + " ist bereits vorhanden.");        
        this.reloadSupplierList();
      }
    });    
  }  

  getDataSource(): OxomiSupplier[] {    
    return this.oxomiSupplierList;
  }

  showAdd() {
    this.newSupplier = new OxomiSupplier(undefined,this.loginService.currentCustomer.id,"","");
    this.addVisible = true;
  }

  showExcelImport() {
    this.excelImportVisible = true;
  }

  onBeforeSendUpload(e: { request: XMLHttpRequest }) {
    e.request.setRequestHeader("customerId", this.loginService.currentUser?.customerId);
    e.request.withCredentials = true;
    e.request.setRequestHeader('Authorization', 'Bearer ' + this.oAuthService.getAccessToken());
  }

  onUploaded(e: { request: XMLHttpRequest }) {
    var response = JSON.parse(e.request.response);
    this.excelImportNewCount = response.imported;
    this.excelImportUpdateCount = response.updated;
    if(response.supplierWithoutNumber && response.supplierWithoutNumber.length > 0) {
        for(var i=0; i<response.supplierWithoutNumber.length; i++) {
          if(this.supplierWithoutNumber != "") {
            this.supplierWithoutNumber += ", " + response.supplierWithoutNumber[i].name;
          }
          else {
            this.supplierWithoutNumber += response.supplierWithoutNumber[i].name;
          }
        }
        this.supplierWithoutNumberExists = true;
    }
    if(response.supplierWithoutName && response.supplierWithoutName.length > 0) {
        for(var i=0; i<response.supplierWithoutName.length; i++) {
          if(this.supplierWithoutName != "") {
            this.supplierWithoutName += ", " + response.supplierWithoutName[i].number;
          }
          else {
            this.supplierWithoutName += response.supplierWithoutName[i].number;
          }
        }
        this.supplierWithoutNameExists = true;
    }
    this.missingValues = this.supplierWithoutNameExists || this.supplierWithoutNumberExists;
    this.excelImportIgnoreCount = response.supplierWithoutNumber.length + response.supplierWithoutName.length;
    this.excelImportVisible = false;
    this.importResumeVisible = true;
    this.reloadSupplierList();
  }

  onUploadError(e: { request: XMLHttpRequest }) {
    var response = JSON.parse(e.request.response);
    this.excelImportVisible = false;
    this.systemService.notifyWarning(this.translate.instant(response.errorKey));
  }

  excelExport() {    
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Supplier');
    var component = this.dataGrid.instance;
    var cols = component.getVisibleColumns(); 
    var numberIndex = 0;
    var nameIndex = 1;
    if (cols[0].dataField == "name") {
      numberIndex = 1;
      nameIndex = 0;
    }
    
    exportDataGrid({
      component: this.dataGrid.instance,
      worksheet,
      autoFilterEnabled: true,
    })
    .then(() => {
      worksheet.columns[numberIndex].header = 'Lieferantennummer [partnerNumbers]';
      worksheet.columns[nameIndex].header = 'Daten-Lieferant [partner]';
      worksheet.columns = [...worksheet.columns, { header: 'Status [state]', key: 'state'}];
      worksheet.getRows(2, worksheet.actualRowCount-2).forEach(row => {
        row.getCell(3).value = 'Bestätigt';
      })
    })
    .then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Supplier.xlsx');
      });
    });
  }

  addSupplier() {
    if (this.newSupplier.number == "") {
      this.systemService.notifyInfo("Bitte einen Lieferantennummer angeben!");
      return;
    }
    if (this.newSupplier.name == "") {
      this.systemService.notifyInfo("Bitte einen Lieferantennamen angeben!");
      return;
    }

    this.oxomiSortimentService.createSupplier(this.newSupplier, this.loginService.currentCustomer.id).then((result: OxomiSupplier) => {
      this.systemService.notifyInfo("Lieferant wurde angelegt");
      this.addVisible = false;
      this.reloadSupplierList();
    }).catch(error => {
      var response = new HttpErrorResponse(error);
      if (response.status == 409) {
        this.systemService.notifyInfo("Lieferant konnte nicht angelegt werden! Ein Lieferant mit der Lieferantennummer " + response.error.number + " ist bereits vorhanden.");
        this.reloadSupplierList();
      }
    });
  }

  reloadSupplierList() {
    this.oxomiSortimentService.getOxomiSupplier(this.loginService.currentCustomer.id).then((result: OxomiSupplier[]) => {
      this.oxomiSupplierList = result;
      this.excelExportVisible = false;
      if(result.length > 0) {
        this.excelExportVisible = true;
      }
    });
  }
}
