import { Component, OnInit } from "@angular/core";
import { CatalogService } from "../../Services/CatalogManagement/catalog.service";
import { TranslationService } from "../../Services/translation.service";
import { Product } from "../../Model/Catalog/Product";
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: "shopViewLukas",
  templateUrl: "shopViewLukas.component.html",
  styleUrls: ["shopViewLukas.component.css"]
})
export class ShopViewLukas implements OnInit {

  constructor(private catalogService: CatalogService, public translationService: TranslationService, public translate: TranslateService) {


  }

  ngOnInit(): void {
    this.translateTexts();


    this.lukasParts = [
      { "headLine": this.txtBeschreibung, "type": "desc" }];
    if (this.catalogService.product != null) {
      this.load();
    } else {
      this.loadDone = false;
    }
  }

  txtBeschreibung: string;
  txtDokumente: string;
  txtAnwendungen: string;
  txtTechnischeDaten: string;
  txtVorteile: string;


  translateTexts() {
    this.txtBeschreibung = this.translate.instant("Beschreibung");
    this.txtDokumente = this.translate.instant("Dokumente");
    this.txtAnwendungen = this.translate.instant("Anwendungen");
    this.txtTechnischeDaten = this.translate.instant("Technische Daten");
    this.txtVorteile = this.translate.instant("Vorteile");

  }

  get downloadLink(): string {
    return "/Cloud/false/" + this.model.catalogMappings[0].catalogId + "/" + this.model.customerId + "/"; // .catalogMappings[0]. ist hier OK
  }

  loadDone: boolean = true;

  load() {
    if (this.catalogService.product.benefitsFeatures.length > 0) {
      this.lukasParts.push({ "headLine": this.txtVorteile, "type": "befits" });
    }
    if (this.catalogService.product.technicalFeatures.length > 0) {
      this.lukasParts.push({ "headLine": this.txtTechnischeDaten, "type": "tech" });
    }
    if (this.catalogService.product.usageFeatures.length > 0) {
      this.lukasParts.push({ "headLine": this.txtAnwendungen, "type": "use" });
    }
    if (this.catalogService.product.productDataSheets.length > 0) {
      this.lukasParts.push({ "headLine": this.txtDokumente, "type": "download" });
    }
  }

  lukasParts = [];

  get model(): Product {
    if (!this.loadDone && this.catalogService.product!=null) {
      this.loadDone = true;
      this.load();
    }
    if (this.translationService.productTranslation != null)
      return this.translationService.productTranslation;

    return this.catalogService.product;
  }

  get mimes() {
    if (this.translationService.productTranslation != null)
      return this.translationService.productTranslation.productImagesNormal;

    return this.catalogService.product.productImagesNormal;
  }

  get features() {
    if (this.translationService.productTranslation != null)
      return this.translationService.productTranslation.features;

    return this.catalogService.product.features;
  }

  get showIndicator() {
    if (this.catalogService.product.productImagesNormal.length == 1) {
      return false;
    }
    return true;
  }

  get marketingClaim() {
    if (this.translationService.productTranslation != null)
      return this.translationService.productTranslation.marketingClaim;
    return this.catalogService.product.marketingClaim;
  }

  get hasPrice() {
    if (this.catalogService.product.price != null && this.catalogService.product.price.prouctPrice != "") {
      return true;
    }
    return false;
  }

  get price() {
    return this.catalogService.product.price;
  }

  forLoopArray(elements): Array<number> {
    return new Array(parseInt(elements));
  }

  getTileImageWidth(ratio) {
    return ratio * 150;
  }

  widthRatio(ratio) {
    switch (ratio) {
      case 1:
        return 1;
      case 2:
        return 2;
      case 3:
        return 3;
      case 11:
        return 1;
      case 12:
        return 1;
      case 13:
        return 1;
      case 21:
        return 2;
      case 22:
        return 2;
      case 23:
        return 2;
      case 31:
        return 3;
      case 32:
        return 3;
      case 33:
        return 3;
    }
    return 1;
  }

  heightRatio(ratio) {
    switch (ratio) {
      case 1:
        return 1;
      case 2:
        return 2;
      case 3:
        return 3;
      case 11:
        return 1;
      case 12:
        return 2;
      case 13:
        return 3;
      case 21:
        return 1;
      case 22:
        return 2;
      case 23:
        return 3;
      case 31:
        return 1;
      case 32:
        return 2;
      case 33:
        return 3;
    }
    return 1;
  }
  get ean() {
    if (this.catalogService.product.internationalPidType == 'gtin' && this.catalogService.product.internationalPid.length == 13) {
      return this.catalogService.product.internationalPid;
    }
    return "";
  }
}
