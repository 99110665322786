export class TreeItem {

  constructor(
    public id?: string | number,
    public text?: string,
    public parent?: string,
    public type?: string,
    public uniqueProductCount?: number,
    public productCount?: number,
    public categoryCount?: number,
    public hasChilds?: boolean,
    public isChild?: boolean,
    public imageUrl?: string,
    public lastImageLookup?: Date,
    public selected?: boolean,
    public order?: number,
    public treeElementId?: number,
    public supplierPid?: string,
    public groupString?: string,
    public catalogMappingCount?: number
  ) {
  }
}
