import { Component, OnInit, ViewChild } from "@angular/core";
import { Catalog } from "../../Model/Catalog/Catalog";
import { Category } from "../../Model/Catalog/Category";
import { Product } from "../../Model/Catalog/Product";
import { TreeItem as TreeElement } from "../../Model/Dto/TreeItem";
import { UrlResponse } from "../../Model/Dto/UrlResponse";
import { DxTreeListComponent } from "devextreme-angular";
import notify from "devextreme/ui/notify";
import { ModelService } from "../../Services/model.service";
import { Clipboard } from "../../Model/Dto/Clipboard";
import { ProductState } from "../../Model/Catalog/ProductState";
import { CustomerWiki } from "../../Model/System/CustomerWiki";
import { LanguageFlag } from "../../Model/Dto/LanguageFlag";
import { TranslateService } from '@ngx-translate/core';
import { custom } from 'devextreme/ui/dialog';
import { UserManagementService } from "../../Services/userManagment.service";
import { MassDataChangeService } from "../../Services/CatalogManagement/massDataChange.service";
import { ProductLogistic } from "../../Model/Catalog/ProductLogistic";
import { ActivatedRoute, Router } from "@angular/router";
import dxTreeList from "devextreme/ui/tree_list";
import { Location } from '@angular/common';
import { ValidationService } from "../../Services/Validation/validation.service";


@Component({
  selector: "catalog",
  templateUrl: "browse.component.html",
  styleUrls: ["./browse.component.css"]
})
export class CatalogComponent implements OnInit {
  @ViewChild(DxTreeListComponent, { static: true })
  treeList: DxTreeListComponent;

  public dataSource: any;
  public expandedRowKeys: any = [];
  public selectedCatalogId: string;
  public selectedCategoryId: string;
  public selectedProductId: string;
  public treeSelectedRowKeys: any = [];
  public productStates: string[] = new Array<string>();
  public languageCodes: string[];
  public massDataChange: boolean = false;
  public massGenerateKITexts: boolean = false;
  public searchText = "";
  public showFilter: boolean = false;

  constructor(public modelService: ModelService, public translate: TranslateService, public validationService: ValidationService,
    public massDataService: MassDataChangeService, public userManagment: UserManagementService, private route: ActivatedRoute, private router: Router,
    private location: Location) {
    let customerId = this.route.snapshot.paramMap.get('customerId')!;
    if (customerId != this.modelService.loginService.currentCustomer.id) {
      this.modelService.router.navigate(["/login/" + customerId], { queryParams: { returnUrl: this.route.snapshot.url } });
      return;
    }

    this.userActionWindow = this.userActionWindow.bind(this);
    this.userActionFullScreen = this.userActionFullScreen.bind(this);
    this.buildContextMenu = this.buildContextMenu.bind(this);
    this.treeListMode = this.treeListMode.bind(this);
    this.setEmptyProduct = this.setEmptyProduct.bind(this);
    this.massCancel = this.massCancel.bind(this);
    this.saveGridInstance = this.saveGridInstance.bind(this);
    this.onContentReady = this.onContentReady.bind(this);

    this.modelService.catalogService.selectedCatalogId = this.route.snapshot.paramMap.get('catalogId')!;
    if (this.modelService.catalogService.selectedCatalogId == "") {
      this.modelService.router.navigate(["/"]);
      return;
    }

    let that = this;
    if (this.modelService.catalogService.catalog == null) {
      this.modelService.catalogService.getCatalog(this.modelService.catalogService.selectedCatalogId, this.modelService.loginService.currentUser.customerId).subscribe(catalog => {
        this.selectedCatalogId = catalog.id;
      });

    }

    this.translateTexts();





    this.dataSource = {
      load: async function (loadOptions) {
        let parentId = "";
        if (loadOptions.parentIds != null) {
          parentId = loadOptions.parentIds.join(",");
        }


        that.childs = await that.modelService.catalogService.getChilds(
          that.modelService.catalogService.selectedCatalogId, 
          parentId, 
          that.modelService.loginService.currentUser.customerId, 
          loadOptions.filter, 
          that.modelService.catalogService.selectedStates,
          that.modelService.loginService.productSearchOptions,
          that.modelService.catalogService.selectedStatesMode);

        let products = that.childs.filter(x => x.type == "pro");
        if (products && products.length > 999 && that.isSearch(that.searchText, loadOptions.filter, that.modelService.catalogService.selectedStates)) {
          that.modelService.systemService.notifyInfo(that.translate.instant("Das Suchergebnis wurde auf 1000 Produkten eingeschränkt"));
        }

        return that.childs;
      }
    };


    //select das entsprechnde TreeItem jenachdem ob Parameter im Link stehen
    let productId = this.route.snapshot.paramMap.get('productId')!;
    let categoryId = this.route.snapshot.paramMap.get('categoryId')!;
    let catalogId = this.route.snapshot.paramMap.get('catalogId')!;
    if (catalogId != null) {
      if (!this.modelService.catalogService.selectedCatalogId) {
        this.modelService.catalogService.selectedCatalogId = catalogId;
      }
    }

    if (productId != null && catalogId != null) {
      this.modelService.catalogService.GetProductTreeItem(productId, this.modelService.loginService.currentCustomer.id, "", catalogId).subscribe(prod => {
        this.selectProductAfterSearch = prod;
      }, () => {
        console.log();
      });
    } else if (categoryId != null && catalogId != null) {
      this.modelService.catalogService.getCategory(categoryId, this.modelService.loginService.currentCustomer.id).subscribe(category => {
        this.selectCategoryAfterSearch = category.id;
      });
    } else if (catalogId != null) {
      this.treeSelectedRowKeys = [catalogId];

    } else if (this.modelService.catalogService.selectedCatalogId != null) {
      this.treeSelectedRowKeys = [this.modelService.catalogService.selectedCatalogId];
    } else {
      this.modelService.systemService.notifyWarning(this.translate.instant("Der verlinkte Katalog existiert nicht in diesem Account"));
      this.modelService.router.navigate(["/catalogs"]);
    }


    this.modelService.systemService.currentNavigationTitle = this.translate.instant("Katalog");
  }

  isSearch(searchText: string, filter: any, states: string[]) {
    if (states && states.length > 0)
      return true;

    if (filter && filter.filterValue)
      return true;

    if (searchText)
      return true;

    return false;
  }

  ngOnInit(): void {
    this.refresh();

    this.modelService.loginService.getLanguages().subscribe(() => {
      this._languageFlags = this.modelService.loginService.languageFlags;
    });
    this.selectedTranslateLanguage = this.modelService.loginService.selectedTranslateLanguage;
    //  this.refresh();
    this.selectedStates = [];
    this.openItem("cat", this.modelService.catalogService.selectedCatalogId, false);

    this.modelService.catalogService.startMassGenerateKITexts.subscribe(() => {
      this.showMassGenerateKITexts();
    });
  }

  TreeListInstance: dxTreeList;
  saveGridInstance(e) {
    this.TreeListInstance = e.component;
  }

  onSearchChanged(text) {
    let i = 0;
  }

  selectProductAfterSearch: TreeElement;
  selectCategoryAfterSearch: string;
  async onContentReady(e) {
    if (this.selectCategoryAfterSearch == null && this.selectProductAfterSearch == null) {
      this.selectCategoryAfterSearch = null;
      this.selectProductAfterSearch = null;
      return;
    }
    let productTreeElement = this.selectProductAfterSearch;
    let categoryId = this.selectCategoryAfterSearch;
    this.selectCategoryAfterSearch = null;
    this.selectProductAfterSearch = null;

    if (productTreeElement != null) {
      await this.expandCategoryTreeToTheTop(productTreeElement.parent);
      await this.TreeListInstance.expandRow(productTreeElement.parent);
      await this.TreeListInstance.selectRows([productTreeElement.treeElementId], false);
    } else {
      await this.expandCategoryTreeToTheTop(categoryId);
      await this.TreeListInstance.expandRow(categoryId);
      await this.TreeListInstance.selectRows([categoryId], false);
    }
  }

  async expandCategoryTreeToTheTop(categoryId: string): Promise<void> {
    let category = await this.modelService.catalogService.getCategory(categoryId, this.modelService.loginService.currentCustomer.id).toPromise();

    if (category.parentCategoryId == "000000000000000000000000") {
      await this.TreeListInstance.expandRow(category.catalogId);
      return;
    }
    await this.expandCategoryTreeToTheTop(category.parentCategoryId);
    await this.TreeListInstance.expandRow(category.parentCategoryId);
  }

  set selectedStatesMode(value: string) {
    this.modelService.catalogService.selectedStatesMode = value;
  }

  get selectedStatesMode() {
    return this.modelService.catalogService.selectedStatesMode;
  }

  set selectedStates(values: string[]) {
    this.modelService.catalogService.selectedStates = values;
  }

  get selectedStates(): string[] {
    return this.modelService.catalogService.selectedStates;
  }

  updateState({ mode, states }: { mode:string; states: string[] }) {
    this.selectedStatesMode = mode;
    this.selectedStates = states;
    this.modelService.catalogService.collapsTree();
    this.modelService.catalogService.treeFilter();
  }

  toggleFilter() {
    if (this.showFilter) {
      this.selectedStates = new Array<string>();
      this.showFilter = false;
    } else {
      this.showFilter = true;
    }
  }

  _languageFlags: LanguageFlag[];

  get languageFlags(): LanguageFlag[] {
    return this._languageFlags;
  }

  set languageFlags(value: LanguageFlag[]) {
    this._languageFlags = value;
  }

  refresh() {
    this.modelService.systemService.showMenu = true;
    this.modelService.catalogService.resetConnection();
    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(["/"]);
      return;
    }
    this.modelService.catalogService.treeList = this.treeList;
    if (this.modelService.loginService.currentUser != null) {
      /*      this.modelService.catalogService.getCatalog(this.modelService.catalogService.selectedCatalogId, this.modelService.loginService.currentUser.customerId)
              .subscribe(() => {
                this.model = this.modelService.catalogService.catalog;
                if (this.modelService.loginService.hasAddonShopWare && this.model.isPublic) {
                  this.modelService.catalogService.testShopConnection();
                }

                //this.openItem("cat", this.modelService.catalogService.selectedCatalogId, false);

              });*/


      this.modelService.catalogService.getCustomerProductStates(this.modelService.loginService.currentUser.customerId)
        .subscribe((states:
          ProductState[]) => {
          this.modelService.productStateService.productStates = new Array<string>();

          states.forEach(s => {
            this.modelService.productStateService.productStates.push(s.name);
          });
        });


      this.modelService.catalogService.getLanguageCodes().subscribe(() => this.languageCodes = this.modelService.catalogService.languageCodes);

    }
    this.modelService.loginService.selectedTranslateLanguage = this.modelService.loginService.currentCustomer.defaultLanguage;
    this.modelService.catalogService.isCatalogSelected = true;
    this.modelService.catalogService.isCategorySelected = false;
    this.modelService.catalogService.isProductSelected = false;
    this.modelService.translationService.productTranslation = null;
  }

  get selectedTranslateLanguage() {
    this.modelService.loginService.setLanguageFlags();
    return this.modelService.loginService.selectedTranslateLanguage;
  }

  set selectedTranslateLanguage(value) {
    this.modelService.loginService.selectedTranslateLanguage = value;
    this.modelService.translationService.productTranslation = null;
    if (this.modelService.catalogService.isProductSelected)
      this.modelService.translationService.getProductTranslation(this.modelService.catalogService.catalog.id, this.modelService.catalogService.product.id);
  }

  //MENU
  public tree: TreeElement[];
  public childs: TreeElement[];

  get canChangePassword() {
    if (this.modelService.loginService.currentUser != null) {
      return true;
    }
    return false;
  }

  //CONTEXTMENU
  private contextMenuVisible: boolean;
  public menuItems = [];

  showContextMenu() {
    this.contextMenuVisible = true;
  }

  onContextMenuShowing(e, item) {
    this.modelService.catalogService.selectTreeElement(item.key);
  }

  async itemClick(e, itemClickData) {
    let treeId = itemClickData.data.treeElementId;

    let txtYes = "";
    let txtNo = "";
    let txtMsg = "";
    let txtTitle = "";
    let myDialog: any;


    if (!e.itemData.items) {
      switch (e.itemData.id) {
        case 1: //Katalog duplizieren
          notify({ message: e.itemData.text + " was clicked", position: { my: "center top", at: "center top" } }, "error", 1500);
          break;
        case 2: // Kategorie hinzufügen
          if (this.modelService.catalogService.isCatalogSelected) {
            this.modelService.catalogService.addCategory(this.modelService.catalogService.catalog.id, this.modelService.loginService.currentUser.customerId, treeId);
            break;
          }
          await this.modelService.catalogService.addCategory(this.selectedCategoryId, this.modelService.loginService.currentUser.customerId, treeId);
          break;
        case 5: // Produkt hinzufügen
          if (this.modelService.catalogService.isCategorySelected) {
            await this.modelService.catalogService.addProduct(this.selectedCategoryId, this.modelService.loginService.currentUser.customerId, treeId);
          } else {
            await this.modelService.catalogService.addProduct(this.selectedProductId, this.modelService.loginService.currentUser.customerId, treeId);
          }
          break;
        case 6: //Item einfügen in Kategorie
          this.modelService.catalogService.pasteItem(this.selectedCategoryId);
          break;
        case 9: //Produkt einfügen in Produkt
          this.modelService.catalogService.pasteItem(this.selectedProductId);
          break;
        case 14: //Kategorie einfügen
          this.modelService.catalogService.pasteItem(this.modelService.catalogService.catalog.id);
          break;
        case 7: // Kategorie löschen

          this.translate.get("Ja").subscribe((text: string) => {
            txtYes = text;
          });
          this.translate.get("Nein").subscribe((text: string) => {
            txtNo = text;
          });
          this.translate.get("Soll die Kategorie wirklich gelöscht werden?").subscribe((text: string) => {
            txtMsg = text;
          });
          this.translate.get("Wirklich löschen?").subscribe((text: string) => {
            txtTitle = text;
          });

          myDialog = custom({
            title: txtTitle,
            messageHtml: txtMsg,
            buttons: [{
              text: txtYes,
              onClick: (e) => {
                return { buttonText: true };
              }
            },
            {
              text: txtNo,
              onClick: (e) => {
                return { buttonText: false };
              }
            }
            ]
          });
          myDialog.show().then((dialogResult) => {
            if (dialogResult.buttonText == true) {
              this.modelService.catalogService.remCategory(this.modelService.catalogService.category, treeId);
            }
          });



          break;
        case 8: // Produkt duplizieren
          let selectedRowObj = this.treeList.instance.getNodeByKey(treeId);
          let parentNodeKey = selectedRowObj.parent.key;
          this.modelService.catalogService.duplicateItem(parentNodeKey, false, this.modelService.loginService.currentUser.customerId, this.selectedProductId);
          break;
        case 0: // Remove löschen
          this.translate.get("Ja").subscribe((text: string) => {
            txtYes = text;
          });
          this.translate.get("Nein").subscribe((text: string) => {
            txtNo = text;
          });
          this.translate.get("Soll das Produkt wirklich gelöscht werden?").subscribe((text: string) => {
            txtMsg = text;
          });
          this.translate.get("Wirklich löschen?").subscribe((text: string) => {
            txtTitle = text;
          });

          myDialog = custom({
            title: txtTitle,
            messageHtml: txtMsg,
            buttons: [{
              text: txtYes,
              onClick: (e) => {
                return { buttonText: true };
              }
            },
            {
              text: txtNo,
              onClick: (e) => {
                return { buttonText: false };
              }
            }
            ]
          });
          myDialog.show().then((dialogResult) => {
            if (dialogResult.buttonText == true) {
              this.modelService.catalogService.remProduct(this.modelService.catalogService.product, treeId);
            }
          });

          break;
        case 10: //Kategorie kopieren
          var clipboard = new Clipboard();
          clipboard.customerId = this.modelService.loginService.currentUser.customerId;
          clipboard.userId = this.modelService.loginService.currentUser.id;
          clipboard.isCut = false;
          clipboard.objectId = this.selectedCategoryId;
          clipboard.content = "grp";
          this.modelService.catalogService.saveClipboard2Subscribe(clipboard).subscribe(() => {
            var selectedRowData = this.modelService.catalogService.treeList.instance.getSelectedRowsData()[0];
            this.buildContextMenu(selectedRowData.isChild, selectedRowData.type);
          });
          break;
        case 11: //Kategorie ausschneiden
          var clipboard = new Clipboard();
          clipboard.customerId = this.modelService.loginService.currentUser.customerId;
          clipboard.userId = this.modelService.loginService.currentUser.id;
          clipboard.isCut = true;
          clipboard.objectId = this.selectedCategoryId;
          clipboard.content = "grp";
          this.modelService.catalogService.saveClipboard(clipboard);
          break;
        case 12: //Produkt kopieren
          var clipboard = new Clipboard();
          clipboard.customerId = this.modelService.loginService.currentUser.customerId;
          clipboard.userId = this.modelService.loginService.currentUser.id;
          clipboard.isCut = false;
          clipboard.isMaster = this.product.productCount > 0;
          clipboard.objectId = this.selectedProductId;
          clipboard.content = "pro";
          this.modelService.catalogService.saveClipboard2Subscribe(clipboard).subscribe(() => {
            var selectedRowData = this.modelService.catalogService.treeList.instance.getSelectedRowsData()[0];
            this.buildContextMenu(selectedRowData.isChild, selectedRowData.type);
          });
          break;
        case 13: //Produkt ausschneiden
          var clipboard = new Clipboard();
          clipboard.customerId = this.modelService.loginService.currentUser.customerId;
          clipboard.userId = this.modelService.loginService.currentUser.id;
          clipboard.isCut = true;
          clipboard.isMaster = this.product.productCount >= 0;
          clipboard.objectId = this.selectedProductId;
          clipboard.content = "pro";
          this.modelService.catalogService.saveClipboard2Subscribe(clipboard).subscribe(() => {
            var selectedRowData = this.modelService.catalogService.treeList.instance.getSelectedRowsData()[0];
            this.buildContextMenu(selectedRowData.isChild, selectedRowData.type);
          });
          break;
        case 20: //Als Kategorie kopieren
          var clipboard = new Clipboard();
          clipboard.customerId = this.modelService.loginService.currentUser.customerId;
          clipboard.userId = this.modelService.loginService.currentUser.id;
          clipboard.isCut = false;
          clipboard.objectId = this.selectedCatalogId;
          clipboard.content = "cat";
          this.modelService.catalogService.saveClipboard2Subscribe(clipboard).subscribe(() => {
            var selectedRowData = this.modelService.catalogService.treeList.instance.getSelectedRowsData()[0];
            this.buildContextMenu(selectedRowData.isChild, selectedRowData.type);
          });
          break;
        case 21:
          //Katalog einfügen
          this.modelService.catalogService.pasteItem(this.modelService.catalogService.catalog.id);
          break;
        case 22:
          //Item einfügen
          this.modelService.catalogService.pasteItem(this.selectedCategoryId);
          break;
        case 23:
          this.showUserActionWindow();
          break;
        case 24:
          //Massendatenbearbeitung Produkt als Vorlage benutzen
          this.modelService.catalogService
            .getProduct(this.selectedProductId, this.modelService.loginService.currentCustomer.id, this.modelService.catalogService.catalog.id).subscribe((result: Product) => {
              this.emptyProduct = this.modelService.productService.getProductFromJson(result, this.modelService.loginService.wawiSettings);
            });
          break;
      }
    }
  }

  //BROWSING
  public model: Catalog;
  public category: Category;
  public _product: Product;

  get product(): Product {
    return this._product;
  }

  set product(value: Product) {
    this._product = value;
  }

  get isCatalogSelected() {
    return this.modelService.catalogService.isCatalogSelected;
  }

  get isCategorySelected() {
    return this.modelService.catalogService.isCategorySelected;
  }

  get isProductSelected() {
    return this.modelService.catalogService.isProductSelected;
  }

  isVirtualCatalog(): boolean {
    return this.model?.isVirtual;
  }

  onCellPrepared(e) {
    if (!e.data.imageUrl) {
      if (this.modelService.catalogService.catalog && this.modelService.catalogService.catalog.lastImageUpload > e.data.lastImageLookup) {
        this.modelService.catalogService.getThumbUrl(e.data.id,
          e.data.type,
          this.modelService.loginService.currentUser.customerId,
          this.modelService.catalogService.selectedCatalogId).subscribe(
            (url: UrlResponse) => {
              if (url) e.data.imageUrl = url.url;
              e.data.lastImageLookup = new Date().getTime();
            });
      }
    }

  }

  onSelectItem(e) {

    if (e.selectedRowsData.length > 0) {
      this.modelService.catalogService.selectedNode = e.node;
      let selectedRowData = e.selectedRowsData[0];
      this.openItem(selectedRowData.type, selectedRowData.id, selectedRowData.isChild);
    }
  }

  txtKategorieHinzufuegen: string;
  txtKategorieEinfuegen: string;
  txtKatalogEinfuegen: string;
  txtProduktHinzufuegen: string;
  txtVarianteHinzufuegen: string;
  txtKategorieLoeschen: string;
  txtKategorieKopieren: string;
  txtProduktEinfuegen: string;
  txtProduktKopieren: string;
  txtProduktLoeschen: string;
  txtProduktDuplizieren: string;
  txtKopierenAlsKategorie: string;






  private translateTexts() {
    this.txtKategorieHinzufuegen = this.translate.instant("Kategorie hinzufügen");

    this.txtKategorieEinfuegen = this.translate.instant("Kategorie einfügen");
    this.txtKatalogEinfuegen = this.translate.instant("Katalog einfügen");

    this.txtProduktHinzufuegen = this.translate.instant("Produkt hinzufügen");
    this.txtVarianteHinzufuegen = this.translate.instant("Variante hinzufügen");
    this.txtKategorieLoeschen = this.translate.instant("Kategorie löschen");
    this.txtKategorieKopieren = this.translate.instant("Kategorie kopieren");
    this.txtProduktEinfuegen = this.translate.instant("Produkt einfügen");
    this.txtProduktKopieren = this.translate.instant("Produkt kopieren");
    this.txtKopierenAlsKategorie = this.translate.instant("Kopieren als Kategorie");
    this.txtProduktLoeschen = this.translate.instant("Produkt löschen");
    this.txtProduktDuplizieren = this.translate.instant("Produkt duplizieren");
  }

  private openItem(type: string, id: string, isChild: boolean) {
    this.modelService.catalogService.isCategorySelected = false;
    this.modelService.catalogService.isCatalogSelected = false;
    this.modelService.catalogService.isProductSelected = false;
    this.modelService.translationService.productTranslation = null;


    this.modelService.catalogService.product = null;
    this.modelService.catalogService.category = null;

    switch (type) {
      case "cat":
        this.modelService.catalogService.isCatalogSelected = true;
        this.selectedCatalogId = id;
        this.location.go("/catalog/" + this.modelService.loginService.currentCustomer.id + "/" + this.selectedCatalogId);
        this.modelService.catalogService.getCatalog(this.modelService.catalogService.selectedCatalogId, this.modelService.loginService.currentUser.customerId)
          .subscribe(() => {
            this.model = this.modelService.catalogService.catalog;
          });
        break;

      case "grp":
        this.modelService.catalogService.isCategorySelected = true;
        this.selectedCategoryId = id;
        this.location.go("/catalog/" + this.modelService.loginService.currentCustomer.id + "/" + this.selectedCatalogId + "/category/" + this.selectedCategoryId);
        this.modelService.catalogService.getCategory(id, this.modelService.loginService.currentUser.customerId)
          .subscribe(() => {
            this.category = this.modelService.catalogService.category;
          });
        break;

      case "pro":
        this.modelService.catalogService.isProductSelected = true;
        this.selectedProductId = id;
        this.location.go("/catalog/" + this.modelService.loginService.currentCustomer.id + "/" + this.selectedCatalogId + "/product/" + this.selectedProductId);
        this.modelService.catalogService
          .getProduct(id, this.modelService.loginService.currentUser.customerId, this.modelService.catalogService.catalog.id)
          .subscribe(() => {
            this.product = this.modelService.catalogService.product;
            if (this.modelService.loginService.selectedTranslateLanguage != this.modelService.loginService.currentCustomer.defaultLanguage) {
              this.modelService.translationService.getProductTranslation(this.modelService.catalogService.catalog.id, this.product.id);
            }
            this.validationService.validateProduct(this.modelService.catalogService.product);
          });
        break;
    }
    this.buildContextMenu(isChild, type);
  }

  buildContextMenu(isChild: boolean, type: string) {
    this.menuItems = [];
    if (this.canView('rightclickCatalog') && !this.massDataChange) {
      switch (type) {
        case "cat":
          this.menuItems.push({ id: 2, text: this.txtKategorieHinzufuegen, icon: 'glyphicon glyphicon-plus', beginGroup: true });
          if (!this.isVirtualCatalog()) {
            this.menuItems.push({ id: 20, text: this.txtKopierenAlsKategorie, icon: 'glyphicon glyphicon-copy', beginGroup: true });
          }
          this.modelService.catalogService.loadClipboard().subscribe(
            () => {
              var clipboard = this.modelService.catalogService.clipboard;
              if (clipboard != null && clipboard.content == "grp" && !this.isVirtualCatalog()) {
                this.menuItems.push(
                  { id: 14, text: this.txtKategorieEinfuegen, icon: 'glyphicon glyphicon-paste', beginGroup: true });
              }
              if (clipboard != null && clipboard.content == "cat" && !this.isVirtualCatalog()) {
                this.menuItems.push(
                  { id: 21, text: this.txtKatalogEinfuegen, icon: 'glyphicon glyphicon-paste', beginGroup: true });
              }
            }
          );
          break;

        case "grp":
          //if (this.canView('rightclickCatalog')) {    // unnötig
          this.menuItems = [];
          this.menuItems.push({ id: 2, text: this.txtKategorieHinzufuegen, icon: 'glyphicon glyphicon-plus', beginGroup: false });
          if (!this.isVirtualCatalog()) {
            this.menuItems.push({ id: 5, text: this.txtProduktHinzufuegen, icon: 'glyphicon glyphicon-plus', beginGroup: false });
          }
          this.menuItems.push({ id: 7, text: this.txtKategorieLoeschen, icon: 'glyphicon glyphicon-trash', beginGroup: true });
          if (!this.isVirtualCatalog()) {
            this.menuItems.push({ id: 10, text: this.txtKategorieKopieren, icon: 'glyphicon glyphicon-copy', beginGroup: true });
          }
          //this.menuItems.push({ id: 11, text: "Kategorie ausschneiden", icon: 'glyphicon glyphicon-copy', beginGroup: false });

          this.modelService.catalogService.loadClipboard().subscribe(
            () => {
              if (this.modelService.catalogService.clipboard != null) {
                var clipboard = this.modelService.catalogService.clipboard;
                if (clipboard.content == "pro" && !this.isVirtualCatalog()) {
                  this.menuItems.push(
                    { id: 6, text: this.txtProduktEinfuegen, icon: 'glyphicon glyphicon-paste', beginGroup: true });
                }
                if (clipboard.content == "grp" && !this.isVirtualCatalog()) {
                  this.menuItems.push(
                    { id: 6, text: this.txtKategorieEinfuegen, icon: 'glyphicon glyphicon-paste', beginGroup: true });
                }
                if (clipboard.content == "cat" && !this.isVirtualCatalog()) {
                  this.menuItems.push(
                    { id: 22, text: this.txtKatalogEinfuegen, icon: 'glyphicon glyphicon-paste', beginGroup: true });
                }
              }
            }
          );
          //}
          break;

        case "pro":
          //if (this.canView('rightclickCatalog')) {    // unnötig
          this.menuItems = [];
          if (!this.isVirtualCatalog()) {
            this.menuItems.push({ id: 5, text: this.txtVarianteHinzufuegen, icon: 'glyphicon glyphicon-plus', beginGroup: true });
            this.menuItems.push({ id: 8, text: this.txtProduktDuplizieren, icon: 'glyphicon glyphicon-duplicate', beginGroup: false });
          }
          this.menuItems.push({ id: 0, text: this.txtProduktLoeschen, icon: 'glyphicon glyphicon-trash', beginGroup: true });
          if (!this.isVirtualCatalog()) {
            this.menuItems.push({ id: 12, text: this.txtProduktKopieren, icon: 'glyphicon glyphicon-copy', beginGroup: true });
          }

          if (isChild) {
            this.menuItems = [];
            if (!this.isVirtualCatalog()) {
              this.menuItems.push({ id: 8, text: this.txtProduktDuplizieren, icon: 'glyphicon glyphicon-duplicate', beginGroup: false });
              this.menuItems.push({ id: 0, text: this.txtProduktLoeschen, icon: 'glyphicon glyphicon-trash', beginGroup: true });
              this.menuItems.push({ id: 12, text: this.txtProduktKopieren, icon: 'glyphicon glyphicon-copy', beginGroup: true });
            }
          }
          if (!isChild) {
            this.modelService.catalogService.loadClipboard().subscribe(
              () => {
                if (this.modelService.catalogService.clipboard != null) {
                  var clipboard = this.modelService.catalogService.clipboard;
                  if (clipboard.content == "pro" && !(clipboard.isMaster && (this.modelService.catalogService.product == null || this.product.productCount > 0))) {
                    this.menuItems.push(
                      {
                        id: 9, text: this.txtProduktEinfuegen, icon: 'glyphicon glyphicon-paste', beginGroup: true
                      });
                  }
                }
              });
          }
          //}
          break;
      }

    } else if (this.massDataChange) {
      switch (type) {
        case "pro":
          this.menuItems.push({ id: 24, text: this.translate.instant("Als Vorlage benutzen"), icon: 'glyphicon glyphicon-copy', beginGroup: true });
          break;
        case "grp":
          this.menuItems.push({ id: 25, text: this.translate.instant("Vorlagen benutzten"), icon: 'glyphicon glyphicon-copy', beginGroup: true });
          break;
      }
    }
  }


  showTranslateBox() {
    if (!this.modelService.catalogService.catalog) {
      return false;
    }

    return this.modelService.loginService.hasAddonTranslate &&
      this.modelService.catalogService.catalog.language.toLowerCase() ==
      this.modelService.loginService.currentCustomer.defaultLanguage.toLowerCase();
  }

  get fullScreen() {
    return this.modelService.catalogService.viewService.fullScreen;
  }

  set fullScreen(value: boolean) {
    this.modelService.catalogService.viewService.fullScreen = value;
  }

  showUserActionWindow() {
    this.modelService.catalogService.showUserAction = true;
  }

  set showUserAction(value) {
    this.modelService.catalogService.showUserAction = value;
  }

  get showUserAction() {
    return this.modelService.catalogService.showUserAction;
  }

  userActionWindow() {
    this.modelService.catalogService.viewService.fullScreen = false;
  }
  userActionFullScreen() {
    this.modelService.catalogService.viewService.fullScreen = true;
  }

  hideUserAction() {
    this.showUserAction = false;
  }

  createChildren = async (parent) => {
    let parentId = parent ? parent.itemData.id : "";
    this.childs = await this.modelService.catalogService.getChilds(
      this.modelService.catalogService.selectedCatalogId, 
      parentId, 
      this.modelService.loginService.currentUser.customerId, 
      null, 
      this.modelService.catalogService.selectedStates,
       null, this.selectedStatesMode);
    return this.childs;
  };

  //TOOLTIPS
  public v: boolean[] = [false];
  public t: boolean[] = [false];

  olf(id) { this.t[id] = !this.t[id]; }

  ttt(id) {
    if (this.modelService.loginService.showToolTips) {
      this.v[id] = !this.v[id];
    }
  }

  //SETTINGS
  get showToolTips(): boolean {
    return this.modelService.loginService.showToolTips;
  }

  get showProToolTips(): boolean {
    return this.modelService.loginService.showProToolTips;
  }

  get showPlaceHolders(): boolean {
    return this.modelService.loginService.showPlaceHolders;
  }

  toggleMenu() {
    this.modelService.systemService.showMenu = !this.modelService.systemService.showMenu;
  }

  toggleHelp() {
    this.modelService.systemService.showHelp = !this.modelService.systemService.showHelp;
    if (this.modelService.systemService.showHelp) {
      this.modelService.systemService.loadHelp(this.modelService.loginService.currentUser.customerId).subscribe((result: CustomerWiki) => this.modelService.systemService.help = result.entries);;
    }
  }

  canView(name: string): boolean {
    return this.userManagment.canView(name);
  }
  readOnly(name: string): boolean {
    return !this.userManagment.canEdit(name);
  }

  treeListMode() {
    if (this.massDataChange || this.massGenerateKITexts)
      return "multiple";
    return "single";
  }

  public emptyProduct: Product = new Product();

  setEmptyProduct(product: Product) {
    this.treeSelectedRowKeys = [];
    this.emptyProduct = product;
    this.emptyProduct.productLogistic = new ProductLogistic();
    this.massDataChange = true;
    this.massGenerateKITexts = false;
    this.buildContextMenu(false, "");
  }

  massCancel(event) {
    this.massDataChange = false;
    this.massGenerateKITexts = false;
    //this.refresh();
    if (event.idToSelect && event.isNewlyAdded) {
      this.openItem("pro", event.idToSelect, false);
      this.modelService.catalogService.GetProductTreeItem(event.idToSelect, this.modelService.loginService.currentCustomer.id, "", this.selectedCatalogId)
        .subscribe(x => {
          this.selectProductAfterSearch = x;
          this.modelService.catalogService.treeRefresh();
        });
      return;
    }
    if (event.idToSelect) {
      this.openItem("pro", event.idToSelect, false);
      this.treeList.instance.selectRows([event.idToSelect], false);
    } else if (this.isCatalogSelected) {
      this.treeList.instance.selectRows([this.selectedCatalogId], false);
    } else if (this.isCategorySelected) {
      this.treeList.instance.selectRows([this.selectedCategoryId], false);
    } else if (this.isProductSelected) {
      this.treeList.instance.selectRows([this.selectedProductId], false);
    } else {
      this.treeList.instance.selectRows([this.modelService.catalogService.catalog.id], false);
    }

    this.modelService.catalogService.treeRefresh();
  }

  showMassGenerateKITexts() {
    this.massGenerateKITexts = true;
    this.massDataChange = false;
  }

  startMassGenerateKITexts() {
    this.massGenerateKITexts = false;
  }

}
