<dx-sortable
  group="dragDropGroup"
  dropFeedbackMode="indicate"
  [data]="excelModel"
  dragTemplate="dragTemplate"
  [cursorOffset]="{ x: 20, y: 20 }"
  (onDragStart)="onDragStart($event)"
  (onAdd)="onDrop($event)"
  (onReorder)="onReorder($event)"
>
  <!-- template for the dragged item -->
  <div
    *dxTemplate="let dragInfo of 'dragTemplate'"
    class="item dx-card dx-theme-text-color dx-theme-background-color shadow-lg p-3 bg-body rounded dragTemplate"
  >
    <div>
      {{ getDragInfo(dragInfo.itemData) }}
    </div>
  </div>

  <div
    *ngFor="let column of excelModelFiltered"
    class="{{ exportMode ? 'templateExcelExportColumn' : 'templateExcelColumn' }}"
    id="templateExcelDiv_{{ column.id }}"
  >
    <div *ngIf="!exportMode" class="{{ getClass(column.id) }} excelCell">
      <div class="d-flex flex-row">
        <i (click)="rowClicked(column.id)" class="handle dx-icon dx-icon-dragvertical align-self-center"></i>
        <div (click)="rowClicked(column.id)" class="templateExcelColumnContent" style="height: 100%">
          <div class="templateExcelHead">{{ column.title }}</div>
          <div class="templateExcelContent" style="float: left; width: 100%">
            <div class="templateExcelLine">{{ column.row1 }}</div>
            <div class="templateExcelLine">{{ column.row2 }}</div>
          </div>
        </div>

        <dx-button
          class="btn-row-icon"
          [ngStyle]="{ color: hasTemplateItemSettings(column) ? 'var(--primary)' : 'var(--secondary)' }"
          hint="{{ 'Feldeinstellungen bearbeiten' | translate }}"
          icon="preferences"
          (onClick)="onOpenFieldSettings($event, column)"
        ></dx-button>

        <dx-popover
          [target]="popoverView.get(column)?.target"
          [visible]="popoverView.get(column)?.visible"
          [showCloseButton]="true"
          [shading]="true"
          width="auto"
          height="auto"
          position="left"
          (onHiding)="onCloseFieldSettings($event, column)"
        >
          <np-advanced-template-fieldsettings
            [excelElement]="column"
            [mappings]="mappings"
            (onUpdate)="updateNew(column)"
          ></np-advanced-template-fieldsettings>
        </dx-popover>

        <dx-button
          *ngIf="column.inUse"
          class="btn-row-icon"
          [ngStyle]="{ color: 'var(--secondary)' }"
          hint="{{ 'Entfernen' | translate }}"
          icon="trash"
          (onClick)="removeClicked(column)"
        ></dx-button>
      </div>
    </div>

    <div *ngIf="exportMode" class="{{ getClass(column.id) }} excelCellExport">
      <div class="d-flex flex-row" [ngStyle]="{ gap: '0.5rem' }">
        <i (click)="rowClicked(column.id)" class="handleExport dx-icon dx-icon-dragvertical align-self-center"></i>
        <div (click)="rowClicked(column.id)" class="d-flex flex-column flex-grow-1">
          <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" [ngStyle]="{width: '100%'}">
            <label class="placeLabel {{ column.title?.length > 0 ? 'placeLabelSmall' : '' }}">FIELD_TITLE</label>
            <dx-text-box [(value)]="column.title" placeholder="" (onValueChanged)="update($event)"></dx-text-box>
          </div>
          <div *ngIf="column.pimFields != null && column.pimFields?.length > 0" class="templateExcelContent">
            <div class="templateExcelLine">{{ getDragInfo(column) }}</div>
            <!-- früher column.field -->
          </div>

          <div *ngIf="column.pimFields == null || column.pimFields?.length == 0" class="templateExcelContent">
            <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" [ngStyle]="{width: '100%'}">
              <label class="placeLabel {{ column.defaultValue?.length > 0 ? 'placeLabelSmall' : '' }}">
                FIELD_VALUE
              </label>
              <dx-text-box
                [(value)]="column.defaultValue"
                [showClearButton]="true"
                placeholder=""
                (onValueChanged)="update($event)"
              ></dx-text-box>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column">
          <dx-button
            class="btn-row-icon"
            [ngStyle]="{ color: hasTemplateItemSettings(column) ? 'var(--primary)' : 'var(--secondary)' }"
            hint="{{ 'Feldeinstellungen bearbeiten' | translate }}"
            icon="preferences"
            (onClick)="onOpenFieldSettings($event, column)"
          ></dx-button>

          <dx-popover
            [target]="popoverView.get(column)?.target"
            [visible]="popoverView.get(column)?.visible"
            [showCloseButton]="true"
            [shading]="true"
            width="auto"
            height="auto"
            position="right"
            (onHiding)="onCloseFieldSettings($event, column)"
          >
            <np-advanced-template-fieldsettings
              [excelElement]="column"
              [mappings]="mappings"
              (onUpdate)="updateNew(column)"
            ></np-advanced-template-fieldsettings>
          </dx-popover>

          <dx-button
            *ngIf="column.inUse"
            class="btn-row-icon"
            [ngStyle]="{ color: 'var(--secondary)' }"
            hint="{{ 'Entfernen' | translate }}"
            icon="trash"
            (onClick)="removeClicked(column)"
          ></dx-button>
        </div>
      </div>
    </div>
  </div>
</dx-sortable>
