<div class="dx-fieldset">
  <dx-box direction="row" width="100%" [ngStyle]="{ gap: '2rem', marginBottom: '2rem' }">
    <dxi-item [ratio]="1">
      <dx-validation-group id="LOGI">
        <div class="dx-card">
          <div class="dx-fieldset">
            <!--Customs_Number-->
            <div class="np-field">
              <np-inputControl
                [(model)]="model"
                [showLinkButtons]="showLinkButtons"
                [showPlaceLabel]="model?.productLogistic?.customsNumber?.length > 0"
                [(selectedElements)]="selectedElements"
                (elementSelected)="elementSelectFunction($event)"
                (onValueChanged)="update($event)"
                labelText="{{ 'Zolltarifnummer' | translate }}"
                field="CUSTOMS_NUMBER"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot">
                  <dx-text-box
                    [(value)]="model?.productLogistic.customsNumber"
                    [readOnly]="readOnlyForLinkedFields('CUSTOMS_NUMBER') || readOnly('logisticZollTarif')"
                    [showClearButton]="!readOnlyForLinkedFields('CUSTOMS_NUMBER')"
                    (onValueChanged)="update($event, 'CUSTOMS_NUMBER')"
                    class="validationBlue"
                  >
                    <dx-validator *ngIf="!templateView" validationGroup="LOGI">
                      <dxi-validation-rule
                        type="async"
                        [validationCallback]="validationService.validateField('CUSTOMS_NUMBER')"
                      ></dxi-validation-rule>
                    </dx-validator>
                  </dx-text-box>
                </div>
              </np-inputControl>
            </div>

            <!--Country of Orgin-->
            <div class="np-field">
              <np-inputControl
                [(model)]="model"
                [showLinkButtons]="showLinkButtons"
                [showPlaceLabel]="model?.productLogistic?.countryOfOrigin?.length > 0"
                [(selectedElements)]="selectedElements"
                (elementSelected)="elementSelectFunction($event)"
                (onValueChanged)="update($event)"
                labelText="{{ 'Ursprungsland' | translate }}"
                field="COUNTRY_OF_ORIGIN"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot">
                  <dx-text-box
                    [(value)]="model.productLogistic.countryOfOrigin"
                    [readOnly]="readOnlyForLinkedFields('COUNTRY_OF_ORIGIN') || readOnly('logisticCountryOfOrigin')"
                    [showClearButton]="!readOnlyForLinkedFields('COUNTRY_OF_ORIGIN')"
                    (onValueChanged)="update($event, 'COUNTRY_OF_ORIGIN')"
                    class="validationBlue"
                  >
                    <dx-validator *ngIf="!templateView" validationGroup="LOGI">
                      <dxi-validation-rule
                        type="async"
                        [validationCallback]="validationService.validateField('COUNTRY_OF_ORIGIN')"
                      ></dxi-validation-rule>
                    </dx-validator>
                  </dx-text-box>
                </div>
              </np-inputControl>
            </div>

            <!--Width-->
            <div class="np-field">
              <np-inputControl
                [(model)]="model"
                [showLinkButtons]="showLinkButtons"
                [showPlaceLabel]="model?.productLogistic?.widthNumber !== null"
                [(selectedElements)]="selectedElements"
                (elementSelected)="elementSelectFunction($event)"
                (onValueChanged)="update($event)"
                labelText="{{ 'Breite' | translate }}"
                field="WIDTH"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot">
                  <dx-number-box
                    [(value)]="model.productLogistic.widthNumber"
                    [readOnly]="readOnlyForLinkedFields('WIDTH') || readOnly('logisticWidth')"
                    [showClearButton]="!readOnlyForLinkedFields('WIDTH')"
                    (onValueChanged)="update($event, 'WIDTH')"
                    class="validationBlue"
                  >
                    <dx-validator *ngIf="!templateView" validationGroup="LOGI">
                      <dxi-validation-rule
                        type="async"
                        [validationCallback]="validationService.validateField('WIDTH')"
                      ></dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </div>
              </np-inputControl>
            </div>

            <!--Length-->
            <div class="np-field">
              <np-inputControl
                [(model)]="model"
                [showLinkButtons]="showLinkButtons"
                [showPlaceLabel]="model?.productLogistic?.lengthNumber !== null"
                [(selectedElements)]="selectedElements"
                (elementSelected)="elementSelectFunction($event)"
                (onValueChanged)="update($event)"
                labelText="{{ 'Länge' | translate }}"
                field="LENGTH"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot">
                  <dx-number-box
                    [(value)]="model.productLogistic.lengthNumber"
                    [readOnly]="readOnlyForLinkedFields('LENGTH') || readOnly('logisticLenghth')"
                    [showClearButton]="!readOnlyForLinkedFields('LENGTH')"
                    (onValueChanged)="update($event, 'LENGTH')"
                    class="validationBlue"
                  >
                    <dx-validator *ngIf="!templateView" validationGroup="LOGI">
                      <dxi-validation-rule
                        type="async"
                        [validationCallback]="validationService.validateField('LENGTH')"
                      ></dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </div>
              </np-inputControl>
            </div>

            <!--Depth-->
            <div class="np-field">
              <np-inputControl
                [(model)]="model"
                [showLinkButtons]="showLinkButtons"
                [showPlaceLabel]="model?.productLogistic?.depthNumber !== null"
                [(selectedElements)]="selectedElements"
                (elementSelected)="elementSelectFunction($event)"
                (onValueChanged)="update($event)"
                labelText="{{ 'Tiefe' | translate }}"
                field="DEPTH"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot">
                  <dx-number-box
                    [(value)]="model.productLogistic.depthNumber"
                    [readOnly]="readOnlyForLinkedFields('DEPTH') || readOnly('logisticDepth')"
                    [showClearButton]="!readOnlyForLinkedFields('DEPTH')"
                    (onValueChanged)="update($event, 'DEPTH')"
                    class="validationBlue"
                  >
                    <dx-validator *ngIf="!templateView" validationGroup="LOGI">
                      <dxi-validation-rule
                        type="async"
                        [validationCallback]="validationService.validateField('DEPTH')"
                      ></dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </div>
              </np-inputControl>
            </div>

            <!--Weight-->
            <div class="np-field">
              <np-inputControl
                [(model)]="model"
                [showLinkButtons]="showLinkButtons"
                [showPlaceLabel]="model?.productLogistic?.weightNumber !== null"
                [(selectedElements)]="selectedElements"
                (elementSelected)="elementSelectFunction($event)"
                (onValueChanged)="update($event)"
                labelText="{{ 'Gewicht' | translate }}"
                field="WEIGHT"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot">
                  <dx-number-box
                    [(value)]="model.productLogistic.weightNumber"
                    [readOnly]="readOnlyForLinkedFields('WEIGHT') || readOnly('logisticWeight')"
                    [showClearButton]="!readOnlyForLinkedFields('WEIGHT')"
                    (onValueChanged)="update($event, 'WEIGHT')"
                    class="validationBlue"
                  >
                    <dx-validator *ngIf="!templateView" validationGroup="LOGI">
                      <dxi-validation-rule
                        type="async"
                        [validationCallback]="validationService.validateField('WEIGHT')"
                      ></dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </div>
              </np-inputControl>
            </div>

            <div
              class="color5Text"
              *ngIf="templateView == false && model?.hintsActiv && !validationService.validationResult.productLogisticsValid"
            >
              <div>{{ 'Nicht alle Logistikfelder sind ausgefüllt' | translate }}.</div>
            </div>
          </div>
        </div>
      </dx-validation-group>
    </dxi-item>

    <dxi-item [ratio]="1">
      <dx-validation-group *ngIf="model" id="ORDER" class="d-flex flex-column" [ngStyle]="{ gap: '2rem' }">
        <div class="dx-card">
          <div class="dx-fieldset">
            <div class="np-field">
              <np-inputControl
                [(model)]="model"
                [(productTranslation)]="translationService.productTranslation"
                [showLinkButtons]="showLinkButtons"
                [suppressPlaceLabel]="true"
                [(selectedElements)]="selectedElements"
                (elementSelected)="elementSelectFunction($event)"
                (onValueChanged)="update($event)"
                infoText="{{ 'Lieferzeit in Tagen' | translate }}"
                labelText="{{ 'Produkt mit Haltbarkeit' | translate }}"
                field="IS_DURABILITY_PRODUCT"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot np-others">
                  <np-booleanRadioGroup
                    [(value)]="model.orderDetail.isDurabilityProduct"
                    [templateView]="templateView"
                    [fieldName]="validationService.fieldNames.isDurabilityProduct"
                    [readOnly]="readOnlyForLinkedFields('IS_DURABILITY_PRODUCT') || readOnly('orderWithDurability')"
                    (onValueChanged)="update($event, 'IS_DURABILITY_PRODUCT')"
                    validatorValidationGroup="ORDER"
                  ></np-booleanRadioGroup>
                </div>
              </np-inputControl>
            </div>
            <div *ngIf="model.orderDetail.isDurabilityProduct" class="np-field">
              <np-inputControl
                [(model)]="model"
                [(productTranslation)]="translationService.productTranslation"
                [showLinkButtons]="showLinkButtons"
                [showPlaceLabel]="model?.orderDetail?.durabilityDays != null"
                [(selectedElements)]="selectedElements"
                (elementSelected)="elementSelectFunction($event)"
                (onValueChanged)="update($event)"
                labelText="{{ 'Haltbarkeit (Tage)' | translate }}"
                field="DURABILITY_DAYS"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot">
                  <dx-number-box
                    [min]="0"
                    [max]="100000"
                    [showClearButton]="!readOnlyForLinkedFields('DURABILITY_DAYS')"
                    [readOnly]="readOnlyForLinkedFields('DURABILITY_DAYS') || readOnly('orderDurabilityDays')"
                    [(value)]="model.orderDetail.durabilityDays"
                    (onValueChanged)="update($event, 'DURABILITY_DAYS')"
                  >
                    <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                      <dxi-validation-rule
                        type="async"
                        [validationCallback]="validationService.validateField('DURABILITY_DAYS')"
                      ></dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </div>
              </np-inputControl>
            </div>

            <hr />

            <div class="np-field">
              <np-inputControl
                [(model)]="model"
                [(productTranslation)]="modelService.translationService.productTranslation"
                [showLinkButtons]="showLinkButtons"
                [showPlaceLabel]="model?.orderDetail?.deliveryTime?.length > 0"
                [(selectedElements)]="selectedElements"
                (elementSelected)="elementSelectFunction($event)"
                (onValueChanged)="update($event)"
                labelText="{{ 'Lieferzeit' | translate }}"
                field="DELIVERY_TIME"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot">
                  <dx-autocomplete
                    [minSearchLength]="0"
                    [dataSource]="zero"
                    [readOnly]="readOnlyForLinkedFields('DELIVERY_TIME') || readOnly('orderTranportTime')"
                    [showClearButton]="!readOnlyForLinkedFields('DELIVERY_TIME')"
                    [showDropDownButton]="!readOnlyForLinkedFields('DELIVERY_TIME')"
                    [(value)]="model.orderDetail.deliveryTime"
                    (onValueChanged)="update($event, 'DELIVERY_TIME')"
                    (onSelectionChanged)="updateFromAutocomplete($event, 'DELIVERY_TIME')"
                  >
                    <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                      <dxi-validation-rule
                        type="async"
                        [validationCallback]="validationService.validateField('DELIVERY_TIME')"
                      ></dxi-validation-rule>
                    </dx-validator>
                  </dx-autocomplete>
                </div>
              </np-inputControl>
            </div>
          </div>
        </div>

        <div class="dx-card">
          <div class="dx-fieldset">
            <div class="np-field">
              <np-inputControl
                [(model)]="model"
                [(productTranslation)]="modelService.translationService.productTranslation"
                [showLinkButtons]="showLinkButtons"
                [suppressPlaceLabel]="true"
                [(selectedElements)]="selectedElements"
                (elementSelected)="elementSelectFunction($event)"
                (onValueChanged)="update($event)"
                labelText="{{ 'Sperrgut' | translate }}"
                field="IS_BULKY_GOOD"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot np-others">
                  <np-booleanRadioGroup
                    [(value)]="model.orderDetail.isBulkyGood"
                    [templateView]="templateView"
                    [fieldName]="validationService.fieldNames.isBulkyGood"
                    [readOnly]="readOnlyForLinkedFields('IS_BULKY_GOOD') || readOnly('orderBulkyGoods')"
                    (onValueChanged)="update($event, 'IS_BULKY_GOOD')"
                    validatorValidationGroup="ORDER"
                  ></np-booleanRadioGroup>
                </div>
              </np-inputControl>
            </div>

            <div class="np-field">
              <np-inputControl
                [(model)]="model"
                [(productTranslation)]="modelService.translationService.productTranslation"
                [showLinkButtons]="showLinkButtons"
                [suppressPlaceLabel]="true"
                [(selectedElements)]="selectedElements"
                (elementSelected)="elementSelectFunction($event)"
                (onValueChanged)="update($event)"
                labelText="{{ 'Speditionsprodukt' | translate }}"
                field="IS_TRUCKAGE_COMPANY_GOOD"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot np-others">
                  <np-booleanRadioGroup
                    [(value)]="model.orderDetail.isTruckageCompanyGood"
                    [templateView]="templateView"
                    [fieldName]="validationService.fieldNames.isTruckageCompanyGood"
                    [readOnly]="readOnlyForLinkedFields('IS_TRUCKAGE_COMPANY_GOOD') || readOnly('orderSpeditionsprodukt')"
                    (onValueChanged)="update($event, 'IS_TRUCKAGE_COMPANY_GOOD')"
                    validatorValidationGroup="ORDER"
                  ></np-booleanRadioGroup>
                </div>
              </np-inputControl>
            </div>
          </div>
        </div>
      </dx-validation-group>
    </dxi-item>
  </dx-box>
</div>
