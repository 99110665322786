/**************************************************************************/
/*                           GENERAL                                      */
/**************************************************************************/
/*
body {
  background-color: #EEE;
  font-family: "Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #2c2f3f;
}
*/

/*
a,
.appContainer a,
.dx-overlay-wrapper a {
  color: #0093D6;
}
*/

/*
.appFooter {
  color: white;
  padding-right: 10px;
  padding-top: 3px;
  background-color: #1E272D;
  border: 0px solid #c0c0c0;
  border-top-width: 1px;
  height: 25px;
  text-align: right;
}
*/

/*SettingsButton*/
/*
.bg-white {
  background-color: #EEE !important;
}

.marginBottom {
  margin-bottom: 10px !important;
}

.marginBottom2 {
  margin-bottom: 30px !important;
}

.buttonMargin {
  margin-left: 0px;
  margin-right: 6px;
}

.menuWidth {
  width: 33%;
  float: left;
  -webkit-transition: width 1s;
}
*/
/*
.contentWidth {
  width: 67%;
  float: left;
  -webkit-transition: width 1s;
}

div .noMenuContent {
  width: 100%;
}

div .noMenu {
  width: 0%;
}

.menuToggle {
  background: #1e272c;
  width: 20px;
  height: 20px;
  float: left;
  border-radius: 0px 0px 3px;
}

.menuToggleImage {
  width: 6px;
  height: 12px;
  margin-left: 7px;
}

.treeBackGround {
  background-color: #1E272C;
  width: 100% !important;
  margin-bottom: 20px;
}

.treeDiv {
  background-color: #1E272C;
}


.detailMenu {
  background-color: #FFF;
  width: calc(100% + 14px);
  height: 114px;
  margin-top: -14px;
  padding-right: 26px;
  padding-top: 14px;
}

.navbarCatList {
  height: 102px;
}

.catListMenu {
  float: right;
}

  .catListMenu a {
    color: white !important;
    font-weight: bold;
  }

.detailMenu a {
  color: #1E272C !important;
  font-weight: bold;
}

.loggedin {
  font-size: 0.8em;
}

.mainContainer {
  overflow: hidden;
}

.detail-wraper {
  overflow-x: hidden;
  height: calc(100vh - 126px);
  overflow-y: auto;
}
*/
/**************************************************************************/
/*                           SYSTEM                                       */
/**************************************************************************/
/*
.noShowContainer {
  display: none;
}

@media (min-width:800px) {
  .containerWidth {
    width: 100% !important;
  }
}

@media (max-width:800px) {
  .mainContainer {
    display: none;
  }

  .noShowContainer {
    display: block;
  }
}

.treeListDiv .dx-datagrid-nodata {
  color: white;
}
*/
/*******************************************************************************/
/*                            PLACEHOLDER                                      */
/*******************************************************************************/
/*
.inputWithOutPlaceHolder .placeLabel {
  display: none;
}


.np-datagrid .dx-texteditor-input {
  background-color: transparent !important;
  top: 6px;
  position: relative;
  left: 10px;
}

.np-datagrid .dx-numberbox .dx-texteditor-input {
  background-color: transparent !important;
  top: 0px;
  position: unset;
  left: 0px;
}

.np-datagrid .dx-numberbox-spin-button {
  background-color: transparent !important;
}

div .noPlaceholder .dx-texteditor-input {
  padding: 0px;
  margin-left: -15px;
}

div.dx-datagrid .dx-row > td {
  padding: 7px 7px 2px 7px;
}

.fullEditableGrid .dx-datagrid .dx-row > td {
  padding: 0px 7px 0px 7px;
}

.fullEditableGrid .dx-placeholder {
  color: #999999
}

.fullEditableColumnContainer {
  display: flex;
  align-items: center;
  height: 33px;
}

  .fullEditableColumnContainer i {
    font: 18px/1 DXIcons;
  }
*/
/*
.inputWithPlaceHolder {
  position: relative;
  background-color: #FFF;
  border-radius: 4px;
}

td > .inputWithPlaceHolder {
  background-color: transparent;
}
*/
/*Placeholder Sichtbarkeit*/
/*
.inputWithPlaceHolder > dx-text-box,
.inputWithPlaceHolder > div > dx-text-box,
.inputWithPlaceHolder textarea,
.inputWithPlaceHolder > dx-number-box,
.inputWithPlaceHolder > dx-date-box,
.inputWithPlaceHolder > dx-autocomplete,
.inputWithPlaceHolder > .dx-texteditor-input,
.inputWithPlaceHolder .dx-textarea,
.inputWithPlaceHolder input,
.inputWithPlaceHolder > .dx-editor-outlined,
.inputWithPlaceHolder .dx-texteditor-input {
  background-color: transparent !important;
}

.inputWithPlaceHolder dx-text-area {
  padding-top: 5px;
}
*/
/*Placeholder bei leerem Feld*/
/*
.inputWithPlaceHolder .placeLabel {
  -webkit-transition: font .1s ease,top .1s ease,-webkit-transform .1s ease;
  transition: font .1s ease,top .1s ease,-webkit-transform .1s ease;
  -o-transition: font .1s ease,top .1s ease,-o-transform .1s ease;
  -moz-transition: font .1s ease,top .1s ease,transform .1s ease,-moz-transform .1s ease;
  transition: font .1s ease,top .1s ease,transform .1s ease;
  transition: font .1s ease,top .1s ease,transform .1s ease,-webkit-transform .1s ease,-moz-transform .1s ease,-o-transform .1s ease;
  left: 10px;
  font-size: 14px;
  position: absolute;
  top: 8px;
  font-weight: normal;
  color: darkgray;
  overflow: hidden;
  width: calc(100% - 40px);
  line-break: strict;
  height: 20px;
}
*/
/*Placeholder klein, wenn keine Buchstaben, Focus*/
/* Geht nicht auf EDGE*/
/*
.inputWithPlaceHolder:focus-within .placeLabel, .placeLabelSmall {
  font-size: 9px !important;
  top: 2px !important;
}
*/
/*Auf EDGE ist der Placeholder immer Klein*/
/*
@supports (-ms-ime-align:auto) {
  .inputWithPlaceHolder .placeLabel {
    font-size: 9px !important;
    top: 2px !important;
  }
}

.inputWithPlaceHolder .dx-icon {
  color: darkgray !important;
}
*/
/*Verschiebung des Input Feldes*/
/*
.inputWithPlaceHolder .dx-texteditor-input {
  padding-bottom: 0px !important;
  padding-left: 15px !important;
}

.inputWithPlaceHolder > div > .boldText,
.boldText {
  font-weight: bold;
}

td > .inputWithPlaceHolder > div > .dx-textbox,
td > .inputWithPlaceHolder > .dx-textbox {
  margin: -7px !important;
  border: 0px !important;
  top: 1px !important;
  padding-left: 10px !important;
}

td > .inputWithPlaceHolder > .dx-autocomplete {
  padding-bottom: 0px !important;
  padding-left: 10px !important;
}

.inputWithPlaceHolder > .dx-tagbox {
  background-color: transparent;
  padding-left: 10px;
  padding-top: 13px;
  padding-bottom: 5px;
}
*/
/*HTML Editor*/
/*
.dx-htmleditor-content.ql-blank::before {
  font-style: normal !important;
  color: #a9a9a9 !important;
  left: 8px !important;
  top: 8px !important;
}
*/
/**************************************************************************/
/*                           LOGIN                                        */
/**************************************************************************/
/*
.loginContainer {
}

.loginHeadLine {
  padding-left: 20px;
}

.forgotPassword {
  padding-left: 10px;
}
*/
/**************************************************************************/
/*                           CATALOG LIST                                 */
/**************************************************************************/
/*
.dx-datagrid-rowsview .dx-dropdowneditor-button-visible.dx-show-clear-button .dx-texteditor-input {
  padding-right: 0px !important;
}

.dx-dropdowneditor-button-visible.dx-show-clear-button .dx-texteditor-input {
  padding-right: 0px !important;
}

div .dx-icon-search dx-texteditor-input {
  background-color: white;
}

input .dx-texteditor-input {
  background-color: transparent;
}

td.dx-editor-cell .dx-texteditor {
  background-color: transparent;
}

  td.dx-editor-cell .dx-texteditor .dx-texteditor-input {
    background-color: transparent;
  }
*/
/*Datagrid, Abstand zwischen Suchfeld und Liste zur richtigen Farbe*/
/*
.dx-datagrid-header-panel .dx-toolbar {
  margin-bottom: 0px !important;
  padding-bottom: 10px !important;
}

.catlistImageDiv {
  width: 100%;
  margin-top: -4px;
  margin-bottom: -7px;
  margin-left: -7px;
}

.catlistImage {
  height: 29px;
  width: 33px;
}

.menuButton {
  padding-bottom: 15px;
  float: left;
  width: 100%;
  border-bottom: 1px solid #FFF;
  margin-bottom: 20px;
}

  .menuButton span {
    padding-right: 5px;
  }

.accountMenuPopover .menuButton:last-of-type {
  padding-bottom: 0px;
  border-bottom: 0px solid azure;
  margin-bottom: 0px;
}
*/
/**************************************************************************/
/*                           BROWSING LIST                                */
/**************************************************************************/
/*
.np-menu-elm {
  padding: 0px 5px 0px 20px;
  height: 54px;
  width: 100%;
  margin: 0px;
}

.np-menu-cat {
}

.np-menu-grp {
}

.np-menu-pro {
  background-color: #343D42 !important;
}

.colorBlue {
  color: #0093D6;
}

.jobsMenu .arrowDown {
  margin-top: 0px;
  width: 20px;
  height: 10px;
  margin-left: 0px;
}

.accountMenu {
  float: right;
  width: 176px;
  margin-top: 3px;
  text-align: center;
}

.jobsMenu {
  float: right;
  margin-right: 100px;
  margin-top: 48px;
}

.menuImage {
  width: 50px;
  float: left;
  padding: 5px;
}

.menuText {
  width: calc(100% - 100px);
  float: left;
  padding-top: 10px;
}

.menuArrow {
  width: 50px;
  float: left;
  padding: 5px;
}

.menuTextwI {
  width: calc(100% - 38px);
  float: left;
  padding-top: 10px;
}

.menuIcon {
  width: 38px;
  float: left;
  padding-left: 10px;
  padding-top: 15px;
}
*/
/* Deaktiviert die Hintergrundfarbe der Menuelemente, damit die Farbe der Selection funktioniert*/
/*
.dx-selection > td > div > div > div {
  background-color: rgba(0, 0, 0, 0) !important;
}

.buttonmenu {
  position: absolute;
  z-index: 5;
}

.imageArrowW {
  display: none;
}

.dx-selection .imageArrow {
  display: none;
}

.dx-selection .imageArrowW {
  display: initial;
}

.arrowUp {
  margin-top: 20px;
  width: 20px;
  height: 10px;
  margin-left: 3px;
}

.arrowDown {
  margin-top: 20px;
  width: 20px;
  height: 10px;
  margin-left: 3px;
}

.arrowRight {
  margin-top: 16px;
  width: 10px;
  height: 20px;
  margin-left: 9px;
}
*/
/**************************************************************************/
/*                           MyAccount                                    */
/**************************************************************************/
/*
.accountList {
  height: calc(100vh - 135px) !important;
}

  .accountList .treeListElement {
    margin-right: 50px !important;
  }

.treeListDiv dx-data-grid {
  margin-left: 36px;
  margin-right: 20px;
  overflow: hidden;
  margin-bottom: 55px !important;
}

.addAccountButton {
  position: relative;
  top: -30px;
  left: 35px;
}
*/
/**************************************************************************/
/*                           DETAIL                                       */
/**************************************************************************/
/*
.divPoints {
  position: relative;
  height: 0;
  width: 100%;
  text-align: right;
}

.imgPoints {
  height: 20px;
  width: 200px;
  top: 170px;
  position: relative;
}

.imgPointsSmall {
  height: 14px;
  width: 100px;
  top: 85px;
  position: relative;
  margin-right: 30px;
}



.catalogDetail .dx-tabpanel .dx-tab {
  width: 110px;
}

.catalogDetail .dx-tabs-scrollable .dx-tabs-wrapper {
  border: none;
}

div .detailHeadline {
  border-top: solid 1px;
  border-bottom: solid 1px;
  margin-left: -20px !important;
  margin-right: -20px !important;
  padding: 10px 20px 10px 20px;
  font-weight: bold;
  margin-top: 10px;
}

div .detailContainer {
  border-bottom: solid 1px;
  margin-left: -20px !important;
  margin-right: -20px !important;
  padding: 20px;
  background-color: #F8F8F8;
  margin-top: -10px !important;
}


.detailContent {
  margin-left: 40px;
  margin-right: 20px;
}

.detailHeader {
  height: 250px;
  min-height: 250px;
  margin-top: 40px;
}

.detailHeaderSmall {
  height: 100px;
  min-height: 100px;
  margin-top: 40px;
}
*/

/*
.detailTabs .dx-tabs {
  border: none;
  background-color: transparent;
}

.detailTabs .dx-item {
  border: none;
  border-bottom: 5px solid transparent !important;
  background-color: transparent;
  font-weight: bold;
}

.detailTabs .dx-tab:hover {
  border-bottom: 4px solid #1E272C !important;
  color: #1E272C !important;
  padding-bottom: 10px;
}

.detailTabs .dx-tab-selected {
  border: none !important;
  border-bottom: 5px solid #0093C6 !important;
  background-color: transparent;
  color: #0093C6 !important;
}

  .detailTabs .dx-tab-selected::after {
    border: none !important;
  }

.detailTabs .dx-tabs {
  background-color: transparent !important;
}
*/
/*
.actionMenuPopover .dx-popup-content {
  padding-right: 0px !important;
}

.color1::before {
  background-color: #A52A2A !important;
}

.color1Text {
  color: #A52A2A !important;
}

.color2::before {
  background-color: #D26915 !important;
}

.color2Text {
  color: #D26915 !important;
}

.color3::before {
  background-color: #FFA500 !important;
}

.color3Text {
  color: #FFA500 !important;
}

.color4::before {
  background-color: #008000 !important;
}

.color4Text {
  color: #008000 !important;
}

.color5::before {
  background-color: #191970 !important;
}

.color5Text {
  color: #191970 !important;
}

.nextPimBlue {
  color: #0093C6 !important;
}

.center {
  text-align: center;
}

.link:hover, a:hover {
  text-decoration: none !important;
  cursor: pointer !important;
}

.aggrementVisible {
  height: 290px !important;
}

.visible {
  visibility: visible !important;
}

.hidden {
  visibility: hidden !important;
}


.headerBigGlyphicon {
  font-size: 230px;
}

.headerSmallGlyphicon {
  font-size: 80px;
}

.headerBigImage {
  height: 230px;
}

.headerSmallImage {
  height: 80px;
}

.headerBigGauge {
  height: 150px;
}

.headerSmallGauge {
  height: 80px;
  top: -20px;
  position: relative;
}


.noPaddingPopup > .dx-overlay-content > .dx-popup-content {
  padding: 0px !important;
}

.noPaddingPopup .dx-popup-title {
  z-index: -500;
}

.buttonPopUpTitle {
  border-style: none !important;
  padding: 7px !important;
}

.catalogDetail .dx-tab {
  float: left;
}
*/
/**************************************************************************/
/*                           Makros                                       */
/**************************************************************************/
/*
.macroHead {
}

.accordionDiv {
  width: 33%;
  float: left;
}

.macroDiv {
  width: 66%;
  float: right;
}

.macroAccordion {
}

div .accordionButtonDiv {
  padding: 0px;
}

div > .macroButton {
  width: 100%;
  border: none;
  border-top-style: none;
  border-top-width: medium;
  text-align: left;
  border-top-color: #ccc;
  border-top-style: solid;
  border-top-width: 1px;
  border-radius: 0px;
}
*/
/**************************************************************************/
/*                           PIM Help                                     */
/**************************************************************************/
/*
.pimContainer {
  width: 65%;
  float: left;
  -webkit-transition: width 1s; 
}

.helpWidth {
  width: 35%;
  float: right;
  height: calc(100vh);
  background-color: #FCFCFC;
  -webkit-transition: width 1s; 
}

div .noHelpContent {
  width: 0%;
}

div .noHelp {
  width: 100%;
}

.helpToggle {
  background: #FCFCFC;
  width: 20px;
  height: 20px;
  float: right;
  border-radius: 0px 0px 0px 3px;
}


@media (max-width:1800px) {
  .helpToggle {
    display: none;
  }

  .helpWidth {
    width: 0%;
  }

  .pimContainer {
    width: 100%;
  }
}

.noDisplay {
  display: none;
}
*/
/*
.menuToggleImage {
  width: 6px;
  height: 12px;
  margin-left: 7px;
}*/
/**************************************************************************/
/*                           PRODUCT                                      */
/**************************************************************************/
/* HTML Editor*/
/*
.dx-htmleditor {
  border: 1px solid #949494;
  border-radius: 4px;
}

  .dx-htmleditor .dx-icon, .dx-htmleditor-toolbar-format .dx-button-content span {
    color: #333 !important;
  }

  .dx-htmleditor .dx-button-content {
    background-color: #FFF !important;
  }

.yellowBorder {
  border-color: #FFC309 !important;
}

.headerImage {
  padding-left: 0px !important;
  width: 250px;
  float: left;
}

.headerImageSmall {
  padding-left: 0px !important;
  width: 100px;
  float: left;
}

.headerText {
  width: calc(100% - 450px);
  height: 220px;
  overflow: hidden;
  float: left;
}

.headerTextSmall {
  width: calc(100% - 300px);
  height: 100px;
  overflow: hidden;
  float: left;
}

.headerValidation {
  width: 200px;
  float: left;
}

.shopViewIcons {
  float: left;
  padding-right: 7px;
  margin-bottom: 10px;
}

.shopViewIconContainer {
  width: 230px;
  float: right;
}

  .shopViewIconContainer .shopViewIcons:last-of-type {
    padding-right: 0px;
  }
*/
/**************************************************************************/
/*                           Translation                                  */
/**************************************************************************/
/*
.flag {
  height: 25px;
  float: left;
  border: 3px solid transparent;
}

.flag-selected {
  border: 3px solid white;
}
/*
.custom-item {
  position: relative;
  min-height: 30px;
}

.mainInfos .smallField {
  width: calc(50% - 100px) !important;
}

.LanguageHeadline {
  top: 3px;
  left: 5px;
  position: relative;
  font-weight: bold;
}
*/
/**************************************************************************/
/*                           JOBS                                         */
/**************************************************************************/
/*
.jobInfos {
  background-color: #FFF;
  margin-top: 20px;
  padding: 2px;
  width: 500px;
}

  .jobInfos th {
    background-color: #CCC;
    padding: 6px 20px 6px 10px;
    border: solid 1px #949494;
    text-align: left;
  }

  .jobInfos td {
    background-color: #FAFAFA;
    padding: 4px 10px 6px 10px;
    border-bottom: solid 1px #999;
  }

  .jobInfos i {
  }
*/
/**************************************************************************/
/*                           MOBILE                                       */
/**************************************************************************/



/**************************************************************************/
/*                           CLASSIFICATION                               */
/**************************************************************************/


/**************************************************************************/
/*                           REST                                         */
/**************************************************************************/
/*
.darkLink {
  color: black !important;
}

  .darkLink:hover {
    color: dimgrey !important;
  }

.warrantyEditor {
  height: calc(100vh - 335px) !important;
}

.hazmatIcon {
  height: 70px;
  margin-right: 20px;
}

.hazmatHeadline {
  font-weight: bold;
  height: 25px;
  margin-top: 20px;
}

.hazmatStatement {
  height: 50px;
}

.fileUrl {
  border-top: solid 1px #949494;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  margin-top: 20px;
}

.dx-field-label54 {
  width: 54% !important;
}

.dx-field-value46 {
  width: 46% !important;
}

.radioGroupPaddingFix {
  padding: 5px 0px 0px 0;
}

.dx-field-label {
  min-width: 200px !important;
}

.dx-field-label200 {
  width: 200px !important;
}

.mainInfos div .dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button) {
  width: calc(100% - 200px);
}

.productInfos .dx-field-label225 {
  width: 225px !important;
}

.productInfos div .dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button) {
  width: calc(100% - 225px);
}

.priceInfos .dx-field-label {
  width: 200px !important;
  min-width: 200px !important;
}

.priceInfos .dx-field-value {
  width: calc(100% - 200px) !important;
}

.dx-tabpanel-container > .dx-multiview-wrapper {
  min-height: 300px;
}

.toolTip {
  white-space: normal !important;
  width: 450px;
}

.toolTipField > .dx-popup-normal > .dx-popup-content > .dx-template-wrapper::before {
  content: "Feld-Informationen:\A";
  white-space: pre;
  font-weight: bold;
  line-height: 20px;
}

.dx-popup-content > .dx-template-wrapper {
  white-space: normal !important;
  text-align: left;
}

.nexMartTooltip::before {
  content: "Entwickler-Informationen:\A";
  white-space: pre;
  font-weight: bold;
  line-height: 20px;
}

.nexMartTooltip {
  background-color: #f0f0f0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #333;
}

.hint .dx-invalid.dx-texteditor.dx-editor-outlined .dx-texteditor-input-container::after {
  display: none !important;
}

.dx-invalid.dx-texteditor.hint .dx-texteditor-container::after, .hint .dx-invalid-message .dx-overlay-content {
  background-color: #FFC309 !important;
}

.dx-invalid.dx-texteditor.hint {
  border-color: rgba(255, 195, 9, 1) !important;
}



.validationYellow .dx-invalid.dx-texteditor.dx-editor-outlined .dx-texteditor-input-container::after {
  display: none !important;
}

.dx-invalid.dx-texteditor.hint .dx-texteditor-container::after, .validationYellow .dx-invalid-message .dx-overlay-content {
  background-color: #FFC309 !important;
}

.dx-invalid.dx-texteditor.validationYellow {
  border-color: rgba(255, 195, 9, 1) !important;
}


.validationBlue .dx-invalid.dx-texteditor.dx-editor-outlined .dx-texteditor-input-container::after {
  display: none !important;
}

.dx-invalid.dx-texteditor.validationBlue .dx-texteditor-container::after, .validationBlue .dx-invalid-message .dx-overlay-content {
  background-color: #191970 !important;
}

.dx-invalid.dx-texteditor.validationBlue {
  border-color: #191970 !important;
}

.validationGreen .dx-invalid.dx-texteditor.dx-editor-outlined .dx-texteditor-input-container::after {
  display: none !important;
}

.dx-invalid.dx-texteditor.validationGreen .dx-texteditor-container::after, .validationGreen .dx-invalid-message .dx-overlay-content {
  background-color: #008000 !important;
}

.dx-invalid.dx-texteditor.validationGreen {
  border-color: #008000 !important;
}

.validationOrange .dx-invalid.dx-texteditor.dx-editor-outlined .dx-texteditor-input-container::after {
  display: none !important;
}

.dx-invalid.dx-texteditor.validationOrange .dx-texteditor-container::after, .validationOrange .dx-invalid-message .dx-overlay-content {
  background-color: #D26915 !important;
}

.dx-invalid.dx-texteditor.validationOrange {
  border-color: #D26915 !important;
}










.dataGridAutocomplete {
  border: 0px !important;
  background-color: rgba(255,255,255,0) !important;
  margin: -7px;
}

  .dataGridAutocomplete > div > div > input {
    background-color: rgba(255,255,255,0) !important;
  }

td > div > .dataGridAutocomplete {
  padding: 0px !important;
}

.inDataGrid {
  border: 0px !important;
  margin: -7px !important;
  background-color: transparent !important;
}

  .inDataGrid input {
    background-color: transparent !important;
  }

  .inDataGrid .dx-numberbox-spin-button {
    background-color: transparent !important;
  }
*/
/*******************************************************************************/
/*                            EXPORT TEMPLATE                                  */
/*******************************************************************************/
/*
.iconButton {
  background-color: transparent !important;
  height: 34px;
}

.inDataGridDeleteIcon {
  border: none !important;
  background-color: transparent !important;
  margin: -15px -0px -10px -5px;
}

  .inDataGridDeleteIcon .dx-icon, .iconButton .dx-icon {
    color: #0093D6 !important;
  }

.templateDivNarrow {
  float: left !important;
  width: 45px !important;
  height: 9px;
  top: -12px;
  position: relative;
}

.templateDivWide {
  float: left !important;
  width: 54px !important;
  height: 9px;
  top: -12px;
  position: relative;
}

.templatPlaceLabel {
  font-size: 9px !important;
  position: relative;
  top: 0px !important;
}

.templateDivNarrow > dx-number-box,
.templateDivWide > dx-select-box {
  position: relative;
  top: -9px;
  border: 0px !important;
  margin: -7px !important;
  width: 53px !important;
  background-color: transparent !important;
}

  .templateDivNarrow > dx-number-box input,
  .templateDivWide > dx-select-box input {
    background-color: transparent !important;
  }

.templateOuterDiv {
  height: 409px;
}

.templateInnerDiv {
  float: right;
  padding-left: 10px;
  padding-right: 10px;
  height: 282px;
}

.templateImportOuterDivFullScreen {
  height: calc(100vh - 150px);
  overflow-y: scroll;
}


.templateImportOuterDiv {
  height: 655px;
  overflow-y: scroll;
}

.templateOuterDivFullScreen {
  height: 709px;
}

.templateInnerDivFullScreen {
  float: right;
  padding-left: 10px;
  padding-right: 10px;
  height: 582px;
}

.inputWithPlaceHolderTemplate {
  background-color: transparent;
  position: relative;
}
*/
  /*Placeholder Sichtbarkeit*/
  /*
  .inputWithPlaceHolderTemplate > dx-text-box {
    background-color: transparent !important;
  }
*/
  /*Placeholder bei leerem Feld*/
  /*
  .inputWithPlaceHolderTemplate .placeLabelTemplate {
    -webkit-transition: font .1s ease,top .1s ease,-webkit-transform .1s ease;
    transition: font .1s ease,top .1s ease,-webkit-transform .1s ease;
    -o-transition: font .1s ease,top .1s ease,-o-transform .1s ease;
    -moz-transition: font .1s ease,top .1s ease,transform .1s ease,-moz-transform .1s ease;
    transition: font .1s ease,top .1s ease,transform .1s ease;
    transition: font .1s ease,top .1s ease,transform .1s ease,-webkit-transform .1s ease,-moz-transform .1s ease,-o-transform .1s ease;
    font-size: 14px;
    position: absolute;
    font-weight: normal;
  }
  */
  /*Placeholder klein, wenn keine Buchstaben, Focus*/
  /* Geht nicht auf EDGE*/
  /*
  .inputWithPlaceHolderTemplate:focus-within .placeLabelTemplate, .placeLabelTemplateSmall {
    font-size: 9px !important;
    top: -4px !important;
  }
  */
/*Auf EDGE ist der Placeholder immer Klein*/
/*
@supports (-ms-ime-align:auto) {
  .inputWithPlaceHolderTemplate .placeLabelTemplate {
    font-size: 9px !important;
    top: -4px !important;
  }
}

.placeLabelTemplateSmall {
  font-size: 9px !important;
  top: -4px !important;
}

td > .inputWithPlaceHolderTemplate > .dx-textbox {
  margin: -7px !important;
  border: 0px !important;
  top: 1px !important;
}
*/
/*Verschiebung des Input Feldes*/
/*
.inputWithPlaceHolderTemplate .dx-texteditor-input {
  padding-bottom: 0px !important;
  background-color: transparent;
}
*/
/*******************************************************************************/
/*                            EXPORT FIELDS                                    */
/*******************************************************************************/
/*
.fieldGroup {
  width: 300px;
  float: left;
}

.exportTabFullScreen {
  height: calc(100vh - 235px) !important;
  overflow-y: auto;
  overflow-x: hidden;
}
*/
/*
.exportTab {
  height: 565px !important; 
  overflow-y: scroll;
  overflow-x: hidden;
}

.exportExcelTabFullScreen {
  height: calc(100vh - 175px) !important;
}

.exportExcelTab {
  height: 625px !important;
}

.exportText {
  top: -16px;
  position: relative;
  left: 5px;
}

.exportLabel {
  top: -8px;
  position: relative;
  font-size: 9px;
  color: darkgray;
}

.maxHeight {
  max-height: 485px;
}
*/
/*******************************************************************************/
/*                            KONTACTFORM                                      */
/*******************************************************************************/
/*
.noMargin {
  margin-top: 20px !important;
  margin-bottom: 0px !important;
}
*/
/*******************************************************************************/
/*                            JOBLIST                                          */
/*******************************************************************************/
/*
.complete .dx-progressbar-range {
  background-color: green;
  border-color: green;
}

.jobListTD {
  font-weight: bold;
}



.templateExcelColumn {
  width: 100%;
  height: 75px;
  overflow: hidden;
  border-bottom: 0.5px solid #949494 !important;
  transition: height 0.5s ease;
  -moz-transition: height 0.5s ease;
  -webkit-transition: height 0.5s ease;
  -o-transition: height 0.5s ease;
  background-color: #FFF;
}

.templateExcelExportColumn {
  width: 100%;
  height: 100px;
  overflow: hidden;
  border-bottom: 0.5px solid #949494 !important;
  transition: height 0.5s ease;
  -moz-transition: height 0.5s ease;
  -webkit-transition: height 0.5s ease;
  -o-transition: height 0.5s ease;
  background-color: #FFF;
}

.templateExcelColumnBig {
  height: 300px !important;
}

.templateExcelExportColumnBig {
  height: 375px !important;
}

.templateExcelColumnContent {
  width: calc(100% - 65px);
  float: left;
}

.templateExcelColumnBig .templateSelected {
  display: block !important;
}

.templateExcelExportColumnBig .templateSelected {
  display: block !important;
}

.templatePlusRound {
  transform: rotate(45deg) !important;
}


.searchResults {
  border: 1px solid #949494;
  width: 250px;  
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 10px;
  height: 350px;
}


.dataSheetDownload {
  border: 1px solid #949494;
  border-radius: 7px;
  padding: 10px;
  margin-bottom: 10px;
}
*/