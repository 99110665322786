import { Component } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { UserActionByListComponent } from "../userAction-byList/userAction-byList.component";


@Component({
  selector: "userAction-searchByList",
  templateUrl: "userAction-searchByList.component.html"
})
export class UserActionSearchByListComponent extends UserActionByListComponent {
  constructor(public modelService: ModelService) {
    super(modelService);
    this.handlePropertyChange = this.handlePropertyChange.bind(this);
    this.startMacro = this.startMacro.bind(this);
    this.searchValue = this.searchValues[0];
  }

  searchValues = ["SupplierPID","InternationalPid", "Hersteller", "SupplierAltPID","Lieferanten-Artikelnummer"];
  searchValue = "";
  handlePropertyChange(event) {
    this.searchValue = event.item.value; 
  }

  startMacro() {
    this.inputList = this.formatListIgnoreSpace(this.inputList);

    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionId = "SearchByList("+ this.searchValue+"|" + this.inputList.split("\n").join(",") + ")";

    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        //additional step
        this.inputList = "";
        this.userActionStarted.emit(next);
      });
  }
}
