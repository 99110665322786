import { OnInit } from "@angular/core";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { Translation } from "../Model/Dto/Translation";
import { TranslateCatalog } from "../Model/Dto/TranslateCatalog";
import { ExportTranslation } from "../Model/Dto/ExportTranslation";
import { LoginService } from "./login.service";
import { CustomerLanguage } from "../Model/Dto/CustomerLanguage";
import { Product } from "../Model/Catalog/Product";
import { ViewService } from './view.service';
import { ProductService } from "./CatalogManagement/product.service";

@Injectable()
export class TranslationService implements OnInit {
  
  languageCodes: string[] = ["deu", "eng", "fra", "spa", "ces"];

  constructor(private http: HttpClient, private loginService: LoginService,private productService : ProductService) {}

  ngOnInit(): void {}

  static translateLanguage(code) {
    var key = ViewService.languages.findIndex((language => language.code == code) as any);

    if (key != -1)
      return ViewService.languages[key].language;
    else
      return "unbekannt";
  }

  translateCatalog(translateCatalog: TranslateCatalog) {
    return this.http.post("api/translation/translateCatalog", translateCatalog).pipe(map(response => {
      return true;
    }));
  }

  getTranslations(selectedLanguageCode: string, customerId: string): Observable<Translation[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
                              .append("targetLanguageCode", selectedLanguageCode),
    }

    return (this.http.get<Translation[]>("api/translation/GetTranslations", options)) as any;
  }

  addTranslation(translation: Translation): Observable<Translation> {
    return (this.http.post<Translation>("api/translation/AddTranslation", translation)) as any;
  }

  updateTranslation(translation) {
    return this.http.post("api/translation/UpdateTranslation", translation).subscribe();
  }

  exportTranslations(selectedLanguageCode: string,customerId: string) {
    let exportTranslation = new ExportTranslation();
    exportTranslation.targetLanguageCode = selectedLanguageCode;
    exportTranslation.customerId = customerId;
    this.http.post("api/translation/ExportTranslation", exportTranslation).subscribe();
  }

  deleteTranslations(id: string): Observable<any> {
    return this.http.delete("api/Translation/DeleteTranslation/" + id) as any;
  }

  deleteLanguage(language: string): Observable<any> {
    var key = this.loginService.customerLanguages.findIndex((languageTemplate => languageTemplate.language == language) as any);

    if (key != -1) {
      var id = this.loginService.customerLanguages[key].id;
      this.loginService.customerLanguages = this.loginService.customerLanguages.filter(languageTemplate => languageTemplate.id !== id);
      let result = this.http.delete("api/AccountLanguage/DeleteCustomerLanguage/" + id) as any;
      this.loginService.setLanguageFlags();
      return result;
    }
  }

  deleteAllTranslations() {
    this.http.delete("api/translation/DeleteAllTranslation/" + this.loginService.currentUser.customerId).subscribe();
  }

  deleteForLanguage(languageCode): Observable<any> {
    return this.http.delete("api/translation/DeleteForLanguage/" + this.loginService.currentUser.customerId + "/" + languageCode) as any;
  }

  addLanguage2Account(languageCode): Observable<any> {
    var customerLanguage = new CustomerLanguage();
    customerLanguage.customerId = this.loginService.currentUser.customerId;
    customerLanguage.language = languageCode;
    return this.http.post<CustomerLanguage>("api/AccountLanguage/AddCustomerLanguage", customerLanguage).pipe(map((result: CustomerLanguage) => this.addLanguage(result))) as any;
  }

  addLanguage(language) {
    this.loginService.addLanguage(language);
  }

  public productTranslation: Product = null;

  getProductTranslation(catalogId, productId) {
    if (!this.loginService.hasAddonTranslate)
      return;

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams()
        .append("catalogId", catalogId)
        .append("productId", productId)
        .append("customerId", this.loginService.currentUser.customerId)
        .append("targetLanguageCode", this.loginService.selectedTranslateLanguage)
    }

    return this.http.get<Product>("api/Translation/TranslateProduct", options).pipe(
      map((result: Product) => this.setTranslatetProduct(result))).subscribe();
  }

  setTranslatetProduct(product) {
    this.productTranslation = this.productService.getProductFromJson(product.product, this.loginService.wawiSettings);;
    //console.log("translationDone");
    //console.log(this.productTranslation);
    this.productTranslation.prepareView();
  }

  translationUpdate(textDEU, textTra) {
    var translation = new Translation();
    translation.customerId = this.loginService.currentUser.customerId;
    translation.sourceLanguageCode = "deu";
    translation.targetLanguageCode = this.loginService.selectedTranslateLanguage;
    translation.text = textDEU;
    translation.translation = textTra;
    //console.log(translation);
    this.updateTranslation(translation);
  }
}
