import { Input, Output, EventEmitter, Component, ViewChild, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Product } from '../../Model/Catalog/Product';
import { ModelService } from '../../Services/model.service';
import { FeatureSystem } from '../../Model/Catalog/FeatureModels/FeatureSystem';
import { ViewService } from '../../Services/view.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { Functions } from '../../Services/functions';
import { Message } from '../../Model/System/Message';
import { custom } from 'devextreme/ui/dialog';
import { TemplateService } from '../../Services/template.service';
import { UserManagementService } from '../../Services/userManagment.service';
import { CatalogService } from '../../Services/CatalogManagement/catalog.service';
import { ValidationService } from '../../Services/Validation/validation.service';
import { ValidFeatureService } from '../../Services/validFeature.service';
import { LoginService } from '../../Services/login.service';
import { lastValueFrom } from 'rxjs';
import { ProductService } from '../../Services/CatalogManagement/product.service';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'editfeaturesystemlist',
  templateUrl: 'editfeaturesystemlist.component.html',
  styleUrls: ['template.css']
})
export class EditFeatureSystemList implements OnInit {
  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;
  @ViewChild(DxDataGridComponent, { static: true }) featureSystemGrid: DxDataGridComponent;
  @Input() templateView: boolean = false;

  _featureSystems: FeatureSystem[];
  featureSystemsDataSource: DataSource;
  get featureSystems(): FeatureSystem[] {
    return this._featureSystems;
  }

  @Input()
  set featureSystems(value: FeatureSystem[]) {
    this._featureSystems = value;
    value.forEach((system) => {
      system.guid = Functions.newGuid();
      system.features.forEach((feature) => {
        feature.guid = Functions.newGuid();
      });
    });

    this.featureSystemsDataSource = new DataSource({
      store: this._featureSystems,
      paginate: true,
      pageSize: 10
    });
  }

  @Input() hintsAktiv = false;
  @Input() showToolTips = false;
  @Input() showProToolTips = false;
  @Input() showPlaceHolders = true;
  @Input() showLinkButtons: Boolean = false;
  @Input() hasAddonCLASSIFICATION = false;
  @Input() hasAddonECOMMERCE = false;

  @Input() displayMode: 'edit' | 'import' | 'export';

  @Input() model: Product = null;
  @Input() selectedElements: [''];
  @Output() elementSelected = new EventEmitter<string>();

  @Input() showDragHandle: boolean = false;
  @Input() dragFilter: string = 'noDrag';

  @Output() excelElementDropped = new EventEmitter<any>();
  @Output() elementDeleted = new EventEmitter<any>();

  @Output() onValueChanged = new EventEmitter<any>();
  @Output() addEcommerceFeatures = new EventEmitter<any>();
  @Output() addClassificationFeatures = new EventEmitter<any>();

  classificationPopupVisible = false;
  updateLock: Boolean = false;
  updateFrontendLinkedFields: boolean = false;

  constructor(
    public modelService: ModelService,
    public templateService: TemplateService,
    public translate: TranslateService,
    public validationService: ValidationService,
    public userManagement: UserManagementService,
    public catalogService: CatalogService,
    public validFeatureService: ValidFeatureService,
    public loginService: LoginService,
    private productService: ProductService
  ) {
    this.onSystemToolbarPreparing = this.onSystemToolbarPreparing.bind(this);
  }

  async ngOnInit() {
    await this.refreshFeatureSystemSource();
  }

  async refreshFeatureSystemSource() {
    const existingNames = new Set(this.featureSystemNameSource);

    let result = await lastValueFrom(this.validFeatureService.getSystems(this.loginService.currentCustomer.id));

    result.forEach((system) => {
      if (!existingNames.has(system.name)) {
        existingNames.add(system.name);
        this.featureSystemNameSource.push(system.name);
      }
    });

    this.featureSystemNameSource.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }

  async addFeatureSystemButtonClicked() {
    if (this.displayMode == 'edit') {
      this.updateLock = true;
    }

    const featureSystem = new FeatureSystem();
    featureSystem.validFeatureSystemId = await this.validFeatureService.getValidIdForSystemName(
      '',
      this.loginService.currentCustomer.id
    );
    featureSystem.order = (this.featureSystems.reduce((maxOrder, fs) => Math.max(maxOrder, fs.order ?? 0), 0) ?? 0) + 1;

    this.featureSystems.push(featureSystem);
    this.featureSystemsDataSource = new DataSource({
      store: this._featureSystems,
      paginate: true,
      pageSize: 10
    });
    if (this.displayMode == 'edit') this.update();
  }

  async setFeatureValidId(featureSystem: FeatureSystem) {
    var result = await lastValueFrom(
      this.validFeatureService.setValidIds(this.loginService.currentCustomer.id, featureSystem)
    );
    let fs = Object.assign(new FeatureSystem(), result);
    featureSystem.validFeatureSystemId = fs.validFeatureSystemId;
    featureSystem.features = fs.features;
    featureSystem.updateLinkedFields = true;
    this.updateFrontendLinkedFields = true;
  }

  async updateFeatureSystemNameAndUpdate(event, featureSystem: FeatureSystem) {
    event.previousValue = featureSystem.validFeatureSystemId;
    await this.setFeatureValidId(featureSystem);
    event.value = featureSystem.validFeatureSystemId;
    this.update(event, featureSystem, 'REFERENCE_FEATURE_SYSTEM_NAME', featureSystem.validFeatureSystemId);
  }

  update(event = null, data?, field?: string, system: string = '-1') {
    this.dataGrid.instance.refresh(true);

    // für den template editor erforderlich:
    if (event && data && !event.createTemplateItem) {
      event.dField = field;
      if (system != '-1' || !this.templateView) {
        event.dSystem = system;
      } else {
        event.dSystem = data.order.toString();
      }
      event.dElement = '-1';
      event.createTemplateItem = true;
    }

    if (data == undefined || this.featureSystemIsValid(data)) {
      this.onValueChanged.emit(event);
    }
  }

  delete(event) {
    if (event.dFields) {
      this.elementDeleted.emit(event);
      return;
    }
    let product = this.modelService.catalogService.product;
    let system;
    if (event.data.validFeatureSystemId) {
      system = event.data.validFeatureSystemId;
    } else {
      system = event.data.order.toString();
    }

    for (let featureSystemField of this.templateService.allFeatureSystemFields) {
      if (product != null && product.isFieldLinked(featureSystemField, system, '-1')) {
        product.toggleLinkedField(featureSystemField, system, '-1');
      }
    }
    this.onValueChanged.emit(event);

    event.dFields = this.templateService.allFeatureSystemFields;
    event.dDetailFields = this.templateService.allFeatureFields;
    event.dSystem = system;
    event.dElement = '-1';
    this.elementDeleted.emit(event);
  }

  public onRowRemoving(e) {
    let product = this.modelService.catalogService.product;
    let system;
    if (e.data.validFeatureSystemId) {
      system = e.data.validFeatureSystemId;
    } else {
      system = e.data.order.toString();
    }

    if (product != null && product.isChild && this.hasFeatureSystemFieldLinked(system)) {
      this.modelService.systemService.notify(
        new Message(this.translate.instant('DeleteLinkedFieldNotAllowed'), 'info'),
        3000
      );
      e.cancel = true;
      return;
    }

    let myDialog = custom({
      title: this.translate.instant('Wirklich löschen?'),
      messageHtml: this.translate.instant('Wollen Sie das FeatureSystem wirlich löschen'),
      buttons: [
        {
          text: this.translate.instant('Ja'),
          onClick: (e) => {
            return false;
          }
        },
        {
          text: this.translate.instant('Nein'),
          onClick: (e) => {
            return true;
          }
        }
      ]
    });

    e.cancel = myDialog.show();
  }

  onSystemToolbarPreparing(e) {
    if (this.addClassificationButtonVisible()) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        locateInMenu: 'auto',
        template: 'addClassificationButtonTemplate'
      });
    }

    if (this.addFeatureSystemButtonVisible()) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        locateInMenu: 'auto',
        template: 'addFeatureSystemButtonTemplate'
      });
    }

    e.toolbarOptions.items.unshift({
      location: 'after',
      locateInMenu: 'auto',
      template: 'profiModeSwitchTemplate'
    });
  }

  customizeColumns(columns) {
    columns.forEach((column) => {
      column.showEditorAlways = true;
    });
  }

  async onContentReady() {
    this.updateLock = false;
    if (this.updateFrontendLinkedFields) {
      this.updateFrontendLinkedFields = false;
      this.model.linkedFields = await lastValueFrom(
        this.productService.getLinkedFields(this.model.id, this.loginService.currentCustomer.id)
      );
    }
  }

  isVirtualCatalog(): boolean {
    return this.catalogService?.catalog?.isVirtual;
  }

  translateMessage(msg: string) {
    return this.translate.instant(msg);
  }

  addClassificationButtonVisible(): boolean {
    return this.hasAddonCLASSIFICATION;
  }

  addFeatureSystemButtonVisible(): boolean {
    return true;
  }

  addClassificationButtonClicked() {
    this.classificationPopupVisible = true;
  }

  closeClassificationPopup() {
    this.classificationPopupVisible = false;
  }

  emitAddClassificationFeatures() {
    this.addClassificationFeatures.emit();
  }

  emitAddEcommerceFeatures() {
    this.addEcommerceFeatures.emit();
  }

  get featureSystemNameSource(): string[] {
    return ViewService.dropDownFeatureSystemNames;
  }

  get featureSystemEditable(): boolean {
    return true;
  }

  get profiModeEnabled(): boolean {
    return this.modelService.loginService.currentUser.featureProfiMode;
  }

  get showValidationSummary(): boolean {
    return this.hintsAktiv && !this.validationService.validationResult.productFeaturesValid;
  }

  get validationMessages(): string[] {
    return this.validationService.validationResult.featureMessages;
  }

  get templateSelectionEnabled(): boolean {
    return this.displayMode === 'export' || this.displayMode === 'import';
  }

  templateSelect(identifier: string, orderString: string) {
    this.elementSelected.emit(`${identifier}_${orderString}`);
  }

  templateChildSelected(key: string) {
    this.elementSelected.emit(key);
  }

  excelElementDrop(e) {
    this.excelElementDropped.emit(e);
  }

  excelFeatureSystemNameDroppedError() {
    this.modelService.systemService.notify(
      new Message(this.translate.instant('FeatureSystemNameDroppedError'), 'error'),
      3000
    );
  }

  public hasFeatureSystemFieldLinked(system: string = '-1'): boolean {
    let product = this.modelService.catalogService.product;
    for (let featureSystemField of this.templateService.allFeatureSystemFields) {
      if (product != null && product.isFieldLinked(featureSystemField, system, '-1')) {
        return true;
      }
    }
    return false;
  }

  public readOnlyForLinkedFields(field: string, system: string = '-1'): boolean {
    let product = this.modelService.catalogService.product;
    if (product != null && field != null) {
      var isLinked = product.isFieldLinked(field, system, '-1');
      if (isLinked && product.isChild != null && product.isChild) {
        return true;
      }
    }
    return false;
  }

  public hasMasterOrChildOrIsTranslated(): boolean {
    let product = this.modelService.catalogService.product;
    if (product != null) {
      return product.hasMasterOrChild();
    }
    return false;
  }

  public isFieldLinked(field: string, system: string = '-1'): boolean {
    let product = this.modelService.catalogService.product;
    if (product != null) {
      return product.isFieldLinked(field, system, '-1');
    }
    return false;
  }

  public toggleLinkedField(event = null, field: string, system: string = '-1') {
    let product = this.modelService.catalogService.product;
    var changed = product.toggleLinkedField(field, system, '-1');
    if (!changed) {
      return;
    }

    var isNowLinked = this.isFieldLinked(field, system);
    event.target.className = product.getClassForLinkedField(isNowLinked);

    if (product.isChild && isNowLinked) {
      event.forceReload = true;
    }

    this.update(event);
  }

  public getInitialClassForLinkedField(field: string, system: string = '-1'): string {
    let product = this.modelService.catalogService.product;
    var isNowLinked = this.isFieldLinked(field, system);
    return product.getClassForLinkedField(isNowLinked);
  }

  canView(name: string): boolean {
    return !this.isVirtualCatalog() && this.userManagement.canView(name);
  }
  readOnly(name: string): boolean {
    return this.isVirtualCatalog() || !this.userManagement.canEdit(name);
  }

  readOnlyTemplateEditor(fs) {
    return this.templateView && !this.featureSystemIsValid(fs);
  }

  featureSystemIsValid(featureSystem: FeatureSystem) {
    return featureSystem.referenceFeatureSystemName != undefined;
  }

  getRelevantSystemKey(featureSystem: FeatureSystem) {
    if (featureSystem.validFeatureSystemId) {
      return featureSystem.validFeatureSystemId;
    }
    return featureSystem.order;
  }
}
