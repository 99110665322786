<fullscreen-page>
  <content-column look="dark">
    <div slot="content" class="h-100 p-2">
      <div class="h-100 w-100 mx-auto">
        <!-- (onRowInserted)="rowInserted($event)" -->
        <dx-data-grid
          id="gridContainer"
          class="h-100"
          (onToolbarPreparing)="onToolbarPreparing($event)"
          [dataSource]="templates"
          [remoteOperations]="false"
          [allowColumnReordering]="true"
          [rowAlternationEnabled]="true"
          [allowColumnResizing]="true"
          [showBorders]="true"
          (onRowUpdated)="rowUpdated($event)"
          (onRowRemoved)="rowRemoved($event)"
          (onRowPrepared)="rowPrepared($event)"
        >
          <!-- [allowAdding]="true" -->
          <dxo-editing mode="cell" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true">
            <dxo-texts
              confirmDeleteMessage="{{ 'Wollen Sie das Template wirklich löschen' | translate }}?"
              confirmDeleteTitle="{{ 'Template löschen' | translate }}"
            ></dxo-texts>
          </dxo-editing>

          <dxo-paging [enabled]="false"></dxo-paging>
          <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>

          <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>

          <dxo-state-storing
            [enabled]="true"
            type="localStorage"
            storageKey="TemplateListStateNewUIV3"
          ></dxo-state-storing>

          <div *dxTemplate="let toolbaritem of 'newTemplateButtonTemplate'">
            <div id="addButton" class="d-flex gap-2">
              <dx-button
                stylingMode="text"
                type="default"
                text="{{ 'Neues Template' | translate }}"
                [disabled]="!canAddTemplate"
                (onClick)="showAddTemplate()"
              ></dx-button>
              <dx-button
                *ngIf="hasAddonAdvancedTemplate"
                stylingMode="outlined"
                text="{{ 'Neues Experten Template' | translate }}"
                [disabled]="!canAddTemplate"
                (onClick)="showAddAdvancedTemplate()"
              ></dx-button>
            </div>
            <dx-tooltip
              target="#addButton"
              *ngIf="!canAddTemplate"
              showEvent="mouseenter"
              hideEvent="mouseleave"
              [hideOnOutsideClick]="true"
            >
              <div *dxTemplate="let data = data; of: 'content'">
                <div>{{ 'Sie haben die maximale Anzahl an Templates in ihrem Account erreicht.' | translate }}</div>
              </div>
            </dx-tooltip>
          </div>

          <div *dxTemplate="let toolbaritem of 'helpButtonTemplate'">
            <np-help-button>
              <np-help-option
                text="Wiki"
                icon="images/Wiki.svg"
                link="https://knowledge.nextpim.de/grundlagen?utm_source=app.nextpim.de&utm_medium=affiliate&utm_campaign=wikilink#verwendung-des-template-editors"
              ></np-help-option>
              <np-help-option
                text="YouTube"
                icon="images/YouTube.svg"
                link="https://www.youtube.com/watch?v=VtYAW8lhN40"
              ></np-help-option>
            </np-help-button>
          </div>

          <dxi-column dataField="name" caption="{{ 'Template' | translate }}" [allowEditing]="true"></dxi-column>
          <dxi-column dataField="remarks" caption="{{ 'Bemerkungen' | translate }}" [allowEditing]="true"></dxi-column>
          <dxi-column
            dataField="isExportTemplate"
            caption="{{ 'Export Template' | translate }}?"
            cellTemplate="exportTemplateTemplate"
          >
            <div *dxTemplate="let cellData of 'exportTemplateTemplate'">
              <dx-check-box
                [disabled]="cellData.data.isAdvancedTemplate"
                [value]="cellData.data.isExportTemplate"
                (onValueChanged)="exportTemplateCheckboxChanged($event, cellData)"
              ></dx-check-box>
            </div>
          </dxi-column>
          <dxi-column
            dataField="deleteEmptyFeatures"
            caption="{{ 'Leere Features löschen ' | translate }}?"
            [allowEditing]="true"
          ></dxi-column>
          <dxi-column
            caption="{{ 'Anzahl Spalten' | translate }}"
            [allowEditing]="false"
            dataType="number"
            [width]="125"
            cellTemplate="itemCountTemplate"
          >
            <div *dxTemplate="let cellData of 'itemCountTemplate'">
              {{ itemCountTemplate(cellData) }}
            </div>
          </dxi-column>
          <dxi-column
            dataField="created"
            caption="{{ 'Angelegt' | translate }}"
            [allowEditing]="false"
            dataType="date"
            [format]="{ type: 'dd.MM.yyyy' }"
            [width]="105"
          ></dxi-column>
          <dxi-column
            dataField="lastChange"
            caption="{{ 'Änderung' | translate }}"
            [allowEditing]="false"
            dataType="date"
            [format]="{ type: 'dd.MM.yyyy' }"
            [width]="105"
          ></dxi-column>

          <dxi-column caption="" width="75" cellTemplate="duplicateTemplate"></dxi-column>
          <div *dxTemplate="let d of 'duplicateTemplate'">
            <a
              (click)="duplicateTemplate(d)"
              class="dx-link glyphicon glyphicon-duplicate mr-2"
              title="{{ 'Template duplizieren' | translate }}"
            ></a>
            <a
              (click)="editTemplate(d)"
              class="dx-link dx-link-edit dx-icon-edit dx-link-icon mr-2"
              title="{{ 'Template bearbeiten' | translate }}"
            ></a>
          </div>
        </dx-data-grid>
      </div>
    </div>
  </content-column>
</fullscreen-page>

<template-editor-dialog
  *ngIf="editorVisible"
  [(visible)]="editorVisible"
  [showSelector]="false"
  [exportMode]="exportMode"
></template-editor-dialog>

<dx-popup
  class="popup"
  [width]="600"
  [height]="400"
  [showTitle]="true"
  title="{{ 'Neues Template' | translate }}"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="addTemplateVisible"
  contentTemplate="popupContent"
>
  <div *dxTemplate="let data of 'popupContent'">
    <newTemplate (onAdded)="addTemplate($event)"></newTemplate>
  </div>
</dx-popup>

<dx-popup
  class="popup"
  [width]="600"
  [height]="400"
  [showTitle]="true"
  title="{{ 'Neues Experten Template' | translate }}"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="addAdvancedTemplateVisible"
  contentTemplate="popupContent"
>
  <div *dxTemplate="let data of 'popupContent'">
    <np-advanced-template-create (onAdded)="addAdvancedTemplate($event)"></np-advanced-template-create>
  </div>
</dx-popup>
