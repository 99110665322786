<dx-data-grid
  [dataSource]="dataSource"
  [remoteOperations]="false"
  [allowColumnReordering]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  [editing]="{
    mode: 'cell',
    allowUpdating: true,
    allowAdding: true,
    allowDeleting: true,
    newRowPosition: 'last'
  }"
  (onRowInserted)="onSaving()"
  (onRowUpdated)="onSaving()"
  (onRowRemoved)="onSaving()"
  (onInitNewRow)="onInitNewRow($event)"
>
  <dxi-column dataField="order" caption="{{ 'Spalte' | translate }}" [width]="150" [allowEditing]="true"></dxi-column>

  <dxi-column dataField="name" [allowEditing]="true" caption="{{ 'Feldname' | translate }}"></dxi-column>
  <dxi-column dataField="value" [allowEditing]="true" caption="{{ 'Feldwert' | translate }}"></dxi-column>
</dx-data-grid>
