<div class="dx-fieldset">
  <dx-validation-group id="LOGI">

    <!--Customs_Number-->
    <div class="np-field">
      <np-inputControl [(model)]="model"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="model?.productLogistic?.customsNumber?.length > 0"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Zolltarifnummer' | translate }}"
                       field="CUSTOMS_NUMBER"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-text-box [(value)]="model?.productLogistic.customsNumber"
                       [readOnly]="readOnlyForLinkedFields('CUSTOMS_NUMBER') || readOnly('logisticZollTarif')"
                       [showClearButton]="!readOnlyForLinkedFields('CUSTOMS_NUMBER')"
                       (onValueChanged)="update($event, 'CUSTOMS_NUMBER')"
                       class="validationBlue">
            <dx-validator *ngIf="!templateView" validationGroup="LOGI">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('CUSTOMS_NUMBER')"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>

      </np-inputControl>
    </div>

    <!--Country of Orgin-->
    <div class="np-field">
      <np-inputControl [(model)]="model"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="model?.productLogistic?.countryOfOrigin?.length > 0"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Ursprungsland' | translate }}"
                       field="COUNTRY_OF_ORIGIN"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-text-box [(value)]="model.productLogistic.countryOfOrigin"
                       [readOnly]="readOnlyForLinkedFields('COUNTRY_OF_ORIGIN') || readOnly('logisticCountryOfOrigin')"
                       [showClearButton]="!readOnlyForLinkedFields('COUNTRY_OF_ORIGIN')"
                       (onValueChanged)="update($event, 'COUNTRY_OF_ORIGIN')"
                       class="validationBlue">
            <dx-validator *ngIf="!templateView" validationGroup="LOGI">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('COUNTRY_OF_ORIGIN')"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>

      </np-inputControl>
    </div>

    <!--Width-->
    <div class="np-field">
      <np-inputControl [(model)]="model"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="model?.productLogistic?.width != null"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Breite' | translate }}"
                       field="WIDTH"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-number-box [(value)]="model.productLogistic.width"
                       [readOnly]="readOnlyForLinkedFields('WIDTH') || readOnly('logisticWidth')"
                       [showClearButton]="!readOnlyForLinkedFields('WIDTH')"
                       (onValueChanged)="update($event, 'WIDTH')"
                       class="validationBlue">
            <dx-validator *ngIf="!templateView" validationGroup="LOGI">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('WIDTH')"></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
        </div>

      </np-inputControl>
    </div>

    <!--Length-->
    <div class="np-field">
      <np-inputControl [(model)]="model"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="model?.productLogistic?.length != null"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Länge' | translate }}"
                       field="LENGTH"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-number-box [(value)]="model.productLogistic.length"
                       [readOnly]="readOnlyForLinkedFields('LENGTH') || readOnly('logisticLenghth')"
                       [showClearButton]="!readOnlyForLinkedFields('LENGTH')"
                       (onValueChanged)="update($event, 'LENGTH')"
                       class="validationBlue">
            <dx-validator *ngIf="!templateView" validationGroup="LOGI">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('LENGTH')"></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
        </div>

      </np-inputControl>
    </div>

    <!--Depth-->
    <div class="np-field">
      <np-inputControl [(model)]="model"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="model?.productLogistic?.depth != null"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Tiefe' | translate }}"
                       field="DEPTH"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-number-box [(value)]="model.productLogistic.depth"
                       [readOnly]="readOnlyForLinkedFields('DEPTH') || readOnly('logisticDepth')"
                       [showClearButton]="!readOnlyForLinkedFields('DEPTH')"
                       (onValueChanged)="update($event, 'DEPTH')"
                       class="validationBlue">
            <dx-validator *ngIf="!templateView" validationGroup="LOGI">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('DEPTH')"></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
        </div>

      </np-inputControl>
    </div>

    <!--Weight-->
    <div class="np-field">
      <np-inputControl [(model)]="model"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="model?.productLogistic?.weight != null"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Gewicht' | translate }}"
                       field="WEIGHT"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-number-box [(value)]="model.productLogistic.weight"
                       [readOnly]="readOnlyForLinkedFields('WEIGHT') || readOnly('logisticWeight')"
                       [showClearButton]="!readOnlyForLinkedFields('WEIGHT')"
                       (onValueChanged)="update($event, 'WEIGHT')"
                       class="validationBlue">
            <dx-validator *ngIf="!templateView" validationGroup="LOGI">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('WEIGHT')"></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
        </div>

      </np-inputControl>
    </div>

    <div class="color5Text" *ngIf="templateView == false && model?.hintsActiv && !validationService.validationResult.productLogisticsValid">
      <div>{{ 'Nicht alle Logistikfelder sind ausgefüllt' | translate }}.</div>
    </div>
  </dx-validation-group>
</div>
