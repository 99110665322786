import { Product } from '../Catalog/Product';
import { DataQualityFacts } from '../Catalog/DataQualityFacts';

export class ValidationRequest {

  constructor(
    public field?: string,
    public product?: Product,
    public methodId?: string,
    public dataQualityFacts?: DataQualityFacts,
    public systemKey?: string,
    public elementKey?: string,
    public booleanRadioGroupValue?: boolean,
    public isBooleanRadioGroup?: boolean
  ) {

  }
}
