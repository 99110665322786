<div class="dx-card">
  <div class="dx-fieldset">
    <dx-box direction="row" width="100%" [ngStyle]="{ gap: '2rem', marginBottom: '2rem' }">
      <dxi-item [ratio]="1">
        <div class="d-flex gap-3">
          <div class="np-field flex-grow-1">
            <np-inputControl
              [(model)]="model"
              [showLinkButtons]="showLinkButtons"
              [showPlaceLabel]="detail.priceType?.length > 0"
              [suppressLabel]="false"
              labelText="{{ 'Welcher Preistyp' | translate }}"
              [(selectedElements)]="selectedElements"
              (elementSelected)="templateSelect($event)"
              (onValueChanged)="update($event)"
              field="PRICE_TYPE"
              [system]="priceList.priceListOrder.toString()"
              [element]="detail.order.toString()"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot">
                <np-dropdown-control
                  [items]="priceTypes"
                  [(model)]="detail"
                  modelProp="priceType"
                  fieldName="PRICE_TYPE"
                  [readOnly]="readOnlyForLinkedFields('PRICE_TYPE') || readOnly('priceType')"
                  [templateView]="templateView"
                  [showRealValue]="true"
                  validationGroup="PRICE"
                  [validationCallback]="validationService.validateField('PRICE_TYPE')"
                  (onValueChanged)="update($event, null, null, 'PRICE_TYPE')"
                ></np-dropdown-control>
              </div>
            </np-inputControl>
          </div>
        </div>

        <div class="d-flex gap-3">
          <div class="np-field flex-grow-1">
            <np-inputControl
              [(model)]="model"
              [showLinkButtons]="showLinkButtons"
              [showPlaceLabel]="detail.priceAmountNumber !== null"
              [suppressLabel]="false"
              labelText="{{ 'Geben Sie den Preis' | translate }}"
              [(selectedElements)]="selectedElements"
              (elementSelected)="templateSelect($event)"
              (onValueChanged)="update($event)"
              field="PRICE_AMOUNT"
              [system]="priceList.priceListOrder.toString()"
              [element]="detail.order.toString()"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot">
                <dx-number-box
                  [showClearButton]="!readOnlyForLinkedFields('PRICE_AMOUNT')"
                  [(value)]="detail.priceAmountNumber"
                  [readOnly]="readOnlyForLinkedFields('PRICE_AMOUNT') || readOnly('priceAmount')"
                  (onValueChanged)="update($event, null, null, 'PRICE_AMOUNT')"
                >
                  <dx-validator *ngIf="!templateView" validationGroup="PRICE">
                    <dxi-validation-rule
                      type="async"
                      [validationCallback]="
                        validationService.validateField(
                          'PRICE_AMOUNT',
                          priceList.priceListOrder.toString(),
                          detail.order.toString()
                        )
                      "
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-number-box>
              </div>
            </np-inputControl>
          </div>
          <div class="flex-grow-1 dx-field-label dx-field-label-np d-flex flex-column">
            {{
              'je Preismenge/Bestelleinheit an'
                | translate
                  : {
                      priceQuantity: model.orderDetail?.priceQuantityNumber,
                      orderUnit: translate.instant(model.orderDetail?.orderUnit)
                    }
            }}
          </div>
        </div>

        <div class="d-flex gap-3">
          <div class="np-field flex-grow-1">
            <np-inputControl
              [(model)]="model"
              [showLinkButtons]="showLinkButtons"
              [showPlaceLabel]="detail.priceCurrency?.length > 0"
              [suppressLabel]="false"
              labelText="{{ 'Welche Währung' | translate }}"
              [(selectedElements)]="selectedElements"
              (elementSelected)="templateSelect($event)"
              (onValueChanged)="update($event)"
              field="PRICE_CURRENCY"
              [system]="priceList.priceListOrder.toString()"
              [element]="detail.order.toString()"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot">
                <np-dropdown-control
                  [items]="currencies"
                  [(model)]="detail"
                  modelProp="priceCurrency"
                  fieldName="PRICE_CURRENCY"
                  [readOnly]="readOnlyForLinkedFields('PRICE_CURRENCY') || readOnly('priceCurrency')"
                  [templateView]="templateView"
                  [showRealValue]="true"
                  translationLabelPrefix="Currency_"
                  validationGroup="PRICE"
                  [validationCallback]="validationService.validateField('PRICE_CURRENCY')"
                  (onValueChanged)="update($event, null, null, 'PRICE_CURRENCY')"
                ></np-dropdown-control>
              </div>
            </np-inputControl>
          </div>
        </div>

        <div class="d-flex gap-3">
          <div class="np-field flex-grow-1">
            <np-inputControl
              [(model)]="model"
              [showLinkButtons]="showLinkButtons"
              [showPlaceLabel]="detail.taxRate !== null"
              [suppressLabel]="false"
              labelText="{{ 'Steuer in %' | translate }}"
              [(selectedElements)]="selectedElements"
              (elementSelected)="templateSelect($event)"
              (onValueChanged)="update($event)"
              field="TAX"
              [system]="priceList.priceListOrder.toString()"
              [element]="detail.order.toString()"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot">
                <dx-autocomplete
                  [minSearchLength]="0"
                  [dataSource]="taxs"
                  [(value)]="detail.taxRate"
                  [showClearButton]="false"
                  [readOnly]="readOnlyForLinkedFields('TAX') || readOnly('priceTax')"
                  [showDropDownButton]="!readOnlyForLinkedFields('TAX')"
                  valueChangeEvent="blur"
                  (onValueChanged)="update($event, null, null, 'TAX')"
                  (onPaste)="numericAutocompletePaste($event)"
                  (onKeyPress)="numericAutocompleteKeyPress($event)"
                >
                  <div slot="content" class="contentSlot">
                    <dx-number-box [(value)]="detail.taxRate" [readOnly]="true"></dx-number-box>
                  </div>
                  <dx-validator *ngIf="!templateView" validationGroup="PRICE">
                    <dxi-validation-rule
                      type="async"
                      [validationCallback]="validationService.validateNumberField('TAX')"
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-autocomplete>
              </div>
            </np-inputControl>
          </div>
          <div class="flex-grow-1 dx-field-label dx-field-label-np d-flex flex-column">
            {{ 'Prozent MwSt.' | translate : { tax } }}
          </div>
        </div>
      </dxi-item>
      <dxi-item [ratio]="1">
        <div class="d-flex gap-3">
          <div class="np-field flex-grow-1">
            <np-inputControl
              [(model)]="model"
              [showLinkButtons]="showLinkButtons"
              [showPlaceLabel]="detail.priceFactorNumber !== null"
              [suppressLabel]="false"
              labelText="{{ 'Rabatt in %' | translate }}"
              [(selectedElements)]="selectedElements"
              (elementSelected)="templateSelect($event)"
              (onValueChanged)="update($event)"
              field="PRICE_FACTOR"
              [system]="priceList.priceListOrder.toString()"
              [element]="detail.order.toString()"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot">
                <dx-autocomplete
                  [minSearchLength]="0"
                  [dataSource]="priceFactors"
                  [showClearButton]="false"
                  [showDropDownButton]="!readOnlyForLinkedFields('PRICE_FACTOR')"
                  [(value)]="detail.priceFactorNumber"
                  [readOnly]="readOnlyForLinkedFields('PRICE_FACTOR') || readOnly('priceFactor')"
                  valueChangeEvent="blur"
                  (onValueChanged)="update($event, null, null, 'PRICE_FACTOR')"
                  (onPaste)="numericAutocompletePaste($event)"
                  (onKeyPress)="numericAutocompleteKeyPress($event)"
                >
                  <div slot="content" class="contentSlot">
                    <dx-number-box [(value)]="detail.priceFactorNumber" [readOnly]="true"></dx-number-box>
                  </div>
                  <dx-validator *ngIf="!templateView" validationGroup="PRICE">
                    <dxi-validation-rule
                      type="async"
                      [validationCallback]="validationService.validateField('PRICE_FACTOR')"
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-autocomplete>
              </div>
            </np-inputControl>
          </div>
        </div>

        <div class="d-flex gap-3">
          <div class="np-field flex-grow-1">
            <np-inputControl
              [(model)]="model"
              [showLinkButtons]="showLinkButtons"
              [showPlaceLabel]="detail.lowerBoundNumber !== null"
              [suppressLabel]="false"
              labelText="{{ 'Staffelpreis gültig ab' | translate }}"
              [(selectedElements)]="selectedElements"
              (elementSelected)="templateSelect($event)"
              (onValueChanged)="update($event)"
              field="LOWER_BOUND"
              [system]="priceList.priceListOrder.toString()"
              [element]="detail.order.toString()"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot">
                <dx-autocomplete
                  [minSearchLength]="0"
                  [dataSource]="priceFactors"
                  [showClearButton]="false"
                  [showDropDownButton]="!readOnlyForLinkedFields('LOWER_BOUND')"
                  [(value)]="detail.lowerBoundNumber"
                  [readOnly]="readOnlyForLinkedFields('LOWER_BOUND') || readOnly('priceFactors')"
                  valueChangeEvent="blur"
                  (onValueChanged)="update($event, null, null, 'LOWER_BOUND')"
                  (onPaste)="numericAutocompletePaste($event)"
                  (onKeyPress)="numericAutocompleteKeyPress($event)"
                >
                  <div slot="content" class="contentSlot">
                    <dx-number-box [(value)]="detail.lowerBoundNumber" [readOnly]="true"></dx-number-box>
                  </div>
                  <dx-validator *ngIf="!templateView" validationGroup="PRICE">
                    <dxi-validation-rule
                      type="async"
                      [validationCallback]="validationService.validateField('LOWER_BOUND')"
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-autocomplete>
              </div>
            </np-inputControl>
          </div>
          <div class="flex-grow-1 dx-field-label dx-field-label-np d-flex flex-column">
            {{ model.orderDetail.orderUnit | translate }}
          </div>
        </div>

        <div class="d-flex gap-3">
          <div class="np-field flex-grow-1">
            <np-inputControl
              [(model)]="model"
              [showLinkButtons]="showLinkButtons"
              [showPlaceLabel]="detail.territory?.length > 0"
              [suppressLabel]="false"
              labelText="{{ 'Verkaufsregion' | translate }}"
              [(selectedElements)]="selectedElements"
              (elementSelected)="templateSelect($event)"
              (onValueChanged)="update($event)"
              field="TERRITORY"
              [system]="priceList.priceListOrder.toString()"
              [element]="detail.order.toString()"
              [showDragHandle]="showDragHandle"
              [dragFilter]="dragFilter"
              (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot">
                <np-dropdown-control
                  [items]="territories"
                  [(model)]="detail"
                  modelProp="territory"
                  fieldName="TERRITORY"
                  [readOnly]="readOnlyForLinkedFields('TERRITORY') || readOnly('territory')"
                  [templateView]="templateView"
                  [showRealValue]="true"
                  translationLabelPrefix="Country_"
                  validationGroup="PRICE"
                  [validationCallback]="validationService.validateField('TERRITORY')"
                  (onValueChanged)="update($event, null, null, 'TERRITORY')"
                ></np-dropdown-control>
              </div>
            </np-inputControl>
          </div>
        </div>
      </dxi-item>
    </dx-box>

    <div *ngIf="detail.priceAmountNumber !== null">
      <hr />

      <p class="price-preview">
        <b>{{ 'Vorschau' | translate }}:</b>
        {{
          'Bruttopreis (excl. MwSt) je Preismenge/Bestelleinheit'
            | translate
              : {
                  grossPrice,
                  tax,
                  priceQuantity: model.orderDetail?.priceQuantityNumber,
                  orderUnit: translate.instant(model.orderDetail?.orderUnit)
                }
        }}
      </p>
    </div>
  </div>
</div>
