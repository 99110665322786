import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Feature } from '../Model/Catalog/FeatureModels/Feature';
import { FeatureSystem } from '../Model/Catalog/FeatureModels/FeatureSystem';
import { Mime } from '../Model/Catalog/Mime';
import { PriceList } from '../Model/Catalog/PriceList';
import { Product } from '../Model/Catalog/Product';
import { ProductPriceDetail } from '../Model/Catalog/ProductPriceDetail';
import { Template } from '../Model/Catalog/Template';
import { TemplateItem } from '../Model/Catalog/TemplateItem';
import { HasElementKey, HasSystemKey, NextPimField } from '../Model/Catalog/NextPimField';
import { FeatureValue } from '../Model/classification/FeatureValue';
import { BackgroundJob } from '../Model/Dto/BackgroundJob';
import { FieldNames } from '../Model/FieldNames';
import { ExcelElement } from '../Model/ui/ExcelElement';
import { HazmatClassService } from './hazmatClass.service';
import { LoginService } from './login.service';
import { ViewService } from './view.service';
import { WarrantyClassService } from './warrantyClass.service';
import { RestartJob } from '../Model/Dto/RestartJob';
import { UrlResponse } from '../Model/Dto/UrlResponse';
import { SystemService } from './system.service';
import { Message } from '../Model/System/Message';
import { UdxField } from '../Model/Catalog/UdxField';
import { Reference } from '../Model/Catalog/Reference';
import { ProductService } from './CatalogManagement/product.service';
import { ProductSupplier } from '../Model/Catalog/ProductSupplier';
import { WawiListService } from './wawiList.service';
import { PackingUnit } from '../Model/Catalog/PackingUnit';
import { StringResponse } from '../Model/Dto/StringResponse';
import { ValidFeatureService } from './validFeature.service';
import { safeAsyncGetFromModel, safeAsyncSetToModel, safeGetFromModel, safeSetToModel } from '../Model/ModelOperations';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  public fieldNames: FieldNames;

  private _templates: Template[];
  private _exportTemplates: Template[];
  private _template: Template;

  get template(): Template {
    return this._template;
  }
  set template(value: Template) {
    this._template = value;
  }

  get templates(): Template[] {
    return this._templates;
  }

  get exportTemplates(): Template[] {
    return this._exportTemplates;
  }

  get exportMode(): boolean {
    return this.template.isExportTemplate;
  }

  private _importJob: BackgroundJob;
  get importJob(): BackgroundJob {
    return this._importJob;
  }
  set importJob(value: BackgroundJob) {
    this._importJob = value;
  }

  private _importTemplateEditorVisible: boolean;
  get importTemplateEditorVisible(): boolean {
    return this._importTemplateEditorVisible;
  }
  set importTemplateEditorVisible(value: boolean) {
    this._importTemplateEditorVisible = value;
  }

  txtGroupString: string = '';

  // listen mit feldnamen. verwendung sowohl für linked fields logik als auch für den template editor.
  public allMimeFields: string[] = [];
  public allFeatureSystemFields: string[] = [];
  public allFeatureFields: string[] = [];
  public allPriceListFields: string[] = [];
  public allPriceFields: string[] = [];
  public allReferenceFields: string[] = [];
  public allUdxFields: string[] = [];
  public allSupplierFields: string[] = [];
  public allSupplierUnitFields: string[] = [];

  public clearPimFieldEmitter = new EventEmitter<NextPimField>();
  public clearPimFieldEmitterDone = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    public loginService: LoginService,
    public systemService: SystemService,
    public hazmatClassService: HazmatClassService,
    public warrantyClassService: WarrantyClassService,
    public translate: TranslateService,
    public productService: ProductService,
    private wawiListService: WawiListService,
    public validFeatureService: ValidFeatureService
  ) {
    this.fieldNames = new FieldNames();

    this.allMimeFields = [
      this.fieldNames.mimeType,
      this.fieldNames.mimeSource,
      this.fieldNames.mimeDescription,
      this.fieldNames.mimeAlt,
      this.fieldNames.mimePurpose
    ];

    this.allFeatureSystemFields = [
      this.fieldNames.featureSystemOrder,
      this.fieldNames.featureSystemName,
      this.fieldNames.featureSystemGroupId,
      this.fieldNames.featureSystemGroupId2,
      this.fieldNames.featureSystemGroupName
    ];

    this.allFeatureFields = [
      this.fieldNames.fOrder,
      this.fieldNames.fName,
      this.fieldNames.fValue,
      this.fieldNames.fUnit,
      this.fieldNames.fDescription,
      this.fieldNames.fValueDetails,
      this.fieldNames.featurePrintOrder,
      this.fieldNames.isVariant
    ];

    this.allPriceListFields = [this.fieldNames.startDate, this.fieldNames.endDate, this.fieldNames.isDailyPrice];

    this.allPriceFields = [
      this.fieldNames.priceType,
      this.fieldNames.priceAmount,
      this.fieldNames.priceFactor,
      this.fieldNames.priceCurrency,
      this.fieldNames.lowerBound,
      this.fieldNames.tax,
      this.fieldNames.territory
    ];

    this.allReferenceFields = [this.fieldNames.productReferencesType, this.fieldNames.productReferencesSpid];

    this.allUdxFields = [this.fieldNames.udxCategory, this.fieldNames.udxName, this.fieldNames.udxValue];

    this.allSupplierFields = [
      this.fieldNames.supplierOrder,
      this.fieldNames.supplierId,
      this.fieldNames.supplierName,
      this.fieldNames.pid,
      this.fieldNames.supplierInternationalId,
      this.fieldNames.supplierInternationalIdType,
      this.fieldNames.supplierProductDescription,
      this.fieldNames.supplierProductDescriptionShort,
      this.fieldNames.supplierDeliveryTime,
      this.fieldNames.supplierPrice,
      this.fieldNames.supplierValidFrom,
      this.fieldNames.supplierDiscountGroupCode,
      this.fieldNames.supplierPricingUnitCode,
      this.fieldNames.supplierPriceAllocationUnitCode,
      this.fieldNames.supplierPriceAllocationUnitCodeOriginal
    ];

    let a = this.translate.instant('Hauptkategorie');
    let b = this.translate.instant('Unterkategorie');
    this.txtGroupString = a + '|' + b;
  }

  public getTemplates() {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', this.loginService.currentUser.customerId)
    };
    const observable = this.http.get<Template[]>('api/import/GetTemplates', options);
    observable.pipe(map((result: Template[]) => (this._templates = result))).subscribe();
    return observable;
  }

  public getTemplatesReturn(): Observable<Template[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', this.loginService.currentUser.customerId)
    };
    return this.http.get<Template[]>('api/import/GetTemplates', options);
  }

  public getExportTemplates(): Promise<Template[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', this.loginService.currentUser.customerId)
    };
    return this.http
      .get<Template[]>('api/import/GetTemplates', options)
      .pipe(
        map((result: Template[]) => {
          if (result == null || result == undefined) {
            this._exportTemplates = Array<Template>();
          }

          let hasExportTemplates = result.some((t) => t.isExportTemplate);
          if (hasExportTemplates) {
            let exportTemplates = result.filter((t) => t.isExportTemplate);
            this._exportTemplates = exportTemplates;
          } else {
            this._exportTemplates = Array<Template>();
          }
        })
      )
      .toPromise()
      .then();
  }

  public async getSAPAribaTemplates(): Promise<Template[]> {
    await this.getExportTemplates();
    this._exportTemplates = this._exportTemplates.filter(x => x.templateType === 'SAP_ARIBA');
    return this._exportTemplates;
  }

  public getTemplateById(templateId: string): Observable<Template> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams()
        .append('customerId', this.loginService.currentUser.customerId)
        .append('templateId', templateId)
    };

    return this.http
      .get<Template>('api/import/GetTemplateById', options)
      .pipe(map((result: Template) => (this.template = result)));
  }

  public getHeadline(field: string, element: string, system: string, customerId: string): Observable<StringResponse> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams()
        .append('field', field)
        .append('system', system)
        .append('element', element)
        .append('customerId', customerId)
    };

    return this.http.get<StringResponse>('api/import/GetHeadline', options) as any;
  }

  public canCustomerAddTemplate(customerId: string): Observable<boolean> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId)
    };

    return this.http.get<boolean>('api/template/CanCustomerAddTemplate', options);
  }

  public setCurrentTemplate(id: string) {
    let index = this._templates.findIndex(((template) => template.id == id) as any);

    if (index != -1) {
      this.template = this._templates[index];
    } else {
      this.template = null;
    }
  }

  public setCurrentExportTemplate(id: string) {
    let index = -1;
    if (this._exportTemplates != null)
      index = this._exportTemplates.findIndex(((template) => template.id == id) as any);

    if (index != -1) {
      this.template = this._exportTemplates[index];
    } else {
      this.template = null;
    }
  }

  public async addTemplate(template: Template = null): Promise<string> {
    if (template == null) {
      template = new Template();
      template.name = this.translate.instant('Neues Template');
    }
    template.customerId = this.loginService.currentUser.customerId;

    let id = await this.saveTemplate(template, true);
    return id;
  }

  public updateTemplate(template: Template) {
    //this.sortTemplateItems(template.templateItems); // eig. haben wir das vorher schon erledigt...
    template.isUseable = true;
    this.saveTemplate(template);
  }

  public async saveTemplate(template: Template, reload = false): Promise<string> {
    template.lastChange = new Date();

    const result = await lastValueFrom<UrlResponse>(this.http.post('api/import/SaveTemplate', template));
    if (reload) await lastValueFrom(this.getTemplates());
    return result.url;
  }

  public async deleteTemplate(template: Template): Promise<void> {
    let id = template.id;
    this.template = null;
    this._templates = this._templates.filter((value) => value.id != id);
    await lastValueFrom(this.http.delete(`api/Import/DeleteTemplate/${id}`));
    await lastValueFrom(this.getTemplates());
  }

  public startImport() {
    if (this.template.isUseable == false) {
      // noch nicht nutzbar => nichts zugeordnet
      let message = this.translate.instant('Es gibt keine Zuordnungen im Template') + '.';
      this.systemService.notify(new Message(message, 'info'), 3000);

      return;
    }

    this.importTemplateEditorVisible = false;

    //this._template.isUseable = true;          // auf keinen fall!! drückt man dann einfach nochmal auf den knopf, greift der check oben nicht mehr!!
    //this._template.isExportTemplate = false;  // bereits vorher gesetzt, unnötig
    let importJobId = this.importJob.id;
    this.saveTemplate(this._template).then(() => {
      var restartJob = new RestartJob();
      restartJob.jobId = importJobId;
      this.http.post('api/Job/RestartJob', restartJob).subscribe();
    });
  }

  // EXPORT

  public createTemplateItems(array: ExcelElement[], isExport: boolean): TemplateItem[] {
    let result: TemplateItem[] = [];

    if (!isExport) {
      for (let i = array.length - 1; i >= 0; i--) {
        let item = array[i];
        // 1. verwaiste einträge wegräumen (treten auf, wenn ein item mit default-value durch ein "richtiges" item ersetzt wird)
        // 2. geleerte (default entfernt) wegräumen

        if (item.title == null && item.pimFields.length == 0) {
          array.splice(i, 1);
          i = 0;
        }
      }
      this.refresExcelElementhOrder(array);
    }

    array.forEach((element, index) => {
      let item = new TemplateItem();
      item.order = index + 1;
      item.key = element.title;
      item.pimFields = element.pimFields;
      item.seperator = element.seperator;

      if (item.pimFields.findIndex((x) => this.needsStandardSeperator(x.field)) != -1) {
        if (item.seperator == null || item.seperator == undefined || item.seperator == '') {
          item.seperator = '|';
        }
      }

      item.catName = element.catName;
      item.name = element.name;

      item.defaultValue = element.defaultValue;
      item.htmlFilter = false;

      item.previewValues = [];
      if (element.row1 != '') {
        item.previewValues.push(element.row1);
      } else {
        item.previewValues.push(element.preview);
      }
      if (element.row2 != '') {
        item.previewValues.push(element.row2);
      }

      item.calculation = element.calculation;
      item.numberformat = element.numberformat;
      item.mapping = element.mapping;
      item.remove = element.remove;
      item.isNewEditorVersion = true;
      item.addprev = element.addprev;
      item.addfoll = element.addfoll;
      item.maxCharacters = element.maxCharacters;
      item.section = element.section;
      item.languageCode = element.languageCode !== '' ? element.languageCode : null;
      item.factorOperator = element.factorOperator;
      item.factor = element.factor;
      item.prefix = element.prefix;
      item.suffix = element.suffix;
      item.catName = element.catName;
      item.name = element.name;

      result.push(item);
    });

    return result;
  }

  public hasTemplateItem(array: TemplateItem[], field: string, system: string, element: string): boolean {
    let found = array.find(
      (i) =>
        i.pimFields != undefined &&
        i.pimFields.findIndex((x) => x.field == field && x.systemKey == system && x.elementKey == element) != -1
    );
    if (found != null) {
      return true;
    }
    return false;
  }

  public refreshTemplateItemOrder(array: TemplateItem[]) {
    let i = 1;
    array.forEach((element) => {
      element.order = i;
      i++;
    });
  }

  public createExcelElements(array: TemplateItem[], isusable: boolean): ExcelElement[] {
    let result: ExcelElement[] = [];

    array.forEach((item) => {
      let element = new ExcelElement();

      element.id = item.order?.toString();
      element.title = item.key;

      element.row1 = '';
      element.row2 = '';
      if (item.previewValues == null) {
        item.previewValues = [];
      }
      let tmp = item.previewValues[0];
      if (tmp != null) {
        element.row1 = tmp;
      } else {
        element.row1 = '[' + element.title + ']';
      }
      tmp = item.previewValues[1];
      if (tmp != null) {
        element.row2 = tmp;
      }

      element.calculation = item.calculation;
      element.numberformat = item.numberformat;
      element.seperator = item.seperator;
      element.mapping = item.mapping;
      element.remove = item.remove;
      element.maxCharacters = item.maxCharacters;
      element.section = item.section;
      element.languageCode = item.languageCode !== '' ? item.languageCode : null;
      element.factorOperator = item.factorOperator;
      element.factor = item.factor;
      element.prefix = item.prefix;
      element.suffix = item.suffix;
      element.catName = item.catName;
      element.name = item.name;

      if (!isusable) {
        // item wurde evtl. als Auto-Template-Item mittels GetTemplateList() neu erstellt und wird zum ersten
        // aus der Job-Liste geöffnet. Diese müssen wir alle entfernen, da sie evtl. nicht bemerkt werden und
        // so falsche bzw. unerwartete Ergebnisse produzieren könnten.
        element.calculation = '';
        element.numberformat = '';
        element.seperator = '';
        element.mapping = '';
        element.remove = '';
        element.maxCharacters = 0;
        element.section = 1;
        element.languageCode = null;
        element.factorOperator = 'NONE';
        element.factor = 1;
        element.prefix = '';
        element.suffix = '';
      }

      if (item.pimFields != undefined) {
        element.pimFields = item.pimFields;
        element.addprev = item.addprev;
        element.addfoll = item.addfoll;
        element.defaultValue = item.defaultValue;

        //element.productId = this.createIdentifier(field.field, field.system, field.element);

        element.preview = element.row1;
        result.push(element);
      }
    });

    return result;
  }

  public hasExcelElement(array: ExcelElement[], field: string, system: string, element: string): boolean {
    let found = array.find((i) =>
      i.pimFields.some((x) => x.field == field && x.systemKey == system && x.elementKey == element)
    );
    if (found != null) {
      return true;
    }
    return false;
  }

  public getExcelElement(array: ExcelElement[], field: string, system: string, element: string): ExcelElement {
    return array.find((i) =>
      i.pimFields.some((x) => x.field == field && x.systemKey == system && x.elementKey == element)
    );
  }

  public refresExcelElementhOrder(array: ExcelElement[]) {
    let i = 1;
    array.forEach((element) => {
      element.id = i.toString();
      i++;
    });
  }

  public setPreviewRow1Row2(excelElement: ExcelElement, preview: string, row1: string, row2: string) {
    excelElement.preview = preview;
    excelElement.row1 = row1;
    excelElement.row2 = row2;
  }

  public setOptions(
    excelElement: ExcelElement,
    defaultValue: string,
    calculation: string,
    numberformat: string,
    remove: string,
    seperator: string
  ) {
    excelElement.defaultValue = defaultValue;

    excelElement.calculation = calculation;
    excelElement.numberformat = numberformat;
    excelElement.remove = remove;

    excelElement.seperator = seperator;
  }

  public clearPreviewRow1Row2(excelElement: ExcelElement) {
    this.setPreviewRow1Row2(excelElement, '', '', '');
  }

  public clearOptions(excelElement: ExcelElement) {
    this.setOptions(excelElement, '', '', '', '', '');
  }

  public createIdentifiers(fields: NextPimField[]): string[] {
    if (fields == undefined) return [];

    return fields.map((x) => {
      if (x.field != null && x.field != '') {
        let result = x.field;
        if (HasSystemKey(x)) {
          result += '_' + x.systemKey;
          if (HasElementKey(x)) {
            result += '_' + x.elementKey;
          }
        }
        return result;
      } else {
        return '';
      }
    });
  }

  public createGroupString(array: ExcelElement[]): string {
    let standard = this.txtGroupString;

    if (this.hasExcelElement(array, this.fieldNames.group, '-1', '-1')) {
      var element = this.getExcelElement(array, this.fieldNames.group, '-1', '-1');
      return standard.split('|').join(element.seperator);
    } else {
      return standard;
    }
  }

  public needsStandardSeperator(field: string): boolean {
    if (
      field == this.fieldNames.keywords ||
      field == this.fieldNames.status ||
      field == this.fieldNames.group ||
      field == this.fieldNames.fValue
    ) {
      // weitere felder??
      return true;
    }
    return false;
  }

  public syncTemplateItems(source: TemplateItem[], target: TemplateItem[], keepDefaultValues: boolean): TemplateItem[] {
    // Jedes Item in Target pauschal platt machen.
    // Dann mit einem evtl. gefundenen Item aus Source überschreiben.

    let result = new Array<TemplateItem>();

    target.forEach((item) => {
      this.clearTemplateItem(item);

      let found = source.filter((s) => s.key == item.key)[0];
      if (found) {
        this.copyTemplateItem(found, item);
      }

      if (item.key != null) {
        result.push(item);
      }
    });

    if (keepDefaultValues) {
      let order = result.length;

      // Default Values haben KEINEN KEY
      let defaultValues = source.filter((s) => s.key == null);

      defaultValues.forEach((item) => {
        order++;

        let newItem = new TemplateItem();

        this.copyTemplateItem(item, newItem);
        newItem.order = order;

        result.push(newItem);
      });
    }

    return result;
  }

  public calcSimilarityRatio(source: TemplateItem[], target: TemplateItem[]): number {
    // default values ausfiltern
    let tmpSource = source.filter((s) => s.key != null);
    let tmpTarget = target.filter((s) => s.key != null);

    let countSource = 0;
    let countTarget = tmpTarget.length;

    tmpTarget.forEach((item) => {
      let found = tmpSource.filter((s) => s.key == item.key)[0];
      if (found) {
        countSource++;
      }
    });

    return (countSource / countTarget) * 100;
  }

  public copyTemplateItem(source: TemplateItem, target: TemplateItem) {
    target.pimFields = source.pimFields;
    target.seperator = source.seperator;
    target.unit = source.unit;
    target.defaultValue = source.defaultValue;
    target.htmlFilter = source.htmlFilter;
    target.catName = source.catName;
    target.name = source.name;
    target.calculation = source.calculation;
    target.numberformat = source.numberformat;
    target.remove = source.remove;
    target.isNewEditorVersion = source.isNewEditorVersion;
  }

  public clearTemplateItem(templateItem: TemplateItem) {
    templateItem.pimFields = [];
    templateItem.seperator = '';
    templateItem.unit = null;
    templateItem.defaultValue = '';
    templateItem.htmlFilter = false;
    templateItem.catName = null;
    templateItem.name = null;
    templateItem.calculation = null;
    templateItem.numberformat = null;
    templateItem.remove = null;
    templateItem.isNewEditorVersion = false;
  }

  public async setValueOnProduct(
    model: Product,
    convert: boolean,
    isExport: boolean,
    excelElement: ExcelElement,
    field: string,
    system: string,
    element: string,
    value: string,
    excelModel: ExcelElement[],
    split: string = '|',
    check: boolean = true
  ) {
    try {
      switch (field) {
        case this.fieldNames.supplierPid:
          model.supplierPid = value;
          break;
        case this.fieldNames.supplierAltPid:
          model.supplierAltAid = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.descriptionShort:
          model.descriptionShort = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.descriptionLong:
          model.descriptionLong = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.manufacturerTypeDescription:
          model.manufacturerTypeDescr = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.internatonalPid:
          model.internationalPid = value;
          break;
        case this.fieldNames.internatonalPidType:
          model.internationalPidType = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.remarks:
          model.remarks = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.manufacturerPID:
          model.manufacturerAid = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.manufacturerName:
          model.manufacturerName = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.keywords:
          if (value == null || value == undefined || value == '') {
            model.keywords = [];
          } else {
            model.keywords = value.split(split);
          }
          break;
        case this.fieldNames.productOrder:
          model.productOrder = this.getNumber(value);
          break;
        case this.fieldNames.edeNumber1:
          model.edeNumber1 = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.edeNumber2:
          model.edeNumber2 = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.edeNumber3:
          model.edeNumber3 = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.edeNumber4:
          model.edeNumber4 = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.edeNumber5:
          model.edeNumber5 = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.erpGroupBuyer:
          model.erpGroupBuyer = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.erpGroupSupplier:
          model.erpGroupSupplier = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.buyerAid:
          model.buyerAid = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.isDiscontinued:
          model.isDiscontinued = this.getNullableBool(value);
          break;
        case this.fieldNames.discontinuedDate:
          model.discontinuedDate = this.getDate(value);
          break;
        case this.fieldNames.status:
          if (value == null || value == undefined || value == '') {
            model.states = [];
          } else {
            model.states = value.split(split);
          }
          break;

        case this.fieldNames.deliveryTime:
          model.orderDetail.deliveryTime = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.orderUnit:
          model.orderDetail.orderUnit = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.contentUnit:
          model.orderDetail.contentUnit = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.noCuPerOu:
          var result = await this.convertValue(excelElement, convert, value, isExport);
          model.orderDetail.noCuPerOuNumber = result ? parseFloat(result.replace(',', '.')) : null;
          break;
        case this.fieldNames.priceQuantity:
          var result = await this.convertValue(excelElement, convert, value, isExport);
          model.orderDetail.priceQuantityNumber = result ? parseFloat(result.replace(',', '.')) : null;
          break;
        case this.fieldNames.quantityMin:
          var result = await this.convertValue(excelElement, convert, value, isExport);
          model.orderDetail.quantityMinNumber = result ? parseFloat(result.replace(',', '.')) : null;
          break;
        case this.fieldNames.quantityMax:
          var result = await this.convertValue(excelElement, convert, value, isExport);
          model.orderDetail.quantityMaxNumber = result ? parseFloat(result.replace(',', '.')) : null;
          break;
        case this.fieldNames.quanityInterval:
          var result = await this.convertValue(excelElement, convert, value, isExport);
          model.orderDetail.quantityIntervalNumber = result ? parseFloat(result.replace(',', '.')) : null;
          break;
        case this.fieldNames.contentQuantity:
          var result = await this.convertValue(excelElement, convert, value, isExport);
          model.orderDetail.contentQuantityNumber = result ? parseFloat(result.replace(',', '.')) : null;
          break;
        case this.fieldNames.contentPackage:
          model.orderDetail.contentPackage = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.basicQuantity:
          var result = await this.convertValue(excelElement, convert, value, isExport);
          model.orderDetail.basicQuantityNumber = result ? parseFloat(result.replace(',', '.')) : null;
          break;
        case this.fieldNames.hasBasicPriceDuty:
          model.orderDetail.hasBasicPriceDuty = this.getNullableBool(value);
          break;
        case this.fieldNames.isBulkyGood:
          model.orderDetail.isBulkyGood = this.getNullableBool(value);
          break;
        case this.fieldNames.isTruckageCompanyGood:
          model.orderDetail.isTruckageCompanyGood = this.getNullableBool(value);
          break;
        case this.fieldNames.costOfLivingAdjustment:
          model.orderDetail.costOfLivingAdjustment = this.getNumber(value);
          break;
        case this.fieldNames.alloySurcharge:
          model.orderDetail.alloySurcharge = this.getNumber(value);
          break;
        case this.fieldNames.isDurabilityProduct:
          model.orderDetail.isDurabilityProduct = this.getNullableBool(value);
          break;
        case this.fieldNames.durabilityDays:
          model.orderDetail.durabilityDays = this.getNumber(value);
          break;

        case this.fieldNames.customsNumber:
          model.productLogistic.customsNumber = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.countryOfOrgin:
          model.productLogistic.countryOfOrigin = await this.convertValue(excelElement, convert, value, isExport);
          break;

        case this.fieldNames.weight:
          var weight = await this.convertValue(excelElement, convert, value, isExport);
          model.productLogistic.weightNumber = weight ? parseFloat(weight.replace(',', '.')) : null;
          break;
        case this.fieldNames.lenght:
          var length = await this.convertValue(excelElement, convert, value, isExport);
          model.productLogistic.lengthNumber = length ? parseFloat(length.replace(',', '.')) : null;
          break;
        case this.fieldNames.width:
          var width = await this.convertValue(excelElement, convert, value, isExport);
          model.productLogistic.widthNumber = width ? parseFloat(width.replace(',', '.')) : null;
          break;
        case this.fieldNames.depth:
          var depth = await this.convertValue(excelElement, convert, value, isExport);
          model.productLogistic.depthNumber = depth ? parseFloat(depth.replace(',', '.')) : null;
          break;

        case this.fieldNames.metaDescription:
          model.metaDescription = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.metaTitel:
          model.metaTitel = await this.convertValue(excelElement, convert, value, isExport);
          break;

        case this.fieldNames.isBiocidalProduct:
          model.legalCharacteristic.isBiocidalProduct = this.getNullableBool(value);
          break;
        case this.fieldNames.isSelfServiceForbidden:
          model.legalCharacteristic.isSelfServiceForbidden = this.getNullableBool(value);
          break;
        case this.fieldNames.isFertigpackv:
          model.legalCharacteristic.isFertigpackv = this.getNullableBool(value);
          break;
        case this.fieldNames.isOekodesignEvpg:
          model.legalCharacteristic.isOekodesignEvpgEnvkg = this.getNullableBool(value);
          break;
        case this.fieldNames.isCommoditiesControll:
          model.legalCharacteristic.isCommoditiesControll = this.getNullableBool(value);
          break;
        case this.fieldNames.isDetergenzienv:
          model.legalCharacteristic.isDetergenzienv = this.getNullableBool(value);
          break;
        case this.fieldNames.isKosmetikv:
          model.legalCharacteristic.isKosmetikv = this.getNullableBool(value);
          break;
        case this.fieldNames.isCeGs:
          model.legalCharacteristic.isCeGs = this.getNullableBool(value);
          break;
        case this.fieldNames.isWeeeRohsEar:
          model.legalCharacteristic.isWeeeRohsEar = this.getNullableBool(value);
          break;
        case this.fieldNames.isReach:
          model.legalCharacteristic.isReach = this.getNullableBool(value);
          break;
        case this.fieldNames.isVerpackungsv:
          model.legalCharacteristic.isVerpackungsv = this.getNullableBool(value);
          break;
        case this.fieldNames.isSecurityDatasheetNeeded:
          model.legalCharacteristic.isSecurityDatasheetNeeded = this.getNullableBool(value);
          break;
        case this.fieldNames.isDualUse:
          model.legalCharacteristic.isDualUse = this.getNullableBool(value);
          break;
        case this.fieldNames.isBatterieV:
          model.legalCharacteristic.isBatterieV = this.getNullableBool(value);
          break;

        case this.fieldNames.group:
          model.groupString = this.txtGroupString.split('|').join(split);
          break;
        case this.fieldNames.master:
          model.master = value;
          break;

        case this.fieldNames.hazmatClass:
          {
            const hazmatClasses = this.hazmatClassService.hazmatClasses ?? [];
            const hazmatClass = hazmatClasses.filter((h) => h.number == value).shift();
            if (hazmatClass != undefined) {
              model.productLogistic.hazardousMaterialClassId = hazmatClass.id;
            }
          }
          break;
        case this.fieldNames.warrantyClass:
          {
            const warrantyClasses = this.warrantyClassService.warrantyClasses ?? [];
            const warrantyClass = warrantyClasses.filter((w) => w.number == value).shift();
            if (warrantyClass != undefined) {
              model.productLogistic.warrantyClassId = warrantyClass.id;
            }
          }
          break;

        case this.fieldNames.line1:
          this.productService.createWawiIfNeeded(model, this.loginService.wawiSettings);
          model.wawi.line1 = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.line2:
          this.productService.createWawiIfNeeded(model, this.loginService.wawiSettings);
          model.wawi.line2 = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.line3:
          this.productService.createWawiIfNeeded(model, this.loginService.wawiSettings);
          model.wawi.line3 = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.line4:
          this.productService.createWawiIfNeeded(model, this.loginService.wawiSettings);
          model.wawi.line4 = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.customField:
          this.productService.createWawiIfNeeded(model, this.loginService.wawiSettings);
          var customField = model.wawi.customFields.filter((f) => f.order?.toString() == system).shift();
          var settingsField = this.loginService.wawiSettings.customFields
            .filter((f) => f.order?.toString() == system)
            .shift();
          if (settingsField && customField) {
            switch (settingsField.fieldType) {
              case 'String':
                customField.stringValue = await this.convertValue(excelElement, convert, value, isExport);
                break;
              case 'Number':
                customField.numberValue = this.getNumber(
                  await this.convertValue(excelElement, convert, value, isExport)
                );
                break;
              case 'Boolean':
                customField.boolValue = this.getNullableBool(
                  await this.convertValue(excelElement, convert, value, isExport)
                );
                break;
              default:
            }
          }
          break;

        case this.fieldNames.mimeType:
          if (check) {
            this.createMimeIfNeeded(model, system);
          }
          model.mimes.filter((mime) => mime.mimeOrder?.toString() == system).shift().mimeType = await this.convertValue(
            excelElement,
            convert,
            value,
            isExport
          );
          break;
        case this.fieldNames.mimeSource:
          if (check) {
            this.createMimeIfNeeded(model, system);
          }
          model.mimes.filter((mime) => mime.mimeOrder?.toString() == system).shift().mimeSource =
            await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.mimeDescription:
          if (check) {
            this.createMimeIfNeeded(model, system);
          }
          model.mimes.filter((mime) => mime.mimeOrder?.toString() == system).shift().mimeDescr =
            await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.mimeAlt:
          if (check) {
            this.createMimeIfNeeded(model, system);
          }
          model.mimes.filter((mime) => mime.mimeOrder?.toString() == system).shift().mimeAlt = await this.convertValue(
            excelElement,
            convert,
            value,
            isExport
          );
          break;
        case this.fieldNames.mimePurpose:
          if (check) {
            this.createMimeIfNeeded(model, system);
          }
          model.mimes.filter((mime) => mime.mimeOrder?.toString() == system).shift().mimePurpose =
            await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.mimeLinkSizeNorm:
          if (check) {
            this.createMimeIfNeeded(model, system);
          }
          model.mimes.filter((mime) => mime.mimeOrder?.toString() == system).shift().normUri = await this.convertValue(
            excelElement,
            convert,
            value,
            isExport
          );
          break;
        case this.fieldNames.mimeExcludeFromShopware6:
          if (check) {
            this.createMimeIfNeeded(model, system);
          }
          model.mimes.filter((mime) => mime.mimeOrder?.toString() == system).shift().excludeFromShopware6 =
            this.getNullableBool(value);
          break;

        case this.fieldNames.supplierId:
          if (check) {
            this.createSupplierIfNeeded(model, system);
          }
          model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift().supplierId =
            await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.supplierName:
          if (check) {
            this.createSupplierIfNeeded(model, system);
          }
          model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift().supplierName =
            await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.supplierDiscountGroupCode:
          if (check) {
            this.createSupplierIfNeeded(model, system);
          }
          model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift().discountGroupCode =
            await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.supplierPricingUnitCode:
          if (check) {
            this.createSupplierIfNeeded(model, system);
          }
          model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift().pricingUnitCode =
            await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.supplierPriceAllocationUnitCodeOriginal:
          if (check) {
            this.createSupplierIfNeeded(model, system);
          }
          model.suppliers
            .filter((supplier) => supplier.order?.toString() == system)
            .shift().originalPriceAllocationUnitCode = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.supplierPriceAllocationUnitCode:
          if (check) {
            this.createSupplierIfNeeded(model, system);
          }
          model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift().priceAllocationUnitCode =
            await this.convertValue(excelElement, convert, value, isExport);
          break;

        case this.fieldNames.mainSupplierId:
          model.mainSupplierId = value;
          break;

        case this.fieldNames.pid:
          if (check) {
            this.createSupplierIfNeeded(model, system);
          }
          model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift().pid =
            await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.supplierInternationalId:
          if (check) {
            this.createSupplierIfNeeded(model, system);
          }
          model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift().internationalId =
            this.getNumber(await this.convertValue(excelElement, convert, value, isExport));
          break;
        case this.fieldNames.supplierInternationalIdType:
          if (check) {
            this.createSupplierIfNeeded(model, system);
          }
          model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift().internationalIdType =
            await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.supplierProductDescription:
          if (check) {
            this.createSupplierIfNeeded(model, system);
          }
          model.suppliers
            .filter((supplier) => supplier.order?.toString() == system)
            .shift().supplierProductDescription = await this.convertValue(excelElement, convert, value, isExport);
          break;

        case this.fieldNames.supplierProductDescriptionShort:
          if (check) {
            this.createSupplierIfNeeded(model, system);
          }
          model.suppliers
            .filter((supplier) => supplier.order?.toString() == system)
            .shift().supplierProductDescriptionShort = await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.supplierDeliveryTime:
          if (check) {
            this.createSupplierIfNeeded(model, system);
          }
          model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift().deliveryTime =
            this.getNumber(await this.convertValue(excelElement, convert, value, isExport));
          break;
        case this.fieldNames.supplierPrice:
          if (check) {
            this.createSupplierIfNeeded(model, system);
          }
          model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift().price = this.getNumber(
            await this.convertValue(excelElement, convert, value, isExport)
          );
          break;
        case this.fieldNames.supplierValidFrom:
          if (check) {
            this.createSupplierIfNeeded(model, system);
          }
          model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift().validFrom = this.getDate(
            await this.convertValue(excelElement, convert, value, isExport)
          );
          break;
        case this.fieldNames.supplierValidUntil:
          if (check) {
            this.createSupplierIfNeeded(model, system);
          }
          model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift().validUntil = this.getDate(
            await this.convertValue(excelElement, convert, value, isExport)
          );
          break;

        case this.fieldNames.packingUnitCode:
          if (check) this.createPackingUnitIfNeeded(model, system);

          model.orderDetail.packingUnits.filter((x) => x.order?.toString() == system).shift().packingUnitCode =
            await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.packingUnitDescr:
          if (check) this.createPackingUnitIfNeeded(model, system);

          model.orderDetail.packingUnits.filter((x) => x.order?.toString() == system).shift().packingUnitDescr =
            await this.convertValue(excelElement, convert, value, isExport);
          break;
        case this.fieldNames.packingUnitQuantityMax:
          if (check) this.createPackingUnitIfNeeded(model, system);

          model.orderDetail.packingUnits.filter((x) => x.order?.toString() == system).shift().quantityMax =
            this.getNumber(await this.convertValue(excelElement, convert, value, isExport));
          break;
        case this.fieldNames.packingUnitQuantityMin:
          if (check) this.createPackingUnitIfNeeded(model, system);

          model.orderDetail.packingUnits.filter((x) => x.order?.toString() == system).shift().quantityMin =
            this.getNumber(await this.convertValue(excelElement, convert, value, isExport));
          break;
        case this.fieldNames.packingUnitAmountContentUnit:
          if (check) this.createPackingUnitIfNeeded(model, system);

          model.orderDetail.packingUnits.filter((x) => x.order?.toString() == system).shift().amountContentUnit =
            this.getNumber(await this.convertValue(excelElement, convert, value, isExport));
          break;
        case this.fieldNames.packingUnitAmountSmallerUnit:
          if (check) this.createPackingUnitIfNeeded(model, system);

          model.orderDetail.packingUnits.filter((x) => x.order?.toString() == system).shift().amountSmallerUnit =
            this.getNumber(await this.convertValue(excelElement, convert, value, isExport));
          break;

        case this.fieldNames.featureSystemName:
          if (check) {
            this.createSystemAndFeatureIfNeeded(model, system, element);
          }

          var featureSystem = this.getRelevantFeatureSystem(model, system);
          featureSystem.referenceFeatureSystemName = await this.convertValue(excelElement, convert, value, isExport);
          break;

        case this.fieldNames.featureSystemGroupId:
          if (check) {
            this.createSystemAndFeatureIfNeeded(model, system, element);
          }

          var featureSystem = this.getRelevantFeatureSystem(model, system);
          featureSystem.referenceFeatureGroupId = await this.convertValue(excelElement, convert, value, isExport);
          break;

        case this.fieldNames.featureSystemGroupId2:
          if (check) {
            this.createSystemAndFeatureIfNeeded(model, system, element);
          }
          var featureSystem = this.getRelevantFeatureSystem(model, system);
          featureSystem.referenceFeatureGroupId2 = await this.convertValue(excelElement, convert, value, isExport);
          break;

        case this.fieldNames.featureSystemGroupName:
          if (check) {
            this.createSystemAndFeatureIfNeeded(model, system, element);
          }
          var featureSystem = this.getRelevantFeatureSystem(model, system);
          featureSystem.referenceFeatureGroupName = await this.convertValue(excelElement, convert, value, isExport);
          break;

        case this.fieldNames.featureSystemOrder:
          if (check) {
            this.createSystemAndFeatureIfNeeded(model, system, element);
          }
          var forder = Number(await this.convertValue(excelElement, convert, value, isExport));
          if (forder > 0) {
            var featureSystem = this.getRelevantFeatureSystem(model, system);
            featureSystem.order = forder;
          }
          break;

        case this.fieldNames.fName:
          if (check) {
            this.createSystemAndFeatureIfNeeded(model, system, element);
          }
          var featureSystem = this.getRelevantFeatureSystem(model, system);
          var feature = this.getRelevantFeature(featureSystem, element);
          feature.name = await this.convertValue(excelElement, convert, value, isExport);
          break;

        case this.fieldNames.fOrder:
          if (check) {
            this.createSystemAndFeatureIfNeeded(model, system, element);
          }
          var featureSystem = this.getRelevantFeatureSystem(model, system);
          var feature = this.getRelevantFeature(featureSystem, element);

          var forder = Number(await this.convertValue(excelElement, convert, value, isExport));
          if (forder > 0) {
            feature.forder = forder;
          }

        case this.fieldNames.fValue:
          if (check) {
            this.createSystemAndFeatureIfNeeded(model, system, element);
          }

          // eig. ändert sich feature.featureValues nie??
          // entweder es kommt immer gleich aus der excel
          // oder immer gleich aus dem produkt

          var featureSystem = this.getRelevantFeatureSystem(model, system);
          var feature = this.getRelevantFeature(featureSystem, element);

          if (value == null || value == undefined || value == '') {
            feature.featureValues = [];
          } else {
            feature.featureValues = [];

            var items = value.split(split);

            feature.value = await this.convertValue(excelElement, convert, items[0], isExport); //items[0];

            if (items.length > 1) {
              let count = 0;
              for (let item of items) {
                if (count > 0) {
                  // zuweisung feature.value erstellt bereits das item mit order 1!!
                  let featureValue = new FeatureValue();
                  featureValue.order = count + 1;
                  featureValue.value = await this.convertValue(excelElement, convert, item, isExport); //item;
                  feature.featureValues.push(featureValue);
                }
                count++;
              }
            }
          }
          break;

        case this.fieldNames.fUnit:
          if (check) {
            this.createSystemAndFeatureIfNeeded(model, system, element);
          }
          var featureSystem = this.getRelevantFeatureSystem(model, system);
          var feature = this.getRelevantFeature(featureSystem, element);
          feature.funit = await this.convertValue(excelElement, convert, value, isExport);
          break;

        case this.fieldNames.fDescription:
          if (check) {
            this.createSystemAndFeatureIfNeeded(model, system, element);
          }
          var featureSystem = this.getRelevantFeatureSystem(model, system);
          var feature = this.getRelevantFeature(featureSystem, element);
          feature.fDescr = await this.convertValue(excelElement, convert, value, isExport);
          break;

        case this.fieldNames.isVariant:
          if (check) {
            this.createSystemAndFeatureIfNeeded(model, system, element);
          }
          var featureSystem = this.getRelevantFeatureSystem(model, system);
          var feature = this.getRelevantFeature(featureSystem, element);
          feature.isVariant = this.getNullableBool(value);
          break;

        case this.fieldNames.fValueDetails:
          if (check) {
            this.createSystemAndFeatureIfNeeded(model, system, element);
          }
          var featureSystem = this.getRelevantFeatureSystem(model, system);
          var feature = this.getRelevantFeature(featureSystem, element);
          feature.fValueDetails = await this.convertValue(excelElement, convert, value, isExport);
          break;

        case this.fieldNames.featurePrintOrder:
          if (check) {
            this.createSystemAndFeatureIfNeeded(model, system, element);
          }
          var featureSystem = this.getRelevantFeatureSystem(model, system);
          var feature = this.getRelevantFeature(featureSystem, element);
          feature.printOrder = this.getNumber(await this.convertValue(excelElement, convert, value, isExport));
          break;

        case this.fieldNames.startDate:
          if (check) {
            this.createListAndPriceIfNeeded(model, system);
          }
          var priceList = model.priceLists.filter((list) => list.priceListOrder?.toString() == system).shift();
          priceList.start = this.getDate(value);
          break;

        case this.fieldNames.endDate:
          if (check) {
            this.createListAndPriceIfNeeded(model, system);
          }
          var priceList = model.priceLists.filter((list) => list.priceListOrder?.toString() == system).shift();
          priceList.end = this.getDate(value);
          break;

        case this.fieldNames.isDailyPrice:
          if (check) {
            this.createListAndPriceIfNeeded(model, system);
          }
          var priceList = model.priceLists.filter((list) => list.priceListOrder?.toString() == system).shift();
          priceList.isDailyPrice = this.getNullableBool(value);
          break;

        case this.fieldNames.priceType:
          if (check) {
            this.createListAndPriceIfNeeded(model, system, element);
          }
          var detail = model.priceLists.flatMap((list) => list.productPriceDetails).find((price) => price.order?.toString() == element);
          detail.priceType = await this.convertValue(excelElement, convert, value, isExport);
          break;

        case this.fieldNames.priceAmount:
          if (check) {
            this.createListAndPriceIfNeeded(model, system, element);
          }
          var detail = model.priceLists.flatMap((list) => list.productPriceDetails).find((price) => price.order?.toString() == element);
          var result = await this.convertValue(excelElement, convert, value, isExport);
          detail.priceAmountNumber = result ? parseFloat(result.replace(',', '.')) : null;
          break;

        case this.fieldNames.priceFactor:
          if (check) {
            this.createListAndPriceIfNeeded(model, system, element);
          }
          var detail = model.priceLists.flatMap((list) => list.productPriceDetails).find((price) => price.order?.toString() == element);
          var result = await this.convertValue(excelElement, convert, value, isExport);
          detail.priceFactorNumber = result ? parseFloat(result.replace(',', '.')) : null;
          break;

        case this.fieldNames.priceCurrency:
          if (check) {
            this.createListAndPriceIfNeeded(model, system, element);
          }
          var detail = model.priceLists.flatMap((list) => list.productPriceDetails).find((price) => price.order?.toString() == element);
          detail.priceCurrency = await this.convertValue(excelElement, convert, value, isExport);
          break;

        case this.fieldNames.lowerBound:
          if (check) {
            this.createListAndPriceIfNeeded(model, system, element);
          }
          var detail = model.priceLists.flatMap((list) => list.productPriceDetails).find((price) => price.order?.toString() == element);
          var result = await this.convertValue(excelElement, convert, value, isExport);
          detail.lowerBoundNumber = result ? parseFloat(result.replace(',', '.')) : null;
          if (isNaN(detail.lowerBoundNumber)) detail.lowerBoundNumber = null;
          break;

        case this.fieldNames.tax:
          if (check) {
            this.createListAndPriceIfNeeded(model, system, element);
          }
          var detail = model.priceLists.flatMap((list) => list.productPriceDetails).find((price) => price.order?.toString() == element);
          var result = await this.convertValue(excelElement, convert, value, isExport);
          detail.taxRate = result ? parseFloat(result.replace(',', '.')) : null;
          if (isNaN(detail.taxRate)) detail.taxRate = null;
          break;

        case this.fieldNames.territory:
          if (check) {
            this.createListAndPriceIfNeeded(model, system, element);
          }
          var priceList = model.priceLists.filter((list) => list.priceListOrder?.toString() == system).shift();
          var price = priceList.productPriceDetails.filter((price) => price.order?.toString() == element).shift();
          price.territory = await this.convertValue(excelElement, convert, value, isExport);
          break;

        case this.fieldNames.productReferencesType:
          if (check) {
            this.createReferenceIfNeeded(model, system);
          }
          var reference = model.references.filter((reference) => reference.order?.toString() == system).shift();
          reference.type = value;
          break;

        case this.fieldNames.productReferencesSpid:
          if (check) {
            this.createReferenceIfNeeded(model, system);
          }
          var reference = model.references.filter((reference) => reference.order?.toString() == system).shift();
          reference.artIdTo = value;
          break;

        case this.fieldNames.udx:
          {
            if (check) {
              this.createUdxIfNeeded(model, system);
            }
            const index = model.udxs.findIndex((udx) => udx.order?.toString() == system);
            const udx = model.udxs[index];
            udx.category = excelElement.catName;
            udx.name = excelElement.name;
            udx.value = value;
          }
          break;

        case this.fieldNames.udxCategory:
          if (check) {
            this.createUdxIfNeeded(model, system);
          }
          var udx = model.udxs.filter((udx) => udx.order?.toString() == system).shift();
          udx.category = value;
          break;

        case this.fieldNames.udxName:
          if (check) {
            this.createUdxIfNeeded(model, system);
          }
          var udx = model.udxs.filter((udx) => udx.order?.toString() == system).shift();
          udx.name = value;

          break;

        case this.fieldNames.udxValue:
          if (check) {
            this.createUdxIfNeeded(model, system);
          }
          var udx = model.udxs.filter((udx) => udx.order?.toString() == system).shift();
          udx.value = value;
          break;

        // diese machen wir alle anders:
        //case "FEATURESYSTEMTABLE":
        //case "FEATURESYSTEMLIST":
        //case "FEATURELIST":
        //case "DESCWITHOUTHTML":
      }

      if (field.startsWith('ELECTRONIC_SALES_')) {
        // bool values
        if (this.fieldNames.electronicSalesBoolMapping.has(field)) {
          const key = this.fieldNames.electronicSalesBoolMapping.get(field);
          const val = this.getNullableBool(value);
          safeSetToModel(model.electronicSales, key, val);
        }

        // number values
        if (this.fieldNames.electronicSalesNumberMapping.has(field)) {
          const key = this.fieldNames.electronicSalesNumberMapping.get(field);
          const val = this.getNumber(value);
          safeSetToModel(model.electronicSales, key, val);
        }

        // date values
        if (field === this.fieldNames.electronicSalesDateCreated) {
          if (value !== '') {
            model.electronicSales.dateCreated = this.getDate(value);
          } else {
            model.electronicSales.dateCreated = null;
          }
        }

        // string values
        if (this.fieldNames.electronicSalesStringMapping.has(field)) {
          const key = this.fieldNames.electronicSalesStringMapping.get(field);
          const resultFn = () => this.convertValue(excelElement, convert, value, isExport);
          await safeAsyncSetToModel(model.electronicSales, key, resultFn);
        }
      }
    } catch (error) {
      console.warn(`An error happened at field "${field}"`);
      console.error(error);
    }
  }

  public async getValueFromProduct(
    model: Product,
    convert: boolean,
    isExport: boolean,
    excelElement: ExcelElement,
    field: string,
    system: string,
    element: string,
    join: string = '|'
  ): Promise<string> {
    switch (field) {
      case this.fieldNames.supplierPid:
        return model.supplierPid;
      case this.fieldNames.supplierAltPid:
        var value = model.supplierAltAid;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.descriptionShort:
        var value = model.descriptionShort;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.descriptionLong:
        var value = model.descriptionLong;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.manufacturerTypeDescription:
        var value = model.manufacturerTypeDescr;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.internatonalPid:
        return model.internationalPid;
      case this.fieldNames.internatonalPidType:
        var value = model.internationalPidType;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.remarks:
        var value = model.remarks;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.manufacturerPID:
        var value = model.manufacturerAid;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.manufacturerName:
        var value = model.manufacturerName;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.keywords:
        return model.keywords.join(join);
      case this.fieldNames.productOrder:
        return this.getStringFromNumber(model.productOrder);
      case this.fieldNames.edeNumber1:
        var value = model.edeNumber1;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.edeNumber2:
        var value = model.edeNumber2;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.edeNumber3:
        var value = model.edeNumber3;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.edeNumber4:
        var value = model.edeNumber4;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.edeNumber5:
        var value = model.edeNumber5;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.erpGroupBuyer:
        var value = model.erpGroupBuyer;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.erpGroupSupplier:
        var value = model.erpGroupSupplier;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.buyerAid:
        var value = model.buyerAid;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.isDiscontinued:
        return this.getStringFromNullableBool(model.isDiscontinued);
      case this.fieldNames.discontinuedDate:
        return this.getStringFromDate(model.discontinuedDate);
      case this.fieldNames.status:
        return model.states.join(join);

      case this.fieldNames.deliveryTime:
        var value = model.orderDetail.deliveryTime;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.orderUnit:
        var value = model.orderDetail.orderUnit;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.contentUnit:
        var value = model.orderDetail.contentUnit;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.noCuPerOu:
        var value = model.orderDetail.noCuPerOuNumber.toString();
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.priceQuantity:
        var value = model.orderDetail.priceQuantityNumber.toString();
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.quantityMin:
        var value = model.orderDetail.quantityMinNumber.toString();
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.quantityMax:
        var value = model.orderDetail.quantityMaxNumber.toString();
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.quanityInterval:
        var value = model.orderDetail.quantityIntervalNumber.toString();
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.contentQuantity:
        var value = model.orderDetail.contentQuantityNumber.toString();
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.contentPackage:
        var value = model.orderDetail.contentPackage;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.basicQuantity:
        var value = model.orderDetail.basicQuantityNumber.toString();
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.hasBasicPriceDuty:
        return this.getStringFromNullableBool(model.orderDetail.hasBasicPriceDuty);
      case this.fieldNames.isBulkyGood:
        return this.getStringFromNullableBool(model.orderDetail.isBulkyGood);
      case this.fieldNames.isTruckageCompanyGood:
        return this.getStringFromNullableBool(model.orderDetail.isTruckageCompanyGood);
      case this.fieldNames.costOfLivingAdjustment:
        return this.getStringFromNumber(model.orderDetail.costOfLivingAdjustment);
      case this.fieldNames.alloySurcharge:
        return this.getStringFromNumber(model.orderDetail.alloySurcharge);
      case this.fieldNames.isDurabilityProduct:
        return this.getStringFromNullableBool(model.orderDetail.isDurabilityProduct);
      case this.fieldNames.durabilityDays:
        return this.getStringFromNumber(model.orderDetail.durabilityDays);

      case this.fieldNames.customsNumber:
        var value = model.productLogistic.customsNumber;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.countryOfOrgin:
        var value = model.productLogistic.countryOfOrigin;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.weight:
        var value = model.productLogistic.weightNumber.toString();
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.lenght:
        var value = model.productLogistic.lengthNumber.toString();
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.width:
        var value = model.productLogistic.widthNumber.toString();
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.depth:
        var value = model.productLogistic.depthNumber.toString();
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.metaDescription:
        var value = model.metaDescription;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.metaTitel:
        var value = model.metaTitel;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.isBiocidalProduct:
        return this.getStringFromNullableBool(model.legalCharacteristic.isBiocidalProduct);
      case this.fieldNames.isSelfServiceForbidden:
        return this.getStringFromNullableBool(model.legalCharacteristic.isSelfServiceForbidden);
      case this.fieldNames.isFertigpackv:
        return this.getStringFromNullableBool(model.legalCharacteristic.isFertigpackv);
      case this.fieldNames.isOekodesignEvpg:
        return this.getStringFromNullableBool(model.legalCharacteristic.isOekodesignEvpgEnvkg);
      case this.fieldNames.isCommoditiesControll:
        return this.getStringFromNullableBool(model.legalCharacteristic.isCommoditiesControll);
      case this.fieldNames.isDetergenzienv:
        return this.getStringFromNullableBool(model.legalCharacteristic.isDetergenzienv);
      case this.fieldNames.isKosmetikv:
        return this.getStringFromNullableBool(model.legalCharacteristic.isKosmetikv);
      case this.fieldNames.isCeGs:
        return this.getStringFromNullableBool(model.legalCharacteristic.isCeGs);
      case this.fieldNames.isWeeeRohsEar:
        return this.getStringFromNullableBool(model.legalCharacteristic.isWeeeRohsEar);
      case this.fieldNames.isReach:
        return this.getStringFromNullableBool(model.legalCharacteristic.isReach);
      case this.fieldNames.isVerpackungsv:
        return this.getStringFromNullableBool(model.legalCharacteristic.isVerpackungsv);
      case this.fieldNames.isSecurityDatasheetNeeded:
        return this.getStringFromNullableBool(model.legalCharacteristic.isSecurityDatasheetNeeded);
      case this.fieldNames.isDualUse:
        return this.getStringFromNullableBool(model.legalCharacteristic.isDualUse);
      case this.fieldNames.isBatterieV:
        return this.getStringFromNullableBool(model.legalCharacteristic.isBatterieV);

      case this.fieldNames.group:
        return this.txtGroupString.split('|').join(join);
      case this.fieldNames.master:
        return model.master;

      case this.fieldNames.hazmatClass: {
        const hazmatClasses = this.hazmatClassService.hazmatClasses ?? [];
        const hazmatClass = hazmatClasses.filter((h) => h.id == model.productLogistic.hazardousMaterialClassId).shift();
        if (hazmatClass == undefined) {
          return '';
        }
        return hazmatClass.number.toString();
      }
      case this.fieldNames.warrantyClass: {
        const warrantyClasses = this.warrantyClassService.warrantyClasses ?? [];
        const warrantyClass = warrantyClasses.filter((w) => w.id == model.productLogistic.warrantyClassId).shift();
        if (warrantyClass == undefined) {
          return '';
        }
        return warrantyClass.number.toString();
      }
      case this.fieldNames.line1:
        var value = '';
        if (model.wawi) {
          return model.wawi.line1;
        }
        return value;
      case this.fieldNames.line2:
        var value = '';
        if (model.wawi) {
          return model.wawi.line2;
        }
        return value;
      case this.fieldNames.line3:
        var value = '';
        if (model.wawi) {
          return model.wawi.line3;
        }
        return value;
      case this.fieldNames.line4:
        var value = '';
        if (model.wawi) {
          return model.wawi.line4;
        }
        return value;
      case this.fieldNames.customField:
        var value = '';
        if (!model.wawi) {
          return value;
        }
        var customField = model.wawi.customFields.filter((f) => f.order?.toString() == system).shift();
        var settingsField = this.loginService.wawiSettings.customFields
          .filter((f) => f.order?.toString() == system)
          .shift();
        if (settingsField && customField) {
          switch (settingsField.fieldType) {
            case 'String':
              return customField.stringValue;
            case 'Mapping':
              if (customField.listId) {
                var wawiListPromise = this.wawiListService
                  .getList(customField.listId, this.loginService.currentCustomer.id)
                  .toPromise();
                return (await wawiListPromise).listName;
              } else {
                return '';
              }
            case 'List':
              var elementValuePromise = this.wawiListService
                .getElementValue(customField.listId, this.loginService.currentCustomer.id, customField.elementId)
                .toPromise();
              return await elementValuePromise;
            case 'Number':
              return this.getStringFromNumber(customField.numberValue);
            case 'Boolean':
              return this.getStringFromNullableBool(customField.boolValue);
            default:
          }
        }

        return value;

      case this.fieldNames.mimeType:
        var mime = model.mimes.filter((mime) => mime.mimeOrder?.toString() == system).shift();
        if (mime == null) {
          return '';
        }
        var value = mime.mimeType;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.mimeSource:
        var mime = model.mimes.filter((mime) => mime.mimeOrder?.toString() == system).shift();
        if (mime == null) {
          return '';
        }
        var value = mime.mimeSource;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.mimeDescription:
        var mime = model.mimes.filter((mime) => mime.mimeOrder?.toString() == system).shift();
        if (mime == null) {
          return '';
        }
        var value = mime.mimeDescr;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.mimeAlt:
        var mime = model.mimes.filter((mime) => mime.mimeOrder?.toString() == system).shift();
        if (mime == null) {
          return '';
        }
        var value = mime.mimeAlt;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.mimePurpose:
        var mime = model.mimes.filter((mime) => mime.mimeOrder?.toString() == system).shift();
        if (mime == null) {
          return '';
        }
        var value = mime.mimePurpose;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.mimeLinkSizeNorm:
        var mime = model.mimes.filter((mime) => mime.mimeOrder?.toString() == system).shift();
        if (mime == null) {
          return '';
        }
        var value = mime.normUri;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.mimeExcludeFromShopware6:
        var mime = model.mimes.filter((mime) => mime.mimeOrder?.toString() == system).shift();
        if (mime == null) {
          return '';
        }
        var value = this.getStringFromNullableBool(mime.excludeFromShopware6);
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.mainSupplierId:
        return model.mainSupplierId;

      case this.fieldNames.supplierId:
        var supplier = model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift();
        if (supplier == null) {
          return '';
        }
        var value = supplier.supplierId;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.supplierName:
        var supplier = model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift();
        if (supplier == null) {
          return '';
        }
        var value = supplier.supplierName;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.supplierDiscountGroupCode:
        var supplier = model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift();
        if (supplier == null) {
          return '';
        }
        var value = supplier.discountGroupCode;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.supplierPricingUnitCode:
        var supplier = model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift();
        if (supplier == null) {
          return '';
        }
        var value = supplier.pricingUnitCode;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.supplierPriceAllocationUnitCode:
        var supplier = model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift();
        if (supplier == null) {
          return '';
        }
        var value = supplier.priceAllocationUnitCode;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.supplierPriceAllocationUnitCodeOriginal:
        var supplier = model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift();
        if (supplier == null) {
          return '';
        }
        var value = supplier.originalPriceAllocationUnitCode;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.pid:
        var supplier = model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift();
        if (supplier == null) {
          return '';
        }
        var value = supplier.pid;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.supplierInternationalId:
        var supplier = model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift();
        if (supplier == null) {
          return '';
        }
        var value = this.getStringFromNumber(supplier.internationalId);
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.supplierInternationalIdType:
        var supplier = model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift();
        if (supplier == null) {
          return '';
        }
        var value = supplier.internationalIdType;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.supplierProductDescription:
        var supplier = model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift();
        if (supplier == null) {
          return '';
        }
        var value = supplier.supplierProductDescription;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.supplierProductDescriptionShort:
        var supplier = model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift();
        if (supplier == null) {
          return '';
        }
        var value = supplier.supplierProductDescriptionShort;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.supplierDeliveryTime:
        var supplier = model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift();
        if (supplier == null) {
          return '';
        }
        var value = this.getStringFromNumber(supplier.deliveryTime);
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.supplierPrice:
        var supplier = model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift();
        if (supplier == null) {
          return '';
        }
        var value = this.getStringFromNumber(supplier.price);
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.supplierValidFrom:
        var supplier = model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift();
        if (supplier == null) {
          return '';
        }
        var value = this.getStringFromDate(supplier.validFrom);
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.supplierValidUntil:
        var supplier = model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift();
        if (supplier == null) {
          return '';
        }
        var value = this.getStringFromDate(supplier.validUntil);
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.supplierContentUnitCode:
        var supplier = model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift();
        if (supplier == null) {
          return '';
        }
        var value = supplier.contentUnitCode;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.supplierPackingAmount:
        var supplier = model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift();
        if (supplier == null) {
          return '';
        }
        var value = supplier.packingAmount;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.supplierOrderIntervall:
        var supplier = model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift();
        if (supplier == null) {
          return '';
        }
        var value = supplier.orderIntervall;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.supplierMinOrderAmount:
        var supplier = model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift();
        if (supplier == null) {
          return '';
        }
        var value = supplier.minOrderAmount;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.supplierDiscontinuedProduct:
        var supplier = model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift();
        if (supplier == null) {
          return '';
        }
        var value = this.getStringFromNullableBool(supplier.discontinuedProduct);
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.supplierDiscontinuedDate:
        var supplier = model.suppliers.filter((supplier) => supplier.order?.toString() == system).shift();
        if (supplier == null) {
          return '';
        }
        var value = this.getStringFromDate(supplier.discontinuedDate);
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.packingUnitCode:
        var packingUnit = model.orderDetail.packingUnits
          .filter((packingUnit) => packingUnit.order?.toString() == system)
          .shift();
        if (packingUnit == null) {
          return '';
        }
        var value = packingUnit.packingUnitCode;
        return await this.convertValue(excelElement, convert, value, isExport);
      case this.fieldNames.packingUnitDescr:
        var packingUnit = model.orderDetail.packingUnits
          .filter((packingUnit) => packingUnit.order?.toString() == system)
          .shift();
        if (packingUnit == null) {
          return '';
        }
        var value = packingUnit.packingUnitDescr;
        return await this.convertValue(excelElement, convert, value, isExport);
      case this.fieldNames.packingUnitQuantityMax:
        var packingUnit = model.orderDetail.packingUnits
          .filter((packingUnit) => packingUnit.order?.toString() == system)
          .shift();
        if (packingUnit == null) {
          return '';
        }
        var value = this.getStringFromNumber(packingUnit.quantityMax);
        return await this.convertValue(excelElement, convert, value, isExport);
      case this.fieldNames.packingUnitQuantityMin:
        var packingUnit = model.orderDetail.packingUnits
          .filter((packingUnit) => packingUnit.order?.toString() == system)
          .shift();
        if (packingUnit == null) {
          return '';
        }
        var value = this.getStringFromNumber(packingUnit.quantityMin);
        return await this.convertValue(excelElement, convert, value, isExport);
      case this.fieldNames.packingUnitAmountContentUnit:
        var packingUnit = model.orderDetail.packingUnits
          .filter((packingUnit) => packingUnit.order?.toString() == system)
          .shift();
        if (packingUnit == null) {
          return '';
        }
        var value = this.getStringFromNumber(packingUnit.amountContentUnit);
        return await this.convertValue(excelElement, convert, value, isExport);
      case this.fieldNames.packingUnitAmountSmallerUnit:
        var packingUnit = model.orderDetail.packingUnits
          .filter((packingUnit) => packingUnit.order?.toString() == system)
          .shift();
        if (packingUnit == null) {
          return '';
        }
        var value = this.getStringFromNumber(packingUnit.amountSmallerUnit);
        return await this.convertValue(excelElement, convert, value, isExport);

      case this.fieldNames.featureSystemName:
        var featureSystem = this.getRelevantFeatureSystem(model, system);
        if (!featureSystem) {
          return undefined;
        }
        var value = featureSystem.referenceFeatureSystemName;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.featureSystemGroupId:
        var featureSystem = this.getRelevantFeatureSystem(model, system);
        if (!featureSystem) {
          return '';
        }
        var value = featureSystem.referenceFeatureGroupId;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.featureSystemGroupId2:
        var featureSystem = this.getRelevantFeatureSystem(model, system);
        if (!featureSystem) {
          return '';
        }
        var value = featureSystem.referenceFeatureGroupId2;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.featureSystemGroupName:
        var featureSystem = this.getRelevantFeatureSystem(model, system);
        if (!featureSystem) {
          return '';
        }
        var value = featureSystem.referenceFeatureGroupName;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.featureSystemOrder:
        var featureSystem = this.getRelevantFeatureSystem(model, system);
        if (!featureSystem) {
          return '';
        }
        var orderValue = featureSystem.order;
        value = await this.convertValue(excelElement, convert, orderValue.toString(), isExport);
        return value;

      case this.fieldNames.fName:
        var featureSystem = this.getRelevantFeatureSystem(model, system);
        if (!featureSystem) {
          return undefined;
        }
        var feature = this.getRelevantFeature(featureSystem, element);
        if (!feature) {
          return undefined;
        }
        var value = feature.name;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;
      case this.fieldNames.fOrder:
        var featureSystem = this.getRelevantFeatureSystem(model, system);
        if (!featureSystem) {
          return '';
        }
        var feature = this.getRelevantFeature(featureSystem, element);
        if (!feature) {
          return '';
        }
        var orderValue = feature.forder;
        value = await this.convertValue(excelElement, convert, orderValue.toString(), isExport);
        return value;

      case this.fieldNames.fValue:
        var featureSystem = this.getRelevantFeatureSystem(model, system);
        if (!featureSystem) {
          return '';
        }
        var feature = this.getRelevantFeature(featureSystem, element);
        if (!feature) {
          return '';
        }

        if (feature.featureValues == null || feature.featureValues == undefined || feature.featureValues.length == 0) {
          if (feature.value != '') {
            var value = feature.value;
            value = await this.convertValue(excelElement, convert, value, isExport);
            return value;
          }
          return '';
        }

        let result = '';

        let count = 0;
        for (let item of feature.featureValues) {
          if (count == 0) {
            result = await this.convertValue(excelElement, convert, item.value, isExport);
          } else {
            result = result + join + (await this.convertValue(excelElement, convert, item.value, isExport));
          }
          count++;
        }
        return result;

      case this.fieldNames.fUnit:
        var featureSystem = this.getRelevantFeatureSystem(model, system);
        if (!featureSystem) {
          return undefined;
        }
        var feature = this.getRelevantFeature(featureSystem, element);
        if (!feature) {
          return undefined;
        }
        var value = feature.funit;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.fDescription:
        var featureSystem = this.getRelevantFeatureSystem(model, system);
        if (!featureSystem) {
          return '';
        }
        var feature = this.getRelevantFeature(featureSystem, element);
        if (!feature) {
          return '';
        }
        var value = feature.fDescr;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.isVariant:
        var featureSystem = this.getRelevantFeatureSystem(model, system);
        if (!featureSystem) {
          return '';
        }
        var feature = this.getRelevantFeature(featureSystem, element);
        if (!feature) {
          return '';
        }
        var value = this.getStringFromNullableBool(feature.isVariant);
        return value;

      case this.fieldNames.fValueDetails:
        var featureSystem = this.getRelevantFeatureSystem(model, system);
        if (!featureSystem) {
          return '';
        }
        var feature = this.getRelevantFeature(featureSystem, element);
        if (!feature) {
          return '';
        }
        var value = feature.fValueDetails;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.featurePrintOrder:
        var featureSystem = this.getRelevantFeatureSystem(model, system);
        if (!featureSystem) {
          return '';
        }
        var feature = this.getRelevantFeature(featureSystem, element);
        if (!feature) {
          return '';
        }
        var value = this.getStringFromNumber(feature.printOrder);
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.startDate:
        var priceList = model.priceLists.filter((list) => list.priceListOrder?.toString() == system).shift();
        if (priceList == null) {
          return '';
        }
        return this.getStringFromDate(priceList.start);

      case this.fieldNames.endDate:
        var priceList = model.priceLists.filter((list) => list.priceListOrder?.toString() == system).shift();
        if (priceList == null) {
          return '';
        }
        return this.getStringFromDate(priceList.end);

      case this.fieldNames.isDailyPrice:
        var priceList = model.priceLists.filter((list) => list.priceListOrder?.toString() == system).shift();
        if (priceList == null) {
          return '';
        }
        return this.getStringFromNullableBool(priceList.isDailyPrice);

      case this.fieldNames.priceType:
        var priceList = model.priceLists.filter((list) => list.priceListOrder?.toString() == system).shift();
        if (priceList == null) {
          return '';
        }
        var price = priceList.productPriceDetails.filter((price) => price.order?.toString() == element).shift();
        if (price == null) {
          return '';
        }
        var value = price.priceType;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.priceAmount:
        var priceList = model.priceLists.filter((list) => list.priceListOrder?.toString() == system).shift();
        if (priceList == null) {
          return '';
        }
        var price = priceList.productPriceDetails.filter((price) => price.order?.toString() == element).shift();
        if (price == null) {
          return '';
        }
        var amount = price.priceAmountNumber;
        value = await this.convertValue(excelElement, convert, amount.toString(), isExport);
        return value;

      case this.fieldNames.priceFactor:
        var priceList = model.priceLists.filter((list) => list.priceListOrder?.toString() == system).shift();
        if (priceList == null) {
          return '';
        }
        var price = priceList.productPriceDetails.filter((price) => price.order?.toString() == element).shift();
        if (price == null) {
          return '';
        }
        var factor = price.priceFactorNumber;
        value = await this.convertValue(excelElement, convert, factor.toString(), isExport);
        return value;

      case this.fieldNames.priceCurrency:
        var priceList = model.priceLists.filter((list) => list.priceListOrder?.toString() == system).shift();
        if (priceList == null) {
          return '';
        }
        var price = priceList.productPriceDetails.filter((price) => price.order?.toString() == element).shift();
        if (price == null) {
          return '';
        }
        var value = price.priceCurrency;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.lowerBound:
        var priceList = model.priceLists.filter((list) => list.priceListOrder?.toString() == system).shift();
        if (priceList == null) {
          return '';
        }
        var price = priceList.productPriceDetails.filter((price) => price?.order.toString() == element).shift();
        if (price == null) {
          return '';
        }
        var lowerBound = price.lowerBoundNumber;
        value = await this.convertValue(excelElement, convert, lowerBound.toString(), isExport);
        return value;

      case this.fieldNames.tax:
        var priceList = model.priceLists.filter((list) => list.priceListOrder?.toString() == system).shift();
        if (priceList == null) {
          return '';
        }
        var price = priceList.productPriceDetails.filter((price) => price?.order.toString() == element).shift();
        if (price == null) {
          return '';
        }
        var taxRate = price.taxRate;
        value = await this.convertValue(excelElement, convert, taxRate === null ? '' : taxRate.toFixed(2), isExport);
        return value;

      case this.fieldNames.territory:
        var priceList = model.priceLists.filter((list) => list.priceListOrder?.toString() == system).shift();
        if (priceList == null) {
          return '';
        }
        var price = priceList.productPriceDetails.filter((price) => price?.order.toString() == element).shift();
        if (price == null) {
          return '';
        }
        var value = price.territory;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.productReferencesType:
        var reference = model.references.filter((reference) => reference?.toString() == system).shift();
        if (reference == null) {
          return '';
        }
        return reference.type;

      case this.fieldNames.productReferencesSpid:
        var reference = model.references.filter((reference) => reference?.toString() == system).shift();
        if (reference == null) {
          return '';
        }
        return reference.artIdTo;

      case this.fieldNames.udxCategory:
        var udx = model.udxs.filter((udx) => udx.order?.toString() == system).shift();
        if (udx == null) {
          return '';
        }
        var value = udx.category;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.udxName:
        var udx = model.udxs.filter((udx) => udx.order?.toString() == system).shift();
        if (udx == null) {
          return '';
        }
        var value = udx.name;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      case this.fieldNames.udxValue:
        var udx = model.udxs.filter((udx) => udx.order?.toString() == system).shift();
        if (udx == null) {
          return '';
        }
        var value = udx.value;
        value = await this.convertValue(excelElement, convert, value, isExport);
        return value;

      // diese machen wir alle anders:
      //case "FEATURESYSTEMTABLE":
      //case "FEATURESYSTEMLIST":
      //case "FEATURELIST":
      //case "DESCWITHOUTHTML":

      //default:
      //  return "TODO...";
    }

    if (field.startsWith('ELECTRONIC_SALES_')) {
      // bool values
      if (this.fieldNames.electronicSalesBoolMapping.has(field)) {
        const key = this.fieldNames.electronicSalesBoolMapping.get(field);
        const val = safeGetFromModel(model.electronicSales, key);
        return this.getStringFromNullableBool(val);
      }

      // number values
      if (this.fieldNames.electronicSalesNumberMapping.has(field)) {
        const key = this.fieldNames.electronicSalesNumberMapping.get(field);
        const val = safeGetFromModel(model.electronicSales, key);
        return this.getStringFromNumber(val);
      }

      // date values
      if (field === this.fieldNames.electronicSalesDateCreated) {
        if (model.electronicSales.dateCreated !== null) {
          const date = new Date(model.electronicSales.dateCreated);
          return this.getStringFromDate(date);
        } else {
          return '';
        }
      }

      // string values
      if (this.fieldNames.electronicSalesStringMapping.has(field)) {
        const key = this.fieldNames.electronicSalesStringMapping.get(field);
        const resultFn = (val: string) => this.convertValue(excelElement, convert, val, isExport);
        return await safeAsyncGetFromModel(model.electronicSales, key, resultFn);
      }
    }
  }

  public async convertValue(
    excelElement: ExcelElement,
    convert: boolean,
    value: string = '',
    isExport: boolean = false
  ): Promise<string> {
    if (convert == false) return value;

    if (value == null || value == undefined || value == '') return value;

    let templateItem = this.template.templateItems.find((i) => i.pimFields == excelElement.pimFields);

    if (templateItem == null || templateItem == undefined) return value;

    if (templateItem.languageCode === '') templateItem.languageCode = null;

    return await this.GetConvertedValue(templateItem, value, isExport, this.loginService.currentCustomer.id);
  }

  public async GetConvertedValue(
    templateItem: TemplateItem,
    value: string,
    isExport: boolean,
    customerId: string
  ): Promise<string> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams()
        .append('value', value)
        .append('export', isExport.toString())
        .append('customerId', customerId)
    };

    return await this.http
      .post('api/import/GetConvertedValue', templateItem, options)
      .pipe(
        map((result: UrlResponse) => {
          return result.url;
        })
      )
      .toPromise();
  }

  isValidDate(d: Date): boolean {
    let date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    return d instanceof Date && date_regex.test(d.toString());
  }

  // aus ImportEngine abgekupfert...
  getNullableBool(value: string): boolean {
    if (value === null || value === undefined) {
      return null;
    }

    value = value.trim().toLowerCase();

    if (value == '1' || value == 'true' || value == 'yes' || value == 'ja') {
      return true;
    }

    if (value == '0' || value == 'false' || value == 'no' || value == 'geen' || value == 'nein') {
      return false;
    }

    if (value.length > 0) {
      return true;
    }

    return null;
  }

  getStringFromNullableBool(value: boolean): string {
    if (value === null || value === undefined) {
      return '';
    }

    return value.toString();
  }

  getDate(value: string): Date {
    let date = new Date(value);
    if (!isNaN(date.getTime())) {
      return date;
    }

    const dateParts1 = value.match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/);
    if (dateParts1) {
      date = new Date(parseInt(dateParts1[1]), parseInt(dateParts1[2]) - 1, parseInt(dateParts1[3]));
      if (!isNaN(date.getTime())) {
        return date;
      }
    }

    const dateParts2 = value.match(/^(\d{1,2})\.(\d{1,2})\.(\d{4})$/);
    if (dateParts2) {
      date = new Date(parseInt(dateParts2[3]), parseInt(dateParts2[2]) - 1, parseInt(dateParts2[1]));
      if (!isNaN(date.getTime())) {
        return date;
      }
    }
    date = new Date();
    return date;
  }

  getStringFromDate(value: Date): string {
    if (value === null || value === undefined) {
      let date = new Date(); // jetzt
      let result = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
        .getDate()
        .toString()
        .padStart(2, '0')}`;
      return result;
    }

    let date = new Date(value);
    let result = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
      .getDate()
      .toString()
      .padStart(2, '0')}`;

    if (result == '1-01-01') {
      let date = new Date(); // jetzt
      result = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
        .getDate()
        .toString()
        .padStart(2, '0')}`;
    }

    return result;
  }

  getNumber(value: string): number {
    let parsed = parseInt(value);
    if (isNaN(parsed)) {
      return 1;
    }
    return parsed;
  }

  getStringFromNumber(value: number): string {
    if (value === null || value === undefined) {
      return '1';
    }

    return value.toString();
  }

  createTableForSystem(system: FeatureSystem) {
    let ret = '<table>\n';

    if (ViewService.isSingleValue(system.referenceFeatureSystemName)) {
      system.features.forEach((feature) => {
        ret += '<tr>';
        ret += '<td>' + (feature.value ?? '') + '</td>';
        ret += '</tr>\n';
      });
    } else {
      system.features.forEach((feature) => {
        ret += '<tr>';
        ret +=
          '<td>' + (feature.name ?? '') + '</td><td>' + (feature.value ?? '') + ' ' + (feature.funit ?? '') + '</td>';
        ret += '</tr>\n';
      });
    }

    ret += '</table>\n';
    return ret;
  }

  createTableForAllSystems(systems: FeatureSystem[]) {
    let ret = '<table>\n';

    systems.forEach((system) => {
      let order = Number(system.order);

      if (order < 1000) {
        if (ViewService.isSingleValue(system.referenceFeatureSystemName)) {
          system.features.forEach((feature) => {
            ret += '<tr>';
            ret += "<td colspan='2'>" + (feature.value ?? '') + '</td>';
            ret += '</tr>\n';
          });
        } else {
          system.features.forEach((feature) => {
            ret += '<tr>';
            ret +=
              '<td>' +
              (feature.name ?? '') +
              '</td><td>' +
              (feature.value ?? '') +
              ' ' +
              (feature.funit ?? '') +
              '</td>';
            ret += '</tr>\n';
          });
        }
      }
    });

    ret += '</table>\n';
    return ret;
  }

  createListForSystem(system: FeatureSystem) {
    let ret = '<ul>\n';

    if (ViewService.isSingleValue(system.referenceFeatureSystemName)) {
      system.features.forEach((feature) => {
        ret += '<li>' + (feature.value ?? '') + '</li>\n';
      });
    } else {
      system.features.forEach((feature) => {
        ret += '<li>' + (feature.name ?? '') + ': ' + (feature.value ?? '') + ' ' + (feature.funit ?? '') + '</li>\n';
      });
    }

    ret += '</ul>\n';
    return ret;
  }

  createSystemAndFeatureIfNeeded(model: Product, sys: string, element: string) {
    let featureSystem: FeatureSystem;

    if (sys <= '0') return;

    if (sys.length == 24) {
      if (!model.featureSystems.some((system) => system.validFeatureSystemId == sys)) {
        featureSystem = new FeatureSystem();
        featureSystem.validFeatureSystemId = sys;
        featureSystem.order =
          (model.featureSystems?.reduce((maxOrder, fs) => Math.max(maxOrder, fs.order ?? 0), 0) ?? 0) + 1;
        model.featureSystems.push(featureSystem);
      }
      featureSystem = model.featureSystems.find((featureSystems) => featureSystems.validFeatureSystemId == sys);
    } else {
      if (!model.featureSystems.some((system) => system.order.toString() == sys)) {
        featureSystem = new FeatureSystem();
        featureSystem.order = +sys;
        model.featureSystems.push(featureSystem);
      }
      featureSystem = model.featureSystems.find((featureSystems) => featureSystems.order.toString() == sys);
    }
    if (element <= '0') return;

    if (element.length == 24) {
      if (!featureSystem.features.some((feature) => feature.validFeatureId == element)) {
        let feature = new Feature();
        feature.forder =
          (featureSystem.features?.reduce((maxOrder, fs) => Math.max(maxOrder, fs.forder ?? 0), 0) ?? 0) + 1;
        feature.validFeatureId = element;
        featureSystem.features.push(feature);
      }
    } else {
      if (!featureSystem.features.some((feature) => feature.forder.toString() == element)) {
        let feature = new Feature();
        feature.forder = +element;
        featureSystem.features.push(feature);
      }
    }
  }

  createMimeIfNeeded(model: Product, system: string) {
    let systemNb = parseInt(system);
    if (!model.mimes.some((mime) => mime.mimeOrder == systemNb)) {
      let mime = new Mime();
      mime.mimeOrder = systemNb;
      model.mimes.push(mime);
    }
  }

  createSupplierIfNeeded(model: Product, system: string) {
    var systemNb = parseInt(system);
    if (!model.suppliers.some((supplier) => supplier.order == systemNb)) {
      let supplier = new ProductSupplier();
      supplier.order = systemNb;
      model.suppliers.push(supplier);
    }
  }

  createPackingUnitIfNeeded(model: Product, system: string) {
    var systemNb = parseInt(system);
    if (!model.orderDetail.packingUnits.some((x) => x.order == systemNb)) {
      let supplier = new PackingUnit();
      supplier.order = systemNb;
      model.orderDetail.packingUnits.push(supplier);
    }
  }

  createListAndPriceIfNeeded(model: Product, system: string, element: string = null) {
    let priceList: PriceList;
    const systemNb = parseInt(system);
    if (!model.priceLists.some((priceList) => priceList.priceListOrder === systemNb)) {
      priceList = new PriceList();
      priceList.priceListOrder = systemNb;
      model.priceLists.push(priceList);
    }

    if (element !== null) {
      priceList = model.priceLists.find((priceList) => priceList.priceListOrder === systemNb);
      const elementNb = parseInt(element);
      if (!priceList.productPriceDetails.find((price) => price.order == elementNb)) {
        const price = new ProductPriceDetail();
        price.clearPrice();
        price.order = elementNb;
        priceList.productPriceDetails.push(price);
      }
    }
  }

  createUdxIfNeeded(model: Product, system: string) {
    var systemNb = parseInt(system);
    if (!model.udxs.some((udx) => udx.order == systemNb)) {
      let udx = new UdxField();
      udx.order = systemNb;
      model.udxs.push(udx);
    }
  }

  createReferenceIfNeeded(model: Product, system: string) {
    var systemNb = parseInt(system);
    if (!model.references.some((reference) => reference.order == systemNb)) {
      let reference = new Reference();
      reference.order = systemNb;
      model.references.push(reference);
    }
  }

  changeKey(
    product: Product,
    oldKey: string,
    newKey: string,
    systemKey: string,
    elementKey: string,
    field: string,
    excelModel: ExcelElement[]
  ): boolean {
    switch (field) {
      case this.fieldNames.featureSystemName:
        return this.changeNameFeatureSystem(product, oldKey, newKey, excelModel);
      case this.fieldNames.fName:
        return this.changeNameFeature(oldKey, newKey, systemKey, elementKey, excelModel);
      case this.fieldNames.featureSystemOrder:
        return this.changeOrderFeatureSystem(oldKey, newKey, systemKey, excelModel);
      case this.fieldNames.fOrder:
        return this.changeOrderFeature(oldKey, newKey, systemKey, elementKey, excelModel);
      case this.fieldNames.fUnit:
        return this.changeUnitFeature(oldKey, newKey, systemKey, elementKey, excelModel);
      case 'MIME_ORDER':
        return this.changeOrderMime(oldKey, newKey, systemKey, elementKey, field, excelModel);
      case 'PRICE_LIST_ORDER':
        return this.changeOrderPriceList(oldKey, newKey, systemKey, elementKey, field, excelModel);
      case 'PRICE_ORDER':
        return this.changeOrderPrice(oldKey, newKey, systemKey, elementKey, field, excelModel);
    }
    return false;
  }

  changeOrderMime(
    oldOrder: string,
    newOrder: string,
    system: string,
    element: string,
    field: string,
    excelModel: ExcelElement[]
  ): boolean {
    let existing = excelModel.filter(
      (x) =>
        x.pimFields != null && x.pimFields.some((y) => y.systemKey == system && this.allMimeFields.includes(y.field))
    );
    if (existing.length > 0) {
      this.systemService.notify(this.translate.instant('Order darf nicht doppelt belegt werden'), 2);
      return false;
    }

    excelModel.forEach((exelElement: ExcelElement) => {
      if (exelElement.pimFields == null) return;
      let existingField = exelElement.pimFields.find(
        (y) => y.systemKey == oldOrder && this.allMimeFields.includes(y.field)
      );
      if (existingField != undefined) {
        existingField.systemKey = newOrder.toString();
      }
    });
    return true;
  }

  changeNameFeatureSystem(product: Product, oldSystemKey: string, newSystemKey: string, excelModel: ExcelElement[]) {
    let existing = excelModel.filter(
      (x) =>
        x.pimFields != null &&
        x.pimFields.some((y) => y.systemKey == newSystemKey && this.allFeatureSystemFields.includes(y.field))
    );
    if (existing.length > 0) {
      this.systemService.notify(this.translate.instant('FeatureSystem-Name darf nicht doppelt belegt werden'), 2);
      return false;
    }

    let featureSystem = this.getRelevantFeatureSystem(product, newSystemKey);

    excelModel.forEach((excelElement: ExcelElement) => {
      if (excelElement.pimFields == null) return;
      let existingFeatureSystemField = excelElement.pimFields.find(
        (y) => y.systemKey == oldSystemKey && this.allFeatureSystemFields.includes(y.field)
      );
      if (existingFeatureSystemField != undefined) {
        existingFeatureSystemField.systemKey = newSystemKey.toString();
      }

      let existingFeatureField = excelElement.pimFields.find(
        (y) => y.systemKey == oldSystemKey && this.allFeatureFields.includes(y.field)
      );
      if (existingFeatureField != undefined) {
        let feature = featureSystem.features.find((x) => x.oldValidFeatureId == existingFeatureField.elementKey);
        existingFeatureField.systemKey = newSystemKey.toString();
        existingFeatureField.elementKey = feature.validFeatureId;
      }
    });
    return true;
  }

  changeOrderFeatureSystem(oldOrder: string, newOrder: string, system: string, excelModel: ExcelElement[]) {
    let existing = excelModel.filter(
      (x) =>
        x.pimFields != null &&
        x.pimFields.some((y) => y.systemKey == system && this.allFeatureSystemFields.includes(y.field))
    );
    if (existing.length > 0) {
      this.systemService.notify(this.translate.instant('Order darf nicht doppelt belegt werden'), 2);
      return false;
    }

    excelModel.forEach((exelElement: ExcelElement) => {
      if (exelElement.pimFields == null) return;
      let existingField = exelElement.pimFields.find(
        (y) =>
          y.systemKey == oldOrder &&
          (this.allFeatureSystemFields.includes(y.field) || this.allFeatureFields.includes(y.field))
      );
      if (existingField != undefined) {
        existingField.systemKey = newOrder.toString();
      }
    });
    return true;
  }

  changeNameFeature(oldOrder: string, newOrder: string, system: string, element: string, excelModel: ExcelElement[]) {
    let existing = excelModel.filter(
      (x) =>
        x.pimFields != null &&
        x.pimFields.some(
          (y) => y.systemKey == system && y.elementKey == element && this.allFeatureFields.includes(y.field)
        )
    );
    if (existing.length > 0) {
      this.systemService.notify(
        this.translate.instant('Feature-Name mit gleicher Einheit darf nicht doppelt belegt werden'),
        2
      );
      return false;
    }

    excelModel.forEach((exelElement: ExcelElement) => {
      if (exelElement.pimFields == null) return;
      let existingField = exelElement.pimFields.find(
        (y) => y.systemKey == system && y.elementKey == oldOrder && this.allFeatureFields.includes(y.field)
      );
      if (existingField != undefined) {
        existingField.elementKey = newOrder.toString();
      }
    });
    return true;
  }

  changeOrderFeature(oldOrder: string, newOrder: string, system: string, element: string, excelModel: ExcelElement[]) {
    let existing = excelModel.filter(
      (x) =>
        x.pimFields != null &&
        x.pimFields.some(
          (y) => y.systemKey == system && y.elementKey == element && this.allFeatureFields.includes(y.field)
        )
    );
    if (existing.length > 0) {
      this.systemService.notify(this.translate.instant('Order darf nicht doppelt belegt werden'), 2);
      return false;
    }

    excelModel.forEach((exelElement: ExcelElement) => {
      if (exelElement.pimFields == null) return;
      let existingField = exelElement.pimFields.find(
        (y) => y.systemKey == system && y.elementKey == oldOrder && this.allFeatureFields.includes(y.field)
      );
      if (existingField != undefined) {
        existingField.elementKey = newOrder.toString();
      }
    });
    return true;
  }

  changeUnitFeature(oldOrder: string, newOrder: string, system: string, element: string, excelModel: ExcelElement[]) {
    let existing = excelModel.filter(
      (x) =>
        x.pimFields != null &&
        x.pimFields.some(
          (y) => y.systemKey == system && y.elementKey == element && this.allFeatureFields.includes(y.field)
        )
    );
    if (existing.length > 0) {
      this.systemService.notify(
        this.translate.instant('Feature-Name mit gleicher Einheit darf nicht doppelt belegt werden'),
        2
      );
      return false;
    }

    excelModel.forEach((exelElement: ExcelElement) => {
      if (exelElement.pimFields == null) return;
      let existingField = exelElement.pimFields.find(
        (y) => y.systemKey == system && y.elementKey == oldOrder && this.allFeatureFields.includes(y.field)
      );
      if (existingField != undefined) {
        existingField.elementKey = newOrder.toString();
      }
    });
    return true;
  }

  changeOrderPriceList(
    oldOrder: string,
    newOrder: string,
    system: string,
    element: string,
    field: string,
    excelModel: ExcelElement[]
  ) {
    let existing = excelModel.filter(
      (x) =>
        x.pimFields != null &&
        x.pimFields.some((y) => y.systemKey == system && this.allPriceListFields.includes(y.field))
    );
    if (existing.length > 0) {
      this.systemService.notify(this.translate.instant('Order darf nicht doppelt belegt werden'), 2);
      return false;
    }

    excelModel.forEach((exelElement: ExcelElement) => {
      if (exelElement.pimFields == null) return;
      let existingField = exelElement.pimFields.find(
        (y) => y.systemKey == oldOrder && this.allPriceListFields.includes(y.field)
      );
      if (existingField != undefined) {
        existingField.systemKey = newOrder.toString();
      }
    });
    return true;
  }

  changeOrderPrice(
    oldOrder: string,
    newOrder: string,
    system: string,
    element: string,
    field: string,
    excelModel: ExcelElement[]
  ) {
    let existing = excelModel.filter(
      (x) =>
        x.pimFields != null &&
        x.pimFields.some(
          (y) => y.systemKey == system && y.elementKey == newOrder && this.allPriceFields.includes(y.field)
        )
    );
    if (existing.length > 0) {
      this.systemService.notify(this.translate.instant('Order darf nicht doppelt belegt werden'), 2);
      return false;
    }

    excelModel.forEach((exelElement: ExcelElement) => {
      if (exelElement.pimFields == null) return;
      let existingField = exelElement.pimFields.find(
        (y) => y.systemKey == system && y.elementKey == newOrder && this.allPriceFields.includes(y.field)
      );
      if (existingField != undefined) {
        existingField.systemKey = newOrder.toString();
      }
    });
    return true;
  }

  getRelevantFeatureSystem(model: Product, system: string) {
    var featureSystem = model.featureSystems
      .filter((featureSystem) => featureSystem.validFeatureSystemId == system)
      .shift();
    if (!featureSystem) {
      var featureSystem = model.featureSystems
        .filter((featureSystem) => featureSystem.order.toString() == system)
        .shift();
      if (!featureSystem) {
        return undefined;
      }
    }
    return featureSystem;
  }

  getRelevantFeature(featureSystem: FeatureSystem, element: string) {
    var feature = featureSystem.features.filter((feature) => feature.validFeatureId == element).shift();
    if (!feature) {
      var feature = featureSystem.features.filter((feature) => feature.forder.toString() == element).shift();
      if (!feature) {
        return undefined;
      }
    }
    return feature;
  }
}
