<div class="dx-fieldset">
  <dx-validation-group *ngIf="model" id="ORDER">
    <dx-box direction="row" width="100%" [ngStyle]="{ gap: '2rem', marginBottom: '2rem' }">
      <dxi-item [ratio]="1">
        <div class="dx-card">
          <div class="dx-fieldset">
            <div class="dx-fieldset-header">{{ 'Bestellinformationen' | translate }}</div>

            <div class="np-field">
              <np-inputControl
                [(model)]="model"
                [(productTranslation)]="translationService.productTranslation"
                [showLinkButtons]="showLinkButtons"
                [showPlaceLabel]="model?.orderDetail?.orderUnit?.length > 0"
                [(selectedElements)]="selectedElements"
                (elementSelected)="elementSelectFunction($event)"
                (onValueChanged)="update($event)"
                infoText="{{ 'In welcher Einheit wird dieser Artikel bestellt?' | translate }}"
                labelText="{{ 'Bestelleinheit' | translate }}"
                field="ORDER_UNIT"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot">
                  <np-dropdown-control
                    fieldName="ORDER_UNIT"
                    [readOnly]="readOnlyForLinkedFields('ORDER_UNIT') || readOnly('orderUnit')"
                    [templateView]="templateView"
                    validationGroup="ORDER"
                    [validationCallback]="validationService.validateField('ORDER_UNIT')"
                    [items]="unitDataSource"
                    [(model)]="model.orderDetail"
                    modelProp="orderUnit"
                    (onValueChanged)="update($event, 'ORDER_UNIT')"
                    (onCustomItemCreating)="onCustomItemCreating($event, 'orderUnit')"
                  ></np-dropdown-control>
                </div>
              </np-inputControl>
            </div>

            <div class="np-field">
              <np-inputControl
                [(model)]="model"
                [(productTranslation)]="translationService.productTranslation"
                [showLinkButtons]="showLinkButtons"
                [showPlaceLabel]="model?.orderDetail?.priceQuantityNumber !== null"
                [(selectedElements)]="selectedElements"
                (elementSelected)="elementSelectFunction($event)"
                (onValueChanged)="update($event)"
                infoText="{{
                  'Auf wieviel Bestelleinheiten bezieht sich der Preis?'
                    | translate : { orderUnit: translatedOrderUnit }
                }}"
                labelText="{{ 'Preismenge' | translate }}"
                field="PRICE_QUANTITY"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot">
                  <dx-number-box
                    [(value)]="model.orderDetail.priceQuantityNumber"
                    [readOnly]="readOnlyForLinkedFields('PRICE_QUANTITY') || readOnly('orderPriceAmount')"
                    (onValueChanged)="update($event, 'PRICE_QUANTITY')"
                  >
                    <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                      <dxi-validation-rule
                        type="async"
                        [validationCallback]="validationService.validateField('PRICE_QUANTITY')"
                      ></dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </div>
              </np-inputControl>
            </div>

            <div class="np-field">
              <np-inputControl
                [(model)]="model"
                [(productTranslation)]="translationService.productTranslation"
                [showLinkButtons]="showLinkButtons"
                [showPlaceLabel]="model?.orderDetail?.quantityMinNumber !== null"
                [(selectedElements)]="selectedElements"
                (elementSelected)="elementSelectFunction($event)"
                (onValueChanged)="update($event)"
                infoText="{{
                  'Wieviel Bestelleinheiten müssen mindestens bestellt werden?'
                    | translate : { orderUnit: translatedOrderUnit }
                }}"
                labelText="{{ 'Mindestbestellmenge' | translate }}"
                field="QUANTITY_MIN"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot">
                  <dx-number-box
                    [(value)]="model.orderDetail.quantityMinNumber"
                    [readOnly]="readOnlyForLinkedFields('QUANTITY_MIN') || readOnly('orderMinimum')"
                    (onValueChanged)="update($event, 'QUANTITY_MIN')"
                  >
                    <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                      <dxi-validation-rule
                        type="async"
                        [validationCallback]="validationService.validateField('QUANTITY_MIN')"
                      ></dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </div>
              </np-inputControl>
            </div>

            <div class="np-field">
              <np-inputControl
                [(model)]="model"
                [(productTranslation)]="translationService.productTranslation"
                [showLinkButtons]="showLinkButtons"
                [showPlaceLabel]="model?.orderDetail?.quantityMaxNumber !== null"
                [(selectedElements)]="selectedElements"
                (elementSelected)="elementSelectFunction($event)"
                (onValueChanged)="update($event)"
                infoText="{{
                  'Wieviel Bestelleinheiten können maximal bestellt werden?'
                    | translate : { orderUnit: translatedOrderUnit }
                }}"
                labelText="{{ 'Maximalbestellmenge' | translate }}"
                field="QUANTITY_MAX"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot">
                  <dx-number-box
                    [(value)]="model.orderDetail.quantityMaxNumber"
                    [readOnly]="readOnlyForLinkedFields('QUANTITY_MAX') || readOnly('orderMaximum')"
                    (onValueChanged)="update($event, 'QUANTITY_MAX')"
                  >
                    <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                      <dxi-validation-rule
                        type="async"
                        [validationCallback]="validationService.validateField('QUANTITY_MAX')"
                      ></dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </div>
              </np-inputControl>
            </div>

            <div class="np-field">
              <np-inputControl
                [(model)]="model"
                [(productTranslation)]="translationService.productTranslation"
                [showLinkButtons]="showLinkButtons"
                [showPlaceLabel]="model?.orderDetail?.quantityIntervalNumber !== null"
                [(selectedElements)]="selectedElements"
                (elementSelected)="elementSelectFunction($event)"
                (onValueChanged)="update($event)"
                infoText="{{
                  'In welchem Intervall müssen die Bestelleinheiten bestellt werden?'
                    | translate : { orderUnit: translatedOrderUnit }
                }}"
                labelText="{{ 'Bestellintervall' | translate }}"
                field="QUANTITY_INTERVAL"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot">
                  <dx-number-box
                    [(value)]="model.orderDetail.quantityIntervalNumber"
                    [readOnly]="readOnlyForLinkedFields('QUANTITY_INTERVAL') || readOnly('orderIntervall')"
                    (onValueChanged)="update($event, 'QUANTITY_INTERVAL')"
                  >
                    <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                      <dxi-validation-rule
                        type="async"
                        [validationCallback]="validationService.validateField('QUANTITY_INTERVAL')"
                      ></dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </div>
              </np-inputControl>
            </div>
          </div>
        </div>
      </dxi-item>
      <dxi-item [ratio]="1">
        <div class="dx-card">
          <div class="dx-fieldset">
            <div class="dx-fieldset-header">{{ 'Grundpreisinformationen' | translate }}</div>
            <div class="np-field">
              <np-inputControl [(model)]="model"
                               [(productTranslation)]="translationService.productTranslation"
                               [showLinkButtons]="showLinkButtons"
                               [suppressPlaceLabel]="true"
                               [(selectedElements)]="selectedElements"
                               (elementSelected)="elementSelectFunction($event)"
                               (onValueChanged)="update($event)"
                               labelText="{{ 'Ist Grundpreispflichtig' | translate }}"
                               field="HAS_BASEPRICE_DUTY"
                               [showDragHandle]="showDragHandle"
                               [dragFilter]="dragFilter"
                               (excelElementDropped)="excelElementDrop($event)">
                <div slot="content" class="contentSlot np-others">
                  <np-booleanRadioGroup [(value)]="model.orderDetail.hasBasicPriceDuty"
                                        [templateView]="templateView"
                                        [fieldName]="validationService.fieldNames.hasBasicPriceDuty"
                                        [readOnly]="readOnlyForLinkedFields('HAS_BASEPRICE_DUTY') || readOnly('orderBasePriceDuty')"
                                        (onValueChanged)="update($event, 'HAS_BASEPRICE_DUTY')"
                                        validatorValidationGroup="ORDER"></np-booleanRadioGroup>
                </div>
              </np-inputControl>
            </div>

            <div class="np-field" *ngIf="model.orderDetail.hasBasicPriceDuty">
              <np-inputControl [(model)]="model"
                               [(productTranslation)]="translationService.productTranslation"
                               [showLinkButtons]="showLinkButtons"
                               [showPlaceLabel]="model?.orderDetail?.contentPackage?.length > 0"
                               [(selectedElements)]="selectedElements"
                               (elementSelected)="elementSelectFunction($event)"
                               (onValueChanged)="update($event)"
                               infoText="{{ 'In welcher Einheit muss der Grundpreis angegeben werden?' | translate }}"
                               labelText="{{ 'Grundmengeneinheit' | translate }}"
                               field="CONTENT_PACKAGE"
                               [showDragHandle]="showDragHandle"
                               [dragFilter]="dragFilter"
                               (excelElementDropped)="excelElementDrop($event)">
                <div slot="content" class="contentSlot">
                  <np-dropdown-control fieldName="CONTENT_PACKAGE"
                                       [readOnly]="readOnlyForLinkedFields('CONTENT_PACKAGE') || readOnly('contentPackage')"
                                       [templateView]="templateView"
                                       validationGroup="ORDER"
                                       [validationCallback]="validationService.validateField('CONTENT_PACKAGE')"
                                       [items]="unitDataSource"
                                       [(model)]="model.orderDetail"
                                       modelProp="contentPackage"
                                       (onValueChanged)="update($event, 'CONTENT_PACKAGE')"
                                       (onCustomItemCreating)="onCustomItemCreating($event, 'contentPackage')"></np-dropdown-control>
                </div>
              </np-inputControl>
            </div>

            <div class="np-field" *ngIf="model.orderDetail.hasBasicPriceDuty">
              <np-inputControl [(model)]="model"
                               [(productTranslation)]="translationService.productTranslation"
                               [showLinkButtons]="showLinkButtons"
                               [showPlaceLabel]="model?.orderDetail?.basicQuantityNumber !== null"
                               [(selectedElements)]="selectedElements"
                               (selectedElements)="elementSelectFunction($event)"
                               (onValueChanged)="update($event)"
                               infoText="{{ 'In welcher Menge wollen Sie den Preis vergleichen?' | translate }}"
                               labelText="{{ 'Grundmenge' | translate }}"
                               field="BASIC_QUANTITY"
                               [showDragHandle]="showDragHandle"
                               [dragFilter]="dragFilter"
                               (excelElementDropped)="excelElementDrop($event)">
                <div slot="content" class="contentSlot">
                  <dx-number-box [(value)]="model.orderDetail.basicQuantityNumber"
                                 [showClearButton]="!readOnlyForLinkedFields('BASIC_QUANTITY')"
                                 [readOnly]="readOnlyForLinkedFields('BASIC_QUANTITY') || readOnly('orderBaseAmount')"
                                 (onValueChanged)="update($event, 'BASIC_QUANTITY')">
                    <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                      <dxi-validation-rule type="async"
                                           [validationCallback]="validationService.validateField('BASIC_QUANTITY')"></dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </div>
              </np-inputControl>
            </div>

            <div class="np-field" *ngIf="model.orderDetail.hasBasicPriceDuty">
              <np-inputControl [(model)]="model"
                               [(productTranslation)]="productTranslation"
                               [showLinkButtons]="showLinkButtons"
                               [showPlaceLabel]="model?.orderDetail?.contentQuantityNumber !== null"
                               [(selectedElements)]="selectedElements"
                               (elementSelected)="elementSelectFunction($event)"
                               (onValueChanged)="update($event)"
                               labelText="{{ 'Inhaltsmenge' | translate }}"
                               field="CONTENT_QUANTITY"
                               [showDragHandle]="showDragHandle"
                               [dragFilter]="dragFilter"
                               (excelElementDropped)="excelElementDrop($event)">
                <div slot="content" class="contentSlot">
                  <dx-number-box [(value)]="model.orderDetail.contentQuantityNumber"
                                 [showClearButton]="!readOnlyForLinkedFields('CONTENT_QUANTITY')"
                                 [readOnly]="readOnlyForLinkedFields('CONTENT_QUANTITY') || readOnly('orderContentAmount')"
                                 (onValueChanged)="update($event, 'CONTENT_QUANTITY')">
                    <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                      <dxi-validation-rule type="async"
                                           [validationCallback]="validationService.validateField('CONTENT_QUANTITY')"></dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </div>
              </np-inputControl>
            </div>

            <div *ngIf="model?.orderDetail?.basicQuantityNumber">
              <hr />
              <div class="dx-fieldset-header">{{ 'Vorschau' | translate }}</div>
              <p [ngStyle]="{ fontSize: '1.1rem' }">{{ previewBasicQuantity() }}</p>
            </div>
          </div>
        </div>
      </dxi-item>
    </dx-box>

    <dx-box direction="row" width="100%" [ngStyle]="{ gap: '2rem', marginBottom: '2rem' }">
      <dxi-item [ratio]="1">
        <div class="dx-card">
          <div class="dx-fieldset">
            <div class="dx-fieldset-header">{{ 'Verpackungsinformationen' | translate }}</div>
            <div class="np-field">
              <np-inputControl
                [(model)]="model"
                [(productTranslation)]="translationService.productTranslation"
                [showLinkButtons]="showLinkButtons"
                [showPlaceLabel]="model?.orderDetail?.contentUnit?.length > 0"
                [(selectedElements)]="selectedElements"
                (elementSelected)="elementSelectFunction($event)"
                (onValueChanged)="update($event)"
                infoText="{{ 'Aus welcher Einheit besteht der Inhalt?' | translate }}"
                labelText="{{ 'Inhaltseinheit' | translate }}"
                field="CONTENT_UNIT"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot">
                  <np-dropdown-control
                    fieldName="CONTENT_UNIT"
                    [readOnly]="readOnlyForLinkedFields('CONTENT_UNIT') || readOnly('contentUnit')"
                    [templateView]="templateView"
                    validationGroup="ORDER"
                    [validationCallback]="validationService.validateField('CONTENT_UNIT')"
                    [items]="unitDataSource"
                    [(model)]="model.orderDetail"
                    modelProp="contentUnit"
                    (onValueChanged)="update($event, 'CONTENT_UNIT')"
                    (onCustomItemCreating)="onCustomItemCreating($event, 'contentUnit')"
                  ></np-dropdown-control>
                </div>
              </np-inputControl>
            </div>
    
            <div class="np-field">
              <np-inputControl
                [(model)]="model"
                [(productTranslation)]="translationService.productTranslation"
                [showLinkButtons]="showLinkButtons"
                [showPlaceLabel]="model?.orderDetail?.noCuPerOuNumber !== null"
                [(selectedElements)]="selectedElements"
                (elementSelected)="elementSelectFunction($event)"
                (onValueChanged)="update($event)"
                infoText="{{
                  'Wieviele Inhaltseinheit befinden sich in einer/einem Bestelleinheit?'
                    | translate : { contentUnit: translatedContentUnit, orderUnit: translatedOrderUnit }
                }}"
                labelText="{{ 'Verpackungsmenge' | translate }}"
                field="NO_CU_PER_OU"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot">
                  <dx-number-box
                    [(value)]="model.orderDetail.noCuPerOuNumber"
                    [readOnly]="readOnlyForLinkedFields('NO_CU_PER_OU') || readOnly('orderPackageAmount')"
                    (onValueChanged)="update($event, 'NO_CU_PER_OU')"
                  >
                    <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                      <dxi-validation-rule
                        type="async"
                        [validationCallback]="validationService.validateField('NO_CU_PER_OU')"
                      ></dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </div>
              </np-inputControl>
            </div>
          </div>
        </div>
      </dxi-item>

      <dxi-item [ratio]="1">
        <div *ngIf="loginService.hasAddonSurcharge" class="dx-card">
          <div class="dx-fieldset">
            <div class="dx-fieldset-header">{{ 'Zusatzkosten' | translate }}</div>
            <div class="np-field">
              <np-inputControl
                [(model)]="model"
                [(productTranslation)]="translationService.productTranslation"
                [showLinkButtons]="showLinkButtons"
                [showPlaceLabel]="model?.orderDetail?.alloySurcharge != null"
                [(selectedElements)]="selectedElements"
                (elementSelected)="elementSelectFunction($event)"
                (onValueChanged)="update($event)"
                labelText="{{ 'Legierungszuschlag (%)' | translate }}"
                field="ALLOY_SURCHARGE"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot">
                  <dx-number-box
                    [min]="0"
                    [max]="100"
                    class="validationBlue"
                    [showClearButton]="!readOnlyForLinkedFields('ALLOY_SURCHARGE')"
                    [(value)]="model.orderDetail.alloySurcharge"
                    [readOnly]="readOnlyForLinkedFields('ALLOY_SURCHARGE') || readOnly('orderLegierungszuschlag')"
                    (onValueChanged)="update($event, 'ALLOY_SURCHARGE')"
                  >
                    <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                      <dxi-validation-rule
                        type="async"
                        [validationCallback]="validationService.validateField('ALLOY_SURCHARGE')"
                      ></dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </div>
              </np-inputControl>
            </div>
            <div class="np-field">
              <np-inputControl
                [(model)]="model"
                [(productTranslation)]="translationService.productTranslation"
                [showLinkButtons]="showLinkButtons"
                [showPlaceLabel]="model?.orderDetail?.costOfLivingAdjustment != null"
                [(selectedElements)]="selectedElements"
                (elementSelected)="elementSelectFunction($event)"
                (onValueChanged)="update($event)"
                labelText="{{ 'Teuerungszuschlag (%)' | translate }}"
                field="COST_OF_LIVING_ADJUSTMENT"
                [showDragHandle]="showDragHandle"
                [dragFilter]="dragFilter"
                (excelElementDropped)="excelElementDrop($event)"
              >
                <div slot="content" class="contentSlot">
                  <dx-number-box
                    [min]="0"
                    [max]="100"
                    [showClearButton]="!readOnlyForLinkedFields('COST_OF_LIVING_ADJUSTMENT')"
                    [readOnly]="readOnlyForLinkedFields('COST_OF_LIVING_ADJUSTMENT') || readOnly('orderTeuerungszuschlag')"
                    [(value)]="model.orderDetail.costOfLivingAdjustment"
                    (onValueChanged)="update($event, 'COST_OF_LIVING_ADJUSTMENT')"
                    class="validationBlue"
                  >
                    <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                      <dxi-validation-rule
                        type="async"
                        [validationCallback]="validationService.validateField('COST_OF_LIVING_ADJUSTMENT')"
                      ></dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </div>
              </np-inputControl>
            </div>
          </div>
        </div>
      </dxi-item>
    </dx-box>
  </dx-validation-group>
</div>
