
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { UpdateTemplate } from '../Model/Catalog/UpdateTemplate';
import { LoginService } from './login.service';
import { StringResponse } from "../Model/Dto/StringResponse";

@Injectable()
export class UpdateTemplateService {
  updateTemplates: UpdateTemplate[];
  isClassificationVisible: boolean = false;

  constructor(private http: HttpClient, public loginService: LoginService) {

  }

  GetFieldGroups(): string[] {
    let result = ["Basic", "Logistic", "Features", "Price", "OrderDetails", "Mimes", "Misc","References", "PackingUnits"];

    if (this.loginService.hasAddonUDX) {
      result.push("Udx");
    }
    if (this.loginService.hasAddonLEGALITY) {
      result.push("Legal");
    }
    if (this.loginService.hasAddonWawiErp) {
      result.push("Wawi/ERP");
    }
    if (this.loginService.hasAddonSUPPLIERS) {
      result.push("Suppliers");
    }
    if (this.loginService.hasAddonElectronicSales) {
      result.push("Electronic Sales");
    }
    return result;
  }

  getUpdateTemplate(id: string): Observable<UpdateTemplate> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("UpdateTemplateId", id).append("customerId", this.loginService.currentUser.customerId)

    }
    return (this.http.get<UpdateTemplate>("api/UpdateTemplate/GetUpdateTemplate", options)) as any;
  }

  getUpdateTemplates(): Observable<UpdateTemplate[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", this.loginService.currentUser.customerId)
    }
    return (this.http.get<UpdateTemplate[]>("api/UpdateTemplate/GetUpdateTemplates", options)) as any;
  }

  deleteUpdateTemplate(element: UpdateTemplate) {
    {
      const options = {
        headers: new HttpHeaders().append("Content-Type", "application/json"),
        params: new HttpParams().append("id", element.id)
      }

      this.http.delete("api/UpdateTemplate/DeleteUpdateTemplate", options)
        .subscribe(
          result => { },
          err => console.error(err)
        );
    }
  }

  updateUpdateTemplate(updateTemplate: UpdateTemplate): Observable<StringResponse> {
    return (this.http.post("api/UpdateTemplate/UpdateUpdateTemplate", updateTemplate)) as any;
  }

  addUpdateTemplate(selectedClass: UpdateTemplate): Observable<UpdateTemplate> {
    return (this.http.post("api/UpdateTemplate/AddUpdateTemplate", selectedClass)) as any;
  }
}
