import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { FtpServer } from '../../../Model/FtpServer';
import { CatalogService } from '../../../Services/CatalogManagement/catalog.service';
import { FtpServerService } from '../../../Services/ftp/ftpServer.service';
import { UserManagementService } from '../../../Services/userManagment.service';
import { ElectronicSalesConnection } from "../../../Model/Catalog/ElectronicSalesConnection";

@Component({
  selector: "electronicSalesConnection",
  templateUrl: "electronicSalesConnection.component.html",
  styleUrls: []
})
export class ElectronicSalesConnectionComponent implements OnInit {
  ftpServers: FtpServer[] = [];
  eclasses: { name: string; version: string; }[];

  constructor(private catalogService: CatalogService, private userManagement: UserManagementService, private ftpServerService: FtpServerService) { }

  async ngOnInit() {
    this.eclasses = this.catalogService.catalog.classificationSystems
      .filter(x => x.name.toUpperCase().includes('ECLASS') || x.name.toUpperCase().includes('ECL@SS'))
      .map(x => {
        const version = x.name.match(/\d+(\.\d+)?/)[0];
        return { name: `ECLASS ${version}`, version };
      });

    this.ftpServers = await lastValueFrom(this.ftpServerService.getAllServer());
  }

  get model(): ElectronicSalesConnection {
    const catalog = this.catalogService.catalog;
    if (!catalog.electronicSalesConnection) {
      catalog.electronicSalesConnection = new ElectronicSalesConnection();
      catalog.electronicSalesConnection.eclassVersion = "";
      catalog.electronicSalesConnection.domain = "";
      catalog.electronicSalesConnection.updateMode = "REPLACE";
      catalog.electronicSalesConnection.productFilePath = "/home/catalog/product/electronicsales";
      catalog.electronicSalesConnection.categoryFilePath = "/home/catalog/category/electronicsales";
      catalog.electronicSalesConnection.featureFilePath = "/home/catalog/attribute";
    }

    if (!catalog.electronicSalesConnection.featureFilePath || catalog.electronicSalesConnection.featureFilePath == "") {
      catalog.electronicSalesConnection.featureFilePath = "/home/catalog/attribute";
    }
    return catalog.electronicSalesConnection;
  }

  valueChanged(e) {
    this.catalogService.catalogChanged(e);
  }
  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}
