import { Component, OnInit } from "@angular/core";
import { Catalog } from "../../Model/Catalog/Catalog";
import { TreeItem } from "../../Model/Dto/TreeItem";
import { ModelService } from "../../Services/model.service";
import { TranslateService } from '@ngx-translate/core';
import { HazmatClass } from '../../Model/Catalog/HazmatClass';
import { WarrantyClass } from '../../Model/Catalog/WarrantyClass';
import { ViewService } from '../../Services/view.service';
import { Message } from '../../Model/System/Message';
import { UserManagementService } from "../../Services/userManagment.service";
import { Output, EventEmitter } from '@angular/core';
import { Product } from "../../Model/Catalog/Product";
import { MassDataChangeService } from "../../Services/CatalogManagement/massDataChange.service";
import { CatalogService } from "../../Services/CatalogManagement/catalog.service";
import { ValidationRequestCatalog } from "../../Model/validation/ValidationRequestCatalog";
import ValidationEngine from "devextreme/ui/validation_engine";
import { ValidationService } from "../../Services/Validation/validation.service";
import { CatalogState } from "../../Model/Catalog/CatalogState";

@Component({
  selector: "catalogDetail",
  templateUrl: "catalogDetail.component.html",
  styleUrls: ["./catalogDetail.component.css", "./../shared/detail.css"]
})
export class CatalogDetailComponent implements OnInit {
  @Output() onMassDataEdit = new EventEmitter<Product>();
  validateCatalog = ValidationEngine.validateGroup;
  agreementDummy: string[] = [];
  emptyArray: string[] = []; // ==> https://supportcenter.devexpress.com/ticket/details/t1110923#f7c58b18-fd34-492b-b7ec-c354d1b53796
  catalogStates: string[] = [];

  constructor(public modelService: ModelService, public translate: TranslateService, public userManagment: UserManagementService,
    public massDataService: MassDataChangeService, public catalogService: CatalogService, public validationService: ValidationService) {
    this.showImportMedia = this.showImportMedia.bind(this);
    this.showImportCatalog = this.showImportCatalog.bind(this);
    this.showExport = this.showExport.bind(this);
    this.showTranslate = this.showTranslate.bind(this);
    this.showCategoryExport = this.showCategoryExport.bind(this);
    this.showCategoryImport = this.showCategoryImport.bind(this);
    this.showUserActions = this.showUserActions.bind(this);
    this.exportWindow = this.exportWindow.bind(this);
    this.exportFullScreen = this.exportFullScreen.bind(this);
    this.openMassDataChange = this.openMassDataChange.bind(this);
    this.validateCatalogId = this.validateCatalogId.bind(this);
    this.updateState = this.updateState.bind(this);
    this.getStatus();
  }

  ngOnInit(): void {
    this.translateTexts();
    this.reset();

    this.agreementDummy.push("dummy");
  }

  getStatus() {
    this.modelService.catalogStateService.getStatus().subscribe(
      (result: CatalogState[]) => {
        this.catalogStates = [];
        result.forEach(x => {
          this.catalogStates.push(x.name);
        });
      });
  }

  updateState(event) {
    for (const state of event.value) {
      if (this.catalogStates.every(item => item !== state)) {
        let status = new CatalogState();
        status.customerId = this.modelService.loginService.currentUser.customerId;
        status.name = state;
        this.catalogStates.push(state);
        this.modelService.catalogStateService.addCatalogState(status).subscribe(
          (result: CatalogState) => {
            status = result;
            this.modelService.catalogStateService.catalogState.push(status.name);
          });
      }
    }
    this.modelService.catalogService.updateCatalog(event);
  }

  showUserActionWindow() {
    this.modelService.catalogService.showUserAction = true;
  }


  showUserActions() {
    this.showUserAction = true;
  }


  validateCatalogId(params) {
    let request = new ValidationRequestCatalog();
    request.catalogId = this.model.id;
    request.value = this.model.catalogId;
    request.customerId = this.model.customerId;
    request.field = "CATALOG_ID";
    request.isActivId = this.model.isActivId;

    this.validationService.validateCatalogId(params, request);

  }



  set showUserAction(value) {
    this.modelService.catalogService.showUserAction = value;
  }

  reload() {
    this.modelService.catalogService.treeRefresh();
    this.modelService.catalogService.getCatalog(this.modelService.catalogService.catalog.id, this.modelService.loginService.currentUser.customerId).subscribe();
  }

  validateCatalogIdInit() {

  }

  updateCatalogGroup() {
    this.modelService.catalogService.setCatalogGroupe();
    this.modelService.catalogService.updateCatalog();
  }

  get showUserAction() {
    return this.modelService.catalogService.showUserAction;
  }

  get hasElectronicSalesAndEClass() {
    return this.modelService.loginService.hasAddonElectronicSales && this.modelService.loginService.hasAddonECLASS;
  }

  txtKatalog: string;
  txtMedien: string;
  txtLieferant: string;
  txtKaeufer: string;
  txtDatenqualitaet: string;
  txtSettings: string;
  txtAlleMedien: string;

  private translateTexts() {
    this.txtKatalog = this.translate.instant("Katalog");
    this.txtMedien = this.translate.instant("Medien");
    this.txtLieferant = this.translate.instant("Lieferant");
    this.txtKaeufer = this.translate.instant("Käufer");
    this.txtDatenqualitaet = this.translate.instant("Datenqualität");
    this.txtSettings = this.translate.instant("Einstellungen");
    this.txtAlleMedien = this.translate.instant("Alle Medien");
  }

  reset() {
    this.pages = [
      { id: 1, title: this.txtKatalog, template: "katalogTemplate" },
      { id: 2, title: this.txtMedien, template: "medienTemplate" }
    ];
    if (this.modelService.loginService.hasAddonCATALOG_DETAILS) {
      this.pages.push({ id: 3, title: this.txtLieferant, template: "lieferantTemplate" });
      this.pages.push({ id: 4, title: this.txtKaeufer, template: "kaeuferTemplate" });
    }
    if (this.modelService.loginService.hasAddonDATA_QUALITY) {
      this.pages.push({ id: 6, title: this.txtDatenqualitaet, template: "dataQualityTemplate" });
    }
    if (this.canView('catalogSettings'))
      this.pages.push({ id: 7, title: this.txtSettings, template: "settingsTemplate" });
    this.pages.push({ id: 8, title: this.txtAlleMedien, template: "poolTemplate" });

    if (this.modelService.loginService.hasAddonCLASSIFICATION) {
      this.modelService.classificationService.getSystems();
    }
    if (this.modelService.loginService.hasAddonGevis) {
      this.pages.push({ id: 9, title: "Gevis", template: "gevisTemplate" });
    }
    if (this.modelService.loginService.hasAddonODOO) {
      this.pages.push({ id: 10, title: "Odoo", template: "odooTemplate" });
    }

    if (this.modelService.loginService.hasAddonLEGALITY) {
      this.modelService.hazmatClassService.getClasses().subscribe(
        (result: HazmatClass[]) => {
          this.modelService.hazmatClassService.hazmatClasses = result;
        });

      this.modelService.warrantyClassService.getClasses().subscribe(
        (result: WarrantyClass[]) => {
          this.modelService.warrantyClassService.warrantyClasses = result;
        });
    }

  }

  tree: TreeItem[];
  childs: TreeItem[];
  pages: { id: number; title: string; template: string; }[] = [];

  createChildren = async (parent) => {
    const parentId = parent ? parent.itemData.id : "";
    this.childs = await this.modelService.catalogService.getChilds(this.modelService.catalogService.selectedCatalogId, parentId, this.modelService.loginService.currentUser.customerId, null, this.modelService.catalogService.selectedStates, null);
    return this.childs;
  };

  catalogChanged(e) {
    this.modelService.catalogService.catalogChanged(e);
  }

  catalogUpdate(e = null) {
    ValidationEngine.validateGroup("catalogValidationGroup");
    this.modelService.catalogService.updateCatalog(e);
  }

  catalogIdUpdate(e = null) {
    ValidationEngine.validateGroup("catalogIdValidationGroup");
    this.modelService.catalogService.updateCatalog(e);
  }

  get model(): Catalog {
    return this.modelService.catalogService.catalog;
  }

  get catalogMainImage(): string {
    if (this.modelService.catalogService.catalog != null && this.modelService.catalogService.catalog.mimes.length > 0) {
      const mime = this.modelService.catalogService.catalog.mimes[0];
      if (mime.normUri) {
        return mime.normUri;
      }
    }
    return null;
  }

  showImportCatalog() {
    this.importCatalogVisible = true;
  }
  
  hideImportCatalog() {
    this.importCatalogVisible = false;
  }

  showImportMedia() {
    this.importMediaVisible = true;
  }

  hideImportMedia() {
    this.importMediaVisible = false;
  }

  //EXPORT WINDOW
  get fullScreen() {
    return this.modelService.catalogService.viewService.fullScreen;
  }

  set fullScreen(value: boolean) {
    this.modelService.catalogService.viewService.fullScreen = value;
  }

  exportFullScreen() {
    this.modelService.catalogService.viewService.fullScreen = true;
  }

  exportWindow() {
    this.modelService.catalogService.viewService.fullScreen = false;
  }

  showExport() {
    this.modelService.catalogService.exportVisible = true;
  }

  showTranslate() {
    if (this.modelService.loginService.languageFlags.length == 1) {
      this.translate.get("Es wurden noch keine Sprachen angelegt, in die eine Übersetzung vorgenommen werden kann.").subscribe((text: string) => {
        this.modelService.systemService.notify(new Message(text), 2500);
      });
    } else {
      this.modelService.catalogService.translateVisible = true;
    }
  }

  get exportVisible() {
    return this.modelService.catalogService.exportVisible;
  }

  set exportVisible(value: boolean) {
    this.modelService.catalogService.exportVisible = value;
  }

  hideExport() {
    this.modelService.catalogService.exportVisible = false;
  }

  private _importMediaVisible: boolean = false;

  get importMediaVisible(): boolean {
    return this._importMediaVisible;
  }

  set importMediaVisible(value: boolean) {
    this._importMediaVisible = value;
  }

  private _importCatalogVisible: boolean = false;

  get importCatalogVisible(): boolean {
    return this._importCatalogVisible;
  }

  set importCatalogVisible(value: boolean) {
    this._importCatalogVisible = value;
  }

  //USABILITY

  get currencies() {
    return ViewService.dropDownCurrencies;
  }

  get idType() {
    return ViewService.idType;
  }

  get territories() {
    return ViewService.dropDownTerritories;
  }

  v: boolean[] = [false, false, false, false, false, false, false, false, false, false, false, false, false, false];

  ttt(id) {
    if (this.modelService.loginService.showToolTips) {
      if (!this.v[id]) {
        this.v = [false, false, false, false, false, false, false, false, false, false, false, false, false, false];
      }
      this.v[id] = !this.v[id];
    }
  }

  get showToolTips(): boolean {
    return this.modelService.loginService.showToolTips;
  }

  get showProToolTips(): boolean {
    return this.modelService.loginService.showProToolTips;
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  aggrementVisible: boolean = false;

  getLanguageText(language): string {
    return this.modelService.loginService.getLanguageText(language);
  }

  toggleAggrement() {
    if (this.aggrementVisible) {
      this.aggrementVisible = false;
    } else {
      this.aggrementVisible = true;
    }
  }

  get bigHeader() {
    if (window) {
      return (window.innerHeight > 1000);
    }
    return true;
  }



  showCategoryExport() {
    this.modelService.categoryService.categoryExportVisible = true;
  }

  hideCategoryExport() {
    this.modelService.categoryService.categoryExportVisible = false;
  }

  get categoryExportVisible() {
    return this.modelService.categoryService.categoryExportVisible;
  }

  set categoryExportVisible(value: boolean) {
    this.modelService.categoryService.categoryExportVisible = value;
  }

  exportCategoryFullScreen() {
    this.modelService.categoryService.viewService.fullScreen = true;
  }

  exportCategoryWindow() {
    this.modelService.categoryService.viewService.fullScreen = false;
  }



  showCategoryImport() {
    this.modelService.categoryService.categoryImportVisible = true;
  }

  hideCategoryImport() {
    this.modelService.categoryService.categoryImportVisible = false;
  }

  get categoryImportVisible(): boolean {
    return this.modelService.categoryService.categoryImportVisible;
  }

  set categoryImportVisible(value: boolean) {
    this.modelService.categoryService.categoryImportVisible = value;
  }

  canView(name: string): boolean {
    return this.userManagment.canView(name);
  }
  readOnly(name: string): boolean {
    return !this.userManagment.canEdit(name);
  }

  renderMakrosButton(): boolean {
    return !this.model?.isVirtual && this.modelService.loginService.hasAddonACTIONS && this.canView('Makros');
  }

  renderMediaImportButton(): boolean {
    return !this.model?.isVirtual && this.canView('mediaImport');
  }

  renderReimportButton(): boolean {
    return !this.model?.isVirtual && this.canView('reimport');
  }

  renderExportButton(): boolean {
    return this.canView('export');
  }

  renderTranslateButton(): boolean {
    return !this.model?.isVirtual && this.modelService.loginService.hasAddonTranslate && this.canView('translate');
  }

  renderCategoryExportButton(): boolean {
    return this.modelService.loginService.hasAddonCATEGORYEXPORT && this.canView('categoryExport');
  }

  renderCategoryImportButton(): boolean {
    return !this.model?.isVirtual && this.modelService.loginService.hasAddonCATEGORYEXPORT && this.canView('categoryImport');
  }

  renderMassDataChangeButton(): boolean {
    return !this.model?.isVirtual && this.canView('massDataChange');
  }

  openMassDataChange() {
    this.massDataService.getEmtpyProductByCatalog(this.catalogService.catalog.id).subscribe((result: Product) => {
      this.onMassDataEdit.emit(this.modelService.productService.getProductFromJson(result, this.modelService.loginService.wawiSettings));
    });
  }
}
