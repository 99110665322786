import { Input, Output, EventEmitter, Component, OnInit, ViewChild } from '@angular/core';
import { Product } from '../../Model/Catalog/Product';
import { ViewService } from '../../Services/view.service';
import { Reference } from '../../Model/Catalog/Reference';
import { DxDataGridComponent } from 'devextreme-angular';
import { TranslateService } from '@ngx-translate/core';
import { custom } from 'devextreme/ui/dialog';
import { ModelService } from '../../Services/model.service';
import { Message } from '../../Model/System/Message';
import { TemplateService } from '../../Services/template.service';
import { UserManagementService } from '../../Services/userManagment.service';
import { CatalogService } from '../../Services/CatalogManagement/catalog.service';
import { ValidationService } from '../../Services/Validation/validation.service';

//Todo: Deatails show -- geht nicht

@Component({
  selector: 'references',
  templateUrl: 'references.html',
  styleUrls: ['./references.css']
})
export class References implements OnInit {
  @ViewChild(DxDataGridComponent, { static: true })
  dataGrid: DxDataGridComponent;
  @Input() model: Product = new Product();
  @Input() showToolTips: Boolean = false;
  @Input() showProToolTips: Boolean = false;
  @Input() showPlaceHolders: Boolean = true;
  @Input() showLinkButtons: Boolean = false;
  @Input() lastImageUpload: Date = new Date();

  @Input() templateView: Boolean = false;

  @Input() selectedElements: string[] = [''];
  @Output() elementSelected = new EventEmitter<string>();

  @Input() showDragHandle: boolean = false;
  @Input() dragFilter: string = 'noDrag';

  @Output() excelElementDropped = new EventEmitter<any>();
  @Output() elementDeleted = new EventEmitter<any>();

  @Output() onValueChanged = new EventEmitter<any>();
  @Output() onReferencePrepare = new EventEmitter<any>();

  constructor(
    public modelService: ModelService,
    public templateService: TemplateService,
    public translate: TranslateService,
    public validationService: ValidationService,
    public userManagement: UserManagementService,
    public catalogService: CatalogService
  ) {}

  onGridToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'newReferenceButtonTemplate'
    });

    e.toolbarOptions.items.unshift({
      location: 'after',
      locateInMenu: 'auto',
      template: 'helpIconTemplate'
    });

    e.toolbarOptions.items.unshift({
      location: 'after',
      locateInMenu: 'auto',
      template: 'groupReferencesSwitchTemplate'
    });
  }

  ngOnInit(): void {}

  isVirtualCatalog(): boolean {
    return this.catalogService?.catalog?.isVirtual;
  }

  customizeColumns(columns) {
    columns.forEach((column) => {
      column.showEditorAlways = true;
    });
  }

  update(event = null, field?: string, system: string = '-1', element: string = '-1') {
    // für den template editor erforderlich:
    if (event) {
      event.dField = field;
      event.dSystem = system;
      event.dElement = element;
      event.createTemplateItem = true;
    }

    this.onValueChanged.emit(event);
  }

  addReference() {
    var reference = new Reference();
    if (!this.templateView) {
      reference.type = ViewService.referenceTypes[0];
    }

    let currentCount = this.model.references.length;
    reference.order = currentCount + 1;

    this.model.references.push(reference);
    this.update();
  }

  translateMessage(msg: string) {
    return this.translate.instant(msg);
  }

  get referenceTypes() {
    return ViewService.referenceTypes;
  }

  onReferencePrepared(event) {
    this.onReferencePrepare.emit(event);
  }

  showReferenceDetails: boolean = false;

  get showReferenceDetail() {
    return this.showReferenceDetails;
  }

  set showReferenceDetail(value) {
    this.showReferenceDetails = value;
    if (value) {
      this.dataGrid.instance.columnOption('type', 'groupIndex', 0);
    } else {
      this.dataGrid.instance.clearGrouping();
    }
  }

  mimedate() {
    return '?' + this.lastImageUpload;
  }

  imageVisable: boolean = false;
  source: string = '';
  target: string = '';

  showImage(source, index) {
    this.target = '#link' + index;
    this.source = source;
    this.imageVisable = true;
  }

  hideImage() {
    this.imageVisable = false;
  }

  //USABILITY

  private emptyArray = [false, false, false];
  public v: boolean[] = this.emptyArray;

  ttt(id) {
    if (this.showToolTips) {
      if (!this.v[id]) {
        this.v = this.emptyArray;
      }
      this.v[id] = !this.v[id];
    }
  }

  delete(event = null) {
    let orderString = event.data.order.toString();

    // Prüfen, ob irgend ein Feld dieser Referenz gelinked ist
    for (let referenceField of this.templateService.allReferenceFields) {
      if (this.model != null && this.model.isFieldLinked(referenceField, orderString, '-1')) {
        this.model.toggleLinkedField(referenceField, orderString, '-1');
      }
    }

    this.onValueChanged.emit(event);

    // für den template editor erforderlich:
    event.dFields = this.templateService.allReferenceFields;
    event.dDetailFields = null;
    event.dSystem = orderString;
    event.dElement = -1;
    this.elementDeleted.emit(event);
  }

  public onRowRemoving(e) {
    let orderString = e.data.order.toString();

    if (this.model.isChild && this.hasReferenceFieldLinked(orderString)) {
      // Löschen nicht erlaubt, INFO

      let txtNotAllowed = '';
      this.translate.get('DeleteLinkedFieldNotAllowed').subscribe((text: string) => {
        txtNotAllowed = text;
      });

      this.modelService.systemService.notify(new Message(txtNotAllowed, 'info'), 3000);
      e.cancel = true;
      return;
    }

    // Löschen erlaubt, Benutzerabfrage, ggf. Abbruch
    let txtYes = '';
    let txtNo = '';
    let txtMsg = '';
    let txtTitle = '';
    let myDialog: any;

    this.translate.get('Ja').subscribe((text: string) => {
      txtYes = text;
    });
    this.translate.get('Nein').subscribe((text: string) => {
      txtNo = text;
    });
    this.translate.get('Wollen Sie die Referenz wirklich löschen').subscribe((text: string) => {
      txtMsg = text;
      if (!txtMsg.endsWith('?')) {
        // Übersetzungen sid nicht einheitlich gepflegt, werden aber auch an anderen Stellen so verwendet.
        txtMsg = txtMsg + '?';
      }
    });
    this.translate.get('Wirklich löschen?').subscribe((text: string) => {
      txtTitle = text;
    });

    myDialog = custom({
      title: txtTitle,
      messageHtml: txtMsg,
      buttons: [
        {
          text: txtYes,
          onClick: (e) => {
            return false;
          }
        },
        {
          text: txtNo,
          onClick: (e) => {
            return true;
          }
        }
      ]
    });

    // Hier wird ein Promise zurück geliefert. Dies enthält den Return Value des (erst später!!)
    // ausgeführten onClick-Handlers des gedrückten Buttons im Dialog.
    // https://supportcenter.devexpress.com/ticket/details/t978828/datagrid-how-to-cancel-the-rowremoving-event
    e.cancel = myDialog.show();
  }

  templateSelect(identifier: string, orderString: string) {
    this.elementSelected.emit(`${identifier}_${orderString}`);
  }

  public excelElementDrop(e) {
    this.excelElementDropped.emit(e);
  }

  public hasReferenceFieldLinked(system: string = '-1'): boolean {
    // Prüfen, ob irgend ein Feld dieser Referenz gelinked ist
    for (let referenceField of this.templateService.allReferenceFields) {
      if (this.model != null && this.model.isFieldLinked(referenceField, system, '-1')) {
        return true;
      }
    }
    return false;
  }

  public readOnlyForLinkedFields(field: string, system: string = '-1', element: string = '-1'): boolean {
    // Wenn dies ein vom Master vererbtes Feld ist, soll es ReadOnly sein:
    if (this.model != null && field != null) {
      var isLinked = this.model.isFieldLinked(field, system, element);
      if (isLinked && this.model.isChild != null && this.model.isChild) {
        // Für einen Master darf es nicht ReadOnly werden!!
        return true;
      }
    }

    return false;
  }

  public hasMasterOrChildOrIsTranslated(): boolean {
    if (this.model != null) {
      return this.model.hasMasterOrChild();
    }
    return false;
  }

  public isFieldLinked(field: string, system: string = '-1', element: string = '-1'): boolean {
    if (this.model != null) {
      return this.model.isFieldLinked(field, system, element);
    }
    return false;
  }

  public toggleLinkedField(field: string, system: string = '-1', element: string = '-1', event = null) {
    var changed = this.model.toggleLinkedField(field, system, element);
    if (!changed) {
      return;
    }

    var isNowLinked = this.isFieldLinked(field, system, element);
    event.target.className = this.model.getClassForLinkedField(isNowLinked);

    if (this.model.isChild && isNowLinked) {
      event.forceReload = true;
    }

    // SAVE
    this.update(event);
  }

  public getInitialClassForLinkedField(field: string, system: string = '-1', element: string = '-1'): string {
    var isNowLinked = this.isFieldLinked(field, system, element);
    return this.model.getClassForLinkedField(isNowLinked);
  }

  canView(name: string): boolean {
    return !this.isVirtualCatalog() && this.userManagement.canView(name);
  }
  readOnly(name: string): boolean {
    return this.isVirtualCatalog() || !this.userManagement.canEdit(name);
  }
}
