import 'devextreme/data/odata/store';
import { ColumnCellTemplateData } from 'devextreme/ui/data_grid';
import { ClickEvent } from 'devextreme/ui/button';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { fieldNames } from 'app/Model/FieldNames';
import { Template } from 'app/Model/Catalog/Template';
import { FieldType, TemplateItem } from 'app/Model/Catalog/TemplateItem';
import { NextPimField } from 'app/Model/Catalog/NextPimField';
import { ValidFeatureSystem } from 'app/Model/ValidFeatureSystem';
import { isFeatureField, isMimeField, isNextPimField } from 'app/Services/templateItemHelpers';
import { LanguageFlag } from 'app/Model/Dto/LanguageFlag';
import { WawiList } from 'app/Model/wawi/WawiList';

import { WarrantyClass } from 'app/Model/Catalog/WarrantyClass';
import { HazmatClass } from 'app/Model/Catalog/HazmatClass';
import { TemplateFieldsService } from 'app/Services/templateFields.service';
import { ItemClickEvent } from 'devextreme/ui/list';
import { ValueChangedEvent } from 'devextreme/ui/drop_down_box';
import { DxButtonTypes } from 'devextreme-angular/ui/button';
import { SystemService } from '../../Services/system.service';

type DropdownFieldSource = { id: string; display: string; translate?: string[] };
type AutocompleteValues = { groupId: string; value: string };

@Component({
  selector: 'np-advanced-template-datagrid',
  templateUrl: './advanced-template-datagrid.component.html'
})
export class AdvancedTemplateDatagridComponent implements OnInit {
  @Input() customerId: string;
  @Input() template: Template;
  @Output() templateChange = new EventEmitter<Template>();
  @Input() sheetIndex: number;
  @Input() wawiListMapping: WawiList[] = [new WawiList(null, '', null, null, null, null)];
  @Input() warrantyClasses: WarrantyClass[] = [];
  @Input() hazmatClasses: HazmatClass[] = [];
  @Input() languageFlags: LanguageFlag[] = [];
  @Input() validFeatureSystems: ValidFeatureSystem[] = [];
  @Output() onUpdate = new EventEmitter<Template>();

  get dataSource(): TemplateItem[] {
    return this.template.worksheetItems[this.sheetIndex].templateItems;
  }

  // Das Popover wird in der registrierten Grid Zelle ausgelöst. Hier wird der State für die Darstellung
  // festgehalten und Elemente, die nicht mehr gebraucht werden, werden automatisch über den Garbage Collector
  // bereinigt.
  popoverView = new WeakMap<ColumnCellTemplateData<TemplateItem>, { target: HTMLElement; visible: boolean }>();

  // Die Property wird verwendet, um Seiteneffekte mit Kontrollelementen (wie z.B. ein drag handle)
  // zu berücksichtigen. Das ist speziell notwendig, da die Ebenen einfach gezählt werden und der
  // offset dann entsprechend mit einkalkuliert werden muss.
  columnOffset = 1;

  levelDepth = 1;
  get levels() {
    return Array(this.levelDepth)
      .fill(0)
      .map((_, index) => index);
  }

  levelChoices: AutocompleteValues[] = [];
  levelChoiceGroups: { [key: string]: string[] };

  typeFieldSource: DropdownFieldSource[] = [
    {
      id: 'standard',
      display: 'Standard',
      translate: [
        fieldNames.descriptionShort,
        fieldNames.descriptionLong,
        fieldNames.descriptionLongWithoutHtml,
        fieldNames.manufacturerTypeDescription,
        fieldNames.remarks,
        fieldNames.manufacturerName
      ]
    },
    {
      id: 'mime',
      display: 'Medien',
      translate: [fieldNames.mimeAlt, fieldNames.mimeDescription]
    },
    {
      id: 'feature',
      display: 'Feature',
      translate: [
        fieldNames.featureSystemGroupName,
        fieldNames.featureSystemGroupName,
        fieldNames.fName,
        fieldNames.fUnit,
        fieldNames.fValue
      ]
    },
    { id: 'orderdetail', display: 'OrderDetails' },
    { id: 'price', display: 'Preis' },
    { id: 'logistics', display: 'Logistik' },
    { id: 'reference', display: 'Referenzen' },
    { id: 'udx', display: 'UDX' },
    { id: 'seo', display: 'SEO - SEA' },
    { id: 'legal', display: 'Recht' },
    { id: 'misc', display: 'MISC' },
    { id: 'supplier', display: 'Lieferant' },
    { id: 'fixed', display: 'Festwert' }
  ];

  columnOrderList: { column: string; order: number }[] = [];

  constructor(
    private translate: TranslateService,
    public fieldService: TemplateFieldsService,
    public systemService: SystemService
  ) {}

  ngOnInit() {
    this.levelChoiceGroups = {};

    for (const fieldSource of this.typeFieldSource) {
      fieldSource.display = this.translate.instant(fieldSource.display);
    }

    this.handleLoadedTemplate();
  }

  async onAddNewRow(event: DxButtonTypes.ClickEvent) {
    const item = new TemplateItem([new NextPimField('-1', '-1', fieldNames.supplierPid)], 1, fieldNames.supplierPid);
    item.fieldType = 'standard';
    item.isNewEditorVersion = true;
    item.languageCode = 'deu';
    item.calculation = '';
    item.numberformat = '';
    item.seperator = '';
    item.mapping = '';
    item.maxCharacters = 0;
    item.section = 1;
    item.keys = [];
    item.order = this.dataSource.length + 1;

    this.dataSource.push(item);

    this.updateDataSource();
    this.updateColumnOrderList();
    this.updateTemplate();
  }

  onOpenFieldSettings(event: ClickEvent, cellData: ColumnCellTemplateData<TemplateItem>) {
    const target = event.element;
    const visible = true;
    this.popoverView.set(cellData, { target, visible });
  }

  onCloseFieldSettings(cellData: ColumnCellTemplateData<TemplateItem>) {
    this.popoverView.delete(cellData);
  }

  onLevelNameChanged(cellData: ColumnCellTemplateData<TemplateItem>, event: ValueChangedEvent) {
    if (!event.event) return;

    const index = cellData.columnIndex - this.columnOffset;
    const keys = cellData.data.keys;
    this.setLevelChoice(keys, event.value, index)
    this.update(cellData);
  }

  async onOrderItemChanged(event: ValueChangedEvent, cellData: ColumnCellTemplateData<TemplateItem>) {
    const oldOrder = event.previousValue;
    const newOrder = this.fieldService.excelColumnToNumber(event.value);
    cellData.data.order = oldOrder;

    if (newOrder !== oldOrder) {
      this.updateOrders(oldOrder, newOrder);
    }

    this.dataSource.sort((a, b) => a.order - b.order);
    this.update(cellData);
  }

  async onOrderItemClick(
    event: ItemClickEvent<{ order: number; column: string }>,
    cellData: ColumnCellTemplateData<TemplateItem>
  ) {
    const newOrder = event.itemData.order;
    const oldOrder = cellData.data.order;
    if (newOrder !== oldOrder) {
      this.updateOrders(oldOrder, newOrder);
    }

    this.dataSource.sort();
    this.update(cellData);
  }

  updateColumnOrderList() {
    this.columnOrderList = [];
    for (let i = 0; i < this.dataSource.length; i++) {
      this.columnOrderList.push({ order: i + 1, column: this.fieldService.numberToExcelColumn(i + 1) });
    }
  }

  update(cellData: ColumnCellTemplateData<TemplateItem>) {
    if (cellData.data.pimFields.length > 0) this.sanitizePimField(cellData.data.fieldType, cellData.data.pimFields[0]);

    this.template.columnCount = 0;
    for (const sheet of this.template.worksheetItems) {
      this.template.columnCount += sheet.templateItems.length;
    }

    this.updateDataSource();
    this.updateColumnOrderList();
    this.updateTemplate();
  }

  fixkeys() {
    for (const source of this.dataSource) {
      while (source.keys.length > this.levelDepth) {
        source.keys.pop();
      }
      while (source.keys.length < this.levelDepth) {
        source.keys.push('');
      }
    }
  }

  addColumn() {
    for (const item of this.dataSource) {
      item.keys[this.levelDepth] ??= '';
    }
    this.levelDepth++;
    this.fixkeys();

    this.updateTemplate();
  }

  removeColumn() {
    if (this.dataSource.some((x) => x.keys[this.levelDepth - 1]?.trim())) {
      this.systemService.notifyInfo('Es gibt noch gefüllte Überschriften auf dieser Ebene');
      return;
    }

    this.levelDepth--;
    this.fixkeys();

    //if (this.levelChoices[this.levelDepth]) this.levelChoices.pop();

    this.updateTemplate();
  }

  hasTemplateItemSettings(templateItem: TemplateItem) {
    if (!templateItem) return false;
    const hasCalculation = templateItem.calculation !== null && templateItem.calculation !== '';
    const hasNumberFormat = templateItem.numberformat !== null && templateItem.numberformat !== '';
    const hasSeparator = templateItem.seperator !== null && templateItem.seperator !== '';
    const hasMapping = templateItem.mapping !== null && templateItem.mapping !== '';
    const hasAutomaticSeparation = templateItem.maxCharacters > 0 && templateItem.section >= 1;
    const hasPrefixSuffix = templateItem.prefix?.trim() || templateItem.suffix?.trim();
    const hasFactor = templateItem.factorOperator && templateItem.calculation === 'FACTOR';
    return (
      hasCalculation ||
      hasNumberFormat ||
      hasSeparator ||
      hasMapping ||
      hasAutomaticSeparation ||
      hasPrefixSuffix ||
      hasFactor
    );
  }

  isTranslatable(templateItem: TemplateItem) {
    const [pimField] = templateItem.pimFields;
    const typeField = this.typeFieldSource.find((x) => x.id === templateItem.fieldType);
    return !typeField?.translate || typeField?.translate.includes(pimField.field);
  }

  private updateTemplate() {
    this.onUpdate.emit(this.template);
  }

  private updateDataSource() {
    const items = this.dataSource;
    items.sort((a, b) => a.order - b.order);
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      item.order = i + 1;
    }
  }

  private updateOrders(oldOrder: number = -1, newOrder: number = -1) {
    const items = this.dataSource;

    // Finde das zu aktualisierende Item
    const updatedItem = items.find((item) => item.order === oldOrder);
    if (!updatedItem) return;

    if (newOrder < 1) newOrder = 1;
    if (newOrder > items.length) newOrder = items.length;

    if (oldOrder < newOrder) {
      // Verschiebung nach hinten
      for (let item of items) {
        if (item.order > oldOrder && item.order <= newOrder) {
          item.order--;
        }
      }
    } else if (oldOrder > newOrder) {
      // Verschiebung nach vorne
      for (let item of items) {
        if (item.order >= newOrder && item.order < oldOrder) {
          item.order++;
        }
      }
    }

    // Setze die neue Order für das aktualisierte Item
    updatedItem.order = newOrder;
    this.updateDataSource();
  }

  private calcRealLevelDepth(items: TemplateItem[]) {
    return Math.max(1, ...items.map((x) => x.keys.length));
  }

  private setLevelChoice(keys: string[], value: string, index: number) {
    const groupId = keys.slice(0, index).join('|');
    if (!this.levelChoiceGroups[groupId]) {
      this.levelChoiceGroups[groupId] = [];
    }
    if (value && !this.levelChoiceGroups[groupId].includes(value)) {
      this.levelChoiceGroups[groupId].push(value);
    }
  }

  private sanitizePimField(type: FieldType, pimField: NextPimField) {
    const field = pimField.field;
    if (
      ('feature' === type && !isFeatureField(field)) ||
      ('mime' === type && !isMimeField(field)) ||
      !isNextPimField(field)
    ) {
      pimField.elementKey = '-1';
      pimField.systemKey = '-1';
      pimField.field = '';
    }
  }

  private handleLoadedTemplate() {
    const items = this.template.worksheetItems[this.sheetIndex].templateItems;
    for (const item of items) {
      const [pimField] = item.pimFields?.length ? item.pimFields : [{ field: null }];
      item.fieldType = this.fieldService.getFieldType(pimField);

      if (item.key?.trim()) {
        item.keys.push(item.key);
        item.key = '';
      }

      for (let i = 0; i < item.keys.length; i++) {
        const value = item.keys[i];
        this.setLevelChoice(item.keys, value, i);
      }
    }
    this.levelDepth = this.calcRealLevelDepth(items);
    if (this.levelDepth === 0) {
      this.addColumn();
    }

    this.updateColumnOrderList();
  }
}
