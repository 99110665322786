
import { Component, ViewChild } from "@angular/core";
import { ModelService } from "../../../Services/model.service";
import { Catalog } from "../../../Model/Catalog/Catalog";
import { DxFileUploaderComponent } from "devextreme-angular";
import { TestResult } from "../../../Model/Dto/TestResult";
import { Message } from "../../../Model/System/Message";
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from "angular-oauth2-oidc";
import {UserIdleService} from "angular-user-idle";

@Component({
    selector: "importMedia",
    templateUrl: "importMedia.component.html"
})
export class ImportMediaComponent {
  @ViewChild(DxFileUploaderComponent)
  dxfileuploader: DxFileUploaderComponent;

  value: any[] = [];
  model: Catalog[];
  uploadHeaders: any;
  pages: { id: number; title; template: string }[];
  txtFromLink: string;
  txtUpload: string;

  constructor(private modelService: ModelService,
              public translate: TranslateService,
              private oAuthService: OAuthService,
              private userIdleService: UserIdleService) {

    this.testLink = this.testLink.bind(this);
    this.startDownload = this.startDownload.bind(this);
    this.onBeforeSendUpload = this.onBeforeSendUpload.bind(this);
    this.onProgress = this.onProgress.bind(this);

    this.uploadHeaders = {
      CatalogId: this.modelService.catalogService.selectedCatalogId,
      CustomerId: this.modelService.loginService.currentUser.customerId,
    }
    this.txtLink = this.translate.instant("Bitte Link zu einer .zip Datei angeben");
    this.txtDownloadStarted = this.translate.instant("Download wird gestartet");
    this.txtUpload = this.translate.instant("Upload");
    this.txtFromLink = this.translate.instant("Aus Link");

    this.pages = [
        { id: 1, title: this.txtUpload, template: "uploadTemplate" },
        { id: 2, title: this.txtFromLink, template: "linkTemplate" },
    ];

  }

  onBeforeSendUpload(e: { request: XMLHttpRequest }) {
    e.request.withCredentials = true;
    e.request.setRequestHeader('Authorization', 'Bearer ' + this.oAuthService.getAccessToken());
  }

  onProgress(e: {}) {
    this.userIdleService.stopWatching();
    this.userIdleService.startWatching();
  }

  onUploaded() {
      this.dxfileuploader.instance.reset();
  }

  fileUrl: string = "";
  fileUrlTest: boolean = false;
  fileUrlTestMessage: string = "";

  testLink() {
      this.fileUrlTest = false;
      var tmp = this.fileUrl.split("?", 1);
      var link = tmp[0];
      link = link.replace("www.dropbox", "dl.dropbox");
      this.fileUrl = link;

      //if (this.fileUrl.substr(-4,4) != ".zip") {
      //  this.fileUrlTestMessage = this.txtLink;
      //  return;
      //}

      this.modelService.systemService.testUrl(this.fileUrl).subscribe((result: TestResult) => this.testLinkResult(result));
  }

  linkChanged(event = null) {
      this.fileUrlTest = false;
      this.fileUrlTestMessage = "";
  }

  testLinkResult(result: TestResult) {
      this.fileUrlTest = result.success;
      this.fileUrlTestMessage = result.message;
  }

  startDownload() {
      this.fileUrlTestMessage = this.txtDownloadStarted;
    this.modelService.systemService.mediaUrlImport(this.fileUrl, this.modelService.catalogService.catalog.id, this.modelService.catalogService.catalog.customerId).subscribe((result: TestResult) => this.downloadStarted(result));
  }

  downloadStarted(result: TestResult) {
      if (result.success) {
          this.fileUrl = "";
          this.fileUrlTest = false;
          this.fileUrlTestMessage = "";
          this.translate.get("Import als Job gestartet").subscribe((text: string) => {
              this.modelService.systemService.notify(new Message(text));
          });
          ;
      } else {
          this.translate.get("Unbekannter Fehler").subscribe((text: string) => {
              this.modelService.systemService.notify(new Message(text, "error"));
          });

      }
  }


  f: boolean[] = [false, false];

  utt(id) {
      if (this.modelService.loginService.showToolTips) {
          this.f[id] = !this.f[id];
      }
  }

  get showToolTips(): boolean {
      return this.modelService.loginService.showToolTips;
  }

  get showProToolTips(): boolean {
      return this.modelService.loginService.showProToolTips;
  }

  get showPlaceHolders() {
      return this.modelService.loginService.showPlaceHolders;
  }

  txtLink: string;
  txtDownloadStarted: string;
}
