
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { LoginService } from './login.service';
import { SupplierPidMapping } from "../Model/supplierPidMapping/SupplierPidMapping";
import { ExportTranslation } from "../Model/Dto/ExportTranslation";
import { Product } from "../Model/Catalog/Product";


@Injectable()
export class SupplierPidMappingService {



  constructor(private http: HttpClient, public loginService: LoginService) {

  }


  getSupplierPidMappings(): Observable<SupplierPidMapping[]> {

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", this.loginService.currentUser.customerId)
    }

    return (this.http.get<SupplierPidMapping[]>("api/SupplierPidMapping/GetSupplierPidMappings", options)) as any;
  }

  mapProduct(model: Product) : Observable<Product> {
    return (this.http.post("api/SupplierPidMapping/mapProduct", model)) as any;
  }

  deleteSupplierPidMapping(id: string) : Observable<void> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("id", id).append("customerId", this.loginService.currentUser.customerId)
    }
    return this.http.delete("api/SupplierPidMapping/DeleteSupplierPidMapping", options) as any;
  }

  deleteAllSupplierPidMapping(): Observable<void> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append('customerId', this.loginService.currentUser.customerId)
    };
    return (this.http.delete("api/SupplierPidMapping/DeleteAllSupplierPidMapping", options)) as any;
  }

  updateSupplierPidMapping(supplierPidMapping: SupplierPidMapping): Observable<SupplierPidMapping> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", this.loginService.currentUser.customerId)
    }
    return (this.http.post("api/SupplierPidMapping/UpdateSupplierPidMapping", supplierPidMapping,options)) as any;
  }

  addSupplierPidMapping(): Observable<SupplierPidMapping> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", this.loginService.currentUser.customerId)
    }
    return (this.http.post("api/SupplierPidMapping/AddSupplierPidMapping", null,options)) as any;
  }

  exportSupplierPidMappings(customerId: string) {
    let exportTranslation = new ExportTranslation();
    exportTranslation.customerId = customerId;
    this.http.post("api/SupplierPidMapping/ExportSupplierPidMappings", exportTranslation).subscribe();
  }

}
