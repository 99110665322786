import { Component, Input, OnInit } from '@angular/core';
import { UpdateTemplate } from '../../Model/Catalog/UpdateTemplate';
import { TranslateService } from '@ngx-translate/core';
import { StringResponse } from '../../Model/Dto/StringResponse';
import { LoginService } from '../../Services/login.service';
import { Router } from '@angular/router';
import { UpdateTemplateService } from '../../Services/updateTemplate.service';
import { CatalogService } from '../../Services/CatalogManagement/catalog.service';
import { ExportParameter } from '../../Model/exportParameter/ExportParameter';
import { ExportConfigurationService } from '../../Services/exportConfiguration.service';
import { ReportLastRun } from '../../Model/Catalog/Reporting/ReportLastRun';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'UpdateTemplate',
  templateUrl: 'updateTemplate.component.html',
  styleUrls: ['updateTemplate.component.css']
})
export class UpdateTemplateComponent implements OnInit {
  @Input() template: UpdateTemplate;

  groups: string[] = [];
  exportParameters: ExportParameter[] = [];

  constructor(
    public translate: TranslateService,
    public loginService: LoginService,
    public router: Router,
    public updateTemplateService: UpdateTemplateService,
    public catalogService: CatalogService,
    public exportConfigurationService: ExportConfigurationService
  ) {}

  ngOnInit(): void {
    if (this.loginService.currentUser == null) {
      this.router.navigate(['/']);
      return;
    }

    this.exportConfigurationService
      .getAll(this.loginService.currentCustomer.id)
      .subscribe((configs: ExportParameter[]) => {
        this.exportParameters = configs;
      });

    this.initFieldsAndGroups();
  }

  public initFieldsAndGroups() {
    this.catalogService.loadFields();
    this.groups = this.updateTemplateService.GetFieldGroups();
  }

  blockUpdates: boolean = false;
  blockChangedField: boolean = false;

  get showPlaceHolders() {
    return this.loginService.showPlaceHolders;
  }

  async update(e) {
    if (!e.event) return;
    await lastValueFrom(this.updateTemplateService.updateUpdateTemplate(this.template));
  }
}
