
import { map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { AdminService } from "./admin.service";
import { LoginService } from "./login.service";
import { SystemService } from "./system.service";
import { ClassificationService } from "./classification.service";
import { CatalogService } from "./CatalogManagement/catalog.service";
import { CategoryService } from "./CatalogManagement/category.service";
import { TranslationService } from "./translation.service";
import { BackupService } from "./CatalogManagement/backup.service";
import { JobService } from "./job.service";
import { DataQualityService } from "./dataQuality.service";
import { MediaService } from "./media.service";
import { OsgConnectionService } from "./osgConnection.service";
import { HazmatClassService } from "./hazmatClass.service";
import { ShopService } from "./shop.service";
import { Contact } from "../Model/Contact";
import { WarrantyClassService } from './warrantyClass.service';
import { UpdateTemplateService } from './updateTemplate.service';
import { MavisConnectionService } from './mavisConnection.service';
import { PrintService } from './print.service';
import { ViewService } from './view.service';
import { SendToNWConfigService } from './sendToNWConfig.service';
import { ProductStateService } from './productState.service';
import { ProductReportingService } from './productreporting.service';
import { FeatureMappingService } from './featureMapping.service';
import { WawiSettingsService } from './wawiSettingsService.service';
import { ProductService } from './CatalogManagement/product.service';
import { SupplierPidMappingService } from './supplierPidMapping.service';
import { DeeplConnectionService } from './deeplConnection.service';
import { ValidFeatureService } from './validFeature.service';
import { CatalogStateService } from './catalogState.service';


@Injectable()
export class ModelService {
  constructor(private http: HttpClient,
    public adminService: AdminService,
    public loginService: LoginService,
    public catalogService: CatalogService,
    public catalogStateService: CatalogStateService,
    public categoryService: CategoryService,
    public systemService: SystemService,
    public router: Router,
    public jobService: JobService,
    public mediaService: MediaService,
    public translationService: TranslationService,
    public shopService: ShopService,
    public backupService: BackupService,
    public dataQualityService: DataQualityService,
    public osgConnectionService: OsgConnectionService,
    public classificationService: ClassificationService,
    public validFeatureService: ValidFeatureService,
    public hazmatClassService: HazmatClassService,
    public warrantyClassService: WarrantyClassService,
    public updateTemplateService: UpdateTemplateService,
    public mavisConnectionService: MavisConnectionService,
    public printService: PrintService,
    public viewService: ViewService,
    public sendToNWConfigService: SendToNWConfigService,
    public productStateService: ProductStateService,
    public productReportingService: ProductReportingService,
    public featureMappingService: FeatureMappingService,
    public wawiSettingsService: WawiSettingsService,
    public productService: ProductService,
    public supplierPidMappingService: SupplierPidMappingService,
    public deeplConnectionServeice: DeeplConnectionService,
  ) {
  }
}
