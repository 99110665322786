
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { HazmatClass } from '../Model/Catalog/HazmatClass';
import { LoginService } from './login.service';
import { Product } from '../Model/Catalog/Product';

@Injectable()
export class HazmatClassService {
  hazmatClasses: HazmatClass[];
  selectedClass: HazmatClass;
  isClassificationVisible: boolean = false;
  _selectedClassId: string = "";

  get selectedClassId(): string {
    return this._selectedClassId;
  }

  set selectedClassId(value: string) {
    if (value != this.selectedClassId && value != "") {
      this.getClass(value).subscribe(
        (cs: HazmatClass) => {
          this.selectedClass = cs;
        });
    }
    this._selectedClassId = value;
  }

  constructor(private http: HttpClient, public loginService: LoginService) {}

  getClass(id: string): Observable<HazmatClass> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("hazmatClassId", id).append("customerId", this.loginService.currentUser.customerId)

    }
    return (this.http.get<HazmatClass>("api/HazmatClass/GetHazmatClass", options)) as any;
  }

  getClasses(): Observable<HazmatClass[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", this.loginService.currentUser.customerId)
    }
    return (this.http.get<HazmatClass[]>("api/HazmatClass/GetHazmatClasses", options)) as any;
  }

  deleteClass(element: HazmatClass) {
    {
      const options = {
        headers: new HttpHeaders().append("Content-Type", "application/json"),
        params: new HttpParams().append("id", element.id)
      }

      this.http.delete("api/HazmatClass/DeleteHazmatClass", options)
        .subscribe(
          result => { },
          err => console.error(err)
        );
    }
  }

  updateClass(selectedClass: HazmatClass): Observable<HazmatClass> {
    return (this.http.post("api/HazmatClass/UpdateHazmatClass", selectedClass)) as any;
  }

  addClass(selectedClass: HazmatClass): Observable<HazmatClass> {
    return (this.http.post("api/HazmatClass/AddHazmatClass", selectedClass)) as any;
  }

  hStatements: string[] = [
    "H200",
    "H201",
    "H202",
    "H203",
    "H204",
    "H205",
    "H220",
    "H221",
    "H222",
    "H223",
    "H224",
    "H225",
    "H226",
    "H228",
    "H229",
    "H230",
    "H231",
    "H240",
    "H241",
    "H242",
    "H250",
    "H251",
    "H252",
    "H260",
    "H261",
    "H270",
    "H271",
    "H272",
    "H280",
    "H281",
    "H290",
    "H300",
    "H301",
    "H302",
    "H304",
    "H310",
    "H311",
    "H312",
    "H314",
    "H315",
    "H317",
    "H318",
    "H319",
    "H330",
    "H331",
    "H332",
    "H334",
    "H335",
    "H336",
    "H340",
    "H341",
    "H350",
    "H350i",
    "H351",
    "H360",
    "H360D",
    "H360F",
    "H360FD",
    "H360Df",
    "H360Fd",
    "H361",
    "H361d",
    "H361f",
    "H361fd",
    "H362",
    "H370",
    "H371",
    "H372",
    "H373",
    "H300 + H310",
    "H300 + H330",
    "H310 + H330",
    "H300 + H310 + H330",
    "H301 + H311",
    "H301 + H331",
    "H311 + H331",
    "H301 + H311 + H331",
    "H302 + H312",
    "H302 + H332",
    "H312 + H332",
    "H302 + H312 + H332",
    "H400",
    "H410",
    "H411",
    "H412",
    "H413",
    "H420"
  ]

  pStatements: string[] = [
    "P101",
    "P102",
    "P103",
    "P201",
    "P202",
    "P210",
    "P211",
    "P220",
    "P222",
    "P223",
    "P230",
    "P231",
    "P232",
    "P233",
    "P234",
    "P235",
    "P240",
    "P241",
    "P242",
    "P243",
    "P244",
    "P250",
    "P251",
    "P260",
    "P261",
    "P262",
    "P263",
    "P264",
    "P270",
    "P271",
    "P272",
    "P273",
    "P280",
    "P282",
    "P283",
    "P284",
    "P231 + P232",
    "P301",
    "P302",
    "P303",
    "P304",
    "P305",
    "P306",
    "P308",
    "P310",
    "P311",
    "P312",
    "P313",
    "P314",
    "P315",
    "P320",
    "P321",
    "P330",
    "P331",
    "P332",
    "P333",
    "P334",
    "P335",
    "P336",
    "P337",
    "P338",
    "P340",
    "P342",
    "P351",
    "P352",
    "P353",
    "P360",
    "P361",
    "P362",
    "P363",
    "P364",
    "P370",
    "P371",
    "P372",
    "P373",
    "P375",
    "P376",
    "P377",
    "P378",
    "P380",
    "P381",
    "P390",
    "P391",
    "P301+P310",
    "P301+P312",
    "P301+P330+P331",
    "P302+P334",
    "P302+P352",
    "P302+P335+P334   ",
    "P303+P361+P353",
    "P304+P340",
    "P305+P351+P338",
    "P306+P360",
    "P308+P311",
    "P308+P313",
    "P332+P313",
    "P333+P313",
    "P336+P315",
    "P337+P313",
    "P342+P311",
    "P361+P364",
    "P362+364",
    "P370+P376",
    "P370+P378",
    "P370+P380+P375",
    "P370+P372+P380+P373",
    "P370+P380+P375[+P378]",
    "P371+P380+P375",
    "P401",
    "P402",
    "P403",
    "P404",
    "P405",
    "P406",
    "P407",
    "P410",
    "P411",
    "P412",
    "P413",
    "P420",
    "P402+P404",
    "P403+P233",
    "P403+P235",
    "P410+P403",
    "P410+P412",
    "P501",
    "P502"
  ]

  hazardPictograms: string[] = [
    "GHS01",
    "GHS02",
    "GHS03",
    "GHS04",
    "GHS05",
    "GHS06",
    "GHS07",
    "GHS08",
    "GHS09"
  ]

  loadProductHazmat(product: Product) {
    if (product.productLogistic.hazardousMaterialClassId != "") {
      this.getClass(product.productLogistic.hazardousMaterialClassId).subscribe(
        (result: HazmatClass) => {
          if (result.hazardPictograms.length > 0) {
            product.hazmatClass = result;
            product.addHazmat();
          }
        });
    }
  }
}
