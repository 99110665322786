import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ModelService } from '../../Services/model.service';
import { TranslateService } from '@ngx-translate/core';
import { dxButtonOptions } from 'devextreme/ui/button';
import { Router } from '@angular/router';
import { TemplateService } from '../../Services/template.service';
import { NewsService } from '../../Services/news.service';
import { UserManagementService } from '../../Services/userManagment.service';
import { JobService } from '../../Services/job.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'menu',
  templateUrl: 'menu.component.html',
  styleUrls: ['menu.component.css']
})
export class MenuComponent implements OnInit, OnDestroy {
  drawerTimeout: NodeJS.Timeout = undefined;

  drawerOpened: boolean = false;
  drawerFocused: boolean = false;
  menuButtonOptions: dxButtonOptions;
  jobsButtonOptions: dxButtonOptions;
  newsButtonOptions: dxButtonOptions;
  accountButtonOptions: dxButtonOptions;
  warningButtonOptions: dxButtonOptions;
  navigationItems: Array<Record<string, unknown>> = [];
  newImport: boolean = true;
  newNews: boolean = false;
  loggedInSubscription: any = null;
  currentNavigationTitle = '';

  jobbadgeclass: string = 'jobbagde-info';
  jobbadgevisible: boolean = false;

  constructor(
    private router: Router,
    public modelService: ModelService,
    public templateService: TemplateService,
    public translate: TranslateService,
    public newsService: NewsService,
    public userManagemant: UserManagementService,
    private cdr: ChangeDetectorRef,
    public jobService: JobService
  ) {
    this.drawerMouseEnter = this.drawerMouseEnter.bind(this);
    this.drawerMouseLeave = this.drawerMouseLeave.bind(this);
    this.updateMenuButtons = this.updateMenuButtons.bind(this);
    this.onLoggedIn = this.onLoggedIn.bind(this);
    this.showNews = this.showNews.bind(this);
    this.logout = this.logout.bind(this);

    this.menuButtonOptions = {
      icon: 'menu',
      onClick: () => {
        this.drawerOpened = !this.drawerOpened;
      }
    };

    this.accountButtonOptions = {
      elementAttr: { id: 'accountToolTip' },
      hint: `Account: ${this.account}`,
      icon: 'user',
      onClick: this.showAccount.bind(this)
    };

    translate.get('Jobs').subscribe((value) => {
      this.jobsButtonOptions = {
        elementAttr: { id: 'jobsToolTip' },
        hint: value,
        icon: 'runner',
        onClick: this.showJobs.bind(this)
      };
    });

    this.newsButtonOptions = {
      elementAttr: { id: 'newsButtonOptions' },
      icon: 'email',
      type: 'normal',
      onClick: this.showNews.bind(this)
    };

    this.navigationItems.push({
      id: 1,
      text: this.translate.instant('Kataloge'),
      icon: 'glyphicon glyphicon-book',
      path: 'catalogs',
      visible: false
    });
    this.navigationItems.push({
      id: 2,
      text: this.translate.instant('Suche'),
      icon: 'glyphicon glyphicon-search',
      path: 'search',
      visible: false
    });
    this.navigationItems.push({
      id: 15,
      text: this.translate.instant('Kataloge kombinieren'),
      icon: 'glyphicon glyphicon-transfer',
      path: 'combineCatalogs',
      visible: false
    });
    this.navigationItems.push({
      id: 12,
      text: this.translate.instant('Excel Templates'),
      icon: 'glyphicon glyphicon-list-alt',
      path: 'templates',
      visible: false
    });
    this.navigationItems.push({
      id: 4,
      text: this.translate.instant('Übersetzungen'),
      icon: 'glyphicon glyphicon-random',
      path: 'translation',
      visible: false
    });
    this.navigationItems.push({
      id: 5,
      text: this.translate.instant('Klassifizierung'),
      icon: 'glyphicon glyphicon-tasks',
      path: 'classification',
      visible: false
    });
    this.navigationItems.push({
      id: 6,
      text: this.translate.instant('Gefahrstoffklassen'),
      icon: 'glyphicon glyphicon-warning-sign',
      path: 'hazmatclasses',
      visible: false
    });
    this.navigationItems.push({
      id: 7,
      text: this.translate.instant('Garantieklassen'),
      icon: 'glyphicon glyphicon-list-alt',
      path: 'warrantyClasses',
      visible: false
    });
    this.navigationItems.push({
      id: 8,
      text: this.translate.instant('Update Templates'),
      icon: 'glyphicon glyphicon-equalizer',
      path: 'updateTemplates',
      visible: false
    });
    this.navigationItems.push({
      id: 9,
      text: this.translate.instant('Status'),
      icon: 'glyphicon glyphicon-filter',
      path: 'states',
      visible: false
    });
    this.navigationItems.push({
      id: 10,
      text: this.translate.instant('Produktdatenblatt Designer'),
      icon: 'glyphicon glyphicon-print',
      path: 'productReportDesigner',
      visible: false
    });
    this.navigationItems.push({
      id: 11,
      text: this.translate.instant('Feature Mapping'),
      icon: 'glyphicon glyphicon-random',
      path: 'featureMapping',
      visible: false
    });
    this.navigationItems.push({
      id: 14,
      text: this.translate.instant('Artikelnummern Mapping'),
      icon: 'glyphicon glyphicon-tag',
      path: 'supplierPidMapping',
      visible: false
    });
    this.navigationItems.push({
      id: 17,
      text: this.translate.instant('EDE Sortiment'),
      icon: 'glyphicon glyphicon-book',
      path: 'edeSortiment',
      visible: false
    });
    this.navigationItems.push({
      id: 18,
      text: this.translate.instant('Wawi Listen'),
      icon: 'glyphicon glyphicon-book',
      path: 'wawiList',
      visible: false
    });
    this.navigationItems.push({
      id: 19,
      text: this.translate.instant('Anlernsets'),
      icon: 'glyphicon glyphicon-cloud',
      path: 'teachingSets',
      visible: false
    });
    this.navigationItems.push({
      id: 20,
      text: this.translate.instant('Multishop Export'),
      icon: 'glyphicon glyphicon-export',
      path: 'multishopExport',
      visible: false
    });
    this.navigationItems.push({
      id: 22,
      text: this.translate.instant('Features'),
      icon: 'glyphicon glyphicon-book',
      path: 'validfeature',
      visible: false
    });
    this.navigationItems.push({
      id: 21,
      text: this.translate.instant('Validierung'),
      icon: 'glyphicon glyphicon-ok-sign',
      path: 'validation',
      visible: false
    });
    this.navigationItems.push({
      id: 23,
      text: this.translate.instant('DataSources'),
      icon: 'glyphicon glyphicon-plane',
      path: 'datasources',
      visible: false
    });
    this.navigationItems.push({
      id: 24,
      text: this.translate.instant('Oxomi Sortiment'),
      icon: 'glyphicon glyphicon-book',
      path: 'oxomi-sortiment',
      visible: false
    });
  }

  ngOnInit() {
    // Fill property once it changes
    this.modelService.systemService.titleUpdate$.subscribe((title) => {
      if (title) {
        this.currentNavigationTitle = title;
      } else {
        const itemTitle = this.navigationItems.find((ni) => this.router.url?.startsWith(`/${ni.path}`));
        if (itemTitle) {
          this.currentNavigationTitle = itemTitle.text as string;
        }
      }

      // Bugfix for ExpressionChangedAfterItHasBeenCheckedError
      this.cdr.detectChanges();
    });
    if (this.modelService.loginService.loggedIn) this.onLoggedIn();
    else this.loggedInSubscription = this.modelService.loginService.onLoggedIn.subscribe(() => this.onLoggedIn());
  }

  ngOnDestroy() {
    try {
      if (this.loggedInSubscription != null) this.loggedInSubscription.unsubscribe();
    } catch {}
  }

  isJobWarningVisible: boolean = false;

  ttt() {
    this.isJobWarningVisible = !this.isJobWarningVisible;
  }

  get isJobRunning(): boolean {
    return this.modelService.catalogService.isJobRunning;
  }

  drawerMouseEnter(e) {
    const self = this;

    this.drawerTimeout = setTimeout(() => {
      self.drawerFocused = true;

      clearTimeout(self.drawerTimeout);
      self.drawerTimeout = undefined;
    }, 1000);
  }

  drawerMouseLeave(e) {
    const self = this;

    if (this.drawerTimeout) {
      clearTimeout(self.drawerTimeout);
      self.drawerTimeout = undefined;
    }

    this.drawerFocused = false;
  }

  loadView(e) {
    this.router.navigate([e.addedItems[0].filePath]);
  }

  showJobs() {
    this.jobbadgevisible = false;
    this.modelService.catalogService.viewService.jobsVisible = true;
  }

  public showNews() {
    this.modelService.router.navigate(['/newsArticle']);
  }

  public switchImport() {
    if (this.newImport) {
      this.newImport = false;
    } else {
      this.newImport = true;
    }
  }

  onLoggedIn() {
    this.updateMenuButtons();
    this.modelService.loginService.notifyTestAccount();
    this.modelService.loginService.onNewNewsAmountChanged.subscribe(() => {
      this.newsService.checkNewNews();
    });

    this.jobService.feedFinishedJobs(this.modelService.loginService.currentCustomer.id).then();
    this.jobService.finishedJobs$.subscribe((x) => {
      if (!x?.message || this.jobsVisible) return;
      console.log('received: ' + x.message);
      this.jobbadgeclass = 'jobbagde-' + x.status;
      this.jobbadgevisible = true;
      let position = { right: 1, top: 64 };
      let direction = 'down push';
      let message =
        x.status == 'error' ? 'Job auf Fehler gelaufen' : x.status == 'warning' ? 'Job hat Warnung' : 'Job ist beendet';
      notify(
        {
          message: this.translate.instant(message, 'jobname:' + x.message),
          height: 45,
          width: 150,
          type: x.status,
          displayTime: 3500
        },
        { position: position, direction: 'down-push' }
      );
    });
  }

  updateMenuButtons() {
    if (this.modelService.loginService.currentCustomer) {
      this.accountButtonOptions = {
        elementAttr: { id: 'accountToolTip' },
        hint: `Account: ${this.account}`,
        icon: 'user',
        onClick: this.showAccount.bind(this)
      };
    } else {
      this.accountButtonOptions = {
        elementAttr: { id: 'accountToolTip' },
        hint: `Account: Not logged in`,
        icon: 'user',
        onClick: this.showAccount.bind(this)
      };
    }

    this.translate.get('Jobs').subscribe((value) => {
      this.jobsButtonOptions = {
        elementAttr: { id: 'jobsToolTip' },
        hint: value,
        icon: 'runner',
        onClick: this.showJobs.bind(this)
      };
    });

    this.newsButtonOptions = {
      elementAttr: { id: 'newsButtonOptions' },
      icon: 'email',
      type: 'normal',
      onClick: this.showNews.bind(this)
    };
  }

  get navigation() {
    const userLoggedIn = this.modelService.loginService.currentUser ? true : false;

    this.navigationItems.find((ni) => ni.id === 1).visible = userLoggedIn;
    this.navigationItems.find((ni) => ni.id === 2).visible =
      userLoggedIn && this.modelService.loginService.hasAddonSEARCH;

    //this.navigationItems.find(ni => ni.id === 3).visible = userLoggedIn && this.modelService.loginService.hasAddonEXCEL_TEMPLATE;
    this.navigationItems.find((ni) => ni.id === 12).visible =
      userLoggedIn && this.modelService.loginService.hasAddonEXCEL_TEMPLATE && this.canView('menuItems');
    //this.navigationItems.find(ni => ni.id === 13).visible = userLoggedIn && this.modelService.loginService.hasAddonDEVELOPMENT;   // Sandbox - KEIN finales Addon!!
    this.navigationItems.find((ni) => ni.id === 4).visible =
      userLoggedIn && this.modelService.loginService.hasAddonTranslate && this.canView('menuItems');
    this.navigationItems.find((ni) => ni.id === 5).visible =
      userLoggedIn && this.isAdmin() && this.canView('menuItems');
    this.navigationItems.find((ni) => ni.id === 6).visible =
      userLoggedIn && this.modelService.loginService.hasAddonLEGALITY && this.canView('menuItems');
    this.navigationItems.find((ni) => ni.id === 7).visible =
      userLoggedIn && this.modelService.loginService.hasAddonLEGALITY && this.canView('menuItems');
    this.navigationItems.find((ni) => ni.id === 8).visible =
      userLoggedIn && this.modelService.loginService.hasAddonACTIONS && this.canView('menuItems');
    this.navigationItems.find((ni) => ni.id === 9).visible = userLoggedIn && this.canView('menuItems');
    this.navigationItems.find((ni) => ni.id === 10).visible =
      userLoggedIn && this.modelService.loginService.hasAddonREPORTPRODUCTDATASHEET && this.canView('menuItems');
    this.navigationItems.find((ni) => ni.id === 11).visible =
      userLoggedIn && this.modelService.loginService.hasAddonFEATURE_MAPPING && this.canView('menuItems');
    this.navigationItems.find((ni) => ni.id === 14).visible =
      userLoggedIn && this.modelService.loginService.hasAddonWawiErp && this.canView('menuItems');
    this.navigationItems.find((ni) => ni.id === 15).visible = userLoggedIn && this.canView('menuItems');
    //this.navigationItems.find(ni => ni.id === 16).visible = userLoggedIn && this.modelService.loginService.hasAddonGevis && this.canView("gevisMerkmalscodeMappings");
    this.navigationItems.find((ni) => ni.id === 17).visible =
      userLoggedIn && this.modelService.loginService.hasAddonEDESortimentsAbo && this.canView('EDESoritmentsAbo');
    this.navigationItems.find((ni) => ni.id === 18).visible =
      userLoggedIn &&
      (this.modelService.loginService.hasAddonWawiErp || this.modelService.loginService.hasAddonEXCEL_TEMPLATE) &&
      this.canView('WawiList');
    this.navigationItems.find((ni) => ni.id === 19).visible =
      userLoggedIn &&
      this.modelService.loginService.hasAddonKI_TEXTS &&
      this.modelService.loginService.isAdmin &&
      this.canView('menuItems');
    this.navigationItems.find((ni) => ni.id === 20).visible =
      userLoggedIn && this.modelService.loginService.hasAddonOSG && this.canView('menuItems');
    this.navigationItems.find((ni) => ni.id === 22).visible = userLoggedIn && this.canView('menuItems');
    this.navigationItems.find((ni) => ni.id === 21).visible =
      userLoggedIn &&
      this.modelService.loginService.hasAddonVALIDATION &&
      this.canView('menuItems') &&
      this.modelService.loginService.isManager;
    this.navigationItems.find((ni) => ni.id === 23).visible =
      userLoggedIn && this.modelService.loginService.hasAddonDataSources && this.canView('menuItems');
    this.navigationItems.find((ni) => ni.id === 24).visible =
      userLoggedIn && this.modelService.loginService.hasAddonOXOMI && this.canView('menuItems');
    return this.navigationItems;
  }

  get selectedNavigationItems() {
    return this.navigationItems.filter((ni) => this.router.url?.startsWith(`/${ni.path}`));
  }

  get jobsVisible(): boolean {
    return this.modelService.catalogService.viewService.jobsVisible;
  }

  set jobsVisible(value: boolean) {
    this.modelService.catalogService.viewService.jobsVisible = value;
  }

  get currentUser() {
    if (this.modelService.loginService.currentUser) {
      return this.modelService.loginService.currentUser;
    } else return '';
  }

  get currentAccount() {
    if (this.modelService.loginService.currentCustomer?.name) {
      return this.modelService.loginService.currentCustomer.name;
    }
    return '';
  }

  get canChangePassword() {
    if (this.modelService.loginService.currentUser) {
      return true;
    }
    return false;
  }

  get isNoLayoutPage(): boolean {
    if (this.modelService.router.url.startsWith('/getProduct')) {
      return true;
    }
    if (this.modelService.router.url.startsWith('/printChart')) {
      return true;
    }

    return false;
  }

  get canLanguages() {
    if (this.modelService.loginService.currentUser) {
      return true;
    }
    return false;
  }

  get canAccount() {
    if (this.modelService.loginService.currentUser) {
      return true;
    }
    return false;
  }

  get canLayers() {
    if (this.modelService.loginService.currentUser) {
      return true;
    }
    return false;
  }

  //Style

  get getViewPort() {
    return this.modelService.loginService.getViewPort();
  }

  //ImportTemplate

  get fullScreen() {
    return this.modelService.catalogService.viewService.fullScreen;
  }

  public toFullScreen() {
    this.fullScreen = true;
  }

  public toWindow() {
    this.fullScreen = false;
  }

  set fullScreen(value) {
    this.modelService.catalogService.viewService.fullScreen = value;
  }

  get accountMenuVisible() {
    return this.modelService.catalogService.accountMenuVisible;
  }

  showAccount() {
    this.modelService.catalogService.accountMenuVisible = true;
  }

  hideAccountMenu() {
    this.modelService.catalogService.accountMenuVisible = false;
  }

  logout() {
    this.modelService.loginService.logout();
    this.modelService.router.navigate(['/login']);
  }

  set accountMenuVisible(value: boolean) {
    this.modelService.catalogService.accountMenuVisible = value;
  }

  /*  get importTemplateVisible() {
    return this.modelService.catalogService.importTemplateVisible;
  }

  set importTemplateVisible(value: boolean) {
    this.modelService.catalogService.importTemplateVisible = value;
  }

  hideImportTemplate() {
    this.modelService.catalogService.importTemplateVisible = false;
  }
*/
  get userName() {
    if (this.modelService.loginService.currentUser == null) return '';
    var name =
      this.modelService.loginService.currentUser.firstName + ' ' + this.modelService.loginService.currentUser.lastName;

    if (this.isAdmin()) {
      name = name + ' (ADMIN)';
    }

    return name;
  }

  isOpened(): boolean {
    return this.drawerOpened || this.drawerFocused;
  }

  isAdmin(): boolean {
    if (this.modelService.loginService.currentUser == null) return false;
    return this.modelService.loginService.currentUser.currentClaim == 'ADMIN';
  }

  get account() {
    if (this.modelService.loginService.currentCustomer) return this.modelService.loginService.currentCustomer.name;
    return '';
  }

  public templateEditorVisible: boolean = false;

  hideTemplateEditor() {
    this.templateEditorVisible = false;
  }

  showTemplateEditor() {
    this.templateEditorVisible = true;
  }

  get hasNewNews() {
    return this.newsService.newNews;
  }

  canView(name: string): boolean {
    return this.userManagemant.canView(name);
  }

  openSupportPage() {
    let email = this.modelService.loginService.currentUser.email;
    let lastName = this.modelService.loginService.currentUser.lastName;
    let firstName = this.modelService.loginService.currentUser.firstName;
    let link = `https://knowledge.nextpim.de/kb-tickets/new?email=${email}&lastname=${lastName}&firstname=${firstName}`;
    window.open(link, '_blank');
    this.hideAccountMenu();
  }
}
