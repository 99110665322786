<div class="dx-fieldset">
  <dx-validation-group *ngIf="model" id="ORDER">
    <div class="dx-fieldset-header">{{ 'Bestelldetails' | translate }}</div>
    <div class="dx-fieldset d-flex flex-row">

      <div class="flex-fill pr-3 w-50">

        <div class="np-field">
              <np-inputControl [(model)]="model"
                               [(productTranslation)]="productTranslation"
                               [showLinkButtons]="showLinkButtons"
                               [showPlaceLabel]="model?.orderDetail?.contentUnit?.length > 0"
                               [(selectedElements)]="selectedElements"
                               (elementSelected)="elementSelectFunction($event)"
                               (onValueChanged)="update($event)"
                               labelText="{{ 'Inhaltseinheit' | translate }}"
                               field="CONTENT_UNIT"
                               [showDragHandle]="showDragHandle"
                               [dragFilter]="dragFilter"
                               (excelElementDropped)="excelElementDrop($event)">

                <div slot="content" class="contentSlot">
                  <dx-autocomplete [minSearchLength]="0"
                                    maxItemCount=90
                                    [dataSource]="units"
                                    [readOnly]="readOnlyForLinkedFields('CONTENT_UNIT') || readOnly('orderConentUnit')"
                                    [showClearButton]="!readOnlyForLinkedFields('CONTENT_UNIT')"
                                    [showDropDownButton]="!readOnlyForLinkedFields('CONTENT_UNIT')"
                                    [(value)]="model.orderDetail.contentUnit"
                                    (onValueChanged)="update($event, 'CONTENT_UNIT')"
                                    (onSelectionChanged)="updateFromAutocomplete($event, 'CONTENT_UNIT')"
                                    class="validationBlue"
                               itemTemplate="contentUnitTemplate">
                    <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                      <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('CONTENT_UNIT')"></dxi-validation-rule>
                    </dx-validator>
                    <div *dxTemplate="let item of 'contentUnitTemplate'">
                      <div *ngIf="item !== 'Button' && item !== ''">
                        <span>{{ item | translate }} ({{ item }})</span>
                      </div>
                      <div *ngIf="item === ''">
                        <button (click)="toggleExtendedList()">Mehr anzeigen</button>
                      </div>
                    </div>
                  </dx-autocomplete>
                </div>

          </np-inputControl>
        </div>

        <div class="np-field">
          <np-inputControl [(model)]="model"
                           [(productTranslation)]="productTranslation"
                           [showLinkButtons]="showLinkButtons"
                           [showPlaceLabel]="model?.orderDetail?.noCuPerOu != null"
                           [(selectedElements)]="selectedElements"
                           (elementSelected)="elementSelectFunction($event)"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Verpackungsmenge' | translate }}"
                           field="NO_CU_PER_OU"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)">

            <div slot="content" class="contentSlot">
              <dx-autocomplete [minSearchLength]="0"
                               [dataSource]="one"
                               [readOnly]="readOnlyForLinkedFields('NO_CU_PER_OU')  || readOnly('orderPackageAmount')"
                               [showClearButton]="!readOnlyForLinkedFields('NO_CU_PER_OU')"
                               [showDropDownButton]="!readOnlyForLinkedFields('NO_CU_PER_OU')"
                               [(value)]="model.orderDetail.noCuPerOu"
                               (onValueChanged)="update($event, 'NO_CU_PER_OU')"
                               (onSelectionChanged)="updateFromAutocomplete($event, 'NO_CU_PER_OU')">
                <div slot="content" class="contentSlot">
                  <dx-number-box [(value)]="model.orderDetail.noCuPerOu" [readOnly]="true"></dx-number-box>
                </div>
                <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                  <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('NO_CU_PER_OU')"></dxi-validation-rule>
                </dx-validator>
              </dx-autocomplete>
            </div>

          </np-inputControl>
        </div>

        <div class="np-field">
          <np-inputControl [(model)]="model"
                           [(productTranslation)]="productTranslation"
                           [showLinkButtons]="showLinkButtons"
                           [showPlaceLabel]="model?.orderDetail?.orderUnit?.length > 0"
                           [(selectedElements)]="selectedElements"
                           (elementSelected)="elementSelectFunction($event)"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Bestelleinheit' | translate }}"
                           field="ORDER_UNIT"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)">

            <div slot="content" class="contentSlot">
              <dx-autocomplete [minSearchLength]="0"
                               [dataSource]="units"
                               [readOnly]="readOnlyForLinkedFields('ORDER_UNIT') || readOnly('orderUnit')"
                               [showClearButton]="!readOnlyForLinkedFields('ORDER_UNIT')"
                               [showDropDownButton]="!readOnlyForLinkedFields('ORDER_UNIT')"
                               [(value)]="model.orderDetail.orderUnit"
                               (onValueChanged)="update($event, 'ORDER_UNIT')"
                               (onSelectionChanged)="updateFromAutocomplete($event, 'ORDER_UNIT')"
                               itemTemplate="orderUnitTemplate">

                <div *dxTemplate="let item of 'orderUnitTemplate'">
                  <span>{{ item | translate}} ({{ item  }})</span>
                </div>
                <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                  <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('ORDER_UNIT')"></dxi-validation-rule>
                </dx-validator>
              </dx-autocomplete>
            </div>

          </np-inputControl>
        </div>

        <div class="np-field">
          <np-inputControl [(model)]="model"
                           [(productTranslation)]="productTranslation"
                           [showLinkButtons]="showLinkButtons"
                           [showPlaceLabel]="model?.orderDetail?.priceQuantity != null"
                           [(selectedElements)]="selectedElements"
                           (elementSelected)="elementSelectFunction($event)"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Preismenge' | translate }}"
                           field="PRICE_QUANTITY"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)">

            <div slot="content" class="contentSlot">
              <dx-autocomplete [minSearchLength]="0"
                               [dataSource]="one"
                               [readOnly]="readOnlyForLinkedFields('PRICE_QUANTITY') || readOnly('orderPriceAmount')"
                               [showClearButton]="!readOnlyForLinkedFields('PRICE_QUANTITY')"
                               [showDropDownButton]="!readOnlyForLinkedFields('PRICE_QUANTITY')"
                               [(value)]="model.orderDetail.priceQuantity"
                               (onValueChanged)="update($event, 'PRICE_QUANTITY')"
                               (onSelectionChanged)="updateFromAutocomplete($event, 'PRICE_QUANTITY')">
                <div slot="content" class="contentSlot">
                  <dx-number-box [(value)]="model.orderDetail.priceQuantity" [readOnly]="true"></dx-number-box>
                </div>
                <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                  <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('PRICE_QUANTITY')"></dxi-validation-rule>
                </dx-validator>
              </dx-autocomplete>
            </div>

          </np-inputControl>
        </div>

        <div class="np-field">
          <np-inputControl [(model)]="model"
                           [(productTranslation)]="productTranslation"
                           [showLinkButtons]="showLinkButtons"
                           [suppressPlaceLabel]="true"
                           [(selectedElements)]="selectedElements"
                           (elementSelected)="elementSelectFunction($event)"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Sperrgut' | translate }}"
                           field="IS_BULKY_GOOD"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)">
            <div slot="content" class="contentSlot np-others" style="width: 300px; height: 49px; padding-top: 12px; padding-left: 10px;">
              <np-booleanRadioGroup [(value)]="model.orderDetail.isBulkyGood"
                                    [templateView]="templateView"
                                    [fieldName]="validationService.fieldNames.isBulkyGood"
                                    [readOnly]="readOnlyForLinkedFields('IS_BULKY_GOOD') || readOnly('orderBulkyGoods')"
                                    (onValueChanged)="update($event, 'IS_BULKY_GOOD')"
                                    validatorValidationGroup="ORDER">
              </np-booleanRadioGroup>
            </div>

          </np-inputControl>
        </div>

        <div class="np-field">
          <np-inputControl [(model)]="model"
                           [(productTranslation)]="productTranslation"
                           [showLinkButtons]="showLinkButtons"
                           [showPlaceLabel]="model?.orderDetail?.alloySurcharge != null"
                           [(selectedElements)]="selectedElements"
                           (elementSelected)="elementSelectFunction($event)"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Legierungszuschlag (%)' | translate }}"
                           field="ALLOY_SURCHARGE"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)">

            <div slot="content" class="contentSlot">
              <dx-number-box [min]="0"
                             [max]="100"
                             class="validationBlue"
                             [showClearButton]="!readOnlyForLinkedFields('ALLOY_SURCHARGE')"
                             [(value)]="model.orderDetail.alloySurcharge"
                             [readOnly]="readOnlyForLinkedFields('ALLOY_SURCHARGE') || readOnly('orderLegierungszuschlag')"
                             (onValueChanged)="update($event, 'ALLOY_SURCHARGE')">
                <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                  <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('ALLOY_SURCHARGE')"></dxi-validation-rule>
                </dx-validator>
              </dx-number-box>
            </div>

          </np-inputControl>
        </div>

        <div *ngIf="hasAddonLEGALITY" class="np-field">
          <np-inputControl [(model)]="model"
                           [(productTranslation)]="productTranslation"
                           [showLinkButtons]="showLinkButtons"
                           [suppressPlaceLabel]="true"
                           [(selectedElements)]="selectedElements"
                           (elementSelected)="elementSelectFunction($event)"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Produkt mit Haltbarkeit' | translate }}"
                           field="IS_DURABILITY_PRODUCT"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)">
            <div slot="content" class="contentSlot np-others" style="width: 300px; height: 49px; padding-top: 12px; padding-left: 10px;">
              <np-booleanRadioGroup [(value)]="model.orderDetail.isDurabilityProduct"
                                    [templateView]="templateView"
                                    [fieldName]="validationService.fieldNames.isDurabilityProduct"
                                    [readOnly]="readOnlyForLinkedFields('IS_DURABILITY_PRODUCT') || readOnly('orderWithDurability')"
                                    (onValueChanged)="update($event, 'IS_DURABILITY_PRODUCT')"
                                    validatorValidationGroup="ORDER">
              </np-booleanRadioGroup>
            </div>

          </np-inputControl>
        </div>

      </div>

      <div class="flex-fill pl-3 w-50">

        <div class="np-field">
          <np-inputControl [(model)]="model"
                           [(productTranslation)]="productTranslation"
                           [showLinkButtons]="showLinkButtons"
                           [showPlaceLabel]="model?.orderDetail?.quantityInterval != null"
                           [(selectedElements)]="selectedElements"
                           (elementSelected)="elementSelectFunction($event)"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Bestellintervall' | translate }}"
                           field="QUANTITY_INTERVAL"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)">
            <div slot="content" class="contentSlot">
              <dx-autocomplete [minSearchLength]="0"
                               [dataSource]="one"
                               [readOnly]="readOnlyForLinkedFields('QUANTITY_INTERVAL') || readOnly('orderIntervall')"
                               [showClearButton]="!readOnlyForLinkedFields('QUANTITY_INTERVAL')"
                               [showDropDownButton]="!readOnlyForLinkedFields('QUANTITY_INTERVAL')"
                               [(value)]="model.orderDetail.quantityInterval"
                               (onValueChanged)="update($event, 'QUANTITY_INTERVAL')"
                               (onSelectionChanged)="updateFromAutocomplete($event, 'QUANTITY_INTERVAL')">
                <div slot="content" class="contentSlot">
                  <dx-number-box [(value)]="model.orderDetail.quantityInterval" [readOnly]="true"></dx-number-box>
                </div>
                <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                  <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('QUANTITY_INTERVAL')"></dxi-validation-rule>
                </dx-validator>
              </dx-autocomplete>
            </div>

          </np-inputControl>
        </div>

        <div class="np-field">
          <np-inputControl [(model)]="model"
                           [(productTranslation)]="productTranslation"
                           [showLinkButtons]="showLinkButtons"
                           [showPlaceLabel]="model?.orderDetail?.quantityMin != null"
                           [(selectedElements)]="selectedElements"
                           (elementSelected)="elementSelectFunction($event)"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Mindestbestellmenge' | translate }}"
                           field="QUANTITY_MIN"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)">

            <div slot="content" class="contentSlot">
              <dx-autocomplete [minSearchLength]="0"
                               [dataSource]="one"
                               [readOnly]="readOnlyForLinkedFields('QUANTITY_MIN') || readOnly('orderMinimum')"
                               [showClearButton]="!readOnlyForLinkedFields('QUANTITY_MIN')"
                               [showDropDownButton]="!readOnlyForLinkedFields('QUANTITY_MIN')"
                               [(value)]="model.orderDetail.quantityMin"
                               (onValueChanged)="update($event, 'QUANTITY_MIN')"
                               (onSelectionChanged)="updateFromAutocomplete($event, 'QUANTITY_MIN')">
                <div slot="content" class="contentSlot">
                  <dx-number-box [(value)]="model.orderDetail.quantityMin" [readOnly]="true"></dx-number-box>
                </div>
                <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                  <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('QUANTITY_MIN')"></dxi-validation-rule>
                </dx-validator>
              </dx-autocomplete>
            </div>

          </np-inputControl>
        </div>

        <div class="np-field">
          <np-inputControl [(model)]="model"
                           [(productTranslation)]="productTranslation"
                           [showLinkButtons]="showLinkButtons"
                           [showPlaceLabel]="model?.orderDetail?.quantityMax != null"
                           [(selectedElements)]="selectedElements"
                           (elementSelected)="elementSelectFunction($event)"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Maximalbestellmenge' | translate }}"
                           field="QUANTITY_MAX"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)">

            <div slot="content" class="contentSlot">
              <dx-autocomplete [minSearchLength]="0"
                               [dataSource]="zero"
                               [readOnly]="readOnlyForLinkedFields('QUANTITY_MAX') || readOnly('orderMaximum')"
                               [showClearButton]="!readOnlyForLinkedFields('QUANTITY_MAX')"
                               [showDropDownButton]="!readOnlyForLinkedFields('QUANTITY_MAX')"
                               [(value)]="model.orderDetail.quantityMax"
                               (onValueChanged)="update($event, 'QUANTITY_MAX')"
                               (onSelectionChanged)="updateFromAutocomplete($event, 'QUANTITY_MAX')">
                <div slot="content" class="contentSlot">
                  <dx-number-box [(value)]="model.orderDetail.quantityMax" [readOnly]="true"></dx-number-box>
                </div>
                <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                  <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('QUANTITY_MAX')"></dxi-validation-rule>
                </dx-validator>
              </dx-autocomplete>
            </div>

          </np-inputControl>
        </div>

        <div class="np-field">
          <np-inputControl [(model)]="model"
                           [(productTranslation)]="productTranslation"
                           [showLinkButtons]="showLinkButtons"
                           [showPlaceLabel]="model?.orderDetail?.deliveryTime?.length > 0"
                           [(selectedElements)]="selectedElements"
                           (elementSelected)="elementSelectFunction($event)"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Lieferzeit' | translate }}"
                           field="DELIVERY_TIME"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)">

            <div slot="content" class="contentSlot">
              <dx-autocomplete [minSearchLength]="0"
                               [dataSource]="zero"
                               [readOnly]="readOnlyForLinkedFields('DELIVERY_TIME') || readOnly('orderTranportTime')"
                               [showClearButton]="!readOnlyForLinkedFields('DELIVERY_TIME')"
                               [showDropDownButton]="!readOnlyForLinkedFields('DELIVERY_TIME')"
                               [(value)]="model.orderDetail.deliveryTime"
                               (onValueChanged)="update($event, 'DELIVERY_TIME')"
                               (onSelectionChanged)="updateFromAutocomplete($event, 'DELIVERY_TIME')">
                <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                  <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('DELIVERY_TIME')"></dxi-validation-rule>
                </dx-validator>
              </dx-autocomplete>
            </div>

          </np-inputControl>
        </div>

        <div class="np-field">
          <np-inputControl [(model)]="model"
                           [(productTranslation)]="productTranslation"
                           [showLinkButtons]="showLinkButtons"
                           [suppressPlaceLabel]="true"
                           [(selectedElements)]="selectedElements"
                           (elementSelected)="elementSelectFunction($event)"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Speditionsprodukt' | translate }}"
                           field="IS_TRUCKAGE_COMPANY_GOOD"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)">

            <div slot="content" class="contentSlot np-others" style="width: 300px; height: 49px; padding-top: 12px; padding-left: 10px;">
              <np-booleanRadioGroup [(value)]="model.orderDetail.isTruckageCompanyGood"
                                    [templateView]="templateView"
                                    [fieldName]="validationService.fieldNames.isTruckageCompanyGood"
                                    [readOnly]="readOnlyForLinkedFields('IS_TRUCKAGE_COMPANY_GOOD') || readOnly('orderSpeditionsprodukt')"
                                    (onValueChanged)="update($event, 'IS_TRUCKAGE_COMPANY_GOOD')"
                                    validatorValidationGroup="ORDER">
              </np-booleanRadioGroup>
            </div>

          </np-inputControl>
        </div>

        <div class="np-field">
          <np-inputControl [(model)]="model"
                           [(productTranslation)]="productTranslation"
                           [showLinkButtons]="showLinkButtons"
                           [showPlaceLabel]="model?.orderDetail?.costOfLivingAdjustment != null"
                           [(selectedElements)]="selectedElements"
                           (elementSelected)="elementSelectFunction($event)"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Teuerungszuschlag (%)' | translate }}"
                           field="COST_OF_LIVING_ADJUSTMENT"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)">

            <div slot="content" class="contentSlot">
              <dx-number-box [min]="0"
                             [max]="100"
                             [showClearButton]="!readOnlyForLinkedFields('COST_OF_LIVING_ADJUSTMENT')"
                             [readOnly]="readOnlyForLinkedFields('COST_OF_LIVING_ADJUSTMENT') || readOnly('orderTeuerungszuschlag')"
                             [(value)]="model.orderDetail.costOfLivingAdjustment"
                             (onValueChanged)="update($event, 'COST_OF_LIVING_ADJUSTMENT')"
                             class="validationBlue">
                <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                  <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('COST_OF_LIVING_ADJUSTMENT')"></dxi-validation-rule>
                </dx-validator>
              </dx-number-box>
            </div>

          </np-inputControl>
        </div>

        <div *ngIf="hasAddonLEGALITY && model.orderDetail.isDurabilityProduct == true" class="np-field">
          <np-inputControl [(model)]="model"
                           [(productTranslation)]="productTranslation"
                           [showLinkButtons]="showLinkButtons"
                           [showPlaceLabel]="model?.orderDetail?.durabilityDays != null"
                           [(selectedElements)]="selectedElements"
                           (elementSelected)="elementSelectFunction($event)"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'Haltbarkeit (Tage)' | translate }}"
                           field="DURABILITY_DAYS"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)">

            <div slot="content" class="contentSlot">
              <dx-number-box [min]="0"
                             [max]="100000"
                             [showClearButton]="!readOnlyForLinkedFields('DURABILITY_DAYS')"
                             [readOnly]="readOnlyForLinkedFields('DURABILITY_DAYS') || readOnly('orderDurabilityDays')"
                             [(value)]="model.orderDetail.durabilityDays"
                             (onValueChanged)="update($event, 'DURABILITY_DAYS')">
                <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                  <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('DURABILITY_DAYS')"></dxi-validation-rule>
                </dx-validator>
              </dx-number-box>
            </div>

          </np-inputControl>
        </div>

      </div>

    </div>

    <!--Basic Price-->
    <div *ngIf="hasAddonLEGALITY">

      <div class="dx-fieldset-header">{{ 'Grundpreisinformationen' | translate }}</div>
      <div class="dx-fieldset d-flex flex-row">

        <div class="flex-fill pr-3 w-50">

          <div class="np-field">
            <np-inputControl [(model)]="model"
                             [(productTranslation)]="productTranslation"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="model?.orderDetail?.contentQuantity?.length > 0"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'Inhaltsmenge' | translate }}"
                             field="CONTENT_QUANTITY"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="model.orderDetail.contentQuantity"
                             [showClearButton]="!readOnlyForLinkedFields('CONTENT_QUANTITY')"
                             [readOnly]="readOnlyForLinkedFields('CONTENT_QUANTITY') || readOnly('orderContentAmount')"
                             (onValueChanged)="update($event, 'CONTENT_QUANTITY')">
                  <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                    <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('CONTENT_QUANTITY')"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>

            </np-inputControl>
          </div>

          <div class="np-field">
            <np-inputControl [(model)]="model"
                             [(productTranslation)]="productTranslation"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="model?.orderDetail?.basicQuantity?.length > 0"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'Grundmenge' | translate }}"
                             field="BASIC_QUANTITY"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="model.orderDetail.basicQuantity"
                             [showClearButton]="!readOnlyForLinkedFields('BASIC_QUANTITY')"
                             [readOnly]="readOnlyForLinkedFields('BASIC_QUANTITY') || readOnly('orderBaseAmount')"
                             (onValueChanged)="update($event, 'BASIC_QUANTITY')">
                  <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                    <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('BASIC_QUANTITY')"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>

            </np-inputControl>
          </div>

        </div>

        <div class="flex-fill pl-3 w-50">

          <div class="np-field">
            <np-inputControl [(model)]="model"
                             [(productTranslation)]="productTranslation"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="model?.orderDetail?.contentPackage?.length > 0"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'Inhaltseinheit' | translate }}"
                             field="CONTENT_PACKAGE"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="model.orderDetail.contentPackage"
                             [showClearButton]="!readOnlyForLinkedFields('CONTENT_PACKAGE')"
                             [readOnly]="readOnlyForLinkedFields('CONTENT_PACKAGE') || readOnly('orderContentPackage')"
                             (onValueChanged)="update($event, 'CONTENT_PACKAGE')">
                  <dx-validator *ngIf="!templateView" validationGroup="ORDER">
                    <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('CONTENT_PACKAGE')"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>

            </np-inputControl>
          </div>

          <div class="np-field">
            <np-inputControl [(model)]="model"
                             [(productTranslation)]="productTranslation"
                             [showLinkButtons]="showLinkButtons"
                             [suppressPlaceLabel]="true"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'Grundpreispflicht' | translate }}"
                             field="HAS_BASEPRICE_DUTY"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot np-others" style="width: 300px; height: 49px; padding-top: 12px; padding-left: 10px;">
                <np-booleanRadioGroup [(value)]="model.orderDetail.hasBasicPriceDuty"
                                      [templateView]="templateView"
                                      [fieldName]="validationService.fieldNames.hasBasicPriceDuty"
                                      [readOnly]="readOnlyForLinkedFields('HAS_BASEPRICE_DUTY') || readOnly('orderBasePriceDuty')"
                                      (onValueChanged)="update($event, 'HAS_BASEPRICE_DUTY')"
                                      validatorValidationGroup="ORDER">
                </np-booleanRadioGroup>
              </div>

            </np-inputControl>
          </div>

        </div>

      </div>

    </div>

  </dx-validation-group>
</div>
