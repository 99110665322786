import { Component, ViewChild } from "@angular/core";
//import { Catalog } from "../../../Model/Catalog/Catalog";
import { ModelService } from "../../../../Services/model.service";
import { DxFileUploaderComponent } from "devextreme-angular";
import { TestResult } from "../../../../Model/Dto/TestResult";
import { Message } from "../../../../Model/System/Message";
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from "angular-oauth2-oidc";



@Component({
  selector: "importCategory",
  templateUrl: "importCategory.component.html"
})
export class ImportCategoryComponent {
  @ViewChild(DxFileUploaderComponent)
  dxfileuploader: DxFileUploaderComponent;

  //value: any[] = [];
  //model: Catalog[];
  fileTypes: string;
  uploadHeaders: any;
  pages: { id: number; title; template: string }[];
  txtFromLink: string;
  txtUpload: string;
  //productRanges: string[];
  //productRange: string;
  //keepRootCategory: boolean = false;
  constructor(public modelService: ModelService, public translate: TranslateService, private oAuthService: OAuthService) {

    this.testLink = this.testLink.bind(this);
    this.startDownload = this.startDownload.bind(this);
    this.onBeforeSendUpload = this.onBeforeSendUpload.bind(this);

    this.fileTypes = ".zip,.xlsx,.csv";//".xml,.zip,.xlsx,.csv";
    this.txtUpload = this.translate.instant("Upload");
    this.txtFromLink = this.translate.instant("Aus Link");
    this.pages = [
      { id: 1, title: this.txtUpload, template: "uploadTemplate" },
      { id: 2, title: this.txtFromLink, template: "linkTemplate" },
    ];

    if (this.modelService.catalogService.catalog) {
      this.uploadHeaders = {
        CatalogId: this.modelService.catalogService.selectedCatalogId,
        CustomerId: this.modelService.loginService.currentUser.customerId,
      }
    }
  }

  onBeforeSendUpload(e: { request: XMLHttpRequest }) {
    e.request.withCredentials = true;
    e.request.setRequestHeader('Authorization', 'Bearer ' + this.oAuthService.getAccessToken());
  }

  onUploaded() {
    //this.modelService.catalogService.jobStarted = 1;  // brauchen wir nicht
    this.dxfileuploader.instance.reset();
    if (this.modelService.categoryService.categoryImportVisible) {
      this.translate.get("Kategorien hochgeladen")
        .subscribe((text: string) => {
          this.modelService.systemService.notify(new Message(text));
        });
    }
  }

  fileUrl: string = "";
  fileUrlTest: boolean = false;
  fileUrlTestMessage: string = "";

  testLink() {
    this.fileUrlTest = false;
    var tmp = this.fileUrl.split("?", 1);
    var link = tmp[0];
    link = link.replace("www.dropbox", "dl.dropbox");
    this.fileUrl = link;

    //if (!(this.fileUrl.substr(-4, 4) == ".zip" || this.fileUrl.substr(-4, 4) == ".xml" || this.fileUrl.substr(-5, 5) == ".xlsx" || this.fileUrl.substr(-4, 4) == ".csv")) {
    if (!(this.fileUrl.substr(-4, 4) == ".zip" || this.fileUrl.substr(-5, 5) == ".xlsx" || this.fileUrl.substr(-4, 4) == ".csv")) {
      //this.translate.get("Bitte Link zu einer .xml .zip .xlsx oder .csv Datei angeben")
      this.fileUrlTestMessage = this.translate.instant("Bitte Link zu einer .zip .xlsx oder .csv Datei angeben");
      return;
    }

    this.modelService.systemService.testUrl(this.fileUrl)
      .subscribe((result: TestResult) => {
        this.testLinkResult(result)
      });
  }

  linkChanged(event = null) {
    this.fileUrlTest = false;
    this.fileUrlTestMessage = "";
  }

  testLinkResult(result: TestResult) {
    this.fileUrlTest = result.success;
    this.fileUrlTestMessage = result.message;
  }

  startDownload() {
    this.modelService.categoryService.categoryImportVisible = false;
    this.modelService.systemService.categoryUrlImport(this.fileUrl, this.modelService.catalogService.selectedCatalogId, this.modelService.loginService.currentCustomer.id)
      .subscribe((result: TestResult) => {
        this.downloadStarted(result);
      });
  }

  downloadStarted(result: TestResult) {
    if (result.success) {
      this.fileUrl = "";
      this.fileUrlTest = false;
      this.fileUrlTestMessage = "";

      this.translate.get("Import als Job gestartet")
        .subscribe((text: string) => {
          this.modelService.systemService.notify(new Message(text));
        });

      //this.modelService.catalogService.jobStarted = 1;  // brauchen wir nicht
    } else {
      this.translate.get("Unbekannter Fehler")
        .subscribe((text: string) => {
          this.modelService.systemService.notify(new Message(text, "error"));
        });
    }
  }

  c: boolean[] = [false, false];

  uct(id) {
    if (this.modelService.loginService.showToolTips) {
      this.c[id] = !this.c[id];
    }
  }

  get showToolTips(): boolean {
    return this.modelService.loginService.showToolTips;
  }

  get showProToolTips(): boolean {
    return this.modelService.loginService.showProToolTips;
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }
}
