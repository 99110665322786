import { Component, OnInit } from "@angular/core";
import CustomStore from "devextreme/data/custom_store";
import { lastValueFrom } from "rxjs";
import { FtpServer } from "../../Model/FtpServer";
import { FtpSupplierPidMappingsJobSettings } from "../../Model/recurringJobs/ftp/ftpSupplierPidMappingsJobSettings";
import { Message } from "../../Model/System/Message";
import { FtpServerService } from "../../Services/ftp/ftpServer.service";
import { FtpSupplierPidMappingsImportJobsService } from "../../Services/ftp/ftpSupplierPidMappingsImportJobs.service";
import { LoginService } from "../../Services/login.service";
import { SystemService } from "../../Services/system.service";

@Component({
  selector: "FtpSupplierPidMappingsImport",
  templateUrl: "ftpSupplierPidMappingsImport.component.html"
})
export class FtpSupplierPidMappingsConponent implements OnInit {
  dataSource: any = {};
  ftpServers: FtpServer[] = [];

  constructor(public ftpServerService: FtpServerService, public ftpSupplierPidMappingsImportJobsService: FtpSupplierPidMappingsImportJobsService,
    public loginService: LoginService, public systemService: SystemService) {
    this.dataSource.store = new CustomStore({
      key: "id",
      load: function (loadOptions: any) {
        return ftpSupplierPidMappingsImportJobsService.getAll(loginService.currentCustomer.id).toPromise()
          .then((data: FtpSupplierPidMappingsJobSettings[]) => {
            data.forEach((f: FtpSupplierPidMappingsJobSettings) => {
              f.time = new Date(f.time);
            });
            return data;
          });
      },
      insert: function (value) {
        value.id = 0;
        value.customerId = loginService.currentCustomer.id;
        return ftpSupplierPidMappingsImportJobsService.update(value, loginService.currentCustomer.id).toPromise();
      },
      update: function (key, values) {
        return ftpSupplierPidMappingsImportJobsService.get(key).toPromise()
          .then((data: FtpSupplierPidMappingsJobSettings) => {
            const updatedData: FtpSupplierPidMappingsJobSettings = {
              ...data,
              ...values,
            };

            return ftpSupplierPidMappingsImportJobsService.update(updatedData, loginService.currentCustomer.id).toPromise()
              .then((data: FtpSupplierPidMappingsJobSettings) => {
                return data;
              });
          });
      },
      remove: function (key) {
        return lastValueFrom(ftpSupplierPidMappingsImportJobsService.delete(key)).then((success) => {
          return;
        });
      }
    });
  }

  async ngOnInit() {
    this.ftpServers = await lastValueFrom(this.ftpServerService.getAllServer());
  }


  isManager(): boolean {
    if (this.loginService.currentUser.currentClaim == "MANAGER" || this.loginService.currentUser.currentClaim == "ADMIN")
      return true;
    return false;
  }

  getTime(rowData): string {
    if (rowData.time instanceof String)
      rowData.time = new Date(rowData.time);

    return rowData.time;
  }

  getLastRun(rowData): string {
    if (rowData.lastRun instanceof String)
      rowData.lastRun = new Date(rowData.lastRun);

    return rowData.lastRun;
  }

  run(d) {
    this.ftpSupplierPidMappingsImportJobsService.run(d.data).subscribe(() => {
      this.systemService.notify(new Message("Job wurde manuell gestartet, er entfernt sich automatisch, sollte er keine Datei importieren."));
    }, error => {
      let msg = new Message();
      msg.type = "Error";
      msg.message = "Fehler beim manuellen starten";
      this.systemService.notify(msg);
    });
  }
}
