<fullscreen-page>
  <navigation-column class="align-self-stretch {{modelService.systemService.showMenu ? '' : 'd-none'}}" style="flex: 1 1;">
    <div class="h-100 d-flex flex-column">

        <div class="px-6 pt-6 align-self-end" style="padding:6px;">

          <a (click)="toggleFilter()"><span class='glyphicon glyphicon-filter' style="font-size:20px;"></span></a>
   
        </div>
    
      <div class="px-3 pb-2" *ngIf="showFilter">
        <productStateOptions
          [stateValue]="selectedStates"
          (onValueChanged)="updateState($event)"
        ></productStateOptions>
        <productSearchOptions></productSearchOptions>
      </div>

      <div class="px-2 flex-fill overflow-hidden">
        <dx-tree-list id="treelist" class="tree"
                      [dataSource]="dataSource"
                      [showBorders]="false"
                      [showColumnHeaders]="false"
                      [showColumnLines]="false"
                      [remoteOperations]="{ filtering: true }"
                      keyExpr="treeElementId"
                      parentIdExpr="parent"
                      hasItemsExpr="hasChilds"
                      rootValue="0"
                      (onSelectionChanged)="onSelectItem($event)"
                      [(selectedRowKeys)]="treeSelectedRowKeys"
                      (onCellPrepared)="onCellPrepared($event)"
                      (onInitialized)="saveGridInstance($event)"
                      (onContentReady)="onContentReady($event)">

          <dxo-search-panel *ngIf="!massDataChange" [visible]="true" placeholder="{{ 'Produkte suchen' | translate }}" width="auto" [(text)]="searchText"></dxo-search-panel>

          <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>
          <dxo-selection [mode]="treeListMode()" [recursive]="true"></dxo-selection>
          <dxi-column dataField="Title" cellTemplate="cellTemplate"></dxi-column>
          <dxi-column dataField="order" [visible]=false sortOrder="asc"></dxi-column>
          <div *dxTemplate="let itemObj of 'cellTemplate'">
            <div class="treeitem" id="image{{itemObj.data.treeElementId}}">
              <div class="container-fluid h-100">
                <div class="row h-100 d-flex">
                  <div class="col order-0 treeitemimage">
                    <span *ngIf="!itemObj.data.imageUrl" class="glyphicon glyphicon-picture" style="font-size: 36px;"></span>
                    <img *ngIf="itemObj.data.imageUrl" src="{{itemObj.data.imageUrl}}" style="height: 40px;" />
                  </div>
                  <div *ngIf="itemObj.data.type === 'cat'" class="col order-2 treeitemlanguage">
                    <dx-select-box *ngIf="showTranslateBox()"
                                   [dataSource]="languageFlags"
                                   displayExpr="language"
                                   valueExpr="code"
                                   [(value)]="selectedTranslateLanguage"
                                   fieldTemplate="field" style="height: 27px; width: 45px; top: 6px;">
                      <div *dxTemplate="let data of 'item'">
                        <div class="custom-item" style="margin-left: -10px;">
                          <img src="images/flags/{{data?.flag}}" class="flag" />
                        </div>
                      </div>
                      <div *dxTemplate="let data of 'field'">
                        <div class="custom-item">
                          <img src="images/flags/{{data?.flag}}" class="flag" />
                          <dx-text-box [value]="data?.text" [readOnly]="true" style="display: none"></dx-text-box>
                        </div>
                      </div>
                    </dx-select-box>
                  </div>
                  <div class="col order-1 overflow-hidden treeitemtext">
                    <div class="treeitemheader">
                      {{itemObj.data.text}}
                    </div>
                    <div class="treeitemdetail">
                      <div [ngSwitch]="itemObj.data.type">
                        <!--CATALOG-->
                        <div *ngSwitchCase="'cat'">
                          {{ 'Kategorien' | translate }}: {{itemObj.data.categoryCount}} - {{ 'Produkte' | translate }}: {{itemObj.data.productCount}} ({{ 'eindeutige' | translate }}: {{itemObj.data.uniqueProductCount}})
                        </div>

                        <!--CATEGORY-->
                        <div *ngSwitchCase="'grp'">
                          {{ 'Kategorien' | translate }}: {{itemObj.data.categoryCount}} - {{ 'Produkte' | translate }}: {{itemObj.data.productCount}}
                        </div>
                        <!--PRODUCT-->
                        <div *ngSwitchCase="'pro'">
                          {{itemObj.data.supplierPid}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <dx-context-menu [dataSource]="menuItems"
                             [width]="200"
                             (onShowing)="onContextMenuShowing($event,itemObj)"
                             target="#image{{itemObj.data.treeElementId}}"
                             id="context{{itemObj.data.treeElementId}}"
                             (onItemClick)="itemClick($event,itemObj)">
              <div *dxTemplate="let e of 'item'">
                <div>
                  <span [ngClass]="e.icon"></span>
                  <span *ngIf="e.items" class="dx-icon-spinright"></span> {{e.text}}
                </div>
              </div>
            </dx-context-menu>
          </div>
        </dx-tree-list>
      </div>
    </div>
  </navigation-column>
  <content-column class="align-self-stretch {{modelService.systemService.showHelp && modelService.systemService.showMenu ? 'w-50' : modelService.systemService.showHelp || modelService.systemService.showMenu ? 'w-75' : 'w-100'}}" look="light" navigationSwitch="true" helpSwitch="true">
    <logo slot="toolbar" [routerLink]="['/catalogs']" look="{{modelService.systemService.showMenu ? 'hidden' : 'light'}}"></logo>
    <dx-scroll-view slot="content" showScrollbar="always">
      <catalogDetail *ngIf="isCatalogSelected && !massDataChange && !massGenerateKITexts" (onMassDataEdit)="setEmptyProduct($event)"></catalogDetail>
      <categoryDetail *ngIf="isCategorySelected && !massDataChange && !massGenerateKITexts" (onMassDataEdit)="setEmptyProduct($event)"></categoryDetail>
      <productDetail *ngIf="isProductSelected && !massDataChange && !massGenerateKITexts" (onMassDataEdit)="setEmptyProduct($event)"></productDetail>
      <massDataChange *ngIf="massDataChange" [treeList]="treeList" [emptyProduct]="emptyProduct" (cancel)="massCancel($event)"></massDataChange>
      <massGenerateKIText *ngIf="massGenerateKITexts" [treeList]="treeList" (cancel)="startMassGenerateKITexts()"></massGenerateKIText>
    </dx-scroll-view>
  </content-column>
  <help-column class="align-self-stretch {{modelService.systemService.showHelp ? '' : 'd-none'}}" style="flex: 1 1;"></help-column>
</fullscreen-page>

<dx-popup title="{{ 'Makros' | translate }}"
          [width]="1200"
          [height]="800"
          [dragEnabled]="false"
          [showTitle]="true"
          [showCloseButton]="true"
          [hideOnOutsideClick]="true"
          [(visible)]="showUserAction"
          [(fullScreen)]="fullScreen"
          (onHiding)="hideUserAction()"
          contentTemplate="popupContent">
  <dxi-toolbar-item *ngIf="fullScreen" toolbar="top" location="after" widget="dxButton" [options]="{ icon: 'chevrondown', onClick: userActionWindow }"></dxi-toolbar-item>
  <dxi-toolbar-item *ngIf="!fullScreen" toolbar="top" location="after" widget="dxButton" [options]="{ icon: 'chevronup', onClick: userActionFullScreen }"></dxi-toolbar-item>
  <dxi-toolbar-item toolbar="top" location="after" widget="dxDropDownButton">
    <np-help-button>
      <np-help-option text="Wiki" icon="images/Wiki.svg" link="https://knowledge.nextpim.de/makros?utm_source=app.nextpim.de&utm_medium=affiliate&utm_campaign=wikilink"></np-help-option>
      <np-help-option text="YouTube" icon="images/YouTube.svg" link="https://www.youtube.com/watch?v=gHtxkwiks9k"></np-help-option>
    </np-help-button>
  </dxi-toolbar-item>


  <div *dxTemplate="let data of 'popupContent'">
    <userAction></userAction>
  </div>
</dx-popup>
