<dx-data-grid #jobGrid
              id="jobGridContainer"
              [ngStyle]="{'max-height': jobListMode ? '670px' : {}}"
              [filterValue]="filterValue"
              [dataSource]="dataSource"
              [remoteOperations]="{ groupPaging: true }"
              [allowColumnReordering]="true"
              [repaintChangesOnly]="true"
              [rowAlternationEnabled]="true"
              [allowColumnResizing]="true"
              columnResizingMode="nextColumn"
              noDataText="{{ 'keine Jobs' | translate }}"
              (onToolbarPreparing)="onGridToolbarPreparing($event)"
              [showBorders]="true"
              (onContentReady)="startRefresher()">

  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping #expand [autoExpandAll]="true" expandMode="rowClick"></dxo-grouping>

  <dxo-state-storing [enabled]="true" type="localStorage" [storageKey]="storeName"></dxo-state-storing>
  <dxo-scrolling columnRenderingMode="virtual"
                 [useNative]="false"
                 showScrollbar="always">
  </dxo-scrolling>
  <dxo-selection *ngIf="adminMode==false"
                 selectAllMode="allPages"
                 showCheckBoxesMode="always"
                 mode="multiple">
  </dxo-selection>

  <dxo-filter-builder></dxo-filter-builder>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true"></dxo-header-filter>

  <dxo-paging [pageSize]="20"> </dxo-paging>
  <dxo-pager [visible]="true"
             [displayMode]="'compact'"
             [showInfo]="true"
             [showNavigationButtons]="true">
  </dxo-pager>

  <div *dxTemplate="let toolbaritem of 'filterUser'">
    <dx-button stylingMode="text" type="default" icon="user" (onClick)="applyFilterUser()" hint="{{ 'Nur eigene zeigen' | translate }}"></dx-button>
  </div>
  <div *dxTemplate="let toolbaritem of 'filterPinned'">
    <dx-button stylingMode="text" type="default" icon="pin" (onClick)="applyFilterPinned()" hint="{{ 'Nur angeheftete zeigen' | translate }}"></dx-button>
  </div>
  <div *dxTemplate="let toolbaritem of 'filterReset'">
    <dx-button stylingMode="text" type="default" icon="undo" (onClick)="applyFilterReset()" hint="{{ 'Filter zurücksetzen' | translate }}"></dx-button>
  </div>



  <div *dxTemplate="let toolbaritem of 'minGroupsButtonTemplate'">
    <dx-button stylingMode="text" (onClick)="onCollapseAll()" type="default" icon="chevronright" hint="{{ 'Alle Gruppen schliessen' | translate }}"></dx-button>
  </div>
  <div *dxTemplate="let toolbaritem of 'maxGroupsButtonTemplate'">
    <dx-button stylingMode="text" (onClick)="onExpandAll()" type="default" icon="chevrondown" hint="{{ 'Alle Gruppen öffnen' | translate }}"></dx-button>
  </div>



  <div *dxTemplate="let toolbaritem of 'deleteButtonTemplate'">
    <div *ngIf="catalogMode==false && adminMode==false && jobGrid?.instance?.getSelectedRowKeys().length == 0">
      <!-- man löscht dort sonst JOBs, die man dort gar nicht sieht!! -->
      <dx-button stylingMode="text" type="default" icon="trash" (click)="deleteReadyJobs()" hint="{{ 'Beendete Jobs löschen' | translate }}"></dx-button>
    </div>

    <div *ngIf="adminMode==false && jobGrid?.instance?.getSelectedRowKeys().length > 0">
      <!-- man löscht dort sonst JOBs, die man dort gar nicht sieht!! -->
      <dx-button stylingMode="text" type="default" icon="trash" (click)="deleteSelectedJobs(jobGrid?.instance?.getSelectedRowsData())" hint="{{ 'Gewählte Jobs löschen' | translate }}"></dx-button>
    </div>
  </div>

  <div *dxTemplate="let toolbaritem of 'dateFilterTemplate'">
    <div *ngIf="adminMode">
      <dx-select-box [dataSource]="dateFilterDataSource" displayExpr="text" valueExpr="dayDelta"
                     [(value)]="dateFilterValue" (onValueChanged)="onDateFilterValueChanged()">
      </dx-select-box>
    </div>
  </div>

  <div *dxTemplate="let toolbaritem of 'maintenanceModeButtonTemplate'">
    <!-- wenn der grade AUS ist -->
    <div *ngIf="isAdmin==true && maintenanceModeActive==false">
      <!-- nur im admin bereich mit funktion, sonst nur INFO-->
      <dx-button [disabled]="adminMode==false" stylingMode="text" type="success" icon="warning" (click)="enableMaintenanceMode()" hint="Wartung beginnen"></dx-button>
    </div>

    <!-- wenn der grade AN ist -->
    <div *ngIf="isAdmin==true && maintenanceModeActive==true">
      <!-- nur im admin bereich mit funktion, sonst nur INFO-->
      <dx-button [disabled]="adminMode==false" stylingMode="text" type="danger" icon="warning" (click)="disableMaintenanceMode()" hint="Wartung beenden"></dx-button>
    </div>
  </div>

  <div *dxTemplate="let toolbaritem of 'helpButtonTemplate'">
    <np-help-button>
      <np-help-option text="YouTube" icon="images/YouTube.svg" link="https://www.youtube.com/watch?v=sp9idXSLAOg"></np-help-option>
    </np-help-button>
  </div>

  <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true" placeholder="{{ 'Suchen' | translate }}"></dxo-search-panel>

  <dxi-column dataField="name" caption="{{ 'Name' | translate }}" dataType="string"></dxi-column>
  <dxi-column dataField="catalogName" caption="{{ 'Katalogname' | translate }}" dataType="string"></dxi-column>
  <dxi-column dataField="created" caption="{{ 'Erstellt' | translate }}" format="HH:mm - d.MMM" dataType="date" width="140"
              calculateGroupValue="createdGroupValue">
  </dxi-column>
  <dxi-column dataField="userName" caption="{{ 'Erstellt von' | translate }}" dataType="string"></dxi-column>
  <dxi-column *ngIf="catalogMode || adminMode" dataField="isDeleted" caption="{{ 'Gelöscht' | translate }}" dataType="boolean"></dxi-column>
  <dxi-column *ngIf="adminMode" dataField="customerName" caption="{{ 'Kunde' | translate }}" dataType="string"></dxi-column>
  <dxi-column dataField="isPinned" caption="{{ 'Angeheftet' | translate }}" dataType="boolean" cellTemplate="cellTemplatePin"></dxi-column>
  <div *dxTemplate="let data of 'cellTemplatePin'">
    <dx-button *ngIf="data.data.isPinned==false" type="default" stylingMode="text" icon="pin" (onClick)="showPindata(data.data)" hint="{{ 'Anheften' | translate }}"></dx-button>
    <dx-button *ngIf="data.data.isPinned==true" type="default" stylingMode="text" icon="pin" (onClick)="showPindata(data.data)" style="transform: rotate(90deg);" hint="{{ 'Angeheftet' | translate }}"></dx-button>
  </div>

  <dxi-column dataField="progress" caption="{{ 'Fortschritt' | translate }}" [allowFiltering]="false" width="100" [allowSorting]="false" cellTemplate="cellTemplateProgress" dataType="number"></dxi-column>
  <div *dxTemplate="let data of 'cellTemplateProgress'">
    <dx-progress-bar #progressBar
                     width="90%"
                     [class.complete]="progressBar.value == 100"
                     [min]="0"
                     [max]="100"
                     [statusFormat]="format"
                     [showStatus]="false"
                     [value]="data.value">
    </dx-progress-bar>
  </div>
  <dxi-column dataField="stateText" caption="{{ 'Status' | translate }}" width="100" cellTemplate="cellTemplateState" dataType="string"></dxi-column>
  <div *dxTemplate="let data of 'cellTemplateState'">
    <div *ngIf="data.value!='TemplateNeeded'">{{data.value}}</div>
    <div *ngIf="data.value=='TemplateNeeded'">
      {{ 'Template erforderlich' | translate }}
    </div>
  </div>
  <dxi-column dataField="resultFile" caption="{{ 'Ergebnis' | translate }}" [allowFiltering]="false" width="85" [allowSorting]="false" cellTemplate="cellTemplateResultFile" dataType="string"></dxi-column>
  <div *dxTemplate="let data of 'cellTemplateResultFile'">
    <dx-button *ngIf="data.value" type="default" stylingMode="text" icon="download" (onClick)="startDownload(data.value)" hint="{{ 'Dateidownload' | translate }}"></dx-button>
    <dx-button *ngIf="data.value" type="default" stylingMode="text" icon="message" (onClick)="sendMail(data.value)" hint="{{ 'Per Email verschicken' | translate }}"></dx-button>
    <dx-button *ngIf="data.data.stateText=='Error' && !(catalogMode && data.data.isDeleted)" type="default" stylingMode="text" icon="video" (onClick)="restartJob(data.data)" hint="{{ 'Job neu starten' | translate }}"></dx-button>
    <dx-button *ngIf="data.data.stateText=='TemplateNeeded' && !(catalogMode && data.data.isDeleted)" type="default" stylingMode="text" icon="detailslayout" (onClick)="showImportTemplate(data)" hint="{{ 'Template erstellen' | translate }}"></dx-button>
    <dx-button *ngIf="userCanEdit" type="default" stylingMode="text" (onClick)="onDeleteClicked(data.data)" icon="trash" hint="{{ 'Job löschen' | translate }}"></dx-button>
    <dx-button *ngIf="adminMode" id="redTrash" type="default" stylingMode="text" (onClick)="onAdminDeleteClicked(data.data)" icon="trash" hint="{{ 'Job löschen' | translate }}"></dx-button>
  </div>
  <dxi-column dataField="typeText" caption="{{ 'Typ' | translate }}" [groupIndex]="0" dataType="string" [customizeText]="translateType"></dxi-column>


  <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>

  <div *dxTemplate="let job of 'detail'">
    <jobDetail [(job)]="job.data" [isVisible]="isVisible()" [jobListInit]="jobListInit" [jobListDestroy]="jobListDestroy" [(dataGrid)]="dataGrid" (onInitialized)="onDetailInitialized($event)"></jobDetail>
  </div>

</dx-data-grid>



<dx-popup class="popup"
          [width]="600"
          [height]="400"
          [showTitle]="true"
          title="{{ 'Anheften' | translate }}"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [(visible)]="pindataVisible"
          contentTemplate="popupContent">
  <div *dxTemplate="let data of 'popupContent'">
    <pindata [pinned]="pinJobIsPinned"
             [remarks]="pinJobRemarks"
             [pinExpires]="pinJobPinExpires"
             (onAdded)="pinJob($event)"
             (onRemoved)="unpinJob($event)">
    </pindata>
  </div>
</dx-popup>
