import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { lastValueFrom, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TestResult } from "../Model/Dto/TestResult";
import { TranslateService } from '@ngx-translate/core';
import { OxomiSupplier } from "../Model/Dto/Oxomi/OxomiSupplier";


@Injectable()
export class OxomiSortimentService {

  constructor(private http: HttpClient, public translate: TranslateService) {}

  getOxomiSupplier(customerId: string): Promise<OxomiSupplier[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    return lastValueFrom(this.http.get<OxomiSupplier[]>("api/OxomiSortiment/GetAllSupplier", options));
  }

  createSupplier(newSupplier: OxomiSupplier, customerId: string): Promise<OxomiSupplier> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    
    var result = this.http.post<OxomiSupplier>("api/OxomiSortiment/CreateSupplier", newSupplier, options);
    return lastValueFrom(result);
  }

  deleteSupplier(supplierToDelete: OxomiSupplier, customerId: string): Promise<boolean> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    
    var result = this.http.post<boolean>("api/OxomiSortiment/DeleteSupplier", supplierToDelete, options);
    return lastValueFrom(result);
  }

  updateSupplier(supplierToUpdate: OxomiSupplier, customerId: string): Promise<boolean> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    
    var result = this.http.post<boolean>("api/OxomiSortiment/UpdateSupplier", supplierToUpdate, options);
    return lastValueFrom(result);
  }
}