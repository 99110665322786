import { Functions } from '../../Services/functions';

export class ProductPriceDetail {

  constructor(
    public order?: number,
    public priceFactor?: number,
    public priceAmount?: number,
    public priceCurrency?: string,
    public priceType?: string,
    public tax?: number,
    public taxCategory?: string,
    public territory?: string,
    public lowerBound?: number,
    public guid: string = Functions.newGuid()
  ) {
    this.priceCurrency = "EUR";
    this.priceFactor = 1;
    this.tax = 0.19;
    this.territory = "DE";
    this.lowerBound = 1;
  }

  public clearPrice() {
    this.priceCurrency = "";
    this.priceFactor = 0;
    this.tax = 0;
    this.territory = "";
    this.lowerBound = 0;
  }
}
