import { Component } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { UserActionByListComponent } from '../userAction-byList/userAction-byList.component';
import { ExportFilterService } from "../../../../Services/exportFilter.service";
import DataSource from "devextreme/data/data_source";


@Component({
  selector: "userAction-deleteByList",
  templateUrl: "userAction-deleteByList.component.html"
})
export class UserActionDeleteByListComponent extends UserActionByListComponent {
  searchValues = ["SupplierPID", "InternationalPid", "Hersteller", "SupplierAltPID"];
  searchValue = "SupplierPID";

  loadingVisible = false;

  manufacturerFilters = [];
  selectedManufacturer = [];

  manufacturerDataSource: DataSource;

  constructor(public modelService: ModelService, public exportFilterService: ExportFilterService) {
    super(modelService);

    this.geNoDataText = this.geNoDataText.bind(this);
    this.loadingVisible = true;
    this.exportFilterService.getManufacturerFilters(this.modelService.catalogService.selectedCatalogId, this.modelService.loginService.currentUser.customerId)
      .toPromise().then((filter: string[]) => {
        this.manufacturerFilters = filter.filter(x => x != null);
        this.manufacturerDataSource = new DataSource({
          store: this.manufacturerFilters,
          paginate: true,
          pageSize: 10
        });
      }).finally(() => {
        this.loadingVisible = false;
      });



  }


  handlePropertyChange(event) {
    this.searchValue = event.item.value;
  }

  geNoDataText() {
    if (this.loadingVisible) {
      return "Daten werden geladen";
    } else {
      return "keine Daten gefunden";

    }
  }


  startMacro() {
    this.inputList = this.formatListIgnoreSpace(this.inputList);

    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;

    startAction.actionType = `DeleteByList`;
    let values = this.inputList.split("\n");
    if (this.searchValue == "Hersteller")
      values = this.selectedManufacturer;

    startAction.actionParameter = JSON.stringify({ SearchValue: this.searchValue, Values: values })

    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        this.inputList = "";
        this.userActionStarted.emit(next);
      });
  }
}
