import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FeatureValue } from "../../Model/classification/FeatureValue";
import { ModelService } from "../../Services/model.service";
import { Feature } from '../../Model/Catalog/FeatureModels/Feature';
import { UserManagementService } from "../../Services/userManagment.service";
import DataSource from "devextreme/data/data_source";
import { ValidFeatureService } from "../../Services/validFeature.service";
import { LoginService } from "../../Services/login.service";
import { ValidationService } from "../../Services/Validation/validation.service";
import { FeatureSystem } from "../../Model/Catalog/FeatureModels/FeatureSystem";
import { ValueChangedEvent } from "devextreme/ui/select_box";
@Component({
  selector: "featurevalue",
  templateUrl: "featurevalue.component.html"
})
export class FeatureValueComponent {
  @Input() displayMode: "edit" | "import" | "export";
  @Input() featureSystem: FeatureSystem = null;
  @Input() feature: Feature = null;
  @Input() templateView: boolean = false;
  @Input() hasAddonCLASSIFICATION = false;
  @Input() modelService: ModelService;

  @Output() onValueChanged = new EventEmitter<any>();

  public expandButton: any;
  public listButton: any;

  public expandPopupVisible: boolean = false;
  private featureValues: FeatureValue[];
  popupFeatureValues: FeatureValue[];

  _readOnly: boolean;

  @Input()
  set readOnly(v: boolean) {
    this._readOnly = v;

  };
  get readOnly(): boolean {
    return this._readOnly;
  }



  constructor(public translate: TranslateService, public userManagement: UserManagementService, public validFeatureService: ValidFeatureService, public loginService: LoginService, public validationService: ValidationService) {

    this.expandButtonClicked = this.expandButtonClicked.bind(this);
    this.onSystemToolbarPreparing = this.onSystemToolbarPreparing.bind(this);
    this.addFeatureValueClicked = this.addFeatureValueClicked.bind(this);

    this.listButton = {
      icon: "orderedlist",
      stylingMode: "text",
      onClick: this.expandButtonClicked
    };

    this.expandButton = {
      icon: "expand",
      stylingMode: "text",
      onClick: this.expandButtonClicked
    };
  }


  showList(): boolean {

    if (this.feature.featureValues.length > 1)
      return true;
    return false;

  }

  expandButtonClicked() {
    this.popupFeatureValues = Object.assign([], this.feature.featureValues);
    this.expandPopupVisible = true;
  }

  onPopupHidden() {
    this.feature.featureValues = Object.assign([], this.popupFeatureValues);
    if (this.feature.featureValues.length > 0) {
      this.feature.value = this.feature.featureValues[0].value;
    }
    this.onValueChanged.emit();
  }

  get featuresEditable(): boolean {
    return (this.displayMode === 'edit' || this.displayMode === "import");
  }

  isClassification() {
    if (this.feature.ftIdref) {
      return true;
    }
    return false;
  }

  update(event = null) {
    this.onValueChanged.emit(event);
  }

  featureValueDataSource: DataSource;

  requestFeatureValues(feature) {

    this.featureValueDataSource = new DataSource({
      store: this.featureValues,
      paginate: true,
      pageSize: 10
    });



    if (feature.valueSource) {
      this.modelService.classificationService.getFeatureValues(feature.name, feature.valueSource).subscribe(result => {
        this.featureValues = result.filter(x => x.value != "");
        this.featureValueDataSource = new DataSource({
          store: this.featureValues,
          paginate: true,
          pageSize: 10
        });


      });
    } else {
      this.validFeatureService.getValues(this.feature.validFeatureId, this.loginService.currentCustomer.id).subscribe(result => {
        this.featureValues = new Array<FeatureValue>();
        result.filter(x => x.value != "").forEach(item => {
          let featureValue = new FeatureValue();
          featureValue.value = item.value;
          featureValue.validFeatureValueId = item.id;
          this.featureValues.push(featureValue);
        });

        this.featureValueDataSource = new DataSource({
          store: this.featureValues,
          paginate: true,
          pageSize: 10
        });


      });
    }

  }

  valueListText(feature: Feature): string {
    let result = "";
    feature.featureValues.forEach(item => {

      if (result != "") {
        result = result + ", ";
      }
      result = result + item.value;
    });
    return result;
  }


  customizeColumns(columns) {
    columns.forEach(column => {
      column.showEditorAlways = true;
    });
  }

  updateFeatureValue(event) {

    //value got deleted
    if (!event.value) {
      if (this.feature?.featureValues)
        this.feature.featureValues = this.feature.featureValues.filter(x => x.value != event.previousValue);
    }
    else if (this.featureValues) {
      const featureValue = this.featureValues.find(x => x.value === this.feature.value || x.valueId == this.feature.value);
      if (featureValue) {
        this.feature.value = featureValue.value;

        if (this.feature.featureValues == undefined || this.feature.featureValues.length == 0) {
          this.feature.featureValues = [];
          this.feature.featureValues.push(featureValue);
        }
        else
          this.feature.featureValues[0].validFeatureValueId = featureValue.validFeatureValueId;

        if (featureValue.valueId && featureValue.valueId != "") {
          this.feature.value = featureValue.valueId;
          this.feature.featureValues[0].valueId = featureValue.value;
          this.feature.featureValues[0].value = featureValue.valueId;
          this.feature.fValueDetails = featureValue.value;
        } else {
          this.feature.featureValues[0].value = featureValue.value;
        }
      }
    }
    this.update(event);
  }

  updateFeatureValueFromList(event, dataRow: FeatureValue) {
    if (this.featureValues) {
      const featureValue = this.featureValues.find(x => x.value === dataRow.value);
      if (featureValue) {
        dataRow.validFeatureValueId = featureValue.validFeatureValueId;
      }
    }
    this.update(event);
  }

  updateFeatureValueClassification(event) {
    if (this.featureValues) {
      const featureValue = this.featureValues.find(x => x.valueId === this.feature.value);
      if (featureValue) {
        if (featureValue.valueId !== featureValue.value) {
          this.feature.fValueDetails = featureValue.value;
          this.feature.value = featureValue.valueId;
          if (!this.feature.fValueDetails || this.feature.fValueDetails === "") {
            this.feature.fValueDetails = this.feature.value;
          }
        } else {
          this.feature.fValueDetails = null;
        }
      }
    }
    this.update(event);
  }

  public onRowRemoving(e) {
    let order = e.data.order;
    if (order == 1) {
      this.feature.featureValues.forEach(system => {
        system.order = system.order - 1;
      });
    }
  }




  featureValueDisplayExpr(featureValue: FeatureValue | string): string {
    if (!featureValue)
      return "";
    if (typeof featureValue === "string") {
      return featureValue;
    }
    if (!featureValue || !featureValue.value)
      return "";


    if (featureValue.valueId && featureValue.valueId != "")
      return featureValue.valueId;
    else
      return featureValue.value;

  }

  featureValueClassificationDisplayExpr(feature: Feature): string {
    if (!feature || !feature.featureValues || feature.featureValues.length == 0)
      return "";

    let fvalue = feature.featureValues[0];

    return fvalue.value;
  }


  geValueOfFeatureValue(item: FeatureValue, feature: Feature) {
    if (!feature.valueSource || feature.valueSource == "")
      return item.value;

    if (item.valueId && item.valueId != "") {
      if (item.value == "")
        return "";
      return item.valueId + " - " + item.value;
    }
    return item.value;
  }

  geValueOfFeature(feature: Feature) {
    if (!feature || !feature.featureValues || feature.featureValues.length == 0)
      return "";
    let fvalue = feature.featureValues[0];
    if (fvalue.valueId && fvalue.valueId != "")
      return fvalue.valueId;
    return fvalue.value;
  }

  addCustomFeatureValue(data: { text: string; customItem: FeatureValue }) {
    if (!data.text) {
      data.customItem = null;
      return;
    }

    if (!this.popupFeatureValues)
      this.popupFeatureValues = [];

    let custom = new FeatureValue();
    custom.value = data.text;
    custom.validFeatureValueId = "";
    custom.valueId = "";
    custom.order = this.popupFeatureValues.length + 1;
    data.customItem = custom;

    this.featureValues.push(custom);
  }

  featureDisplayExpr(item: FeatureValue): string {
    if (item === null || item === undefined)
      return "";
    if (item.value)
      return item.value;
    return "";
  }

  onSystemToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'addFeatureValueButtonTemplate',
    });
  }

  addFeatureValueClicked() {
    let max = 0;
    this.popupFeatureValues.forEach(system => {
      if (system.order > max)
        max = system.order;

    });
    let fv = new FeatureValue();
    fv.order = max + 1;
    this.popupFeatureValues.push(fv);
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}
