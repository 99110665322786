import { Component, OnInit } from '@angular/core';
import { ModelService } from '../Services/model.service';
import { TranslateService } from '@ngx-translate/core';
import { Template } from '../Model/Catalog/Template';
import { TemplateService } from '../Services/template.service';
import { lastValueFrom } from 'rxjs';
import {
  ColumnCellTemplateData,
  RowPreparedEvent,
  RowRemovedEvent,
  RowUpdatedEvent,
  ToolbarPreparingEvent
} from 'devextreme/ui/data_grid';
import { ValueChangedEvent } from 'devextreme/ui/check_box';

@Component({
  selector: 'templateList',
  templateUrl: 'templateList.component.html',
  styleUrls: ['./templateList.component.css']
})
export class TemplateListComponent implements OnInit {
  exportMode: boolean = false;
  editorVisible: boolean = false;
  public canAddTemplate: boolean = false;

  txtDuplikat: string = null;

  get hasAddonAdvancedTemplate() {
    return this.modelService.loginService.hasAddonAdvancedTemplate;
  }

  get customerId() {
    return this.modelService.loginService.currentUser.customerId;
  }

  constructor(
    public modelService: ModelService,
    public templateService: TemplateService,
    public translate: TranslateService
  ) {
    this.getAddTemplateHint = this.getAddTemplateHint.bind(this);
  }

  async ngOnInit() {
    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(['/']);
      return;
    }

    if (this.modelService.router.url === '/templates') {
      this.modelService.systemService.currentNavigationTitle = this.translate.instant('Excel Templates');
    }

    this.templateService.getTemplates();

    this.txtDuplikat = this.translate.instant('Duplikat');

    this.canAddTemplate = await lastValueFrom(
      this.templateService.canCustomerAddTemplate(this.modelService.loginService.currentCustomer.id)
    );
  }

  public onLogoClicked() {
    this.modelService.router.navigate(['/catalogs']);
  }

  get templates(): Template[] {
    return this.templateService.templates;
  }

  onToolbarPreparing(event: ToolbarPreparingEvent<Template>) {
    event.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'newTemplateButtonTemplate'
    });
    event.toolbarOptions.items.unshift({
      location: 'after',
      locateInMenu: 'auto',
      template: 'helpButtonTemplate'
    });
  }

  public addTemplateVisible = false;
  showAddTemplate() {
    this.addTemplateVisible = true;
  }

  async addTemplate(name: string) {
    this.addTemplateVisible = false;

    let newTemplate = new Template(); // data würde an sich auch gehen... vllt., ABER der CTOR initialisiert noch ein bissel was...
    newTemplate.lastChange = newTemplate.created; // Wird nicht vom CTOR gesetzt
    newTemplate.isExportTemplate = true;

    newTemplate.name = name;

    await this.templateService.addTemplate(newTemplate);
    this.canAddTemplate = await lastValueFrom(
      this.templateService.canCustomerAddTemplate(this.modelService.loginService.currentCustomer.id)
    );
  }

  addAdvancedTemplateVisible = false;
  showAddAdvancedTemplate() {
    this.addAdvancedTemplateVisible = true;
  }

  async addAdvancedTemplate({ name, type }: { name: string; type: string }) {
    this.addAdvancedTemplateVisible = false;

    const newTemplate = new Template();
    newTemplate.lastChange = newTemplate.created;
    newTemplate.isExportTemplate = true;
    newTemplate.isAdvancedTemplate = true;
    newTemplate.useMetadata = true;
    newTemplate.templateType = type !== 'DEFAULT' ? type : null;

    switch (type) {
      case 'SAP_ARIBA':
        {
          newTemplate.useMetadata = true;
        }
        break;
    }

    newTemplate.name = name;

    const templateId = await this.templateService.addTemplate(newTemplate);
    this.canAddTemplate = await lastValueFrom(
      this.templateService.canCustomerAddTemplate(this.modelService.loginService.currentCustomer.id)
    );

    await this.modelService.router.navigate(['/templates/expert', this.customerId, templateId]);
  }

  getAddTemplateHint() {
    if (this.canAddTemplate) return null;

    return this.translate.instant('In ihrer Lizenz dürfen Sie keine Templates mehr anlegen.');
  }

  rowPrepared(e: RowPreparedEvent<Template>) {
    if (e.rowType === 'data') {
      if (e.data.isAdvancedTemplate) {
        e.rowElement.classList.add('template-highlight');
      }
    }
  }

  exportTemplateCheckboxChanged(event: ValueChangedEvent, cellData: ColumnCellTemplateData<Template>) {
    if (cellData.data.isAdvancedTemplate) {
      cellData.data.isExportTemplate = true;
      return;
    }
    const dataGrid = cellData.component;
    const rowIndex = cellData.rowIndex;
    dataGrid.cellValue(rowIndex, 'isExportTemplate', event.value);
    dataGrid.saveEditData();
  }

  rowUpdated(event: RowUpdatedEvent<Template>) {
    let template = event.data;
    template.lastChange = new Date();

    // Advanced Templates (alias Expertentemplates) dürfen nur Export Templates sein.
    if (template.isAdvancedTemplate) {
      template.isExportTemplate = true;
    }

    this.templateService.saveTemplate(template, true);
  }

  async rowRemoved(event: RowRemovedEvent<Template>) {
    let template = event.data as Template;

    await this.templateService.deleteTemplate(template);

    this.canAddTemplate = await lastValueFrom(
      this.templateService.canCustomerAddTemplate(this.modelService.loginService.currentCustomer.id)
    );
  }

  async duplicateTemplate(event: ColumnCellTemplateData<Template>) {
    if (!this.canAddTemplate) {
      this.modelService.systemService.notifyWarning(
        this.translate.instant('In ihrer Lizenz dürfen Sie keine Templates mehr anlegen.')
      );
      return;
    }

    let templatetemplate = event.data;

    let copiedTemplate = new Template(); // data würde an sich auch gehen... vllt., ABER der CTOR initialisiert noch ein bissel was...
    copiedTemplate.lastChange = copiedTemplate.created; // Wird nicht vom CTOR gesetzt
    copiedTemplate.isExportTemplate = templatetemplate.isExportTemplate;
    copiedTemplate.isAdvancedTemplate = templatetemplate.isAdvancedTemplate;

    copiedTemplate.customerId = templatetemplate.customerId;
    copiedTemplate.name = templatetemplate.name + ' - ' + this.txtDuplikat;
    copiedTemplate.remarks = templatetemplate.remarks;
    copiedTemplate.isUseable = templatetemplate.isUseable;
    copiedTemplate.templateItems = templatetemplate.templateItems;

    await this.templateService.addTemplate(copiedTemplate);

    this.canAddTemplate = await lastValueFrom(
      this.templateService.canCustomerAddTemplate(this.modelService.loginService.currentCustomer.id)
    );
  }

  editTemplate(event: ColumnCellTemplateData<Template>) {
    let template = event.data;
    if (template.isAdvancedTemplate) {
      this.modelService.router.navigate(['/templates/expert', this.customerId, template.id]);
    } else {
      this.editorVisible = true;
      this.templateService.template = template;
      this.exportMode = template.isExportTemplate;
    }
  }

  itemCountTemplate(cellData: ColumnCellTemplateData<Template>) {
    const template = cellData.data;
    if (template.isAdvancedTemplate) {
      const worksheetCount = template.worksheetItems.length;
      const itemCount = template.worksheetItems.reduce((a, b) => a + b.templateItems.length, 0);
      return `${worksheetCount}/${itemCount}`;
    }
    return template.templateItems.length;
  }
}
