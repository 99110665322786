import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { LoginService } from './login.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  private connectionStatus = new BehaviorSubject<boolean>(false);
  hubConnection: signalR.HubConnection;

  get connectionStatus$(): Observable<boolean> {
    return this.connectionStatus.asObservable();
  }

  constructor(private loginService: LoginService, private builder: signalR.HubConnectionBuilder) {}

  async startConnection(urlPath: string) {
    const url = `${location.origin}/${urlPath}`;
    this.hubConnection = this.builder
      .withUrl(url, {
        accessTokenFactory: () => this.loginService.accessToken
      })
      .withAutomaticReconnect()
      .build();

    this.hubConnection.onclose(() => {
      this.connectionStatus.next(false);
    });
    this.hubConnection.onreconnected(() => {
      this.connectionStatus.next(true);
    });

    try {
      await this.hubConnection.start();
      console.log('Connection started');
      this.connectionStatus.next(true);
    } catch (err) {
      console.log('Error while starting connection: ' + err);
      this.connectionStatus.next(false);
    }
  }

  async send(method: string, ...args: unknown[]): Promise<any> {
    if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
      try {
        return await this.hubConnection.invoke(method, ...args);
      } catch (error) {
        console.error('Error invoking SignalR method:', error);
        throw error;
      }
    } else {
      console.warn('Connection is not in Connected state. Attempting to reconnect...');
      try {
        await this.hubConnection.start();
        return await this.hubConnection.invoke(method, ...args);
      } catch (error) {
        console.error('Failed to reconnect and send message:', error);
        throw error;
      }
    }
  }
}
