import { NextPimField } from './NextPimField';

export type FieldType =
  | 'standard'
  | 'mime'
  | 'feature'
  | 'orderdetail'
  | 'price'
  | 'logistics'
  | 'reference'
  | 'udx'
  | 'seo'
  | 'legal'
  | 'misc'
  | 'supplier'
  | 'esales'
  | 'wawi'
  | 'fixed';

export class TemplateItem {
  constructor(
    public pimFields: NextPimField[] = [],
    public order?: number,
    public key?: string,
    public seperator?: string,
    public unit?: string,
    public defaultValue?: string,
    public htmlFilter?: boolean,
    public catName?: string,
    public name?: string,
    public previewValues?: string[],
    public calculation: string = '',
    public numberformat: string = '',
    public mapping: string = '',
    public remove: string = '',
    public isNewEditorVersion: boolean = false,
    public addprev: string = '',
    public addfoll: string = '',
    public maxCharacters: number = 0,
    public section: number = 1,
    public keys?: string[],
    public languageCode?: string,
    public fieldType?: FieldType,
    public factorOperator: string = '',
    public factor: number = 1,
    public prefix: string = '',
    public suffix: string = '',    
  ) {}
}
