import { Component, OnInit, ViewChild } from '@angular/core';
import { Customer } from '../../Model/User/Customer';
import { ModelService } from '../../Services/model.service';
import { CustomerStatistics } from '../../Model/Dto/CustomerStatistics';
import { LanguageFlag } from '../../Model/Dto/LanguageFlag';
import { TranslateService } from '@ngx-translate/core';
import { ViewService } from '../../Services/view.service';
import { OsgConnection } from '../../Model/Dto/OsgConnection';
import { MavisConnection } from '../../Model/Dto/MavisConnection';
import { SendToNWConfig } from '../../Model/Dto/SendToNWConfig';
import { WawiSettings } from '../../Model/User/WawiSettings';
import { DxDataGridComponent } from 'devextreme-angular';
import { UserManagementService } from '../../Services/userManagment.service';
import { CustomerService } from '../../Services/customer.service';
import { LoginService } from '../../Services/login.service';
import { PimLicense } from '../../Model/User/PimLicense';
import { AddonService } from '../../Services/addon.service';
import { lastValueFrom, Subscription, switchMap, tap } from 'rxjs';
import { OxomiConnection } from 'app/Model/Dto/Oxomi/OxomiConnection';

@Component({
  selector: 'myAccount',
  templateUrl: 'myAccount.component.html'
})
export class MyAccountComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  public customers: Customer[];
  versions: string[] = Object.values(PimLicense);
  version: string = 'Essential';

  accountTypes: string[] = ['Unknown', 'Dealer', 'Manufacturer', 'DscDealer'];

  possibleNormalAddons: string[] = [];
  possibleExportAddons: string[] = [];
  possibleClassificationAddons: string[] = [];

  isClaimDropDownBoxOpened: boolean = false;
  loggedInSubscription: Subscription = null;
  customerStatistics: CustomerStatistics[];

  get customer(): Customer {
    return this.modelService.loginService.currentCustomer;
  }
  set customer(val: Customer) {
   
  }

  normalAddons: string[] = [];
  exportAddons: string[] = [];
  classificationAddons: string[] = [];

  readonly allAddons = [
    'CSV',
    'EXCEL_TEMPLATE',
    'AGREEMENT',
    'CATALOG_DETAILS',
    'VALIDATION',
    'UDX',
    'SHOP_VIEW',
    'ACTIONS',
    'SHOPWARE',
    'STATUS',
    'TRANSLATE',
    'PRODUCT_DETAILS',
    'SEO',
    'DATA_QUALITY',
    'PRINT',
    'TWOBA',
    'OSG',
    'CLASSIFICATION',
    'ECOMMERCE',
    'EDENUMBERS',
    'LEGALITY',
    'DATA_QUALITY_REPORT',
    'SEARCH',
    'MAVIS',
    'MULTI_ACCOUNT',
    'ETIM',
    'ECLASS',
    'NWCLASS',
    'NEXTPIM_DATA_QUALITY_REPORT',
    'SHOPWARE_6',
    'NORDWEST',
    'PRODUCT_TEMPLATE',
    'EBAY',
    'CS_CART',
    'CATEGORY_EXPORT',
    'REPORT_PRODUCT_DATASHEET',
    'DEEPL_TRANSLATE',
    'SHOPWARE6_VARIANTS',
    'FEATURE_MAPPING',
    'TEMPLATE_LUKAS',
    'GEVIS',
    'OXOMI'
  ];

  readonly demoAddons = [
    'CSV',
    'EXCEL_TEMPLATE',
    'VALIDATION',
    'UDX',
    'SHOP_VIEW',
    'ACTIONS',
    'STATUS',
    'TRANSLATE',
    'DATA_QUALITY',
    'CLASSIFICATION',
    'LEGALITY',
    'ETIM',
    'NEXTPIM_DATA_QUALITY_REPORT',
    'SHOPWARE_6',
    'PRODUCT_TEMPLATE',
    'EBAY',
    'REPORT_PRODUCT_DATASHEET',
    'DEEPL_TRANSLATE',
    'SHOPWARE6_VARIANTS'
  ];

  constructor(
    public modelService: ModelService,
    public translate: TranslateService,
    public userManagement: UserManagementService,
    public customerService: CustomerService,
    public loginService: LoginService,
    public addonService: AddonService
  ) {
    this.addCustomer = this.addCustomer.bind(this);
    this.hideAddCustomer = this.hideAddCustomer.bind(this);
    this.showAddCustomer = this.showAddCustomer.bind(this);
    this.showAddUser = this.showAddUser.bind(this);
    this.setDemoValues = this.setDemoValues.bind(this);
    this.setAllAddons = this.setAllAddons.bind(this);
    this.onLoggedIn = this.onLoggedIn.bind(this);
    this.onContentReadyHandler = this.onContentReadyHandler.bind(this);
    this.deleteCustomer = this.deleteCustomer.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.onAddonChanged = this.onAddonChanged.bind(this);
    this.checkAddons = this.checkAddons.bind(this);
  }

  async ngOnInit() {
    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(['/']);
      return;
    }
    this.updateCurrentCustomer();
    this.refresh();
    this.modelService.systemService.help = [];
    this.modelService.systemService.showHelp = false;
    this.possibleExportAddons = await lastValueFrom(this.addonService.getAllExportAddons());
    this.possibleNormalAddons = await lastValueFrom(this.addonService.getAllNormalAddons());
    this.possibleClassificationAddons = await lastValueFrom(this.addonService.getAllClassificationAddons());
    this.refreshAddons();

    this.modelService.systemService.currentNavigationTitle = this.translate.instant('Meine Accounts');

    if (this.modelService.loginService.loggedIn) this.onLoggedIn();
    else this.loggedInSubscription = this.modelService.loginService.onLoggedIn.subscribe(() => this.onLoggedIn());
  }

  ngOnDestroy() {
    try {
      if (this.loggedInSubscription != null) this.loggedInSubscription.unsubscribe();
    } catch (error) {
      console.error(error);
    }
  }

  onLoggedIn() {
    this.modelService.loginService.customerService
      .getAllCustomerByUser(this.modelService.loginService.currentUser.id)
      .subscribe((result: Customer[]) => {
        this.customers = result;
      });
  }

  onGridToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'newAccountButtonTemplate'
    });
  }

  onUserGridToolbarPreparing() {}

  pages = [];

  get searchText() {
    return this.modelService.systemService.searchText;
  }

  set searchText(value) {
    this.modelService.systemService.searchText = value;
  }

  private refresh() {
    let nutzerText = '';
    nutzerText = this.translate.instant('Nutzer');

    let einstellungenText = '';
    einstellungenText = this.translate.instant('Einstellungen');
    let statisticText = '';
    statisticText = this.translate.instant('Statistik');

    let dscText = '';
    dscText = this.translate.instant('Data Sharing Cockpit');

    let osgText = '';
    osgText = this.translate.instant('OSG Verbindung');
    let mavisText = '';
    mavisText = this.translate.instant('Mavis Verbindung');

    let deeplText = '';
    deeplText = this.translate.instant('Deepl Verbindung');

    let rJobsText = '';
    rJobsText = this.translate.instant('Autonmatische Jobs');

    let easyCatalogText = '';
    easyCatalogText = this.translate.instant('EasyCatalog');
    let nordwestText = '';
    nordwestText = this.translate.instant('NORDWEST');
    let wawiText = '';
    wawiText = this.translate.instant('Wawi / ERP');
    let edeAboText = '';
    edeAboText = this.translate.instant('EDE Sortimentsabo');
    
    let oxomiAccessdataText = '';
    oxomiAccessdataText = this.translate.instant('OXOMI Zugangsdaten');

    this.pages = [
      { id: 1, title: nutzerText, template: 'userTemplate' },
      { id: 2, title: einstellungenText, template: 'setupTemplate' },
      { id: 5, title: statisticText, template: 'statisticTemplate' }
    ];

    if (this.modelService.loginService.isAdmin) {
      this.pages.push({ id: 3, title: 'Addons', template: 'addonsTemplate' });
    }

    if (this.modelService.loginService.isManager) {
      if (
        this.modelService.loginService.hasAddonAutoJobsExport ||
        this.modelService.loginService.hasAddonAutoJobsImport
      ) {
        this.pages.push({ id: 12, title: rJobsText, template: 'recurringJobsTemplate' });
        this.pages.push({ id: 13, title: 'FTP Server', template: 'ftpServerTemplate' });
      }

      if (this.modelService.loginService.hasAddonDataSharing) {
        this.pages.push({ id: 16, title: dscText, template: 'dscTemplate' });
      }
      if (this.modelService.loginService.hasAddonKI_TEXTS) {
        this.pages.push({ id: 15, title: 'Description Wizard', template: 'invoiceTemplate' });
      }
      if (this.modelService.loginService.hasAddonOSG) {
        this.pages.push({ id: 6, title: osgText, template: 'osgTemplate' });
      }
      if (
        this.modelService.loginService.hasAddonNordwest &&
        this.modelService.loginService.currentUser.currentClaim == 'ADMIN'
      ) {
        this.pages.push({ id: 9, title: nordwestText, template: 'nordwestTemplate' });
      }

      if (this.modelService.loginService.hasAddonMavis) {
        this.pages.push({ id: 7, title: mavisText, template: 'mavisTemplate' });
      }
      if (this.modelService.loginService.hasAddonDEEPLTRANSLATE && this.customer.pimLicense == PimLicense.EdeEditon) {
        this.pages.push({ id: 11, title: deeplText, template: 'deeplTemplate' });
      }
      if (this.modelService.loginService.hasAddonPRINT) {
        this.pages.push({ id: 8, title: easyCatalogText, template: 'easyCatalogTemplate' });
      }
      if (this.modelService.loginService.hasAddonWawiErp) {
        this.pages.push({ id: 10, title: wawiText, template: 'wawiTemplate' });
      }
      if (
        this.modelService.loginService.hasAddonEDESortimentsAbo &&
        this.modelService.loginService.currentUser.currentClaim == 'ADMIN'
      ) {
        this.pages.push({ id: 14, title: edeAboText, template: 'edeSortimentsAboTemplate' });
      }
      if (this.modelService.loginService.hasAddonREPORTPRODUCTDATASHEET) {
        this.pages.push({ id: 15, title: this.translate.instant("Produktdatenblätter"), template: "datasheetTemplate" });
      }
      if (this.modelService.loginService.hasAddonOXOMI) {
        this.pages.push({ id: 17, title: oxomiAccessdataText, template: 'oxomiAccessdataTemplate' });
      }
    }
  }

  getLanguageText(language): string {
    return this.modelService.loginService.getLanguageText(language);
  }

  getLanguageFlag(language) {
    return this.modelService.loginService.getLanguageFlag(language);
  }

  onCustomerClick(e) {
    if (e.selectedRowsData[0].id != this.customer.id) {
      this.modelService.loginService.setCustomer(e.selectedRowsData[0].id).then(() => {
        this.updateCurrentCustomer();
      });
    }
  }

  onContentReadyHandler(e) {
    const key = e.component.getRowIndexByKey(this.modelService.loginService.currentUser.customerId);
    e.component.selectRowsByIndexes([key]);
  }

  updateCurrentCustomer() {
    this.refresh();
    this.modelService.loginService.getCustomerStatistics(this.customer.id).subscribe((stats: CustomerStatistics[]) => {
      this.customerStatistics = stats.map((au: CustomerStatistics) => new CustomerStatistics().deserialize(au));
    });

    this.modelService.osgConnectionService.getConnection(this.customer.id, false).subscribe((result: OsgConnection) => {
      if (result != null) this.modelService.loginService.osgConnection = result;
      else {
        this.modelService.loginService.osgConnection = new OsgConnection();
        this.modelService.loginService.osgConnection.customerId = this.customer.id;
      }
    });

    this.modelService.osgConnectionService.getConnection(this.customer.id, true).subscribe((result: OsgConnection) => {
      if (result != null) this.modelService.loginService.osgConnectionTest = result;
      else {
        this.modelService.loginService.osgConnectionTest = new OsgConnection();
        this.modelService.loginService.osgConnectionTest.isTest = true;
        this.modelService.loginService.osgConnectionTest.customerId = this.customer.id;
      }
    });

    this.modelService.mavisConnectionService
      .getTestConnection(this.customer.id)
      .subscribe((result: MavisConnection) => {
        if (result != null) this.modelService.loginService.mavisConnectionTest = result;
        else this.modelService.loginService.mavisConnectionTest = new MavisConnection();
      });
    this.modelService.mavisConnectionService
      .getLiveConnection(this.customer.id)
      .subscribe((result: MavisConnection) => {
        if (result != null) this.modelService.loginService.mavisConnectionLive = result;
        else this.modelService.loginService.mavisConnectionLive = new MavisConnection();
      });

    this.modelService.wawiSettingsService.getWawiSettings(this.customer.id).subscribe((result: WawiSettings) => {
      if (result != null) this.modelService.loginService.wawiSettings = result;
      else {
        this.modelService.loginService.wawiSettings = new WawiSettings();
        this.modelService.loginService.wawiSettings.customerId = this.customer.id;
      }
    });

    this.modelService.sendToNWConfigService.getConnection(this.customer.id).subscribe((result: SendToNWConfig) => {
      if (result != null) this.modelService.loginService.sendToNWConfig = result;
      else this.modelService.loginService.sendToNWConfig = new SendToNWConfig();
      this.modelService.loginService.sendToNWConfig.customerId = this.customer.id;
    });
    this.refreshAddons();
  }

  updateUser(event = null) {
    if (event != null && event.event == undefined) return;

    this.modelService.loginService.CheckAndSetFrontEndLanguage();
    this.modelService.loginService.updateUser();
  }

  isCurrentCustomer(customer: Customer): boolean {
    return customer.id != this.modelService.loginService.currentUser.customerId;
  }

  async addonChanged(newAddons) {
    const errors = await lastValueFrom(this.addonService.setAddonsToCustomer(this.customer.id, newAddons));
    if (errors.length > 0) {
      this.modelService.systemService.notifyWarning(errors.join('\n'));
    }
    const addons = await lastValueFrom(this.addonService.getAddonsOfCustomer(this.customer.id));
    await this.modelService.loginService.setAddons(addons);
    this.refresh();
  }

  async onAddonChanged(event: { previousValue: string[]; value: string[]; event }) {
    if (event.event == undefined)
      //event caused programmatically
      return;

    const addedAddons: string[] = event.value.filter((x) => !event.previousValue.some((y) => y == x));
    const removedAddons: string[] = event.previousValue.filter((x) => !event.value.some((y) => y == x));

    const errors = await lastValueFrom(
      this.addonService.addonSelectionChanged(this.customer.id, addedAddons, removedAddons)
    );
    if (errors.length > 0) {
      this.modelService.systemService.notifyWarning(errors.join('\n'));
    }
    const addons = await lastValueFrom(this.addonService.getAddonsOfCustomer(this.customer.id));
    await this.modelService.loginService.setAddons(addons);
    this.refreshAddons();
  }

  refreshAddons() {
    this.normalAddons = this.modelService.loginService.addons
      .filter((x) => this.possibleNormalAddons.includes(x))
      .sort((a, b) => a.localeCompare(b));
    this.exportAddons = this.modelService.loginService.addons
      .filter((x) => this.possibleExportAddons.includes(x))
      .sort((a, b) => a.localeCompare(b));
    this.classificationAddons = this.modelService.loginService.addons
      .filter((x) => this.possibleClassificationAddons.includes(x))
      .sort((a, b) => a.localeCompare(b));
  }

  get possibleLanguages(): LanguageFlag[] {
    return ViewService.languages;
  }

  get possibleFrontEndLanguages(): LanguageFlag[] {
    return ViewService.frontendLanguages;
  }

  public addAccountVisible: boolean = false;

  showAddCustomer() {
    this.addAccountVisible = true;
  }

  hideAddCustomer() {
    this.addAccountVisible = false;
  }

  addCustomer() {
    this.addAccountVisible = false;
    const customer = new Customer();
    customer.defaultLanguage = 'deu';
    this.modelService.loginService.customerService.create(customer).subscribe((createdCustomer: Customer) => {
      this.modelService.loginService.customerService
        .getAllCustomerByUser(this.modelService.loginService.currentUser.id)
        .subscribe((result: Customer[]) => {
          this.customers = result;
          this.modelService.loginService.currentCustomer = createdCustomer;
          this.updateCurrentCustomer();
        });
    });
  }

  public addUserVisible: boolean = false;
  public newUserMail: string = '';

  showAddUser() {
    this.addUserVisible = true;
    this.newUserMail = '';
  }

  async setAllAddons() {
    await this.addonChanged(this.allAddons);
  }
  async setDemoValues() {
    await this.addonChanged(this.demoAddons);
  }

  canUserEdit(user): boolean {
    if (user == this.modelService.loginService.currentUser.email) {
      return true;
    }

    return this.modelService.loginService.isManager;
  }

  public statusVisible: boolean = false;

  public toggleStatus() {
    if (this.statusVisible) {
      this.statusVisible = false;
    } else {
      this.statusVisible = true;
    }
  }

  public v: boolean[] = [false, false, false, false, false, false, false, false, false, false];

  ttt(id) {
    if (this.modelService.loginService.showToolTips) {
      this.v[id] = !this.v[id];
    }
  }

  get showToolTips(): boolean {
    return this.modelService.loginService.showToolTips;
  }

  get showProToolTips(): boolean {
    return this.modelService.loginService.showProToolTips;
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  hideName(name: string) {
    return name;
  }

  public async changeVersion(event = null) {
    if (event != null && event.event == undefined) return;

    await this.checkAddons();
  }

  async checkAddons() {
    this.modelService.loginService.currentCustomer = await lastValueFrom(
      this.modelService.loginService.customerService.update(this.customer)
    );
    const errors = await lastValueFrom(
      this.addonService.checkCurrentCustomerAddons(this.loginService.currentCustomer.id)
    );
    if (errors.length > 0) {
      this.modelService.systemService.notifyWarning(errors.join('\n'), 10000);
    }
  }

  public updateCustomer(event = null) {
    if (event != null && event.event == undefined) return;

    this.modelService.loginService.customerService.update(this.customer).subscribe((result: Customer) => {
      this.modelService.loginService.currentCustomer = result;
      const key = this.customers.findIndex((x) => x.id == result.id);
      this.customers[key] = result;
    });
  }

  public checkEdit(e) {
    if (
      e.data.email == this.modelService.loginService.currentUser.email ||
      this.modelService.loginService.currentUser.currentClaim == 'ADMIN' ||
      this.modelService.loginService.currentUser.currentClaim == 'MANAGER'
    ) {
      return true;
    }
    return false;
  }

  deleteAccountVisible = false;
  deleteConfirmName = '';
  accountToDelete: Customer;
  public deleteCustomer(e) {
    this.deleteConfirmName = '';
    this.accountToDelete = e.row.data as Customer;
    if (this.accountToDelete.id == this.modelService.loginService.currentCustomer.id) {
      this.modelService.systemService.notifyWarning(
        'Es können nur Accounts gelöscht werden, die nicht aktuell ausgewählt sind'
      );
    } else {
      this.deleteAccountVisible = true;
    }
  }

  public confirmDelete() {
    if (this.modelService.loginService.currentCustomer.name == this.accountToDelete.name) {
      this.modelService.systemService.notifyWarning(
        'Es können nur Accounts gelöscht werden, die nicht aktuell ausgewählt sind'
      );
    } else if (
      this.deleteConfirmName == this.accountToDelete.name ||
      (this.deleteConfirmName == '' && this.accountToDelete.name == null)
    ) {
      this.customerService
        .deleteCustomer(this.accountToDelete.id)
        .pipe(
          tap(() => this.modelService.systemService.notifyInfo(this.deleteConfirmName + ' wurde gelöscht ')),
          switchMap(() =>
            this.modelService.loginService.customerService.getAllCustomerByUser(
              this.modelService.loginService.currentUser.id
            )
          )
        )
        .subscribe((result) => (this.customers = result));
    } else {
      this.modelService.systemService.notifyWarning('Accountname passt nicht');
    }

    this.deleteAccountVisible = false;
  }
}
