import { Component, OnInit } from "@angular/core";
import { ModelService } from "../../Services/model.service";
import { AppUser } from "../../Model/User/AppUser";
import { Translation } from "../../Model/Dto/Translation";
import isString from "lodash.isstring";
import { Message } from "../../Model/System/Message";
import { CustomerWiki } from "../../Model/System/CustomerWiki";
import { LanguageFlag } from "../../Model/Dto/LanguageFlag";
import { ViewService } from '../../Services/view.service';
import { TranslationService } from '../../Services/translation.service';
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: "translations",
  templateUrl: "translation.component.html",
  styleUrls: ["./translation.component.css"]
})
export class TranslationComponent implements OnInit {

  constructor(public modelService: ModelService, public translate: TranslateService) {
    this.addLanguage = this.addLanguage.bind(this);
    this.deleteAllTranslationsClose = this.deleteAllTranslationsClose.bind(this);
    this.deleteAllTranslationsConfirmed = this.deleteAllTranslationsConfirmed.bind(this);

    this.exportExcel = this.exportExcel.bind(this);
    this.showImportTranslations = this.showImportTranslations.bind(this);
    this.deleteAllTranslations = this.deleteAllTranslations.bind(this);
    this.selectedLanguageCodeChanged = this.selectedLanguageCodeChanged.bind(this);

    this.modelService.systemService.currentNavigationTitle = this.translate.instant("Übersetzungen");

  }

  selectedLanguageCode: string;
  user: AppUser;
  translations: Translation[];
  addLanguageVisable: boolean = false;

  ngOnInit(): void {
    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(["/"]);
      return;
    }
    this.user = this.modelService.loginService.currentUser;

    this.modelService.loginService.getLanguages().subscribe(() => {

      if (this.modelService.loginService.customerLanguages.length > 0) {
        this.selectedLanguageCode = this.modelService.loginService.customerLanguages[0].language;
        this.selectedLanguageCodeChanged();
      }

    });
  }

  onNavigationToolbarPreparing(e) {

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'addLanguageButtonTemplate',
    });
  }

  onContentToolbarPreparing(e) {


    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'deleteTranslationsButtonTemplate',
    });

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'excelImportButtonTemplate',
    });

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'excelExportButtonTemplate',
    });

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'addTranslationButtonTemplate',
    });

    e.toolbarOptions.items.unshift({
      location: 'after',
      locateInMenu: 'auto',
      template: 'refreshButtonTemplate',
    });
  }

  get languages(): LanguageFlag[] {
    return ViewService.languages;
  }

  public onLanguageClick(event) {
    this.selectedLanguageCode = event.data.language;
    this.selectedLanguageCodeChanged();
  }

  public isCurrentLanguage(data) {
    return data.language == this.selectedLanguageCode;
  }

  public showAddLanguage() {
    this.addLanguageSelected = "";
    this.addLanguageVisable = true;
  }

  addLanguageSelected: string = "";

  addLanguage() {
    let that = this;

    var key = this.modelService.loginService.customerLanguages.findIndex((languageTemplate => languageTemplate.language == this.addLanguageSelected) as any);
    if (key > -1) {
      that.addLanguageVisable = false;
      this.modelService.systemService.notify(new Message("Diese Sprache ist bereits angelegt.")); // TODO: übersetzen!!
      return;
    }

    this.modelService.translationService.addLanguage2Account(this.addLanguageSelected).subscribe((result: any) => {
      that.addLanguageVisable = false;
      that.selectedLanguageCode = that.addLanguageSelected;
      that.selectedLanguageCodeChanged();
    });
  }

  translateLanguage(code) {
    return TranslationService.translateLanguage(code);
  }

  private _importTranslationsVisible: boolean = false;

  get importTranslationsVisible(): boolean {
    return this._importTranslationsVisible;
  }

  set importTranslationsVisible(value: boolean) {
    this._importTranslationsVisible = value;
    if (value == false) {
      //this.refresh();
    }
  }

  showImportTranslations() {
    this.importTranslationsVisible = true;
  }

  selectedLanguageCodeChanged() {
    let that = this;

    this.translations = [];

    if (this.selectedLanguageCode !== "") {

      this.modelService.translationService.getTranslations(this.selectedLanguageCode, this.modelService.loginService.currentUser.customerId).subscribe((result: Translation[]) => {
        //if (result.length > 0) {
        that.translations = result;
        //}
      });

    }
  }

  update(event) {
    var translation;
    if (isString(event)) {
      translation = this.translations.find(item => item.id == event);
    } else {
      if (event.key == null)
        return;
      translation = this.translations.find(item => item.id == event.key.id);
    }
    this.modelService.translationService.updateTranslation(translation);
  }

  addTranslation(translation: Translation = null, id = null) {
    if (id == null) {
      translation = new Translation();
      translation.customerId = this.modelService.loginService.currentUser.customerId;
      translation.targetLanguageCode = this.selectedLanguageCode;
      translation.sourceLanguageCode = this.modelService.loginService.currentUser.defaultLanguage;

      this.modelService.translationService.addTranslation(translation).subscribe((result: Translation) => {
        this.addTranslation(result, result.id);
      });;


    } else {
      translation.id = id;
      this.translations.push(translation);
    }
  }

  getLanguageFlag(language) {
    return ViewService.getFlag(language);
  }

  exportExcel() {
    this.modelService.translationService.exportTranslations(this.selectedLanguageCode, this.modelService.loginService.currentUser.customerId);
    this.modelService.systemService.notify(new Message("Der Export wird als Job ausgeführt.")); // TODO: übersetzen!!
  }


  deleteTranslation(event) {
    let that = this;
    this.modelService.translationService.deleteTranslations(event.data.id).subscribe(() => {
      that.selectedLanguageCodeChanged();
    });
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  popupDeleteTranslateVisible: boolean = false;

  deleteAllTranslations() {
    this.popupDeleteTranslateVisible = true;
  }

  deleteAllTranslationsClose() {
    this.popupDeleteTranslateVisible = false;
  }


  deleteAllTranslationsConfirmed() {
    this.popupDeleteTranslateVisible = false;

    //this.modelService.translationService.deleteAllTranslations();

    let that = this;
    this.modelService.translationService.deleteForLanguage(this.selectedLanguageCode)
      .subscribe(() => {
        that.selectedLanguageCodeChanged();
      });
  }

  onDeleteClicked(event) {
    let that = this;
    this.modelService.translationService.deleteLanguage(event.data.language).subscribe(() => {
      if (this.modelService.loginService.customerLanguages.length > 0) {
        this.selectedLanguageCode = this.modelService.loginService.customerLanguages[0].language;
      } else {
        this.selectedLanguageCode = "";
      }
      this.selectedLanguageCodeChanged();
    });
  }

  toggleHelp() {
    this.modelService.systemService.showHelp = !this.modelService.systemService.showHelp;
    if (this.modelService.systemService.showHelp) {
      this.modelService.systemService.loadHelp(this.modelService.loginService.currentUser.customerId).subscribe((result: CustomerWiki) => {
        this.modelService.systemService.help = result.entries;
      });
    }
  }


}
