import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FtpSupplierPidMappingsJobSettings } from "../../Model/recurringJobs/ftp/ftpSupplierPidMappingsJobSettings";

@Injectable()
export class FtpSupplierPidMappingsImportJobsService {
  private readonly apiUrl = 'api/FtpSuppleriPidMappingsJobs';

  constructor(private http: HttpClient) { }

  public getAll(customerId): Observable<FtpSupplierPidMappingsJobSettings[]> {
    const url = `${this.apiUrl}/GetAll?customerId=${customerId}`;
    return this.http.get<FtpSupplierPidMappingsJobSettings[]>(url);
  }

  public get(id): Observable<FtpSupplierPidMappingsJobSettings> {
    const url = `${this.apiUrl}/Get?id=${id}`;
    return this.http.get<FtpSupplierPidMappingsJobSettings>(url);
  }


  public update(model: FtpSupplierPidMappingsJobSettings, customerId: string): Observable<FtpSupplierPidMappingsJobSettings> {
    const url = `${this.apiUrl}/Update`;
    return this.http.post<FtpSupplierPidMappingsJobSettings>(url, model);
  }

  public delete(model: FtpSupplierPidMappingsJobSettings): Observable<boolean> {
    const url = `${this.apiUrl}/Delete`;
    return this.http.post<boolean>(url, model);
  }

  public run(model: FtpSupplierPidMappingsJobSettings): Observable<boolean> {
    const url = `${this.apiUrl}/Run`;
    return this.http.post<boolean>(url, model);
  }
}
