import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { DscContact } from '../Model/User/DscContact';


@Injectable()
export class DscContactService {
  private readonly apiUrl = 'api/DscContact';

  constructor(private http: HttpClient) { }

  get(id: string): Promise<DscContact> {
    const url = `${this.apiUrl}/Get?id=${id}`;
    return lastValueFrom (this.http.get<DscContact>(url));
  }

  getAll(customerId: string): Promise<DscContact[]> {
    const url = `${this.apiUrl}/GetAll?customerId=${customerId}`;
    return lastValueFrom(this.http.get<DscContact[]>(url));
  }

  delete(id: string): Promise<void> {
    const url = `${this.apiUrl}/Delete?id=${id}`;
    return lastValueFrom(this.http.delete<void>(url));
  }

  update(model: DscContact): Promise<DscContact> {
    const url = `${this.apiUrl}/Update`;
    return lastValueFrom(this.http.post<DscContact>(url, model));
  }

  create(model: DscContact): Promise<DscContact> {
    const url = `${this.apiUrl}/Create`;
    return lastValueFrom (this.http.post<DscContact>(url, model));
  }
}
