<fullscreen-page>
  <content-column helpSwitch="true" look="light">
    <div slot="content" class="p-2 flex-fill overflow-hidden">
      <dx-data-grid
        class="h-100"
        (onToolbarPreparing)="onContentToolbarPreparing($event)"
        [dataSource]="getDataSource()"
        keyExpr="id"
        [remoteOperations]="false"
        [allowColumnReordering]="true"
        [rowAlternationEnabled]="true"
        [allowColumnResizing]="true"
        noDataText="{{ 'keine Lieferanten im System' | translate }}"
        [showBorders]="true"
        (onRowRemoved)="onRemovedSupplier($event)"
        (onRowUpdated)="onUpdatedSupplier($event)">

        <div *dxTemplate="let toolbaritem of 'addSupllierTemplate'">
          <dx-button stylingMode="text" type="default" icon="add" text="{{ 'Neuer Lieferant' | translate }}" (onClick)="showAdd()"></dx-button>
        </div>
        
        <div *dxTemplate="let toolbaritem of 'excelImportButtonTemplate'">
          <dx-button stylingMode="text" type="default" icon="import" text="{{ 'Excel Import' | translate }}" (onClick)="showExcelImport()"></dx-button>
        </div>
        
        <div *dxTemplate="let toolbaritem of 'excelExportButtonTemplate'">
          <dx-button *ngIf="excelExportVisible" stylingMode="text" type="default" icon="export" text="{{ 'Excel Export' | translate }}" (onClick)="excelExport($event)" ></dx-button>
        </div>

        <dxo-editing mode="row" [allowDeleting]="true" [allowUpdating]="true" [useIcons]="true">
          <dxo-texts confirmDeleteMessage="{{ 'Wollen Sie diesen Lieferanten wirklich löschen' | translate }}?"
                    confirmDeleteTitle="{{ 'Lieferant löschen' | translate }}"></dxo-texts>
        </dxo-editing>

        <dxi-column dataField="number" caption="{{ 'Nummer' | translate }}" width="30%" [allowSearch]="true"></dxi-column>
        <dxi-column dataField="name" caption="{{ 'Lieferant' | translate }}" width="70%" [allowSearch]="true"></dxi-column>
      </dx-data-grid>
    </div>
  </content-column>
</fullscreen-page>

<dx-popup [showTitle]="true"
          title="{{ 'Lieferant anlegen' | translate }}"
          [dragEnabled]="true"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [(visible)]="addVisible"
          contentTemplate="popupContent"
          height="30%"
          width="30%">
  <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Lieferant anlegen' | translate, onClick: addSupplier }"></dxi-toolbar-item>
  <div *dxTemplate="let data of 'popupContent'">    
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Nummer' | translate }}</div>
      <div class="dx-field-value">
        <dx-text-box [(value)]="newSupplier.number" [showClearButton]="true"></dx-text-box>
      </div>
    </div>
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Lieferant' | translate }}</div>
      <div class="dx-field-value">
        <dx-text-box [(value)]="newSupplier.name" [showClearButton]="true"></dx-text-box>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup [width]="600"
          [height]="350"
          [showTitle]="true"
          title="{{ 'Lieferantenliste importieren' | translate }}"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [(visible)]="excelImportVisible"
          contentTemplate="popupContent2">
  <div *dxTemplate="let data of 'popupContent2'">    
    <dx-file-uploader #fileUploader
                      name="file"
                      uploadUrl="api/OxomiSortiment/ImportSupplier"
                      [multiple]="false"
                      [chunkSize]="10000000"
                      accept=".xlsx"
                      (onUploaded)="onUploaded($event)"
                      (onUploadError)="onUploadError($event)"
                      uploadMode="instantly"
                      (onBeforeSend)="onBeforeSendUpload($event)">
    </dx-file-uploader>
  </div>
</dx-popup>

<dx-popup [showTitle]="true"
          title="{{ 'Import Zusammenfassung' | translate }}"
          [dragEnabled]="true"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [(visible)]="importResumeVisible"
          contentTemplate="popupContent3"
          height="50%"
          width="30%">
  <div *dxTemplate="let data of 'popupContent3'">    
    <p class="oxomiImportSummary">{{'Lieferanten neu' | translate}}: {{excelImportNewCount}}</p>  
    <p class="oxomiImportSummary">{{'Lieferanten upgedatet' | translate}}: {{excelImportUpdateCount}}</p>
    <div *ngIf="missingValues">
      <p class="oxomiImportSummary">{{'Lieferanten ignoriert' | translate}}: {{excelImportIgnoreCount}}</p>
      <p class="oxomiImportSummary oxomiImportSummaryWarning">{{'Lieferanten mit unvollständigen Daten wurden ignoriert' | translate}}!</p>
      <div *ngIf="supplierWithoutNameExists">
        <label class="oxomiImportSummary oxomiImportSummaryWarning">{{'Lieferanten ohne Namen' | translate}}:</label>
        <p>{{supplierWithoutName}}</p>
      </div>
      <div *ngIf="supplierWithoutNumberExists">
        <label class="oxomiImportSummary oxomiImportSummaryWarning">{{'Lieferanten ohne Lieferantennummer' | translate}}:</label>
        <p>{{supplierWithoutNumber}}</p>
      </div>
    </div>
  </div>
</dx-popup>