import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { IntershopConnection } from '../../../Model/Catalog/IntershopConnection';
import { FtpServer } from '../../../Model/FtpServer';
import { CatalogService } from '../../../Services/CatalogManagement/catalog.service';
import { FtpServerService } from '../../../Services/ftp/ftpServer.service';
import { UserManagementService } from '../../../Services/userManagment.service';

@Component({
  selector: 'intershopConnection',
  templateUrl: './intershopConnection.component.html',
  styleUrls: ['./intershopConnection.component.css']
})
export class IntershopConnectionComponent implements OnInit {
  ftpServers: FtpServer[] = [];

  constructor(private catalogService: CatalogService, private userManagement: UserManagementService, private ftpServerService: FtpServerService) { }

  async ngOnInit() {
    this.ftpServers = await lastValueFrom(this.ftpServerService.getAllServer());
  }


  valueChanged(e) {
    this.catalogService.catalogChanged(e);
  }

  get model(): IntershopConnection {
    if (!this.catalogService.catalog.intershopConnection) {
      this.catalogService.catalog.intershopConnection = new IntershopConnection();
      this.catalogService.catalog.intershopConnection.domain = "";
      this.catalogService.catalog.intershopConnection.updateMode = "REPLACE";
      this.catalogService.catalog.intershopConnection.productFilePath = "/home/catalog/product/intershop";
      this.catalogService.catalog.intershopConnection.categoryFilePath = "/home/catalog/category/intershop";
      this.catalogService.catalog.intershopConnection.attributesFilePath = "/home/catalog/attribute";
      this.catalogService.catalog.intershopConnection.mediaFilePath = "/home/catalog/Asset";
    }
    if (!this.catalogService.catalog.intershopConnection.attributesFilePath || this.catalogService.catalog.intershopConnection.attributesFilePath == "")
      this.catalogService.catalog.intershopConnection.attributesFilePath = "/home/catalog/attribute";

    if (!this.catalogService.catalog.intershopConnection.mediaFilePath || this.catalogService.catalog.intershopConnection.mediaFilePath == "")
      this.catalogService.catalog.intershopConnection.mediaFilePath = "/home/catalog/Asset";

    if (!this.catalogService.catalog.intershopConnection.exportMedia == undefined)
      this.catalogService.catalog.intershopConnection.exportMedia = false;

    return this.catalogService.catalog.intershopConnection;
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}
