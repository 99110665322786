<dx-sortable
  group="dragDropGroup"
  dropFeedbackMode="indicate"
  [data]="excelModel"
  dragTemplate="dragTemplate"
  [cursorOffset]="{ x: 20, y: 20 }"
  (onDragStart)="onDragStart($event)"
  (onAdd)="onDrop($event)"
  (onReorder)="onReorder($event)"
>
  <!-- template for the dragged item -->
  <div
    *dxTemplate="let dragInfo of 'dragTemplate'"
    class="item dx-card dx-theme-text-color dx-theme-background-color shadow-lg p-3 bg-body rounded dragTemplate"
  >
    <div>
      {{ getDragInfo(dragInfo.itemData) }}
    </div>
  </div>

  <div
    *ngFor="let column of excelModelFiltered"
    class="{{ exportMode ? 'templateExcelExportColumn' : 'templateExcelColumn' }}"
    id="templateExcelDiv_{{ column.id }}"
  >
    <div *ngIf="!exportMode" class="{{ getClass(column.id) }} excelCell">
      <div class="d-flex flex-row">
        <i (click)="rowClicked(column.id)" class="handle dx-icon dx-icon-dragvertical align-self-center"></i>
        <div *ngIf="column.pimFields?.length > 0" id="plus_{{ column.id }}" class="expandButton">
          <a (click)="toggleSettings(column.id)" class="dx-icon dx-icon-chevronnext templateButton"></a>
        </div>

        <div (click)="rowClicked(column.id)" class="templateExcelColumnContent" style="height: 100%">
          <div class="templateExcelHead">{{ column.title }}</div>
          <div class="templateExcelContent" style="float: left; width: 100%">
            <div class="templateExcelLine">{{ column.row1 }}</div>
            <div class="templateExcelLine">{{ column.row2 }}</div>
          </div>
        </div>

        <div class="deleteButton" *ngIf="hasFields(column)">
          <i (click)="removeClicked(column.id)" class="dx-icon-trash templateButton"></i>
        </div>
      </div>
    </div>
    <div
      *ngIf="!exportMode"
      class="templateSelected"
      id="settings_{{ column.id }}"
      style="
        --np-row-count: {{ getNpRowCount(2, column) }};
        --dx-row-count: {{ getDxRowCount(4, column) }};
      "
    >
      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label2">{{ 'Umrechnung' | translate }}</div>
          <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
            <label class="placeLabel {{ column.calculation?.length > 0 ? 'placeLabelSmall' : '' }}">CALCULATION</label>
            <dx-select-box
              id="{{ column.id }}_cal"
              [dataSource]="calculationImport"
              displayExpr="displayExpr"
              valueExpr="valueExpr"
              [(value)]="column.calculation"
              placeholder=""
              (onValueChanged)="update($event)"
            ></dx-select-box>
          </div>
        </div>

        <div *ngIf="column.calculation == 'FACTOR'" class="container np-row">
          <div class="row mb-2">
            <div class="col-12 p-0">
              <div>{{ 'Umrechnungsfaktor' | translate }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 pl-0 pr-1">
              <dx-select-box
                id="{{ column.id }}_op_import"
                [dataSource]="operators"
                displayExpr="displayExpr"
                valueExpr="valueExpr"
                [(value)]="column.factorOperator"
                label="{{ 'Operator' | translate }}"
                labelMode="outside"
                (onValueChanged)="update($event)"
              ></dx-select-box>
            </div>
            <div class="col-6 pl-1 pr-0">
              <dx-number-box
                id="{{ column.id }}_factor_import"
                displayExpr="displayExprExport"
                valueExpr="valueExpr"
                label="{{ 'Faktor' | translate }}"
                labelMode="outside"
                [(value)]="column.factor"
                [min]="1"
                (onValueChanged)="update($event)"
              ></dx-number-box>
            </div>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label2">{{ 'Zahlenformat' | translate }}</div>
          <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
            <label class="placeLabel {{ column.numberformat?.length > 0 ? 'placeLabelSmall' : '' }}">
              NUMBERFORMAT
            </label>
            <dx-select-box
              id="{{ column.id }}_num"
              [dataSource]="numberformat"
              displayExpr="displayExpr"
              valueExpr="valueExpr"
              [(value)]="column.numberformat"
              placeholder=""
              (onValueChanged)="update($event)"
            ></dx-select-box>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label2">{{ 'Trennung' | translate }}</div>
          <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
            <label class="placeLabel {{ column.seperator?.length > 0 ? 'placeLabelSmall' : '' }}">SEPERATOR</label>
            <dx-select-box
              id="{{ column.id }}_sep"
              [dataSource]="seperator"
              displayExpr="displayExpr"
              valueExpr="valueExpr"
              [(value)]="column.seperator"
              placeholder=""
              (onValueChanged)="update($event)"
            ></dx-select-box>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label2">{{ 'Mapping' | translate }}</div>
          <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
            <label class="placeLabel {{ column.mapping?.length > 0 ? 'placeLabelSmall' : '' }}">MAPPING</label>
            <dx-select-box
              id="{{ column.id }}_map"
              [dataSource]="mappings"
              displayExpr="listName"
              valueExpr="id"
              [(value)]="column.mapping"
              placeholder=""
              (onValueChanged)="update($event)"
            ></dx-select-box>
          </div>
        </div>

        <div class="container np-row">
          <div class="row mb-2">
            <div class="col-12 p-0">
              <div>{{ 'Textzusatz' | translate }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 pl-0 pr-1">
              <dx-text-box
                id="{{ column.id }}_prefix"
                displayExpr="displayExprExport"
                valueExpr="valueExpr"
                label="{{ 'Präfix' | translate }}"
                labelMode="outside"
                [(value)]="column.prefix"
                (onValueChanged)="update($event)"
              ></dx-text-box>
            </div>
            <div class="col-6 pl-1 pr-0">
              <dx-text-box
                id="{{ column.id }}_suffix"
                displayExpr="displayExprExport"
                valueExpr="valueExpr"
                label="{{ 'Suffix' | translate }}"
                labelMode="outside"
                [(value)]="column.suffix"
                (onValueChanged)="update($event)"
              ></dx-text-box>
            </div>
          </div>
        </div>

        <div class="container np-row">
          <div class="row mb-2">
            <div class="col-12 p-0">
              <div>{{ 'Automatische Trennung' | translate }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 pl-0 pr-1">
              <dx-number-box
                id="{{ column.id }}_maxchar"
                displayExpr="displayExprExport"
                valueExpr="valueExpr"
                label="{{ 'Max. Zeichenlänge' | translate }}"
                labelMode="outside"
                [(value)]="column.maxCharacters"
                (onValueChanged)="update($event)"
              ></dx-number-box>
            </div>
            <div class="col-6 pl-1 pr-0">
              <dx-number-box
                id="{{ column.id }}_sec"
                displayExpr="displayExprExport"
                valueExpr="valueExpr"
                label="{{ 'Abschnitt' | translate }}"
                labelMode="outside"
                [(value)]="column.section"
                (onValueChanged)="update($event)"
              ></dx-number-box>
            </div>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label2">{{ 'Entfernen' | translate }}</div>
          <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
            <label class="placeLabel {{ column.remove?.length > 0 ? 'placeLabelSmall' : '' }}">REMOVE</label>
            <dx-text-box
              id="{{ column.id }}_rem"
              [(value)]="column.remove"
              placeholder=""
              (onValueChanged)="update($event)"
            ></dx-text-box>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="exportMode" class="{{ getClass(column.id) }} excelCellExport" style="padding: 5px">
      <div class="d-flex flex-row">
        <i (click)="rowClicked(column.id)" class="handleExport dx-icon dx-icon-dragvertical align-self-center"></i>
        <div id="plus_{{ column.id }}" class="expandButtonExport">
          <a (click)="toggleSettings(column.id)" class="dx-icon dx-icon-chevronnext templateButton"></a>
        </div>

        <div (click)="rowClicked(column.id)" class="templateExcelColumnContent" style="height: 100%">
          <div
            class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}"
            style="width: 100%; margin-bottom: 7px"
          >
            <label class="placeLabel {{ column.title?.length > 0 ? 'placeLabelSmall' : '' }}">FIELD_TITLE</label>
            <dx-text-box [(value)]="column.title" placeholder="" (onValueChanged)="update($event)"></dx-text-box>
          </div>

          <div
            *ngIf="column.pimFields == null || column.pimFields?.length == 0"
            class="templateExcelContent"
            style="float: left; width: 100%"
          >
            <div
              class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}"
              style="width: 100%"
            >
              <label class="placeLabel {{ column.defaultValue?.length > 0 ? 'placeLabelSmall' : '' }}">
                FIELD_VALUE
              </label>
              <dx-text-box
                [(value)]="column.defaultValue"
                [showClearButton]="true"
                placeholder=""
                (onValueChanged)="update($event)"
              ></dx-text-box>
            </div>
          </div>

          <div
            *ngIf="column.pimFields != null && column.pimFields?.length > 0"
            class="templateExcelContent"
            style="float: left; width: calc(100% - 20px)"
          >
            <div class="templateExcelLine">{{ getDragInfo(column) }}</div>
            <!-- früher column.field -->
          </div>

          <div *ngIf="column.field?.length > 0" class="deleteButtonExport">
            <i (click)="removeClicked(column.id)" class="dx-icon-trash templateButton"></i>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="exportMode"
      class="templateSelected"
      id="settings_{{ column.id }}"
      style="
        --np-row-count: {{ getNpRowCount(2, column) }};
        --dx-row-count: {{ getDxRowCount(4, column) }};
      "
    >
      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label2">{{ 'Umrechnung' | translate }}</div>
          <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
            <label class="placeLabel {{ column.calculation?.length > 0 ? 'placeLabelSmall' : '' }}">CALCULATION</label>
            <dx-select-box
              id="{{ column.id }}_cal"
              [dataSource]="calculationExport"
              displayExpr="displayExprExport"
              valueExpr="valueExpr"
              [(value)]="column.calculation"
              placeholder=""
              (onValueChanged)="update($event)"
            ></dx-select-box>
          </div>
        </div>

        <div *ngIf="column.calculation == 'FACTOR'" class="container np-row">
          <div class="row mb-2">
            <div class="col-12 p-0">
              <div>{{ 'Umrechnungsfaktor' | translate }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 pl-0 pr-1">
              <dx-select-box
                id="{{ column.id }}_op_export"
                [dataSource]="operators"
                displayExpr="displayExprExport"
                valueExpr="valueExpr"
                [(value)]="column.factorOperator"
                label="{{ 'Operator' | translate }}"
                labelMode="outside"
                (onValueChanged)="update($event)"
              ></dx-select-box>
            </div>
            <div class="col-6 pl-1 pr-0">
              <dx-number-box
                id="{{ column.id }}_factor_export"
                label="{{ 'Faktor' | translate }}"
                labelMode="outside"
                [(value)]="column.factor"
                [min]="1"
                (onValueChanged)="update($event)"
              ></dx-number-box>
            </div>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label2">{{ 'Zahlenformat' | translate }}</div>
          <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
            <label class="placeLabel {{ column.numberformat?.length > 0 ? 'placeLabelSmall' : '' }}">
              NUMBERFORMAT
            </label>
            <dx-select-box
              id="{{ column.id }}_num"
              [dataSource]="numberformat"
              displayExpr="displayExprExport"
              valueExpr="valueExpr"
              [(value)]="column.numberformat"
              placeholder=""
              (onValueChanged)="update($event)"
            ></dx-select-box>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label2">{{ 'Trennung' | translate }}</div>
          <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
            <label class="placeLabel {{ column.seperator?.length > 0 ? 'placeLabelSmall' : '' }}">SEPERATOR</label>
            <dx-select-box
              id="{{ column.id }}_sep"
              [dataSource]="seperator"
              displayExpr="displayExprExport"
              valueExpr="valueExpr"
              [(value)]="column.seperator"
              placeholder=""
              (onValueChanged)="update($event)"
            ></dx-select-box>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label2">{{ 'Mapping' | translate }}</div>
          <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
            <label class="placeLabel {{ column.mapping?.length > 0 ? 'placeLabelSmall' : '' }}">MAPPING</label>
            <dx-select-box
              id="{{ column.id }}_map"
              [dataSource]="mappings"
              displayExpr="listName"
              valueExpr="id"
              [(value)]="column.mapping"
              placeholder=""
              (onValueChanged)="update($event)"
            ></dx-select-box>
          </div>
        </div>

        <div class="container np-row">
          <div class="row mb-2">
            <div class="col-12 p-0">
              <div>{{ 'Textzusatz' | translate }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 pl-0 pr-1">
              <dx-text-box
                id="{{ column.id }}_prefix"
                displayExpr="displayExprExport"
                valueExpr="valueExpr"
                label="{{ 'Präfix' | translate }}"
                labelMode="outside"
                [(value)]="column.prefix"
                (onValueChanged)="update($event)"
              ></dx-text-box>
            </div>
            <div class="col-6 pl-1 pr-0">
              <dx-text-box
                id="{{ column.id }}_suffix"
                displayExpr="displayExprExport"
                valueExpr="valueExpr"
                label="{{ 'Suffix' | translate }}"
                labelMode="outside"
                [(value)]="column.suffix"
                (onValueChanged)="update($event)"
              ></dx-text-box>
            </div>
          </div>
        </div>

        <div class="container np-row">
          <div class="row mb-2">
            <div class="col-12 p-0">
              <div>{{ 'Automatische Trennung' | translate }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 pl-0 pr-1">
              <dx-number-box
                id="{{ column.id }}_maxchar"
                displayExpr="displayExprExport"
                valueExpr="valueExpr"
                label="{{ 'Max. Zeichenlänge' | translate }}"
                labelMode="outside"
                [(value)]="column.maxCharacters"
                [min]="0"
                (onValueChanged)="update($event)"
              ></dx-number-box>
            </div>
            <div class="col-6 pl-1 pr-0">
              <dx-number-box
                id="{{ column.id }}_sec"
                displayExpr="displayExprExport"
                valueExpr="valueExpr"
                label="{{ 'Abschnitt' | translate }}"
                labelMode="outside"
                [(value)]="column.section"
                [min]="1"
                (onValueChanged)="update($event)"
              ></dx-number-box>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</dx-sortable>
