import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'app/Services/login.service';
import Validator from 'devextreme/ui/validator';

interface TemplateType {
  id: number;
  text: string;
  value: string;
}

@Component({
  selector: 'np-advanced-template-create',
  templateUrl: './advanced-template-create.component.html'
})
export class AdvancedTemplateCreateComponent {
  templateTypes: TemplateType[] = [{ id: 0, text: 'normal', value: 'DEFAULT' }];
  templateName: string = '';
  templateType: TemplateType['value'] = 'DEFAULT';
  isRadioGroupEnabled = false;

  @Output() onAdded = new EventEmitter<{ name: string; type: TemplateType['value'] }>();

  constructor(private loginService: LoginService) {}

  ngOnInit(): void {
    this.templateName = '';
    if (this.loginService.hasAddonSapAriba) {
      this.templateTypes.push({ id: 0, text: 'SAP Ariba', value: 'SAP_ARIBA' });
    }

    this.isRadioGroupEnabled = this.templateTypes.length > 1;
  }

  ngOnDestroy(): void {}

  validatorInstance: Validator;
  saveValidatorInstance(e) {
    this.validatorInstance = e.component;
  }

  onAdd() {
    this.onAdded.emit({
      name: this.templateName,
      type: this.templateType
    });
    this.templateName = '';
    this.validatorInstance.reset();
  }
}
