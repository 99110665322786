<fullscreen-page>
  <content-column helpSwitch="true" look="dark">
    <logo slot="toolbar" [routerLink]="['/catalogs']" look="dark" route="['/catalogs']"></logo> 
    <div slot="content" class="h-100 p-3 w-75 mx-auto">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1> {{ 'Impressum' | translate }}</h1>
            <br /><br />
            {{ 'Verantwortlich für den Inhalt dieser Webseite ist die' | translate }}
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-12  bold">
            <a href="http://nextpim.de" target="_blank">
              nextPIM GmbH
            </a>

          </div>
        </div>
        <div class="row">
          <div class="col-md-12  bold">
            Von Galen Straße 2
          </div>
        </div>
        <div class="row">
          <div class="col-md-12  bold">
            48477 Hörstel
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-2 bold">
            {{ 'Email' | translate }}:
          </div>
          <div class="col-md-4">
            mail&#64;nextpim.de
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-2  bold">
            {{ 'Rechtsform' | translate }}:
          </div>
          <div class="col-md-4">
            {{ 'Gesellschaft mit beschränkter Haftung' | translate }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-2  bold">
            {{ 'Geschäftsführer' | translate }}:
          </div>
          <div class="col-md-4">
            Thomas Reimelt
          </div>
        </div>
        <div class="row">
          <div class="col-md-2  bold">
            {{ 'Registergericht' | translate }}:
          </div>
          <div class="col-md-4">
            Amtsgericht Steinfurt
          </div>
        </div>
        <div class="row">
          <div class="col-md-2 bold">
            {{ 'Registernummer' | translate }}:
          </div>
          <div class="col-md-4">
            HRB 12233
          </div>
        </div>
      </div>
    </div>
  </content-column>
</fullscreen-page>
