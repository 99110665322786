import { Input, Output, EventEmitter, Component, OnInit } from "@angular/core";

import { ViewService } from '../../../Services/view.service';
import { Product } from '../../../Model/Catalog/Product';
import { ModelService } from '../../../Services/model.service';
import { TranslateService } from '@ngx-translate/core';
import { Message } from '../../../Model/System/Message';
import { custom } from 'devextreme/ui/dialog';
import { TemplateService } from "../../../Services/template.service";
import { UserManagementService } from "../../../Services/userManagment.service";
import { Functions } from '../../../Services/functions';
import { ProductSupplier } from "../../../Model/Catalog/ProductSupplier";
import { ValidationService } from "../../../Services/Validation/validation.service";



@Component({
  selector: "productSuppliers",
  templateUrl: "productSupplier.component.html",
  styleUrls: ["./productSupplier.component.css", "../template.css"]
})
export class ProductSupplierComponent implements OnInit {
  @Input() model: ProductSupplier[] = [];
  @Input() product: Product = null;
  @Input() showLinkButtons: Boolean = false;
  @Input() catalogId: string = "";
  @Input() customerId: string = "";
  @Input() exportMode: Boolean = false;
  @Input() modelService: ModelService;
  @Input() isTemplate: Boolean = false;
  @Output() onValueChanged = new EventEmitter<any>();
  @Output() reloadNedded = new EventEmitter<any>();

  @Input() templateView: Boolean = false;
  @Input() selectedElements: string[] = [""];
  @Output() elementSelected = new EventEmitter<string>();

  @Input() showDragHandle: boolean = false;
  @Input() dragFilter: string = "noDrag";

  @Output() excelElementDropped = new EventEmitter<any>();
  @Output() elementDeleted = new EventEmitter<any>();

  txtExportReportProductDatasheetShort: string;
  order: number = 0;
  newOrder: number = 0;
  updateLock: boolean = false;
  unitCodeDataSource = [];
  supplierNames = [];




  constructor(public templateService: TemplateService, public validationService: ValidationService, public translate: TranslateService, public userManagement: UserManagementService) {
    this.addSupplier = this.addSupplier.bind(this);

  }

  ngOnInit(): void {
    this.txtExportReportProductDatasheetShort = this.translate.instant("ReportProductDatasheetShort");
    this.unitCodeDataSource.push(this.translate.instant("Einheiten"))
    this.model.forEach(x => {
      x.guid = Functions.newGuid();
    });

    for (const productSupplier of this.model) {
      if (productSupplier.order > this.order && productSupplier.order < 1000) {
        this.newOrder = productSupplier.order;
      }
    }
    this.newOrder += 1;
    this.generateSupplierNames();
  }

  customizeColumns(columns) {
    columns.forEach(column => {
      column.showEditorAlways = true;
    });
  }

  onGridToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'newSupplierButtonTemplate'
    });
  }

  onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }


  generateSupplierNames() {
    let names = this.model.map(x => x.supplierName).filter(this.onlyUnique);
    names.push(this.translate.instant("Lieferant mit niedrigster Order"));
    this.supplierNames=  names;
  }

  get mainSupplierName() {
    if (this.product.mainSupplierId == null)
      return this.translate.instant("Lieferant mit niedrigster Order")

    let mainSupplierName = this.model.find(x => x.supplierId == this.product.mainSupplierId)?.supplierName;
    if (mainSupplierName != null) {
      return mainSupplierName;
    } else {
      return "Id:" + this.product.mainSupplierId;
    }
  }

  mainSupplierChanged(e) {
    let mainSupplierId = this.model.find(x => x.supplierName == e.selectedItem)?.supplierId;
    if (mainSupplierId != null) {
      this.product.mainSupplierId = mainSupplierId;
    } else {
      this.product.mainSupplierId = null;
    }
    this.onValueChanged.emit();
  }

  calculateCurrentValue(data: { validFrom: string | null; validUntil: string | null }): boolean {
    const now = Date.now();
    const isValidFrom = data.validFrom === null || Date.parse(data.validFrom) < now;
    const isValidUntil = data.validUntil === null || Date.parse(data.validUntil) > now;
    return isValidFrom && isValidUntil;
  }

  isVirtualCatalog(): boolean {
    return this.modelService?.catalogService?.catalog?.isVirtual;
  }

  update(event = null, supplierChange?, data?, field?: string, system: string = "-1", element: string = "-1") {
    //Order wird bereits benutzt
    if (field == "SUPPLIER_ORDER") {
      let fs = this.model.filter(x => x.guid == data.guid)[0];
      let originalOrder = event.previousValue;
      let change = event.value > event.previousValue ? 1 : -1;
      while (this.model.filter(x => x.order == event.value).length > 1) {
        event.value += change;
        fs.order = event.value;
      }
      if (event.value < 1) {
        fs.order = originalOrder;
        return;
      }


      if (fs.order >= this.newOrder) {
        for (const supplier of this.model) {
          if (supplier.order > this.newOrder && supplier.order < 1000) {
            this.newOrder = supplier.order;
          }
        }
        this.newOrder += 1;
      }
    }

    // für den template editor erforderlich:
    if (event && !event.supplierPackingChanged) {
      event.dField = field;
      event.dSystem = system;
      event.dElement = element;
      event.createTemplateItem = true;
    }


    this.generateSupplierNames();

    this.onValueChanged.emit(event);
  }

  updateFromAutocomplete(event = null, field?: string, system: string = "-1", element: string = "-1") {
    if (event.event == undefined) {
      event.event = new Object(); // für den template editor erforderlich, da sonst keine default-items erstellt werden
    }

    // für den template editor erforderlich:
    if (event) {
      event.dField = field;
      event.dSystem = system;
      event.dElement = element;
      event.createTemplateItem = true;

      event.value = event.selectedItem; // value ist in diesem fall nicht gesetzt, darauf baut aber der template editor
    }


    this.generateSupplierNames();

    this.onValueChanged.emit(event);
  }

  delete(event = null) {

    let order = event.data.order;

    // Prüfen, ob irgend ein Feld dieses suppliers gelinked ist
    for (let supplierField of this.templateService.allSupplierFields) {
      if (this.product != null && this.product.isFieldLinked(supplierField, order, "-1")) {
        this.product.toggleLinkedField(supplierField, order, "-1");
      }
    }

    this.onValueChanged.emit(event);

    // für den template editor erforderlich:
    event.dFields = this.templateService.allSupplierFields;
    event.dDetailFields = null;
    event.dSystem = order;
    event.dElement = -1;


    this.generateSupplierNames();

    this.elementDeleted.emit(event);
  }



  addSupplier() {
    this.updateLock = true;
    var supplier = new ProductSupplier();
    supplier.order = this.newOrder++;
    this.model.push(supplier);
    this.update();
  }

  onContentReady() {
    this.updateLock = false;
  }

  get allowDeleting() {
    //return !this.templateView;
    return true;
  }

  translateMessage(msg: string) {
    return this.translate.instant(msg);
  }

  getEncodedString(s: string) {
    return encodeURIComponent(s);
  }



  public onRowRemoving(e) {
    let order = e.data.supplierOrder;

    if (this.product != null && this.product.isChild && this.hasSupplierFieldLinked(order)) {
      // Löschen nicht erlaubt, INFO

      let txtNotAllowed = "";
      this.translate.get("DeleteLinkedFieldNotAllowed").subscribe((text: string) => {
        txtNotAllowed = text;
      });

      this.modelService.systemService.notify(new Message(txtNotAllowed, "info"), 3000);
      e.cancel = true;
      return;
    }

    // Löschen erlaubt, Benutzerabfrage, ggf. Abbruch
    let txtYes = "";
    let txtNo = "";
    let txtMsg = "";
    let txtTitle = "";
    let myDialog: any;

    this.translate.get("Ja").subscribe((text: string) => {
      txtYes = text;
    });
    this.translate.get("Nein").subscribe((text: string) => {
      txtNo = text;
    });
    this.translate.get("Wollen Sie den Lieferanten wirklich löschen").subscribe((text: string) => {
      txtMsg = text;
      if (!txtMsg.endsWith("?")) {  // Übersetzungen sid nicht einheitlich gepflegt, werden aber auch an anderen Stellen so verwendet.
        txtMsg = txtMsg + "?";
      }
    });
    this.translate.get("Wirklich löschen?").subscribe((text: string) => {
      txtTitle = text;
    });

    myDialog = custom({
      title: txtTitle,
      messageHtml: txtMsg,
      buttons: [
        {
          text: txtYes,
          onClick: (e) => { return false; }
        },
        {
          text: txtNo,
          onClick: (e) => { return true; }
        }
      ]
    });

    // Hier wird ein Promise zurück geliefert. Dies enthält den Return Value des (erst später!!)
    // ausgeführten onClick-Handlers des gedrückten Buttons im Dialog.
    // https://supportcenter.devexpress.com/ticket/details/t978828/datagrid-how-to-cancel-the-rowremoving-event
    e.cancel = myDialog.show();
  }



  //ExcelTemplate

  templateSelect(identifier: string, order: string) {
    this.elementSelected.emit(`${identifier}_${order}`);
  }



  public excelElementDrop(e) {
    this.excelElementDropped.emit(e);
  }



  public hasSupplierFieldLinked(system: string = "-1"): boolean {
    // Prüfen, ob irgend ein Feld dieses suppliers gelinked ist
    for (let supplierField of this.templateService.allSupplierFields) {
      if (this.product != null && this.product.isFieldLinked(supplierField, system, "-1")) {
        return true;
      }
    }
    return false;
  }






  public readOnlyForLinkedFields(field: string, system: string = "-1", element: string = "-1"): boolean {
    // Wenn dies ein vom Master vererbtes Feld ist, soll es ReadOnly sein:
    if (this.product != null && field != null) {
      var isLinked = this.product.isFieldLinked(field, system, element);
      if (isLinked && this.product.isChild != null && this.product.isChild) { // Für einen Master darf es nicht ReadOnly werden!!
        return true;
      }
    }

    //return this.readOnly(productId);

    return false;
  }

  public hasMasterOrChildOrIsTranslated(): boolean {
    if (this.product != null) {
      return this.product.hasMasterOrChild();
    }
    return false;
  }

  public isFieldLinked(field: string, system: string = "-1", element: string = "-1"): boolean {
    if (this.product != null) {
      return this.product.isFieldLinked(field, system, element);
    }
    return false;
  }

  public toggleLinkedField(event = null, field: string, system: string = "-1", element: string = "-1") {
    var changed = this.product.toggleLinkedField(field, system, element);
    if (!changed) {
      return;
    }



    var isNowLinked = this.isFieldLinked(field, system, element);
    event.target.className = this.product.getClassForLinkedField(isNowLinked);

    if (this.product.isChild && isNowLinked) {
      event.forceReload = true;
    }



    if (field == "supplier_PURPOSE" || field == "supplier_SOURCE") {
      // Etwas schmutzig, aber sonst können wir den Refresh am Tree nicht antriggern.
      event.forceTreeRefresh = true;
    }



    // SAVE
    this.update(event, true);
  }

  public getInitialClassForLinkedField(field: string, system: string = "-1", element: string = "-1"): string {
    var isNowLinked = this.isFieldLinked(field, system, element);
    return this.product.getClassForLinkedField(isNowLinked);
  }

  get internationalPidType() {
    return ViewService.dropDownInternationalPidType;
  }

  canView(name: string): boolean {
    // Wenn product == null befinden wir uns im Katalog oder einer Kategorie.
    return (this.product == null || !this.isVirtualCatalog()) && this.userManagement.canView(name);
  }
  readOnly(name: string): boolean {
    // Wenn product == null befinden wir uns im Katalog oder einer Kategorie.
    return (this.product != null && this.isVirtualCatalog()) || !this.userManagement.canEdit(name);
  }

  renderNewSupplierButton(): boolean {
    // Wenn product == null befinden wir uns im Katalog oder einer Kategorie.
    return (this.product == null || !this.isVirtualCatalog()) && this.canView('supplierNew');
  }




}






