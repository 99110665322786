<form (submit)="onAdd()">
  <div class="dx-fieldset">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Templatename' | translate }}</div>
      <div class="dx-field-value">
        <dx-text-box [(value)]="templateName" [showClearButton]="true">
          <dx-validator (onInitialized)="saveValidatorInstance($event)">
            <dxi-validation-rule
              type="required"
              message="{{ 'Bitte geben Sie einen Templatenamen an' | translate }}!"
            ></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>
    <div *ngIf="isRadioGroupEnabled" class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">{{ 'Template Art' | translate }}:</div>
        <div class="dx-field-value">
          <dx-radio-group
            valueExpr="value"
            displayExpr="text"
            layout="horizontal"
            [items]="templateTypes"
            [value]="templateType"
          ></dx-radio-group>
        </div>
      </div>
    </div>

    <dx-validation-summary id="summary"></dx-validation-summary>

    <div class="dx-field d-flex flex-row">
      <dx-button
        stylingMode="text"
        type="default"
        text="{{ 'Anlegen' | translate }}"
        [useSubmitBehavior]="true"
      ></dx-button>
    </div>
  </div>
</form>
