import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
import { Observable, of } from "rxjs";
import { last } from "rxjs/operators";
import { TestResult } from "../../Model/Dto/TestResult";
import { FtpServer } from "../../Model/FtpServer";
import { ModelService } from "../model.service";

@Injectable()
export class FtpServerService {

  public onServerChanged: EventEmitter<void> = new EventEmitter<void>();
  constructor(public modelService: ModelService, private http: HttpClient) {

  }

  public getServer(id: number): Observable<FtpServer> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("id", id.toString())
    }
    return (this.http.get("api/FtpServer/Get", options)) as any;
  }

  public getAllServer(): Observable<FtpServer[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", this.modelService.loginService.currentCustomer.id)
    }
    return (this.http.get("api/FtpServer/GetAll", options)) as any;
  }

  public update(ftpImport: FtpServer): Observable<FtpServer> {
    var result = this.http.post<FtpServer>("api/FtpServer/Update", ftpImport);
    return (result);
  }

  public create(ftpImport: FtpServer): Observable<FtpServer> {
    ftpImport.customerId = this.modelService.loginService.currentCustomer.id;
    var result = this.http.post<FtpServer>("api/FtpServer/Create", ftpImport);
    return (result);
  }

  public delete(id: number): Observable<FtpServer> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("id", id.toString())
    }
    var result = this.http.delete<FtpServer>("api/FtpServer/Delete", options);
    return result;
  }

  public test(ftpImport: FtpServer): Observable<TestResult> {
    var result = this.http.post<TestResult>("api/FtpServer/Test", ftpImport);
    return (result);
  }

  public getFolderStructure(ftpImport: FtpServer): Observable<string[]> {
    var result = this.http.post<string[]>("api/FtpServer/GetFolderStructure", ftpImport);
    return (result);
  }

}
