import { map } from 'rxjs/operators';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { from, of, lastValueFrom, Observable, Subscription, BehaviorSubject } from 'rxjs';
import { Credentials } from '../Model/User/Credentials';
import { AppUser } from '../Model/User/AppUser';
import { Customer } from '../Model/User/Customer';
import { ChangePassword } from '../Model/User/ChangePassword';
import { UserToCustomer } from '../Model/User/UserToCustomer';
import { RequestPassword } from '../Model/User/RequestPassword';
import { LanguageFlag } from '../Model/Dto/LanguageFlag';
import { CustomerLanguage } from '../Model/Dto/CustomerLanguage';
import { CustomerStatistics } from '../Model/Dto/CustomerStatistics';
import { TranslateService } from '@ngx-translate/core';
import { Toggle } from '../Model/ui/Toggle';
import { ViewService } from './view.service';
import { OsgConnection } from '../Model/Dto/OsgConnection';
import { MavisConnection } from '../Model/Dto/MavisConnection';
import { SendToNWConfig } from '../Model/Dto/SendToNWConfig';
import { Message } from '../Model/System/Message';
import { SystemService } from './system.service';
import { WawiSettings } from '../Model/User/WawiSettings';
import { WawiSettingsService } from './wawiSettingsService.service';
import { CustomerService } from './customer.service';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserIdleService } from 'angular-user-idle';
import { ProductSearchOptions } from '../Model/User/ProductSearchOptions';
import { SearchFields } from '../Model/User/SearchFields';
import { AddonService } from './addon.service';
import { RegisterUser } from '../Model/User/RegisterUser';

@Injectable()
export class LoginService implements OnDestroy {
  private userSubject = new BehaviorSubject<AppUser>(null);
  userUpdate$ = this.userSubject.asObservable();

  constructor(
    private oauthService: OAuthService,
    private userIdleService: UserIdleService,
    private http: HttpClient,
    private systemService: SystemService,
    public translate: TranslateService,
    public wawiSettingsService: WawiSettingsService,
    public customerService: CustomerService,
    public userService: UserService,
    private router: Router,
    private addonService: AddonService
  ) {}

  /*  customerList: Customer[];
    customer: Customer;
    customerClaim: string = "";*/
  public osgConnection: OsgConnection = new OsgConnection();
  public osgConnectionTest: OsgConnection = new OsgConnection();
  public mavisConnectionTest: MavisConnection = new MavisConnection();
  public mavisConnectionLive: MavisConnection = new MavisConnection();
  public wawiSettings: WawiSettings = new WawiSettings();
  public sendToNWConfig: SendToNWConfig = new SendToNWConfig();

  private idleStartSubscription?: Subscription;
  private idleEndSubscription?: Subscription;
  private idlePingSubscription?: Subscription;

  loggedIn: boolean = false;
  onLoggedIn: EventEmitter<void> = new EventEmitter<void>();
  onNewNewsAmountChanged: EventEmitter<void> = new EventEmitter<void>();

  private token = '';
  private tokenExpiration: Date;
  currentUser: AppUser;
  currentCustomer: Customer;
  selectedProductTabIndex: number = 0;

  ngOnDestroy() {
    this.userIdleService.stopWatching();
    this.idleStartSubscription?.unsubscribe();
    this.idleEndSubscription?.unsubscribe();
    this.idlePingSubscription?.unsubscribe();
  }

  get isAdmin() {
    if (this.currentUser == null) {
      return false;
    }
    return this.currentUser.currentClaim == 'ADMIN';
  }

  get isUser(): boolean {
    if (this.currentUser == null) {
      return false;
    }
    return (
      this.currentUser.currentClaim == 'USER' ||
      this.currentUser.currentClaim == 'MANAGER' ||
      this.currentUser.currentClaim == 'ADMIN'
    );
  }

  get isManager(): boolean {
    if (this.currentUser == null) {
      return false;
    }
    return this.currentUser.currentClaim == 'MANAGER' || this.currentUser.currentClaim == 'ADMIN';
  }

  get productSearchOptions(): ProductSearchOptions {
    return this.currentUser.productSearchOptions;
  }
  set productSearchOptions(value: ProductSearchOptions) {
    this.currentUser.productSearchOptions = value;
  }

  get productSearchOptionsTarget(): ProductSearchOptions {
    return this.currentUser.productSearchOptions;
  }
  set productSearchOptionsTarget(value: ProductSearchOptions) {
    this.currentUser.productSearchOptions = value;
  }

  setCustomer(id: string): Promise<Customer> {
    return lastValueFrom(this.customerService.getCustomer(id)).then((result: Customer) => {
      this.currentCustomer = result;
      return this.userService
        .getUser(this.currentUser.id, this.currentCustomer.id)
        .toPromise()
        .then((user: AppUser) => {
          user.customerId = this.currentCustomer.id;
          this.updateUser(user);
          this.currentUser = user;
          return this.updateAddons().then(async () => {
            await this.getLanguages().toPromise();
            this.setShow();
            this.setLanguageFlags();
            this.userSubject.next(user);
            return result;
          });
        });
    });
  }

  CheckAndSetFrontEndLanguage() {
    if (this.currentUser.frontEndLanguage) {
      if (this.translate.currentLang != this.currentUser.frontEndLanguage) {
        this.translate.use(this.currentUser.frontEndLanguage);
        this.translate.getTranslation(this.currentUser.frontEndLanguage);
      }
    }
  }

  getCustomerStatistics(customerId: string): Observable<CustomerStatistics[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId)
    };
    return this.http.get<CustomerStatistics[]>('api/Account/getStatistics', options) as any;
  }

  get loginRequired(): boolean {
    return this.token.length == 0 || this.tokenExpiration > new Date();
  }

  get accessToken(): string {
    return this.oauthService.getAccessToken();
  }

  login(creds: Credentials, customerId: string) {
    return this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(creds.username, creds.password).then(() => {
      this.token = this.oauthService.getAccessToken();
      this.tokenExpiration = new Date(this.oauthService.getAccessTokenExpiration());

      let userId = this.oauthService.getIdentityClaims()['sub'];
      let customerId = this.oauthService.getIdentityClaims()['customerId'];

      return this.userService
        .getUser(userId, customerId)
        .toPromise()
        .then((user: AppUser) => {
          this.currentUser = user;
          this.userSubject.next(user);
        })
        .then(() =>
          this.customerService
            .getCustomer(customerId)
            .toPromise()
            .then((result: Customer) => {
              this.currentCustomer = result;
              this.onLoggedIn.emit();
              this.loggedIn = true;
              return this.updateAddons().then(async () => {
                await this.getLanguages().toPromise();
                this.CheckAndSetFrontEndLanguage();

                this.userIdleService.resetTimer();
                this.userIdleService.startWatching();

                this.idleStartSubscription = this.userIdleService.onTimerStart().subscribe((count) => {
                  console.log(`User idle ${count}`);
                });

                this.idleEndSubscription = this.userIdleService.onTimeout().subscribe(() => {
                  console.log('Logged out because of idle timeout');
                  this.logout();
                  this.router.navigate(['/login']);
                });

                this.idlePingSubscription = this.userIdleService.ping$.subscribe(() => {
                  if (this.loggedIn) {
                    console.log('Refreshing token');
                    this.oauthService.refreshToken().then(() => {
                      this.token = this.oauthService.getAccessToken();
                      this.tokenExpiration = new Date(this.oauthService.getAccessTokenExpiration());
                    });
                  }
                });

                return true;
              });
            })
        );
    });
  }

  silentlogin(customerId: string) {
    if (this.oauthService.getRefreshToken()) {
      return from(
        this.oauthService
          .refreshToken()
          .then(() => {
            return this.oauthService.loadUserProfile();
          })
          .then(() => {
            this.token = this.oauthService.getAccessToken();
            this.tokenExpiration = new Date(this.oauthService.getAccessTokenExpiration());

            let userId = this.oauthService.getIdentityClaims()['sub'];
            let customerId = this.oauthService.getIdentityClaims()['customerId'];

            return this.userService
              .getUser(userId, customerId)
              .toPromise()
              .then((user: AppUser) => {
                this.currentUser = user;
                this.userSubject.next(user);
              })
              .then(() =>
                this.customerService
                  .getCustomer(customerId)
                  .toPromise()
                  .then((result: Customer) => {
                    this.currentCustomer = result;
                    this.onLoggedIn.emit();
                    this.loggedIn = true;
                    return this.updateAddons().then(async () => {
                      await this.getLanguages();
                      this.CheckAndSetFrontEndLanguage();

                      this.userIdleService.resetTimer();
                      this.userIdleService.startWatching();

                      this.idleStartSubscription = this.userIdleService.onTimerStart().subscribe((count) => {
                        console.log(`User idle ${count}`);
                      });

                      this.idleEndSubscription = this.userIdleService.onTimeout().subscribe(() => {
                        console.log('Logged out because of idle timeout');
                        this.logout();
                        this.router.navigate(['/login']);
                      });

                      this.idlePingSubscription = this.userIdleService.ping$.subscribe(() => {
                        if (this.loggedIn) {
                          console.log('Refreshing token');
                          this.oauthService.refreshToken().then(() => {
                            this.token = this.oauthService.getAccessToken();
                            this.tokenExpiration = new Date(this.oauthService.getAccessTokenExpiration());
                          });
                        }
                      });

                      return true;
                    });
                  })
              );
          })
      );
    } else {
      return of(false);
    }
  }

  logout() {
    this.idleStartSubscription?.unsubscribe();
    this.idleEndSubscription?.unsubscribe();
    this.idlePingSubscription?.unsubscribe();

    this.userIdleService.stopWatching();
    this.oauthService.logOut();

    this.loggedIn = false;
    this.token = '';
    this.tokenExpiration = null;
    this.currentUser = null;
    this.userSubject.next(null);
  }

  getCurrentCustomer(): Observable<Customer> {
    return this.customerService.getCustomer(this.currentUser.customerId);
  }

  notifyTestAccount() {
    this.notifyTestAccountForCustomer(this.currentCustomer);
  }

  notifyTestAccountForCustomer(customer: Customer) {
    if (customer.isTestAccount) {
      let now = new Date(Date.now());
      let end = new Date(customer.accountValidDate.toString());

      //https://stackoverflow.com/a/51861734
      let diff = Math.abs(now.getTime() - end.getTime());
      let remaining = Math.floor(diff / (1000 * 60 * 60 * 24)) + 1; // / 365.25);       // oder nicht +2? was ist mit sommer/winterzeit?

      if (end.getTime() < now.getTime()) {
        this.translate.get('TestLoginHintExpired').subscribe((text: string) => {
          let translation = text;
          this.systemService.notify(new Message(translation, 'info'), 10000);
        });
      } else {
        this.translate.get('TestLoginHint').subscribe((text: string) => {
          let translation = text.replace('{{days}}', remaining.toString());
          this.systemService.notify(new Message(translation, 'info'), 10000);
        });
      }
    }
  }

  async updateAddons() {
    const addons = await lastValueFrom<string[]>(this.addonService.getAddonsOfCustomer(this.currentCustomer.id));
    await this.setAddons(addons);
  }

  getLanguages(): Observable<void> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', this.currentUser.customerId)
    };

    return this.http.get('api/AccountLanguage/GetCustomerLanguages', options).pipe(
      map((result: CustomerLanguage[]) => {
        this.setLanguages(result);
      })
    );
  }

  register(registerUser: RegisterUser): Promise<string> {
    return lastValueFrom(this.http.post('api/account/register', registerUser) as Observable<string>);
  }

  updateUser(user: AppUser = null) {
    if (user == null) user = this.currentUser;

    this.userService.update(user).subscribe((result: AppUser) => {
      this.currentUser = result;
      this.userSubject.next(user);
    });
  }

  changePassword(changePassword: ChangePassword) {
    return this.http.post('api/account/ChangePassword', changePassword).pipe(
      map((response) => {
        return true;
      })
    );
  }

  requestPassword(email: RequestPassword) {
    return this.http.post('api/account/requestPassword', email).pipe(
      map((response) => {
        return true;
      })
    );
  }
  updateSearchFields(
    selectedSearchFields: string[],
    searchMode: string,
    userId: string,
    isTarget: boolean,
    searchStateMode: 'or' | 'and' = null
  ): Observable<ProductSearchOptions> {
    let options = new ProductSearchOptions();
    options.searchFields = [];
    options.searchMode = searchMode;
    options.userId = userId;
    options.isTarget = isTarget;
    options.searchStateMode = searchStateMode;
    selectedSearchFields.forEach((value) => {
      let field = new SearchFields();
      field.searchField = value;
      options.searchFields.push(field);
    });
    return this.http.post('api/user/updateSearchFields', options).pipe(
      map((result: ProductSearchOptions) => {
        return result;
      })
    );
  }

  /*******************************************************************************/
  /*                            ACCOUNT                                          */
  /*******************************************************************************/

  private _customers: UserToCustomer[];

  addons: string[] = [];

  hasAddonCSV = false;
  hasAddonMULTI_ACCOUNT = false;
  hasAddonEXCEL_TEMPLATE = false;
  hasAddonEXCEL_ORDER_EXPORT = false;
  hasAddonAGREEMENT = false;
  hasAddonCATALOG_DETAILS = false;
  hasAddonVALIDATION = false;
  hasAddonUDX = false;
  hasAddonSHOP_VIEW = false;
  hasAddonACTIONS = false;
  hasAddonShopWare = false;
  hasAddonMavis = false;
  hasAddonTranslate = false;
  hasAddonPRODUCT_DETAILS = false;
  hasAddonSEO = false;
  hasAddonDATA_QUALITY = false;
  hasAddonPRINT = false;
  hasAddon2BAExport = false;
  hasAddonOSG = false;
  hasAddonCLASSIFICATION = false;

  hasAddonEDENUMBERS = false;
  hasAddonLEGALITY = false;
  hasAddonSEARCH = false;
  hasAddonETIM = false;
  hasAddonECLASS = false;

  hasAddonNWCLASS = false;
  hasAddonECLASS_FREE = false;
  hasAddonShopware6 = false;
  hasAddonNordwest = false;
  hasAddonProductTemplates = false;
  hasAddonTemplateLukas = false;
  hasAddonEBAY = false;
  hasAddonCATEGORYEXPORT = false;
  hasAddonREPORTPRODUCTDATASHEET = false;
  hasAddonDEEPLTRANSLATE = false;
  hasAddonSHOPWARE6_VARIANTS = false;
  hasAddonSHOPWARE5_VARIANTS = false;
  hasAddonFEATURE_MAPPING = false;
  hasAddonDEVELOPMENT = false;
  hasAddonWawiErp = false;
  hasAddonSHOPWARE6_MULTILANGUAGE = false;
  hasAddonGevis = false;
  hasAddonEDESortimentsAbo = false;
  hasAddonRClass = false;
  hasAddonMassDataChange = false;
  hasAddonKI_TEXTS = false;
  hasAddonSHOPWARE5 = false;
  hasAddonSMARTSTORE = false;
  hasAddonVIRTUAL_CATALOGS = false;
  hasAddonSUPPLIERS = false;
  hasAddonODOO = false;
  hasAddonEDC = false;
  hasAddonINTERSHOP = false;
  hasAddonSHOPWARE6_ASYNCINDEXING = false;
  hasAddonEIS_CLASS = false;
  hasAddonElectronicSales = false;
  hasAddonAdvancedTemplate = false;
  hasAddonDataSharing = false;
  hasAddonDataSources = false;
  hasAddonAutoJobsImport = false;
  hasAddonAutoJobsExport = false;
  hasAddonSapAriba = false;

  async setAddons(addons: string[]) {
    this.hasAddonMavis = false;
    this.hasAddonEDC = false;
    this.hasAddonRClass = false;
    this.hasAddonOSG = false;
    this.hasAddonCSV = false;
    this.hasAddonMULTI_ACCOUNT = false;
    this.hasAddonEXCEL_TEMPLATE = false;
    this.hasAddonAGREEMENT = false;
    this.hasAddonEXCEL_ORDER_EXPORT = false;

    this.hasAddonCATALOG_DETAILS = false;
    this.hasAddonVALIDATION = false;
    this.hasAddonUDX = false;
    this.hasAddonSHOP_VIEW = false;
    this.hasAddonACTIONS = false;
    this.hasAddonShopWare = false;
    this.hasAddonTranslate = false;
    this.hasAddonPRODUCT_DETAILS = false;
    this.hasAddonSEO = false;
    this.hasAddonDATA_QUALITY = false;
    this.hasAddonPRINT = false;
    this.hasAddon2BAExport = false;
    this.hasAddonCLASSIFICATION = false;

    this.hasAddonEDENUMBERS = false;
    this.hasAddonLEGALITY = false;
    this.hasAddonSEARCH = false;
    this.hasAddonETIM = false;
    this.hasAddonECLASS = false;
    this.hasAddonNWCLASS = false;
    this.hasAddonShopware6 = false;
    this.hasAddonNordwest = false;
    this.hasAddonProductTemplates = false;
    this.hasAddonTemplateLukas = false;
    this.hasAddonEBAY = false;
    this.hasAddonCATEGORYEXPORT = false;
    this.hasAddonREPORTPRODUCTDATASHEET = false;
    this.hasAddonDEEPLTRANSLATE = false;
    this.hasAddonSHOPWARE6_VARIANTS = false;
    this.hasAddonSHOPWARE5_VARIANTS = false;
    this.hasAddonFEATURE_MAPPING = false;
    this.hasAddonDEVELOPMENT = false;
    this.hasAddonWawiErp = false;
    this.hasAddonSHOPWARE6_MULTILANGUAGE = false;
    this.hasAddonGevis = false;
    this.hasAddonEDESortimentsAbo = false;
    this.hasAddonMassDataChange = false;
    this.hasAddonKI_TEXTS = false;
    this.hasAddonSHOPWARE5 = false;
    this.hasAddonSMARTSTORE = false;
    this.hasAddonVIRTUAL_CATALOGS = false;
    this.hasAddonSUPPLIERS = false;
    this.hasAddonODOO = false;
    this.hasAddonSHOPWARE6_ASYNCINDEXING = false;
    this.hasAddonINTERSHOP = false;
    this.hasAddonEIS_CLASS = false;
    this.hasAddonElectronicSales = false;
    this.hasAddonAdvancedTemplate = false;
    this.hasAddonDataSharing = false;
    this.hasAddonDataSources = false;
    this.hasAddonSapAriba = false;

    const promises = [];
    for (let entry of addons) {
      promises.push(this.setAddonByString(entry));
    }
    await Promise.all(promises);

    this.addons = addons;
  }

  private async setAddonByString(entry: string) {
    switch (entry) {
      case 'CSV':
        this.hasAddonCSV = true;
        break;
      case 'EIS_CLASS':
        this.hasAddonEIS_CLASS = true;
        break;
      case 'EXCEL_ORDER_EXPORT':
        this.hasAddonEXCEL_ORDER_EXPORT = true;
        break;
      case 'EDC':
        this.hasAddonEDC = true;
        break;
      case 'RCLASS':
        this.hasAddonRClass = true;
        break;
      case 'SUPPLIERS':
        this.hasAddonSUPPLIERS = true;
        break;
      case 'SHOPWARE6_MULTILANGUAGE':
        this.hasAddonSHOPWARE6_MULTILANGUAGE = true;
        break;
      case 'WAWI_ERP':
        this.hasAddonWawiErp = true;
        this.wawiSettings = await lastValueFrom<WawiSettings>(this.wawiSettingsService.getWawiSettings(this.currentCustomer.id));
        break;
      case 'FEATURE_MAPPING':
        this.hasAddonFEATURE_MAPPING = true;
        break;
      case 'SHOPWARE6_VARIANTS':
        this.hasAddonSHOPWARE6_VARIANTS = true;
        break;
      case 'SHOPWARE5_VARIANTS':
        this.hasAddonSHOPWARE5_VARIANTS = true;
        break;
      case 'CATEGORY_EXPORT':
        this.hasAddonCATEGORYEXPORT = true;
        break;
      case 'REPORT_PRODUCT_DATASHEET':
        this.hasAddonREPORTPRODUCTDATASHEET = true;
        break;
      case 'DEEPL_TRANSLATE':
        this.hasAddonDEEPLTRANSLATE = true;
        break;
      case 'SHOPWARE_6':
        this.hasAddonShopware6 = true;
        break;
      case 'MULTI_ACCOUNT':
        this.hasAddonMULTI_ACCOUNT = true;
        break;
      case 'CLASSIFICATION':
        this.hasAddonCLASSIFICATION = true;
        break;
      case 'OSG':
        this.hasAddonOSG = true;
        break;
      case 'DATA_QUALITY':
        this.hasAddonDATA_QUALITY = true;
        break;
      case 'SEO':
        this.hasAddonSEO = true;
        break;
      case 'TWOBA':
        this.hasAddon2BAExport = true;
        break;
      case 'EXCEL_TEMPLATE':
        this.hasAddonEXCEL_TEMPLATE = true;
        break;
      case 'AGREEMENT':
        this.hasAddonAGREEMENT = true;
        break;
      case 'CATALOG_DETAILS':
        this.hasAddonCATALOG_DETAILS = true;
        break;
      case 'VALIDATION':
        this.hasAddonVALIDATION = true;
        break;
      case 'UDX':
        this.hasAddonUDX = true;
        break;
      case 'SHOP_VIEW':
        this.hasAddonSHOP_VIEW = true;
        break;
      case 'ACTIONS':
        this.hasAddonACTIONS = true;
        break;
      case 'SHOPWARE':
        this.hasAddonShopWare = true;
        break;
      case 'NORDWEST':
        this.hasAddonNordwest = true;
        break;
      case 'TRANSLATE':
        this.hasAddonTranslate = true;
        break;
      case 'PRODUCT_DETAILS':
        this.hasAddonPRODUCT_DETAILS = true;
        break;
      case 'PRINT':
        this.hasAddonPRINT = true;
        break;
      case 'TEMPLATE_LUKAS':
        this.hasAddonTemplateLukas = true;
        break;
      case 'EDENUMBERS':
        this.hasAddonEDENUMBERS = true;
        break;
      case 'LEGALITY':
        this.hasAddonLEGALITY = true;
        break;
      case 'SEARCH':
        this.hasAddonSEARCH = true;
        break;
      case 'MAVIS':
        this.hasAddonMavis = true;
        break;
      case 'ETIM':
        this.hasAddonETIM = true;
        break;
      case 'ECLASS':
        this.hasAddonECLASS = true;
        break;
      case 'ECLASS_FREE':
        this.hasAddonECLASS_FREE = true;
        break;
      case 'NWCLASS':
        this.hasAddonNWCLASS = true;
        break;
      case 'PRODUCT_TEMPLATE':
        this.hasAddonProductTemplates = true;
        break;
      case 'EBAY':
        this.hasAddonEBAY = true;
        break;
      case 'GEVIS':
        this.hasAddonGevis = true;
        break;
      case 'EDE_SORTIMENTSABO':
        this.hasAddonEDESortimentsAbo = true;
        break;
      case 'MASSDATACHANGE':
        this.hasAddonMassDataChange = true;
        break;
      case 'DEVELOPMENT':
        this.hasAddonDEVELOPMENT = true;
        break;
      case 'KI_TEXTS':
        this.hasAddonKI_TEXTS = true;
        break;
      case 'SHOPWARE_5':
        this.hasAddonSHOPWARE5 = true;
        break;
      case 'SMARTSTORE':
        this.hasAddonSMARTSTORE = true;
        break;
      case 'VIRTUAL_CATALOGS':
        this.hasAddonVIRTUAL_CATALOGS = true;
        break;
      case 'ODOO':
        this.hasAddonODOO = true;
        break;
      case 'SHOPWARE6_ASYNCINDEXING':
        this.hasAddonSHOPWARE6_ASYNCINDEXING = true;
        break;
      case 'INTERSHOP':
        this.hasAddonINTERSHOP = true;
        break;
      case 'ELECTRONIC_SALES':
        this.hasAddonElectronicSales = true;
        break;
        case "ADVANCED_TEMPLATE":
          this.hasAddonAdvancedTemplate = true;
      case "DATA_SHARING":
        this.hasAddonDataSharing = true;
        break;
      case "AUTO_JOBS_IMPORT":
        this.hasAddonAutoJobsImport = true;
        break;
      case "AUTO_JOBS_EXPORT":
        this.hasAddonAutoJobsExport = true;
        break;
      case 'DATASOURCES':
        this.hasAddonDataSources = true;
        break;
      case 'SAP_ARIBA':
        this.hasAddonSapAriba = true;
        break;
    }
  }

  customerLanguages: CustomerLanguage[] = [];
  setLanguages(languges: CustomerLanguage[]) {
    this.customerLanguages = languges;
    this.setLanguageFlags();
  }

  addLanguage(language) {
    this.customerLanguages.push(language);
    this.setLanguageFlags();
  }

  selectedTranslateLanguage = 'deu';
  languageFlags = new Array<LanguageFlag>();

  getLanguageText(language) {
    const key = ViewService.languages.indexOf(((languageTemplate) => languageTemplate.code == language) as any);

    if (key != -1) return ViewService.languages[key].language;

    return 'Deutsch';
  }

  getLanguageFlag(language) {
    const key = ViewService.languages.findIndex((languageTemplate) => languageTemplate.code == language);

    if (key != -1) return ViewService.languages[key].flag;

    return 'de.gif';
  }

  setLanguageFlags() {
    this.languageFlags = this.setLanguageFlagsByCustomerLanguage(this.customerLanguages);
  }

  setLanguageFlagsByCustomerLanguage(customerLanguages): LanguageFlag[] {
    const languageFlags = new Array<LanguageFlag>();
    let foundElement = ViewService.languages.find((element) => element.code == this.currentCustomer.defaultLanguage);
    if (foundElement) languageFlags.push(foundElement);

    for (let language of customerLanguages) {
      foundElement = ViewService.languages.find((element) => element.code == language.language);

      if (foundElement) languageFlags.push(foundElement);
    }
    return languageFlags;
  }

  getLocaleFromFrontEndLanguage() {
    let lang = this.currentUser.frontEndLanguage;

    let locale = 'de-DE';
    switch (lang) {
      //case "de": locale = "de-DE"; break;
      case 'en':
        locale = 'en-GB';
        break;
      case 'nl':
        locale = 'nl-NL';
        break;
      default:
        locale = 'de-DE';
        break;
    }

    return locale;
  }

  /*******************************************************************************/
  /*                            SETTINGS                                         */
  /*******************************************************************************/

  get showToolTips() {
    if (this.currentUser) return this.currentUser.showToolTips;
    return true;
  }

  set showToolTips(value: boolean) {
    if (this.currentUser) {
      this.currentUser.showToolTips = value;
      this.userService.update(this.currentUser);
    }
  }

  get showProToolTips() {
    if (this.currentUser) return this.currentUser.showProToolTips;
    return true;
  }

  set showProToolTips(value: boolean) {
    if (this.currentUser) {
      this.currentUser.showProToolTips = value;
      this.userService.update(this.currentUser);
    }
  }

  get showPlaceHolders() {
    if (this.currentUser) return this.currentUser.showPlaceHolders;
    return true;
  }

  set showPlaceHolders(value: boolean) {
    if (this.currentUser) {
      this.currentUser.showPlaceHolders = value;
      this.userService.update(this.currentUser);
    }
  }

  setShow() {
    this.showHTMLEditor.state = true;
    this.showGroupReference.state = this.currentUser.showGroupReference;
  }

  public showHTMLEditor = new Toggle(true);
  public showGroupReference = new Toggle(false);

  saveShow() {
    this.currentUser.showGroupReference = this.showGroupReference.state;
  }

  get viewPort() {
    if (this.currentUser) {
      return this.currentUser.viewPort;
    }
    return false;
  }

  set viewPort(value: boolean) {
    if (this.currentUser) {
      this.currentUser.viewPort = value;
      this.userService.update(this.currentUser);
    }
  }

  getViewPort(): string {
    if (this.viewPort) {
      return 'containerWidth';
    }
    return '';
  }

  set lastNewRead(value: Date) {
    if (this.currentUser) {
      this.userService
        .setLastNewsRead(this.currentUser.id, value)
        .toPromise()
        .then((result: AppUser) => {
          this.currentUser = result;
          this.userSubject.next(result);
          this.onNewNewsAmountChanged.emit();
        });
    }
  }

  get lastNewRead(): Date {
    if (this.currentUser) {
      if (this.currentUser.lastNewsRead instanceof Date) {
        return this.currentUser.lastNewsRead;
      }
      return new Date(Date.parse(this.currentUser.lastNewsRead));
    }
    return null;
  }
}
