import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
import { Observable, of } from "rxjs";
import { last } from "rxjs/operators";
import { TestResult } from "../Model/Dto/TestResult";
import { NewsArticle } from "../Model/news/newsArticle";
import { FtpServer } from "../Model/FtpServer";
import { ModelService } from "./model.service";
import { EdeSortiment } from "../Model/edeSortimentsAbo/EdeSortiment";
import { EdeSortimentVersion } from "../Model/edeSortimentsAbo/edeSortimentVersion";

@Injectable()
export class EdeSortimentService {
  constructor(public modelService: ModelService, private http: HttpClient) { }

  public getAll(customerId: string): Observable<EdeSortiment[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    return (this.http.get("api/EdeSortiment/GetAll", options)) as any;
  }

  public parseFtp(): Observable<object> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json")
    }
    return (this.http.get("api/EdeSortiment/ParseFtp", options)) as any;
  }

  public parseFtpClaims(): Observable<object> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json")
    }
    return (this.http.get("api/EdeSortiment/ParseFtpClaims", options)) as any;
  }

  public getSortimentsVersions(updateBez: string): Observable<EdeSortimentVersion[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("sortimentsKennung", updateBez)
    }
    return (this.http.get("api/EdeSortiment/GetSortimentsVersions", options)) as any;
  }

  public startUpdateEDEJob(customerId: string, katalogIdent: string, targetVersion: number): Observable<object> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId).append("katalogIdent", katalogIdent).append("targetVersion", targetVersion.toString())
    }
    return (this.http.get("api/EdeSortiment/StartUpdateEDEJob", options)) as any;
  }

  public delete(katalogIdent: string, customerId: string): Observable<object> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("katalogIdent", katalogIdent).append("customerId", customerId)
    }
    return (this.http.get("api/EdeSortiment/delete", options)) as any;
  }

  public addEdeInvoice(updateBez: string, customerId: string, catalogIdent :string): Observable<object> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId).append("updateBez", updateBez).append("catalogIdent", catalogIdent)
    }
    return (this.http.get("api/EdeSortiment/addEdeInvoice", options)) as any;
  }
}
