import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
import { Observable, of } from "rxjs";
import { last } from "rxjs/operators";
import { FtpServer } from "../../Model/FtpServer";
import { FtpImportJobSettings } from "../../Model/recurringJobs/ftp/ftpImportJobSettings";
import { ModelService } from "../model.service";

@Injectable()
export class FtpImportJobService {



  constructor(public modelService: ModelService, private http: HttpClient) {

  }

  public getFtpImportJobById(id: number): Observable<FtpImportJobSettings> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("id", id.toString())
    }
    return (this.http.get("api/FtpImportJob/Get", options)) as any;
  }

  public getAllFtpImportJobs(): Observable<FtpImportJobSettings[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", this.modelService.loginService.currentCustomer.id)
    }
    return (this.http.get("api/FtpImportJob/GetAll", options)) as any;
  }

  public update(ftpImport: FtpImportJobSettings): Observable<FtpImportJobSettings> {
    var result = this.http.post<FtpImportJobSettings>("api/FtpImportJob/Update", ftpImport);
    return (result);
  }

  public create(ftpImport: FtpImportJobSettings): Observable<FtpImportJobSettings> {
    ftpImport.customerId = this.modelService.loginService.currentCustomer.id;
    var result = this.http.post<FtpImportJobSettings>("api/FtpImportJob/Create", ftpImport);
    return (result);
  }

  public delete(id: number): Observable<FtpImportJobSettings> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("id", id.toString())
    }
    return (this.http.delete<FtpServer>("api/FtpImportJob/Delete", options)) as any;
  }

  public run(id: number): Observable<FtpImportJobSettings> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("id", id.toString())
    }
    return (this.http.get<object>("api/FtpImportJob/Run", options)) as any;
  }
}
