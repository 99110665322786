import { Input, Output, EventEmitter, Component, OnInit } from '@angular/core';
import { Product } from '../../../Model/Catalog/Product';
import { ModelService } from '../../../Services/model.service';
import { TranslateService } from '@ngx-translate/core';
import { HazmatClass } from '../../../Model/Catalog/HazmatClass';
import { WarrantyClass } from '../../../Model/Catalog/WarrantyClass';
import { ValidationService } from '../../../Services/Validation/validation.service';
import { ExcelElement } from '../../../Model/ui/ExcelElement';
import { Catalog } from 'app/Model/Catalog/Catalog';
import { LoginService } from '../../../Services/login.service';
import { ProductService } from '../../../Services/CatalogManagement/product.service';
import { HazmatClassService } from '../../../Services/hazmatClass.service';
import { WarrantyClassService } from '../../../Services/warrantyClass.service';
import { CatalogService } from '../../../Services/CatalogManagement/catalog.service';

@Component({
  selector: 'templateProduct',
  templateUrl: 'templateProduct.component.html',
  styleUrls: ['template.css']
})
export class TemplateProductComponent implements OnInit {
  @Input() model: Product = new Product();
  @Input() selectedElements: string[] = [''];
  @Input() exportMode: Boolean = false;
  @Input() showDragHandle: Boolean = true;
  @Input() excelModel: ExcelElement[];

  @Output() elementDeleted = new EventEmitter<any>();
  @Output() elementSelected = new EventEmitter<string>();
  @Output() excelElementDropped = new EventEmitter<any>();
  @Output() onValueChanged = new EventEmitter<any>();

  constructor(
    public modelService: ModelService,
    public translate: TranslateService,
    public validationService: ValidationService,
    public loginService: LoginService,
    public productService: ProductService,
    public hazmatClassService: HazmatClassService,
    public warrantyClassService: WarrantyClassService,
    public catalogService: CatalogService
  ) {}

  ngOnInit(): void {
    this.productService.createWawiIfNeeded(this.model, this.loginService.wawiSettings);
    if (this.loginService.hasAddonLEGALITY) {
      this.hazmatClassService.getClasses().subscribe((result: HazmatClass[]) => {
        this.hazmatClassService.hazmatClasses = result;
      });

      this.warrantyClassService.getClasses().subscribe((result: WarrantyClass[]) => {
        this.warrantyClassService.warrantyClasses = result;
      });
    }

    if (!this.catalogService.catalog) {
      this.catalogService.catalog = new Catalog();
    }

    this.reset();
  }

  get showToolTips(): boolean {
    return this.loginService.showToolTips;
  }

  public elementSelectFunction(event) {
    this.elementSelected.emit(event);
  }

  reset() {
    if (this.loginService.hasAddonWawiErp) {
      this.productService.createWawiIfNeeded(this.model, this.loginService.wawiSettings);
    }
  }

  //Referencen nachladen
  onReferencePrepare(event) {
    if (event.columnIndex == 4 && event.rowType == 'data') {
      if (event.data.artIdTo != null) {
        this.catalogService
          .getProductBySupplierPid(
            event.data.artIdTo,
            this.loginService.currentUser.customerId,
            this.catalogService.catalog.id
          )
          .subscribe(
            (result) => {
              event.data.descriptionShort = result.descriptionShort;
              for (let mime of result.mimes.sort((a, b) => (a.mimeOrder > b.mimeOrder ? 1 : -1))) {
                if (mime.mimePurpose == 'normal' && mime.normUri != null) {
                  event.data.normUri = mime.normUri;
                }
              }
            },
            (error) => {
              event.data.descriptionShort = '';
              event.data.normUri = '';
            }
          );
      } else {
        event.data.descriptionShort = '';
        event.data.normUri = '';
      }
    }
  }

  // wie in c:\workspaces\nextpim\test\Pim\ClientApp\src\app\catalog\product\productDetail.component.ts
  addEcommerceFeatures() {
    let dummy = this.productService.getProductFromJson(new Product(), this.loginService.wawiSettings);
    dummy.featureSystems = this.model.featureSystems;

    this.catalogService.addEcommerceFeatures(dummy).subscribe((result: Product) => {
      this.model.featureSystems = result.featureSystems;
    });
  }

  public update(event = null) {
    if (event == null || event == undefined) {
      //|| event.value == null
      return;
    }

    this.onValueChanged.emit(event);
  }

  onTitleClick(event = null) {
    this.elementSelected.emit('');
  }

  public excelElementDrop(e) {
    this.excelElementDropped.emit(e);
  }

  public elementDelete(e) {
    this.elementDeleted.emit(e);
  }

  private _mimeUpdateMode: string = 'NORMAL';

  get mimeUpdateMode(): string {
    return this._mimeUpdateMode;
  }

  @Input()
  set mimeUpdateMode(value: string) {
    this._mimeUpdateMode = value;
  }

  @Output() onMimeUpdateModeChanged = new EventEmitter<string>();

  mimeUpdateModeChanged(e) {
    this.onMimeUpdateModeChanged.emit(e);
  }

  private _featureUpdateMode: string = 'NORMAL';

  get featureUpdateMode(): string {
    return this._featureUpdateMode;
  }

  @Input()
  set featureUpdateMode(value: string) {
    this._featureUpdateMode = value;
  }

  @Output() onFeatureUpdateModeChanged = new EventEmitter<string>();

  featureUpdateModeChanged(e) {
    this.onFeatureUpdateModeChanged.emit(e);
  }

  private _priceUpdateMode: string = 'NORMAL';

  get priceUpdateMode(): string {
    return this._priceUpdateMode;
  }

  @Input()
  set priceUpdateMode(value: string) {
    this._priceUpdateMode = value;
  }

  @Output() onPriceUpdateModeChanged = new EventEmitter<string>();

  priceUpdateModeChanged(e) {
    this.onPriceUpdateModeChanged.emit(e);
  }

  private _udxUpdateMode: string = 'NORMAL';

  get udxUpdateMode(): string {
    return this._udxUpdateMode;
  }

  @Input()
  set udxUpdateMode(value: string) {
    this._udxUpdateMode = value;
  }

  @Output() onUdxUpdateModeChanged = new EventEmitter<string>();

  udxUpdateModeChanged(e) {
    this.onUdxUpdateModeChanged.emit(e);
  }

  private _supplierUpdateMode: string = 'NORMAL';

  get supplierUpdateMode(): string {
    return this._supplierUpdateMode;
  }

  @Input()
  set supplierUpdateMode(value: string) {
    this._supplierUpdateMode = value;
  }

  @Output() onSupplierUpdateModeChanged = new EventEmitter<string>();

  supplierUpdateModeChanged(e) {
    this.onSupplierUpdateModeChanged.emit(e);
  }

  private _categoryUpdateMode: string = 'NORMAL';

  get categoryUpdateMode(): string {
    return this._categoryUpdateMode;
  }

  @Input()
  set categoryUpdateMode(value: string) {
    this._categoryUpdateMode = value;
  }

  @Output() onCategoryUpdateModeChanged = new EventEmitter<string>();

  categoryUpdateModeChanged(e) {
    this.onCategoryUpdateModeChanged.emit(e);
  }
}
