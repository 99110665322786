import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ShopWare5Connection } from "../../../Model/Catalog/Shopware5Connection";
import { ShopWare5ShopDto } from "../../../Model/Dto/Shopware5ShopDto";
import { ModelService } from "../../../Services/model.service";
import { Shopware5Service } from "../../../Services/shopware5.service";
import { UserManagementService } from "../../../Services/userManagment.service";
import { TreeItem as TreeElement } from "../../../Model/Dto/TreeItem";
import { DxDropDownBoxComponent, DxTreeListComponent, DxTreeViewComponent } from "devextreme-angular";
import { HttpParams } from "@angular/common/http";
import { SystemService } from "../../../Services/system.service";


@Component({
  selector: "catalogShopware5",
  templateUrl: "catalogShopware5.component.html",
  styleUrls: ["catalogShopware5.component.css"]
})
export class CatalogShopware5Component {
  @ViewChild(DxTreeListComponent, { static: false }) treeList: DxTreeListComponent;
  public shops: ShopWare5ShopDto[] = [];
  public shop: ShopWare5ShopDto;
  public shopCategories: any;
  dataSource: any;
  selectedItem: TreeElement;

  constructor(public modelService: ModelService, public translate: TranslateService, public userManagement: UserManagementService, public shopware5service: Shopware5Service, public systemService: SystemService) {
    this.valueChanged = this.valueChanged.bind(this);
    this.sendCatalog = this.sendCatalog.bind(this);
    this.reloadCategories = this.reloadCategories.bind(this);

    this.selectedItem = new TreeElement();
    this.selectedItem.text = this.model.rootCatName;
    this.selectedItem.id = this.model.rootCatId;

    let that = this;
    this.dataSource = {
      load(loadOptions) {
        return that.shopware5service.getCategories(that.model.baseUri, that.model.user, that.model.apiKey, loadOptions.parentIds).toPromise().then((result: TreeElement[]) => {
          return result;
        }, (error) => {
          systemService.notifyWarning(error.error.detail);
          return [];
        })
      },
    };
  }


  valueChanged(e) {
    this.modelService.catalogService.catalogChanged(e);
  }

  get rootCatId(): string {
    return this.model.rootCatId.toString();
  }

  get model(): ShopWare5Connection {
    if (!this.modelService.catalogService.catalog.shopWare5Connection) {
      this.modelService.catalogService.catalog.shopWare5Connection = new ShopWare5Connection();
      this.modelService.catalogService.catalog.shopWare5Connection.baseUri = "";
      this.modelService.catalogService.catalog.shopWare5Connection.apiKey = "";
      this.modelService.catalogService.catalog.shopWare5Connection.user = "";
      this.modelService.catalogService.catalog.shopWare5Connection.topFeatureAttribute = "";
      this.modelService.catalogService.catalog.shopWare5Connection.benefitsAttribute = "";
      this.modelService.catalogService.catalog.shopWare5Connection.rootCatName = "";
      this.modelService.catalogService.catalog.shopWare5Connection.shopId = -1;
      this.modelService.catalogService.catalog.shopWare5Connection.rootCatId = -1;
      this.modelService.catalogService.catalog.shopWare5Connection.onlyCreatePrices = false;
    }
    return this.modelService.catalogService.catalog.shopWare5Connection;
  }

  treeView_itemSelectionChanged(e) {
    this.model.rootCatId = e.selectedRowsData[0].id;
    this.model.rootCatName = e.selectedRowsData[0].text;
    this.selectedItem = e.selectedRowsData[0];
    this.modelService.catalogService.updateCatalog();
  }

  reloadCategories() {
    this.treeList.instance.refresh();
  }



  public sendCatalog() {
    if (this.model.rootCatId == -1) {
      this.modelService.systemService.notifyWarning("Bitte wählen Sie erst eine Kategory aus");
      return;
    }

    this.shopware5service.startExport(this.modelService.catalogService.catalog.id, this.modelService.catalogService.catalog.customerId).subscribe((result: ShopWare5ShopDto[]) => {
      this.modelService.systemService.notifyInfo("Export wurde erstellt");
    }, error => {
      this.modelService.systemService.notifyWarning(error.error);
    });
  }

  get showToolTips(): boolean {
    return this.modelService.loginService.showToolTips;
  }

  get showProToolTips(): boolean {
    return this.modelService.loginService.showProToolTips;
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}
