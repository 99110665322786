import { Component } from "@angular/core";
import { Message } from "../../Model/System/Message";
import { TestResult } from "../../Model/Dto/TestResult";

import { ModelService } from '../../Services/model.service';
import CustomStore from "devextreme/data/custom_store";
import { FtpServer } from "../../Model/FtpServer";
import { DxDataGridComponent } from "devextreme-angular";
import { ViewChild } from "@angular/core";
import { FtpServerService } from "../../Services/ftp/ftpServer.service";

@Component({
  selector: "ftpServer",
  templateUrl: "ftpServer.component.html",
  styleUrls: ["ftpServer.component.css"]
})
export class FtpServerComponent {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  ftpImport: FtpServer = new FtpServer();
  currentEditedFtpServer: FtpServer;
  modes: string[] = ["ftp", "sftp"];
  dirs: string[] = ["/out", "/in", "/", ""];
  passwordMode = "password";

  editRowKey;
  folders = [];
  isTested: boolean = false;
  dataSource: any = {};
  data: any;
  dirOptions: any = {
    items: this.getDirs,
    minSearchLength: '0',
    placeholder: "bsp.: /in/",
    hint: "Test Connection "
  }
  buttonOptions = {
    text: "Ordner laden",
    type: "normal",
    onClick: this.loadFolderStructure.bind(this)
  };
  editorOptions = {
    acceptCustomValue: true,
    dataSource: this.folders,
    onOpened: (e) => {
      this.loadFolderStructure(e);
    }
  }

  constructor(public modelService: ModelService, public ftpServerService: FtpServerService) {


    this.testftpConnection = this.testftpConnection.bind(this);
    this.loadFolderStructure = this.loadFolderStructure.bind(this);
    this.test = this.test.bind(this);
    this.dataSource.store = new CustomStore({
      key: "id",
      load: function (loadOptions: any) {
        return ftpServerService.getAllServer().toPromise()
          .then((data: FtpServer[]) => {
            return data;
          });
      },
      insert: function (value) {
        value.id = 0;
        ftpServerService.onServerChanged.emit();
        return ftpServerService.create(value).toPromise();
      },
      update: function (key, values) {
        return ftpServerService.getServer(key).toPromise()
          .then((data: FtpServer) => {
            Object.assign(data, values);
            return ftpServerService.update(data).toPromise()
              .then((data: FtpServer) => {
                ftpServerService.onServerChanged.emit();
                return data;
              });
          });
      },
      remove: function (key) {
        return ftpServerService.delete(key).toPromise().then((data: FtpServer) => {
          ftpServerService.onServerChanged.emit();
          return;
        });
      }
    });
  }


  editedRowData: any;

  onInitNewRow(e) {
    this.editedRowData = e.data;
  }

  onEditingStart(e) {
    this.editedRowData = e.data;
  }

  onRowUpdated(e) {
    this.editedRowData = null;
  }

  update(event) {
    this.ftpServerService.update(this.ftpImport).subscribe((data: FtpServer) => {
      this.ftpImport = data;
      this.ftpServerService.onServerChanged.emit();
    }, data => {
      console.log(data);
    });
  }

  isManager(): boolean {
    if (this.modelService.loginService.currentUser.currentClaim == "MANAGER" || this.modelService.loginService.currentUser.currentClaim == "ADMIN")
      return true;
    return false;
  }

  testftpConnection(server: FtpServer) {
    this.ftpServerService.test(server).subscribe((result: TestResult) => {
      let msg = new Message();
      result.success ? msg.type = "Success" : msg.type = "Error";
      msg.message = result.message;
      this.modelService.systemService.notify(msg);
      this.isTested = true;

    }, error => {
      let msg = new Message();
      msg.type = "Error";
      msg.message = "Fehler beim testen";
      this.modelService.systemService.notify(msg);
    });
  }


  isLoading = false;

  loadFolderStructure(ftpServer: FtpServer): void {
    this.isLoading = true;
    this.ftpServerService.getFolderStructure(ftpServer).subscribe((folders: string[]) => {
      this.folders = folders;
      this.isLoading = false;
    }, (error) => {
      this.modelService.systemService.notifyWarning(error.error.detail);
      this.isLoading = false;
    });
  }

  test(d) {
    this.testftpConnection(d);
  }

  get getDirs() {
    return this.dirs;
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }


  async onDetailChange(updatedServer: FtpServer) {
    this.dataSource.store.update(updatedServer.id, updatedServer)
      .then(() => {
      })
      .catch(error => {
      });
  }

  onFileSelected(e: any, updatedServer: FtpServer): void {
    const file = e.value[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        updatedServer.privateKey = event.target.result;
        this.onDetailChange(updatedServer);
      };
      reader.readAsText(file);
    }
  }
}
