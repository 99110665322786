import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PriceList } from 'app/Model/Catalog/PriceList';
import { Product } from 'app/Model/Catalog/Product';
import { ProductPriceDetail } from 'app/Model/Catalog/ProductPriceDetail';
import { CatalogService } from 'app/Services/CatalogManagement/catalog.service';
import { UserManagementService } from 'app/Services/userManagment.service';
import { ValidationService } from 'app/Services/Validation/validation.service';
import { ViewService } from 'app/Services/view.service';
import { KeyPressEvent, PasteEvent } from 'devextreme/ui/autocomplete';
import { ItemClickEvent } from 'devextreme/ui/list';

@Component({
  selector: 'np-pricedetail',
  templateUrl: './pricedetail.component.html',
  styleUrl: './pricedetail.component.css'
})
export class PricedetailComponent {
  @Input() templateView = false;
  @Input() showLinkButtons = false;
  @Input() selectedElements: string[] = [];
  @Input() showDragHandle = false;
  @Input() dragFilter = 'noDrag';
  @Input() model: Product;
  @Input() priceList: PriceList;
  @Input() detail: ProductPriceDetail;

  @Output() onElementSelected = new EventEmitter<{ identifier: string; system: string; element: string }>();
  @Output() onExcelElementDropped = new EventEmitter<any>();
  @Output() onUpdate = new EventEmitter<any>();

  priceTypes = ViewService.dropDownPriceTypes;
  currencies = ViewService.dropDownCurrencies;
  taxs = ViewService.dropDownTaxs;
  priceFactors = ViewService.dropDownPriceFactors;
  lowerBounds = ViewService.dropDownLowerBounds;
  territories = ViewService.dropDownTerritories;
  units = ViewService.dropDownUnits;
  zero = ViewService.dropDownZero;
  one = ViewService.dropDownOne;

  get grossPrice() {
    if (this.detail.priceType.startsWith('net_')) return this.calculateGrossPrice();
    return this.detail.priceAmountNumber;
  }

  get tax() {
    const taxRate = typeof this.detail.taxRate === 'string' ? parseFloat(this.detail.taxRate) : this.detail.taxRate;
    if (taxRate && !isNaN(taxRate)) return taxRate.toLocaleString(this.translate.currentLang, { style: 'percent' });
    return this.translate.instant('n.v.');
  }

  constructor(
    public validationService: ValidationService,
    public translate: TranslateService,
    private catalogService: CatalogService,
    private userManagement: UserManagementService
  ) {}

  onItemClick(event: ItemClickEvent<{ value: string; displayValue: string }>, propertyName: string) {
    this.detail[propertyName] = event.itemData.value;
  }

  templateSelect(identifier: string) {
    this.onElementSelected.emit({
      identifier,
      system: this.priceList.priceListOrder.toString(),
      element: this.detail.order.toString()
    });
  }

  excelElementDrop(event) {
    this.onExcelElementDropped.emit(event);
  }

  update(event = null, data?, productPriceDetails?: ProductPriceDetail[], field?: string) {
    const system = this.priceList.priceListOrder.toString();
    const element = this.detail.order.toString();
    productPriceDetails = productPriceDetails || this.priceList.productPriceDetails;
    this.onUpdate.emit({ event, data, productPriceDetails, field, system, element });
  }

  numericAutocompletePaste(e: PasteEvent) {
    if (isNaN(e.event.clipboardData.getData('text') as unknown as number)) {
      e.event.preventDefault();
    }
  }

  numericAutocompleteKeyPress(e: KeyPressEvent) {
    if (isNaN(+e.event.key) || e.event.key === ' ') {
      e.event.preventDefault();
    }
  }

  readOnlyForLinkedFields(field: string): boolean {
    if (field) {
      const system = this.priceList.priceListOrder.toString();
      const element = this.detail.order.toString();
      const isLinked = this.model.isFieldLinked(field, system, element);
      return isLinked && this.model.isChild != null && this.model.isChild;
    }
    return false;
  }

  readOnly(name: string): boolean {
    return this.isVirtualCatalog() || !this.userManagement.canEdit(name);
  }

  isVirtualCatalog(): boolean {
    return this.catalogService?.catalog?.isVirtual;
  }

  private calculateGrossPrice() {
    const priceAmount =
      (typeof this.detail.priceAmountNumber === 'string'
        ? parseFloat(this.detail.priceAmountNumber)
        : this.detail.priceAmountNumber) || 0;
    const taxRate = typeof this.detail.taxRate === 'string' ? parseFloat(this.detail.taxRate) : this.detail.taxRate;
    return (priceAmount / (1 + taxRate)).toLocaleString(this.translate.currentLang, {
      style: 'currency',
      currency: this.detail.priceCurrency
    });
  }
}
