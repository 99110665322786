<dx-validation-group *ngIf="model" id="PRICE">

  <dx-data-grid (onToolbarPreparing)="onGridToolbarPreparing($event)"
                [dataSource]="model?.priceLists"
                [remoteOperations]="false"
                [allowColumnReordering]="true"
                [rowAlternationEnabled]="true"
                [allowColumnResizing]="true"
                noDataText="{{ 'keine Preislisten' | translate }}"
                [showBorders]="true"
                (onRowRemoving)="onRowRemovingMaster($event)"
                (onRowUpdated)="update()"
                (onRowRemoved)="onRowRemovedMaster($event)"
                [masterDetail]="{ enabled: true, template: 'detail' }"
                (onContentReady)="onContentReadyPriceLists()"
                [customizeColumns]="customizeColumns">
    <div *dxTemplate="let toolbaritem of 'newPricelistButtonTemplate'">
      <dx-button [visible]="!readOnly('priceListNew')" [disabled]="priceListUpdateLock" stylingMode="text" type="default" text="{{ 'Neue Preisliste' | translate }}" (onClick)="addPriceList()"></dx-button>
    </div>
    <dxo-editing mode="cell" [allowUpdating]=" !readOnly('priceListUpdate')" [allowDeleting]="allowDeleting &&  !readOnly('priceListDelete')" [useIcons]="true">
      <dxo-texts confirmDeleteMessage="" confirmDeleteTitle=""></dxo-texts>
    </dxo-editing>

    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="PriceListsStateNewUIV2"></dxo-state-storing>

    <dxi-column visibleIndex="0" caption="{{ 'Order' | translate }}" [width]="70" [allowEditing]="true" cellTemplate="priceOrderTemplate" editCellTemplate="priceOrderTemplate"></dxi-column>
    <div *dxTemplate="let data of 'priceOrderTemplate'">

      <div class="np-field w-100">
        <np-inputControl [(model)]="model"
                         [showLinkButtons]="false"
                         [showPlaceLabel]="data.data.priceListOrder != null"
                         [suppressLabel]="true"
                         [suppressLinkButton]="true"
                         [suppressTranslation]="true"
                         [suppressToolTip]="true"
                         [suppressDragHandle]="true"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="null"
                         (onValueChanged)="update($event)"
                         labelText="{{ 'Order' | translate }}"
                         field="ORDER"
                         [system]="data.data.priceListOrder.toString()"
                         [showDragHandle]="false"
                         [dragFilter]="dragFilter">

          <div slot="content" class="contentSlot">
            <dx-number-box class="inDataGrid"
                           [(value)]="data.data.priceListOrder"
                           [min]="1"
                           [showSpinButtons]="!hasPriceListFieldLinked(data.data.priceListOrder?.toString())"
                           [showClearButton]="false"
                           [readOnly]="hasPriceListFieldLinked(data.data.priceListOrder.toString()) || readOnly('priceListOrder')"
                           (onValueChanged)="update($event, data.data, null, 'PRICE_LIST_ORDER', data.data.priceListOrder.toString())">
            </dx-number-box>
          </div>
        </np-inputControl>
      </div>
    </div>

    <dxi-column visibleIndex="1" caption="{{ 'Preisliste' | translate }}" [allowEditing]="false" cellTemplate="priceListTemplate"></dxi-column>
    <div *dxTemplate="let data of 'priceListTemplate'">
      <div class="np-field w-100">
        <np-inputControl [(model)]="model"
                         [showLinkButtons]="false"
                         [showPlaceLabel]="false"
                         [suppressLabel]="true"
                         [suppressLinkButton]="true"
                         [suppressTranslation]="true"
                         [suppressPlaceLabel]="true"
                         [suppressToolTip]="true"
                         [suppressDragHandle]="true"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="null"
                         field="READ_ONLY_FIELD"
                         [showDragHandle]="false"
                         [dragFilter]="dragFilter">

          <div slot="content" class="contentSlot">
            <dx-text-box [showClearButton]="false"
                         [value]="priceListName(data.data.priceListOrder)"
                         [readOnly]="true">
            </dx-text-box>
          </div>
        </np-inputControl>
      </div>
    </div>

    <dxi-column visibleIndex="2" caption="{{ 'Start' | translate }}" [width]="220" dataType="date" [format]="{ type: 'dd.MM.yyyy' }" [allowEditing]="true" cellTemplate="startTemplate" editCellTemplate="startTemplate"></dxi-column>
    <div *dxTemplate="let data of 'startTemplate'" class="d-flex flex-row">

      <div class="np-field w-100">
        <np-inputControl [(model)]="model"
                         [showLinkButtons]="showLinkButtons"
                         [showPlaceLabel]="data?.data?.start != null"
                         [suppressLabel]="true"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="templateSelect($event, data.data.priceListOrder.toString())"
                         (onValueChanged)="update($event)"
                         field="DATETIME_START_DATE"
                         [system]="data.data.priceListOrder.toString()"
                         [showDragHandle]="showDragHandle"
                         [dragFilter]="dragFilter"
                         (excelElementDropped)="excelElementDrop($event)">

          <div slot="content" class="contentSlot">
            <dx-date-box [(value)]="data.data.start"
                         type="date"
                         [showClearButton]="!readOnlyForLinkedFields('DATETIME_START_DATE', data.data.priceListOrder.toString())"
                         [readOnly]="readOnlyForLinkedFields('DATETIME_START_DATE', data.data.priceListOrder.toString())  || readOnly('priceListStartDate')"
                         displayFormat="dd.MM.yyyy"
                         (onValueChanged)="update($event, null, null, 'DATETIME_START_DATE', data.data.priceListOrder.toString())">
              <dx-validator *ngIf="!templateView" validationGroup="PRICE">
                <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('DATETIME_START_DATE', data.data.priceListOrder.toString())"></dxi-validation-rule>
              </dx-validator>
            </dx-date-box>
          </div>

        </np-inputControl>
      </div>

    </div>

    <dxi-column visibleIndex="3" caption="{{ 'Ende' | translate }}" [width]="220" dataType="date" [format]="{ type: 'dd.MM.yyyy' }" [allowEditing]="true" cellTemplate="endTemplate" editCellTemplate="endTemplate"></dxi-column>
    <div *dxTemplate="let data of 'endTemplate'" class="d-flex flex-row">

      <div class="np-field w-100">
        <np-inputControl [(model)]="model"
                         [showLinkButtons]="showLinkButtons"
                         [showPlaceLabel]="data?.data?.end != null"
                         [suppressLabel]="true"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="templateSelect($event, data.data.priceListOrder.toString())"
                         (onValueChanged)="update($event)"
                         field="DATETIME_END_DATE"
                         [system]="data.data.priceListOrder.toString()"
                         [showDragHandle]="showDragHandle"
                         [dragFilter]="dragFilter"
                         (excelElementDropped)="excelElementDrop($event)">

          <div slot="content" class="contentSlot">
            <dx-date-box [(value)]="data.data.end"
                         type="date"
                         [showClearButton]="!readOnlyForLinkedFields('DATETIME_END_DATE', data.data.priceListOrder.toString())"
                         [readOnly]="readOnlyForLinkedFields('DATETIME_END_DATE', data.data.priceListOrder.toString())  || readOnly('priceListEndDate')"
                         displayFormat="dd.MM.yyyy"
                         (onValueChanged)="update($event, null, null, 'DATETIME_END_DATE', data.data.priceListOrder.toString())">
              <dx-validator *ngIf="!templateView" validationGroup="PRICE">
                <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('DATETIME_END_DATE', data.data.priceListOrder.toString())"></dxi-validation-rule>
              </dx-validator>
            </dx-date-box>
          </div>

        </np-inputControl>
      </div>

    </div>

    <dxi-column visibleIndex="4" caption="{{ 'Tagespreis' | translate }}" [width]="120" [allowEditing]="true" cellTemplate="isDailyPriceTemplate" editCellTemplate="isDailyPriceTemplate"></dxi-column>
    <div *dxTemplate="let data of 'isDailyPriceTemplate'" class="d-flex flex-row">

      <div class="np-field w-100">
        <np-inputControl [(model)]="model"
                         [showLinkButtons]="showLinkButtons"
                         [suppressLabel]="true"
                         [suppressPlaceLabel]="true"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="templateSelect($event, data.data.priceListOrder.toString())"
                         (onValueChanged)="update($event)"
                         field="IS_DAILY_PRICE"
                         [system]="data.data.priceListOrder.toString()"
                         [showDragHandle]="showDragHandle"
                         [dragFilter]="dragFilter"
                         (excelElementDropped)="excelElementDrop($event)">

          <div slot="content" class="contentSlot np-others" style="height: 49px; padding-top: 14px;">
            <dx-check-box [(value)]="data.data.isDailyPrice"
                          [readOnly]="readOnlyForLinkedFields('IS_DAILY_PRICE', data.data.priceListOrder.toString())  || readOnly('priceListDailyPrice')"
                          (onValueChanged)="update($event, null, null, 'IS_DAILY_PRICE', data.data.priceListOrder.toString())">
              <dx-validator *ngIf="!templateView" validationGroup="PRICE">
                <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('IS_DAILY_PRICE', data.data.priceListOrder.toString())"></dxi-validation-rule>
              </dx-validator>
            </dx-check-box>
          </div>

        </np-inputControl>
      </div>

    </div>



    <div *dxTemplate="let priceLists of 'detail'">
      <div class="internal-grid-container">
        <dx-data-grid (onToolbarPreparing)="onDetailGridToolbarPreparing($event)"
                      [dataSource]="priceLists.data.productPriceDetails"
                      [remoteOperations]="false"
                      [allowColumnReordering]="true"
                      [rowAlternationEnabled]="true"
                      [allowColumnResizing]="true"
                      noDataText="{{ 'keine Preise' | translate }}"
                      [showBorders]="true"
                      (onRowRemoving)="onRowRemovingDetail($event, priceLists.data)"
                      (onRowUpdated)="update()"
                      (onRowRemoved)="onRowRemovedDetail($event, priceLists.data)"
                      (onContentReady)="onContentReadyPrices()"
                      [customizeColumns]="customizeColumns">
          <div *dxTemplate="let toolbaritem of 'newPriceButtonTemplate'">
            <dx-button [visible]="!readOnly('priceListNew')" [disabled]="priceUpdateLock" stylingMode="text" type="default" text="{{ 'Neuer Preis' | translate }}" (onClick)="addPrice(priceLists)"></dx-button>
          </div>
          <dxo-editing mode="cell" [allowUpdating]="!readOnly('priceListUpdate')" [allowDeleting]="allowDeleting && !readOnly('priceListDelete')" [useIcons]="true">
            <dxo-texts confirmDeleteMessage="" confirmDeleteTitle=""></dxo-texts>
          </dxo-editing>

          <dxo-state-storing [enabled]="true" type="localStorage" storageKey="PriceDetailsStateNewUIV2"></dxo-state-storing>

          <!--<dxi-column dataField="order" caption="" alignment="center" [width]="50" [dataType]="number"></dxi-column>-->
          <dxi-column dataField="order" caption="{{ 'Order' | translate }}" [width]="50" [allowEditing]="true" cellTemplate="priceOrderTemplate" editCellTemplate="priceOrderTemplate"></dxi-column>
          <div *dxTemplate="let price of 'priceOrderTemplate'">

            <div class="np-field w-100">
              <np-inputControl [(model)]="model"
                               [showLinkButtons]="false"
                               [showPlaceLabel]="price?.data?.order != null"
                               [suppressLabel]="true"
                               [suppressLinkButton]="true"
                               [suppressTranslation]="true"
                               [suppressToolTip]="true"
                               [suppressDragHandle]="true"
                               [(selectedElements)]="selectedElements"
                               (elementSelected)="null"
                               (onValueChanged)="update($event)"
                               labelText="{{ 'Order' | translate }}"
                               field="ORDER"
                               [system]="priceLists.data.priceListOrder.toString()"
                               [element]="price.data.order.toString()"
                               [showDragHandle]="false"
                               [dragFilter]="dragFilter">

                <div slot="content" class="contentSlot">
                  <dx-number-box [(value)]="price.data.order"
                                 [min]="1"
                                 [showSpinButtons]="!hasPriceFieldLinked(priceLists.data.priceListOrder.toString(), price.data.order.toString())"
                                 [showClearButton]="false"
                                 [readOnly]="hasPriceFieldLinked(priceLists.data.priceListOrder.toString(), price.data.order.toString()) || readOnly('priceOrder')"
                                 (onValueChanged)="update($event, price.data, priceLists.data.productPriceDetails, 'PRICE_ORDER', priceLists.data.priceListOrder.toString(), price.data.order.toString())">
                  </dx-number-box>
                </div>

              </np-inputControl>
            </div>

          </div>

          <dxi-column dataField="priceType" caption="{{ 'Preistyp' | translate }}" [allowEditing]="true" [width]="175" cellTemplate="priceTypeTemplate" editCellTemplate="priceTypeTemplate"></dxi-column>
          <div *dxTemplate="let price of 'priceTypeTemplate'">

            <div class="np-field w-100">
              <np-inputControl [(model)]="model"
                               [showLinkButtons]="showLinkButtons"
                               [showPlaceLabel]="price?.data?.priceType?.length > 0"
                               [suppressLabel]="true"
                               [(selectedElements)]="selectedElements"
                               (elementSelected)="templateSelect($event, priceLists.data.priceListOrder.toString(), price.data.order.toString())"
                               (onValueChanged)="update($event)"
                               field="PRICE_TYPE"
                               [system]="priceLists.data.priceListOrder.toString()"
                               [element]="price.data.order.toString()"
                               [showDragHandle]="showDragHandle"
                               [dragFilter]="dragFilter"
                               (excelElementDropped)="excelElementDrop($event)">

                <div slot="content" class="contentSlot">
                  <dx-autocomplete [minSearchLength]="0"
                                   [dataSource]="priceTypes"
                                   [showClearButton]="false"
                                   [showDropDownButton]="!readOnlyForLinkedFields('PRICE_TYPE', priceLists.data.priceListOrder.toString(), price.data.order.toString())"
                                   [(value)]="price.data.priceType"
                                   [readOnly]="readOnlyForLinkedFields('PRICE_TYPE', priceLists.data.priceListOrder.toString(), price.data.order.toString())  || readOnly('priceType')"
                                   valueChangeEvent="blur"
                                   (onValueChanged)="update($event, null, null, 'PRICE_TYPE', priceLists.data.priceListOrder.toString(), price.data.order.toString())">
                    <dx-validator *ngIf="!templateView" validationGroup="PRICE">
                      <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('PRICE_TYPE', priceLists.data.priceListOrder.toString(), price.data.order.toString())"></dxi-validation-rule>
                    </dx-validator>
                  </dx-autocomplete>
                </div>

              </np-inputControl>
            </div>

          </div>

          <dxi-column dataField="priceAmount" caption="{{ 'Preis' | translate }}" cellTemplate="priceAmountTemplate" editCellTemplate="priceAmountTemplate"></dxi-column>
          <div *dxTemplate="let price of 'priceAmountTemplate'">

            <div class="np-field w-100">
              <np-inputControl [(model)]="model"
                               [showLinkButtons]="showLinkButtons"
                               [showPlaceLabel]="price?.data?.priceAmount !== null"
                               [suppressLabel]="true"
                               [(selectedElements)]="selectedElements"
                               (elementSelected)="templateSelect($event, priceLists.data.priceListOrder.toString(), price.data.order.toString())"
                               (onValueChanged)="update($event)"
                               field="PRICE_AMOUNT"
                               [system]="priceLists.data.priceListOrder.toString()"
                               [element]="price.data.order.toString()"
                               [showDragHandle]="showDragHandle"
                               [dragFilter]="dragFilter"
                               (excelElementDropped)="excelElementDrop($event)">

                <div slot="content" class="contentSlot">
                  <dx-number-box [showClearButton]="!readOnlyForLinkedFields('PRICE_AMOUNT', priceLists.data.priceListOrder.toString(), price.data.order.toString())"
                               [(value)]="price.data.priceAmount"
                               [readOnly]="readOnlyForLinkedFields('PRICE_AMOUNT', priceLists.data.priceListOrder.toString(), price.data.order.toString())  || readOnly('priceAmount')"
                               (onValueChanged)="update($event, null, null, 'PRICE_AMOUNT', priceLists.data.priceListOrder.toString(), price.data.order.toString())">
                    <dx-validator *ngIf="!templateView" validationGroup="PRICE">
                      <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('PRICE_AMOUNT', priceLists.data.priceListOrder.toString(), price.data.order.toString())"></dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </div>

              </np-inputControl>
            </div>

          </div>

          <dxi-column dataField="priceCurrency" caption="{{ 'Währung' | translate }}" [allowEditing]="true" cellTemplate="priceCurrencyTemplate" editCellTemplate="priceCurrencyTemplate"></dxi-column>
          <div *dxTemplate="let price of 'priceCurrencyTemplate'">

            <div class="np-field w-100">
              <np-inputControl [(model)]="model"
                               [showLinkButtons]="showLinkButtons"
                               [showPlaceLabel]="price?.data?.priceCurrency?.length > 0"
                               [suppressLabel]="true"
                               [(selectedElements)]="selectedElements"
                               (elementSelected)="templateSelect($event, priceLists.data.priceListOrder.toString(), price.data.order.toString())"
                               (onValueChanged)="update($event)"
                               field="PRICE_CURRENCY"
                               [system]="priceLists.data.priceListOrder.toString()"
                               [element]="price.data.order.toString()"
                               [showDragHandle]="showDragHandle"
                               [dragFilter]="dragFilter"
                               (excelElementDropped)="excelElementDrop($event)">

                <div slot="content" class="contentSlot">
                  <dx-autocomplete [minSearchLength]="0"
                                   [dataSource]="currencys"
                                   [showClearButton]="false"
                                   [showDropDownButton]="!readOnlyForLinkedFields('PRICE_CURRENCY', priceLists.data.priceListOrder.toString(), price.data.order.toString())"
                                   [(value)]="price.data.priceCurrency"
                                   [readOnly]="readOnlyForLinkedFields('PRICE_CURRENCY', priceLists.data.priceListOrder.toString(), price.data.order.toString())  || readOnly('priceCurrency')"
                                   valueChangeEvent="blur"
                                   (onValueChanged)="update($event, null, null, 'PRICE_CURRENCY', priceLists.data.priceListOrder.toString(), price.data.order.toString())">
                    <div slot="content" class="contentSlot">
                      <dx-number-box [(value)]="price.data.priceAmount" [readOnly]="true"></dx-number-box>
                    </div>
                    <dx-validator *ngIf="!templateView" validationGroup="PRICE">
                      <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('PRICE_CURRENCY', priceLists.data.priceListOrder.toString(), price.data.order.toString())"></dxi-validation-rule>
                    </dx-validator>
                  </dx-autocomplete>
                </div>

              </np-inputControl>
            </div>

          </div>

          <dxi-column dataField="tax" caption="{{ 'Steuer' | translate }}" [allowEditing]="true" cellTemplate="pricTaxTemplate" editCellTemplate="pricTaxTemplate"></dxi-column>
          <div *dxTemplate="let price of 'pricTaxTemplate'">

            <div class="np-field w-100">
              <np-inputControl [(model)]="model"
                               [showLinkButtons]="showLinkButtons"
                               [showPlaceLabel]="price?.data?.tax !== null"
                               [suppressLabel]="true"
                               [(selectedElements)]="selectedElements"
                               (elementSelected)="templateSelect($event, priceLists.data.priceListOrder.toString(), price.data.order.toString())"
                               (onValueChanged)="update($event)"
                               field="TAX"
                               [system]="priceLists.data.priceListOrder.toString()"
                               [element]="price.data.order.toString()"
                               [showDragHandle]="showDragHandle"
                               [dragFilter]="dragFilter"
                               (excelElementDropped)="excelElementDrop($event)">

                <div slot="content" class="contentSlot">
                  <dx-autocomplete [minSearchLength]="0"
                                   [dataSource]="taxs"
                                   [(value)]="price.data.tax"
                                   [showClearButton]="false"
                                   [readOnly]="readOnlyForLinkedFields('TAX', priceLists.data.priceListOrder.toString(), price.data.order.toString())  || readOnly('priceTax')"
                                   [showDropDownButton]="!readOnlyForLinkedFields('TAX', priceLists.data.priceListOrder.toString(), price.data.order.toString())"
                                   valueChangeEvent="blur"
                                   (onValueChanged)="update($event, null, null, 'TAX', priceLists.data.priceListOrder.toString(), price.data.order.toString())">
                    <div slot="content" class="contentSlot">
                      <dx-number-box [(value)]="price.data.tax" [readOnly]="true"></dx-number-box>
                    </div>
                    <dx-validator *ngIf="!templateView" validationGroup="PRICE">
                      <dxi-validation-rule type="async" [validationCallback]="validationService.validateNumberField('TAX', priceLists.data.priceListOrder.toString(), price.data.order.toString())"></dxi-validation-rule>
                    </dx-validator>
                  </dx-autocomplete>
                </div>

              </np-inputControl>
            </div>

          </div>

          <dxi-column dataField="priceFactor" caption="{{ 'Faktor' | translate }}" [allowEditing]="true" cellTemplate="priceFactorTemplate" editCellTemplate="priceFactorTemplate"></dxi-column>
          <div *dxTemplate="let price of 'priceFactorTemplate'">

            <div class="np-field w-100">
              <np-inputControl [(model)]="model"
                               [showLinkButtons]="showLinkButtons"
                               [showPlaceLabel]="price?.data?.priceFactor != null"
                               [suppressLabel]="true"
                               [(selectedElements)]="selectedElements"
                               (elementSelected)="templateSelect($event, priceLists.data.priceListOrder.toString(), price.data.order.toString())"
                               (onValueChanged)="update($event)"
                               field="PRICE_FACTOR"
                               [system]="priceLists.data.priceListOrder.toString()"
                               [element]="price.data.order.toString()"
                               [showDragHandle]="showDragHandle"
                               [dragFilter]="dragFilter"
                               (excelElementDropped)="excelElementDrop($event)">

                <div slot="content" class="contentSlot">
                  <dx-autocomplete [minSearchLength]="0"
                                   [dataSource]="priceFactors"
                                   [showClearButton]="false"
                                   [showDropDownButton]="!readOnlyForLinkedFields('PRICE_FACTOR', priceLists.data.priceListOrder.toString(), price.data.order.toString())"
                                   [(value)]="price.data.priceFactor"
                                   [readOnly]="readOnlyForLinkedFields('PRICE_FACTOR', priceLists.data.priceListOrder.toString(), price.data.order.toString())  || readOnly('priceFactor')"
                                   valueChangeEvent="blur"
                                   (onValueChanged)="update($event, null, null, 'PRICE_FACTOR', priceLists.data.priceListOrder.toString(), price.data.order.toString())">
                    <dx-validator *ngIf="!templateView" validationGroup="PRICE">
                      <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('PRICE_FACTOR', priceLists.data.priceListOrder.toString(), price.data.order.toString())"></dxi-validation-rule>
                    </dx-validator>
                  </dx-autocomplete>
                </div>

              </np-inputControl>
            </div>

          </div>

          <dxi-column dataField="lowerBound" caption="{{ 'Untergrenze' | translate }}" [allowEditing]="true" cellTemplate="priceLowerBoundTemplate" editCellTemplate="priceLowerBoundTemplate"></dxi-column>
          <div *dxTemplate="let price of 'priceLowerBoundTemplate'">

            <div class="np-field w-100">
              <np-inputControl [(model)]="model"
                               [showLinkButtons]="showLinkButtons"
                               [showPlaceLabel]="price?.data?.lowerBound != null"
                               [suppressLabel]="true"
                               [(selectedElements)]="selectedElements"
                               (elementSelected)="templateSelect($event, priceLists.data.priceListOrder.toString(), price.data.order.toString())"
                               (onValueChanged)="update($event)"
                               field="LOWER_BOUND"
                               [system]="priceLists.data.priceListOrder.toString()"
                               [element]="price.data.order.toString()"
                               [showDragHandle]="showDragHandle"
                               [dragFilter]="dragFilter"
                               (excelElementDropped)="excelElementDrop($event)">

                <div slot="content" class="contentSlot">
                  <dx-autocomplete [minSearchLength]="0"
                                   [dataSource]="priceFactors"
                                   [showClearButton]="false"
                                   [showDropDownButton]="!readOnlyForLinkedFields('LOWER_BOUND', priceLists.data.priceListOrder.toString(), price.data.order.toString())"
                                   [(value)]="price.data.lowerBound"
                                   [readOnly]="readOnlyForLinkedFields('LOWER_BOUND', priceLists.data.priceListOrder.toString(), price.data.order.toString())  || readOnly('priceFactors')"
                                   valueChangeEvent="blur"
                                   (onValueChanged)="update($event, null, null, 'LOWER_BOUND', priceLists.data.priceListOrder.toString(), price.data.order.toString())">
                    <dx-validator *ngIf="!templateView" validationGroup="PRICE">
                      <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('LOWER_BOUND', priceLists.data.priceListOrder.toString(), price.data.order.toString())"></dxi-validation-rule>
                    </dx-validator>
                  </dx-autocomplete>
                </div>

              </np-inputControl>
            </div>

          </div>

          <dxi-column dataField="territory" caption="{{ 'Gebiet' | translate }}" [allowEditing]="true" cellTemplate="priceTerritoryTemplate" editCellTemplate="priceTerritoryTemplate"></dxi-column>
          <div *dxTemplate="let price of 'priceTerritoryTemplate'">

            <div class="np-field w-100">
              <np-inputControl [(model)]="model"
                               [showLinkButtons]="showLinkButtons"
                               [showPlaceLabel]="price?.data?.territory?.length > 0"
                               [suppressLabel]="true"
                               [(selectedElements)]="selectedElements"
                               (elementSelected)="templateSelect($event, priceLists.data.priceListOrder.toString(), price.data.order.toString())"
                               (onValueChanged)="update($event)"
                               field="TERRITORY"
                               [system]="priceLists.data.priceListOrder.toString()"
                               [element]="price.data.order.toString()"
                               [showDragHandle]="showDragHandle"
                               [dragFilter]="dragFilter"
                               (excelElementDropped)="excelElementDrop($event)">

                <div slot="content" class="contentSlot">
                  <dx-autocomplete [minSearchLength]="0"
                                   [dataSource]="territories"
                                   [showClearButton]="false"
                                   [showDropDownButton]="!readOnlyForLinkedFields('TERRITORY', priceLists.data.priceListOrder.toString(), price.data.order.toString())"
                                   [(value)]="price.data.territory"
                                   [readOnly]="readOnlyForLinkedFields('TERRITORY', priceLists.data.priceListOrder.toString(), price.data.order.toString())  || readOnly('priceTerritory')"
                                   valueChangeEvent="blur"
                                   (onValueChanged)="update($event, null, null, 'TERRITORY', priceLists.data.priceListOrder.toString(), price.data.order.toString())">
                    <dx-validator *ngIf="!templateView" validationGroup="PRICE">
                      <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('TERRITORY', priceLists.data.priceListOrder.toString(), price.data.order.toString())"></dxi-validation-rule>
                    </dx-validator>
                  </dx-autocomplete>
                </div>

              </np-inputControl>
            </div>

          </div>
        </dx-data-grid>
      </div>
    </div>
  </dx-data-grid>

  <div class="color1Text">
    <div *ngIf="model?.hintsActiv && !validationService.validationResult?.productPriceValid" class="color3Text">
      <table>
        <tr *ngFor="let msg of validationService.validationResult?.priceMessages">
          <td>
            {{ translateMessage(msg) }}
          </td>
        </tr>
      </table>
    </div>


    <dx-tooltip target="#tm1" class="toolTip toolTipField" [(visible)]="v[1]">
      <div *dxTemplate="let data = data of 'content'">
        {{'Der zur Gültigkeit des Preises müssen einige Angaben gemacht werden' | translate }}.
        {{'Zum einen muss die Preisliste ein gültiges Datum haben' | translate }}.
        {{'Zum anderen müssen alle Felder des Preisen mit gültigen Werten versehen sein' | translate }}.
        {{'Beispiele zum jeweiligen Feld finden Sie über den Pfeil im Feld' | translate }}.
      </div>
    </dx-tooltip>

    <div *ngIf="model?.hintsActiv && !validationService.validationResult?.productOrderDetailsValid" (mouseenter)="ttt(2)" (mouseleave)="ttt(2)" id="tm2">{{ 'Das Produkt sollte vollständige Bestelldetails haben' | translate }}.</div>
    <dx-tooltip target="#tm2" class="toolTip toolTipField" [(visible)]="v[2]">
      <div *dxTemplate="let data = data of 'content'">
        {{'Alle Bestelldetailfelder müssen ausgefüllt sein' | translate }}.
        {{'Beispiele zum jeweiligen Feld finden Sie über den Pfeil im Feld' | translate }}.
      </div>
    </dx-tooltip>
  </div>

</dx-validation-group>
